import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid" style={{paddingLeft: '10%', paddingRight: '5%'}}>
            <div className="row">
                <div className="col-md-6">
                    2020 © Random Walk - Ranwalk.com support by Monte Carlo Consulting
                </div>
                <div className="col-md-6">
                    <div className="text-md-right footer-links d-none d-md-block">
                        <a href="javascript: void(0);">About</a>
                        <a href="javascript: void(0);">Support</a>
                        <a href="javascript: void(0);">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    )
  }
}
