import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Line, Bar } from "react-chartjs-2";
import {
	formatBigNum,
	chartBgc,
	chartWatermark,
	chartDataSorter,
	getNextSunday,
} from "../utils";
import moment from "moment";

const SearchChart = ({
	dataFetched,
	isLoading,
	chartData = [],
	graphRef,
	title,
	displayedDates,
	setDisplayedDates,
	volume,
	setVolume,
	selectedDomains,
	startDate,
	endDate,
}) => {
	const [dataDict, setDataDict] = useState({});

	const colors = [
		"#6d0000",
		"#8d3527",
		"#ab5d4c",
		"#c88474",
		"#e4ad9f",
		"#ffd7cc",
	];

	useEffect(() => {
		const loadDisplayData = () => {
			if (chartData.length === 0) return;
			chartData.sort(chartDataSorter);

			let dates = generateWeeks();
			let buffer_start = moment(getNextSunday(startDate)).subtract(2, "M");
			let buffer_end = moment(endDate).add(1, "M");
			dates.unshift(buffer_start.format("YYYY-MM-DD"));
			dates.push(buffer_end.format("YYYY-MM-DD"));

			let collections = {};
			for (const domain of selectedDomains) {
				collections[domain.domain] = new Array(dates.length).fill(0);
			}

			let tmpTotal = new Array(dates.length).fill(0);
			for (let data of chartData) {
				let dateInd = dates.indexOf(data.week);
				let domainInd = data.domain;
				let vol = data.total_volume;
				collections[domainInd][dateInd] += vol;
				tmpTotal[dateInd] += vol;
			}
			setDisplayedDates(dates);
			setVolume(tmpTotal);
			setDataDict(collections);
		};

		const generateWeeks = () => {
			let weeks = [];
			let start = moment(chartData[0].week);
			let end = moment(chartData[chartData.length - 1].week);
			// let start = moment(getNextSunday(startDate));
			// let end = moment(endDate);
			do {
				weeks.push(start.format("YYYY-MM-DD"));
				start.add(7, "d");
			} while (start.isSameOrBefore(end));

			return weeks;
		};

		loadDisplayData();
	}, [dataFetched, chartData]);

	if (isLoading) {
		return <h4 style={{ textAlign: "center" }}>Loading Chart...</h4>;
	} else if (!dataFetched) {
		return "";
	} else {
		return (
			<Col sm={12}>
				<React.Fragment>
					<Line
						ref={graphRef}
						className="history-container"
						height={350}
						data={{
							labels: displayedDates,
							datasets: Object.keys(dataDict).map((domain, index) => {
								return {
									data: dataDict[domain],
									type: "bar",
									label: domain,
									backgroundColor: colors[index % colors.length],
									borderWidth: 1,
								};
							}),
						}}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							scales: {
								xAxes: [
									{
										gridLines: {
											display: false,
										},
										type: "time",
										time: {
											unit: "month",
										},
										ticks: {
											callback: (value) => value.slice(0, -4) + value.slice(-2),
										},
									},
								],
								yAxes: [
									{
										id: "A",
										type: "linear",
										position: "left",
										gridLines: {
											borderDash: [8, 4],
										},
										ticks: {
											callback: (value) => formatBigNum(value),
										},
									},
								],
							},
							title: {
								display: true,
								text: title,
								fontSize: 15,
							},
							legend: {
								display: false,
							},
							tooltips: {
								mode: "index",
								callbacks: {
									label: function (tooltipItem, data) {
										let value = formatBigNum(tooltipItem.yLabel);
										let label =
											data.datasets[tooltipItem.datasetIndex].label || "";
										if (label) {
											label += ": ";
											label += value;
										}
										return label;
									},
								},
							},
						}}
						plugins={[chartBgc, chartWatermark]}
					/>
				</React.Fragment>
			</Col>
		);
	}
};

export default SearchChart;
