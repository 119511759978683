import React, { useState, useEffect } from "react";
import { axios } from "./utils";
import { getLunaUrl, getNumericalHeader } from "./utils";
import { toast } from "react-toastify";
import { Card, CardBody, Table } from "reactstrap";

const EmailfeedOnepager = () => {
	const [feedId, setFeedId] = useState("");
	const [emailfeed, setEmailfeed] = useState({});
	const [sysDiscount, setSysDiscount] = useState({});
	const [isLoading, setLoading] = useState(false);

	const search = async () => {
		if (feedId === "") {
			toast.warn("Feed ID cannot be empty");
			return;
		}
		setLoading(true);
		await axios
			.get(
				getLunaUrl(`v1/system-discount/fetch/?feed_id=${feedId}`),
				getNumericalHeader()
			)
			.then((res) => {
				let cur = {};
				Object.assign(cur, res.data);
				setSysDiscount(cur);
				console.log("sys discount", cur);
			})
			.catch((err) => {
				setSysDiscount({});
			});

		await axios
			.get(getLunaUrl(`v1/emailfeed/data/${feedId}/`), getNumericalHeader())
			.then((res) => {
				let cur = {};
				Object.assign(cur, res.data);
				setEmailfeed(cur);
			})
			.catch((err) => {
				setEmailfeed({});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<h4 className="page-title">Emailfeed Onepager</h4>
			<div className="search-wrapper">
				<div className="searchbox">
					<div class="search_btn" onClick={search}>
						<i class="bi bi-search" style={{ color: "#fff" }}></i>
					</div>
					<input
						type="text"
						value={feedId}
						placeholder="Please Enter a Feed ID"
						onChange={(e) => setFeedId(e.target.value)}
					/>
				</div>
			</div>
			{isLoading ? (
				<div className="loader-wrapper">
					<div className="loader"></div>
				</div>
			) : (
				<div className="emailfeed-wrapper">
					<div className="column">
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">System Discount</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#ffe8cc" }}
								>
									<tbody>
										{Object.keys(sysDiscount).length > 0
											? Object.keys(sysDiscount).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(sysDiscount[k])}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</div>
					<div className="column">
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">company_resume</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 &&
										emailfeed["company_resume"]
											? Object.keys(emailfeed["company_resume"]).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(
																		emailfeed["company_resume"][k]
																	)}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">project</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 && emailfeed["project"]
											? Object.keys(emailfeed["project"]).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(emailfeed["project"][k])}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">emailfeed</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 && emailfeed["in_dt"] ? (
											<tr>
												<td>
													<h5 className="font-14 mb-1">in_dt</h5>
												</td>
												<td>
													<h5 className="font-14 mb-1 font-weight-normal">
														{emailfeed["in_dt"]}
													</h5>
												</td>
											</tr>
										) : (
											""
										)}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</div>
					<div className="column">
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">email_detail</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 &&
										emailfeed["email_detail"]
											? Object.keys(emailfeed["email_detail"]).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(emailfeed["email_detail"][k])}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">deep_discount</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 &&
										emailfeed["deep_discount"]
											? Object.keys(emailfeed["deep_discount"]).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(
																		emailfeed["deep_discount"][k]
																	)}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</div>
					<div className="column">
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">campaign_information</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 &&
										emailfeed["campaign_information"]
											? Object.keys(emailfeed["campaign_information"]).map(
													(k) => {
														return (
															<tr>
																<td>
																	<h5 className="font-14 mb-1">{k}</h5>
																</td>
																<td>
																	<h5 className="font-14 mb-1 font-weight-normal">
																		{JSON.stringify(
																			emailfeed["campaign_information"][k]
																		)}
																	</h5>
																</td>
															</tr>
														);
													}
											  )
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<h4 className="header-title mb-2">harmonic_discount</h4>
								<Table
									hover
									responsive
									className="mb-0 mt-2"
									style={{ backgroundColor: "#e6f2ff" }}
								>
									<tbody>
										{Object.keys(emailfeed).length > 0 &&
										emailfeed["harmonic_discount"]
											? Object.keys(emailfeed["harmonic_discount"]).map((k) => {
													return (
														<tr>
															<td>
																<h5 className="font-14 mb-1">{k}</h5>
															</td>
															<td>
																<h5 className="font-14 mb-1 font-weight-normal">
																	{JSON.stringify(
																		emailfeed["harmonic_discount"][k]
																	)}
																</h5>
															</td>
														</tr>
													);
											  })
											: ""}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</div>
				</div>
			)}
		</div>
	);
};

export default EmailfeedOnepager;
