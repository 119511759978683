import React, { useEffect } from "react";
import DiscountTable from "./DiscountTable";
import "../../staticfiles/css/rule-playground.css";

const RulePlayground = (props) => {
	useEffect(() => {
		document.title = "Rule Playground";
	});
	return (
		<div>
			<h4 className="page-title">System Rule Playground</h4>
			<DiscountTable
				uniqueDomains={props.uniqueDomains}
				tickers={props.tickers}
				loaded={props.loaded}
			/>
		</div>
	);
};

export default RulePlayground;
