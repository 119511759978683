import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
	formatBigNum,
	chartWatermark,
	chartBgc,
	chartDataSorter,
} from "../utils";
import moment from "moment";

const WebCompositeChart = ({
	dataFetched,
	isLoading,
	organicData,
	totalData,
	paidData,
	startDate,
	endDate,
	title,
}) => {
	const [organicList, setOrganic] = useState([]);
	const [totalList, setTotal] = useState([]);
	const [paidList, setPaid] = useState([]);
	const [dates, setDates] = useState([]);

	useEffect(() => {
		const loadDisplayData = () => {
			let tempOrganicData = [];
			let tempTotalData = [];
			let tempPaidData = [];
			let tempDateData = [];

			organicData.sort(chartDataSorter);
			totalData.sort(chartDataSorter);
			paidData.sort(chartDataSorter);

			for (let i = 0; i < organicData.length; i++) {
				if (
					new Date(organicData[i].start_date) >= new Date(startDate) &&
					new Date(organicData[i].end_date) <= new Date(endDate)
				) {
					tempOrganicData.push(organicData[i].entry);
					tempDateData.push(organicData[i].end_date);
				}
			}
			for (let i = 0; i < totalData.length; i++) {
				if (
					new Date(organicData[i].start_date) >= new Date(startDate) &&
					new Date(organicData[i].end_date) <= new Date(endDate)
				) {
					tempTotalData.push(totalData[i].entry);
				}
			}
			for (let i = 0; i < paidData.length; i++) {
				if (
					new Date(paidData[i].start_date) >= new Date(startDate) &&
					new Date(paidData[i].end_date) <= new Date(endDate)
				) {
					tempPaidData.push(paidData[i].entry);
				}
			}
			tempDateData.sort();

			let buffer_start = moment(tempDateData[0]).subtract(14, "d");
			let buffer_end = moment(tempDateData[tempDateData.length - 1]).add(
				14,
				"d"
			);
			tempDateData.unshift(buffer_start.format("YYYY-MM-DD"));
			tempDateData.push(buffer_end.format("YYYY-MM-DD"));
			tempOrganicData.push(null);
			tempTotalData.push(null);
			tempPaidData.push(null);
			tempOrganicData.unshift(null);
			tempTotalData.unshift(null);
			tempPaidData.unshift(null);

			setOrganic(tempOrganicData);
			setTotal(tempTotalData);
			setPaid(tempPaidData);
			setDates(tempDateData);
		};
		loadDisplayData();
	}, [dataFetched, isLoading, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h3>Chart Loading...</h3>;
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Line
					height={300}
					className="history-container"
					data={{
						labels: dates,
						datasets: [
							{
								data: totalList,
								type: "line",
								label: "Total",
								borderColor: "#0000FF",
								pointRadius: 0,
							},
							{
								data: organicList,
								type: "bar",
								label: "Organic",
								backgroundColor: "#00A36C",
							},
							{
								data: paidList,
								type: "bar",
								label: "Paid",
								backgroundColor: "#C70039",
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "month",
									},
									stacked: true,
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									ticks: {
										callback: (value) => formatBigNum(value),
									},
									gridLines: {
										borderDash: [8, 4],
									},
									stacked: true,
								},
							],
						},
						title: {
							display: true,
							text: title,
							fontSize: 15,
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(WebCompositeChart);
