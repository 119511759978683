import React, { useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Dropdown.Toggle
		variant="outline-dark"
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</Dropdown.Toggle>
));

export const CustomMenu = React.forwardRef(
	({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
		const [value, setValue] = useState("");

		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				<FormControl
					autoFocus
					style={{ margin: "10px auto" }}
					placeholder="Type to filter..."
					onChange={(e) => setValue(e.target.value)}
					value={value}
				/>
				<ul className="list-unstyled">
					{React.Children.toArray(children).filter(
						(child) =>
							!value ||
							child.props.children.toLowerCase().startsWith(value.toLowerCase())
					)}
				</ul>
			</div>
		);
	}
);
