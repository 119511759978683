import React from "react";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import moment from "moment";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import CsvDownloader from "react-csv-downloader";
import { getLunaUrl, getNumericalHeader, rounding, axios } from "../utils";
import "../../staticfiles/css/billboard.css";

const headers = [
	{
		id: "ticker",
		displayName: "Symbol",
	},
	{
		id: "latest_end_date",
		displayName: "Last Update",
	},
	{
		id: "brand",
		displayName: "Brand",
	},
	{
		id: "category",
		displayName: "Category",
	},
	{
		id: "Total Raw Volume",
		displayName: "Total Raw Volume",
	},
	{
		id: "Current Index (2w MA)",
		displayName: "Current Index (2w MA)",
	},
	{
		id: "Prior Year Index (2w MA)",
		displayName: "Prior Year Index (2w MA)",
	},
	{
		id: "YoY",
		displayName: "YoY (%)",
	},
	{
		id: "week ago YoY",
		displayName: "week ago YoY (%)",
	},
	{
		id: "WoW change",
		displayName: "WoW change (%)",
	},
	{
		id: "Two Years ago Index (2w MA)",
		displayName: "Two Years ago Index (2w MA)",
	},
	{
		id: "Yo2Y",
		displayName: "Yo2Y (%)",
	},
];

const BillboardRankingChart = ({ dataFetched, weekList }) => {
	const [displayData, setDisplayData] = useState([]);
	const [downloadData, setDownloadData] = useState([]);
	const [displayWeekList, setDisplayWeekList] = useState([]);
	const [displayTimeList, setDisplayTimeList] = useState([]);
	const [ignoreList, setIgnoreList] = useState({});
	const [selectedWeek, setSelectedWeek] = useState("");
	const [selectedTimeId, setSelectedTimeId] = useState(-1);
	const [billboardCacheTime, setBillboardCacheTime] = useState("");

	const columns = [
		{
			dataField: "ticker",
			text: "Symbol",
		},
		{
			dataField: "latest_end_date",
			text: "Last Update",
		},
		{
			dataField: "brand",
			text: "Brand",
		},
		{
			dataField: "category",
			text: "Category",
		},
		{
			dataField: "Total Raw Volume",
			text: "Total Raw Volume",
			formatter: (cell, row) => {
				return cell.toLocaleString();
			},
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort(
						(a, b) => a["Total Raw Volume"] - b["Total Raw Volume"]
					);
				} else {
					tempData.sort(
						(a, b) => b["Total Raw Volume"] - a["Total Raw Volume"]
					);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "Current Index (2w MA)",
			text: "Current Index (2w MA)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort(
						(a, b) => a["Current Index (2w MA)"] - b["Current Index (2w MA)"]
					);
				} else {
					tempData.sort(
						(a, b) => b["Current Index (2w MA)"] - a["Current Index (2w MA)"]
					);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "Prior Year Index (2w MA)",
			text: "Prior Year Index (2w MA)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort(
						(a, b) =>
							a["Prior Year Index (2w MA)"] - b["Prior Year Index (2w MA)"]
					);
				} else {
					tempData.sort(
						(a, b) =>
							b["Prior Year Index (2w MA)"] - a["Prior Year Index (2w MA)"]
					);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "YoY",
			text: "YoY (%)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort((a, b) => a.YoY - b.YoY);
				} else {
					tempData.sort((a, b) => b.YoY - a.YoY);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "week ago YoY",
			text: "week ago YoY (%)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort((a, b) => a["week ago YoY"] - b["week ago YoY"]);
				} else {
					tempData.sort((a, b) => b["week ago YoY"] - a["week ago YoY"]);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "WoW change",
			text: "WoW change (%)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort((a, b) => a["WoW change"] - b["WoW change"]);
				} else {
					tempData.sort((a, b) => b["WoW change"] - a["WoW change"]);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "Two Years ago Index (2w MA)",
			text: "Two Years ago Index (2w MA)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort(
						(a, b) =>
							a["Two Years ago Index (2w MA)"] -
							b["Two Years ago Index (2w MA)"]
					);
				} else {
					tempData.sort(
						(a, b) =>
							b["Two Years ago Index (2w MA)"] -
							a["Two Years ago Index (2w MA)"]
					);
				}
				setDownloadData(tempData);
			},
		},
		{
			dataField: "Yo2Y",
			text: "Yo2Y (%)",
			sort: true,
			onSort: (field, order) => {
				let tempData = downloadData;
				if (order === "asc") {
					tempData.sort((a, b) => a["Yo2Y"] - b["Yo2Y"]);
				} else {
					tempData.sort((a, b) => b["Yo2Y"] - a["Yo2Y"]);
				}
				setDownloadData(tempData);
			},
		},
	];

	const getCacheDateData = async (week) => {
		setSelectedWeek(week);
		let tempWeek = moment(week);
		tempWeek.add(1, "d");

		await axios
			.get(
				getLunaUrl(
					`v1/email-index/ignore/?date=${tempWeek.format("YYYY-MM-DD")}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				let tempList = ignoreList;
				tempList[response.data.date] = response.data.data;
				setIgnoreList(tempList);
			});

		let end_date = moment(tempWeek).add(6, "d");
		await axios
			.get(
				getLunaUrl(
					`v1/email-index/data_by_rank_delta/?start=${tempWeek.format(
						"YYYY-MM-DD"
					)}&end=${end_date.format("YYYY-MM-DD")}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				let tempCacheList = [];
				for (let i = 0; i < response.data.length; i++) {
					tempCacheList.unshift({
						value: response.data[i].id,
						label: response.data[i].date.substring(0, 16),
					});
				}
				setDisplayTimeList(tempCacheList);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getRawData = async () => {
		let temp = [];
		let categories = [];
		let cate_string = "";
		let topData = downloadData.slice(0, 30);
		for (let i = 0; i < topData.length; i++) {
			let domain_cate = topData[i].domain + "-" + topData[i].category;
			if (!categories.includes(domain_cate)) {
				categories.push(domain_cate);
				cate_string += domain_cate + ",";
			}
		}

		await axios
			.get(
				getLunaUrl(
					`v1/email-index/bulk_download_csv/?domain_categories=${cate_string}&start=2018-01-01&end=2025-01-01`
				),
				getNumericalHeader()
			)
			.then((response) => {
				temp = response.data.split("\n");
			})
			.catch((err) => {
				console.log(err);
			});

		for (let i = 0; i < temp.length; i++) {
			temp[i] = temp[i].split(",");
		}
		return temp;
	};

	const getRawIndexData = async () => {
		let temp = [];
		let categories = [];
		let cate_string = "";
		let topData = downloadData.slice(0, 30);
		for (let i = 0; i < topData.length; i++) {
			let domain_cate = topData[i].domain + "-" + topData[i].category;
			if (!categories.includes(domain_cate)) {
				categories.push(domain_cate);
				cate_string += domain_cate + ",";
			}
		}

		await axios
			.get(
				getLunaUrl(
					`v1/email-index/bulk_download_csv_ma/?domain_categories=${cate_string}&start=2018-01-01&end=2025-01-01`
				),
				getNumericalHeader()
			)
			.then((response) => {
				temp = response.data.split("\n");
			})
			.catch((err) => {
				console.log(err);
			});

		for (let i = 0; i < temp.length; i++) {
			temp[i] = temp[i].split(",");
		}
		return temp;
	};

	const handleCacheTimeSelect = async (v) => {
		setBillboardCacheTime(v.label.replace(":", "."));
		let value = v.value;
		setSelectedTimeId(value);
		let tempData = [];

		await axios
			.get(
				getLunaUrl(`v1/email-index/data_by_rank_delta/?id=${value}&detail=1`),
				getNumericalHeader()
			)
			.then((response) => {
				tempData = response.data[0]["data"];
				for (let i = 0; i < tempData.length; i++) {
					tempData[i]["YoY"] *= 100;
					tempData[i]["week ago YoY"] *= 100;
					tempData[i]["Yo2Y"] *= 100;
					tempData[i]["WoW change"] *= 100;

					tempData[i]["YoY"] = rounding(tempData[i]["YoY"], 2);
					tempData[i]["Yo2Y"] = rounding(tempData[i]["Yo2Y"], 2);
					tempData[i]["WoW change"] = rounding(tempData[i]["WoW change"], 2);
					tempData[i]["week ago YoY"] = rounding(
						tempData[i]["week ago YoY"],
						2
					);
					tempData[i]["Current Index (2w MA)"] = rounding(
						tempData[i]["Current Index (2w MA)"],
						2
					);
					tempData[i]["Prior Year Index (2w MA)"] = rounding(
						tempData[i]["Prior Year Index (2w MA)"],
						2
					);
					tempData[i]["Two Years ago Index (2w MA)"] = rounding(
						tempData[i]["Two Years ago Index (2w MA)"],
						2
					);

					tempData[i]["latest_end_date"] = tempData[i][
						"latest_end_date"
					].substring(0, 10);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		setDisplayData(tempData);
		let tempDownload = tempData.filter(
			(elem) => !ignoreList[selectedWeek].includes(elem.id)
		);

		setDownloadData(tempDownload);
	};

	const updateIgnoreList = async (row, isSelect) => {
		let currentIgnore = ignoreList;
		if (isSelect) {
			currentIgnore[selectedWeek].push(row.id);
		} else {
			currentIgnore[selectedWeek] = currentIgnore[selectedWeek].filter(
				(item) => item !== row.id
			);
		}
		setIgnoreList(currentIgnore);
		await axios
			.post(
				getLunaUrl(`v1/email-index/ignore/`),
				{ date: selectedWeek, data: currentIgnore[selectedWeek] },
				getNumericalHeader()
			)
			.then((response) => {})
			.catch((err) => {
				console.log(err);
			});
	};

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
		hideSelectAll: true,
		style: { textDecoration: "line-through", backgroundColor: "#d3d3d3" },
		selected: ignoreList[selectedWeek],
		onSelect: (row, isSelect, rowIndex) => {
			updateIgnoreList(row, isSelect);
		},
		selectionRenderer: ({ checked, disabled }) => {
			return (
				<div className="custom-control custom-checkbox">
					<input
						type="checkbox"
						className="custom-control-input"
						checked={checked}
						disabled={disabled}
						onChange={() => {}}
					/>
					<label
						className="custom-control-label"
						onClick={(e) => {
							e.preventDefault();
						}}
					></label>
				</div>
			);
		},
		selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
			return (
				<div className="custom-control custom-checkbox select-header">
					<input
						type="checkbox"
						className="custom-control-input"
						id="customCheck1"
						ref={(input) => {
							if (input) input.indeterminate = indeterminate;
						}}
						{...rest}
						onChange={() => {}}
					/>
					<label
						className="custom-control-label"
						htmlFor="customCheck1"
						onClick={(e) => {
							e.preventDefault();
						}}
					></label>
				</div>
			);
		},
	};

	useEffect(() => {
		const formatData = async () => {
			let tempWeekList = [];
			for (let i = 0; i < weekList.length; i++) {
				tempWeekList.unshift({ value: weekList[i], label: weekList[i] });
			}

			let tempIgnoreList = {};
			for (let i = 0; i < weekList.length; i++) {
				tempIgnoreList[weekList[i]] = [];
			}

			setDisplayWeekList(tempWeekList);
			setIgnoreList(tempIgnoreList);
		};
		formatData();
	}, [weekList]);

	if (!dataFetched) {
		return <div></div>;
	}

	return (
		<Card>
			<CardBody>
				<Row>
					<Col md={6}>
						<p className="mb-1 mt-3 font-weight-bold text-muted">Select Week</p>
						<Select
							className="react-select"
							classNamePrefix="react-select"
							options={displayWeekList}
							onChange={(value) => getCacheDateData(value.value)}
						></Select>
					</Col>
					<Col md={6}>
						<p className="mb-1 mt-3 font-weight-bold text-muted">
							Select Cache Date
						</p>
						<Select
							className="react-select"
							classNamePrefix="react-select"
							defaultValue={displayTimeList[0]}
							options={displayTimeList}
							onChange={(value) => handleCacheTimeSelect(value)}
						></Select>
					</Col>
				</Row>
			</CardBody>

			<CardBody>
				<h4 className="header-title">Billboard Ranking Chart</h4>
				<p className="text-muted font-14 mb-4">
					Select the rows that you want to exclude from the chart
				</p>
				<Dropdown
					style={{
						width: "10%",
					}}
				>
					<Dropdown.Toggle style={{ width: "100%" }} variant="outline-primary">
						<i className="dripicons-cloud-download"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu style={{ width: "100%" }}>
						<Dropdown.Item>
							<CsvDownloader
								datas={downloadData}
								text="Billboard"
								columns={headers}
								filename={"billboard-table-" + billboardCacheTime}
								extension=".csv"
								className="download-dropdown-item"
							/>
						</Dropdown.Item>
						<Dropdown.Item>
							<CsvDownloader
								datas={getRawData}
								text="Raw Volume"
								filename={"billboard-top30-rawVolume-" + billboardCacheTime}
								extension=".csv"
								className="download-dropdown-item"
							/>
						</Dropdown.Item>
						<Dropdown.Item>
							<CsvDownloader
								datas={getRawIndexData}
								text="Current Index"
								filename={"billboard-top30-currentIndex-" + billboardCacheTime}
								extension=".csv"
								className="download-dropdown-item"
							/>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				<BootstrapTable
					bootstrap4
					keyField="id"
					data={displayData}
					columns={columns}
					defaultSorted={[
						{
							dataField: "Current Index (2w MA)",
							order: "desc",
						},
					]}
					bordered={false}
					selectRow={selectRow}
					pagination={paginationFactory({ sizePerPage: 10, showTotal: true })}
					wrapperClasses="table-responsive"
				/>
				{/* <ToolkitProvider keyField="ticker-domain-category" data={displayData[selectedType]} columns={columns} columnToggle>
                    {props => (
                        <div>
                            <CustomToggleList {...props.columnToggleProps} />
                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                pagination={paginationFactory({ sizePerPage: 10, showTotal: true})}
                                wrapperClasses="table-responsive"
                            />
                        </div>
                    )}
                </ToolkitProvider> */}
			</CardBody>
		</Card>
	);
};

export default BillboardRankingChart;
