import React, { useState } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { getLunaUrl, getNumericalHeader, axios } from "../utils";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const DataTable = ({ dataFetching, totalData }) => {
	const columns = [
		{
			dataField: "user",
			text: "User",
			sort: true,
		},
		{
			dataField: "type",
			text: "Type",
			sort: true,
		},
		{
			dataField: "time",
			text: "Time",
			sort: true,
		},
		{
			dataField: "user",
			text: "User",
			sort: true,
		},
		{
			dataField: "feed_ids",
			text: "Feed Ids",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "domain",
			text: "Domain",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "min_discount_pct",
			text: "Min %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "max_discount_pct",
			text: "Max %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "effective_discount_pct",
			text: "Effective %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "state_discount",
			text: "State",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "product_type",
			text: "Product",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "is_storewide",
			text: "Storewide",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "is_steep",
			text: "Steep",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "is_significant",
			text: "Significant",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "holiday_tag",
			text: "Holiday",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "free_ship_condition",
			text: "Free Ship",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "db_actions",
			hidden: true,
		},
	];

	const revert = async (id) => {
		if (
			!window.confirm(
				"Are You Sure that you want to revert this operation? All Emails involved in this operation will be rolled back to their states prior to this operation and thus discarding all consequent changes. The Revert Operation cannot be undone!"
			)
		) {
			return;
		}
		await axios
			.get(
				getLunaUrl(`v1/emailfeed/log/revert/?action_id=${id}`),
				getNumericalHeader()
			)
			.then((res) => {
				if (res.data.success) {
					toast.success(res.data.message);
				} else {
					toast.warn(res.data.message);
				}
			})
			.catch((err) => {
				toast.error("An error occured, please try again");
			});
	};

	if (dataFetching) {
		return (
			<Card>
				<CardBody>
					<h4>Fetching Data...</h4>
				</CardBody>
			</Card>
		);
	} else if (totalData.length === 0) {
		return (
			<Card>
				<CardBody>
					<h4 className="header-title">Currently No Log</h4>
				</CardBody>
			</Card>
		);
	}

	const expandRow = {
		renderer: (row) => {
			return (
				<>
					<div>
						<Button variant="danger" onClick={() => revert(row.action_id)}>
							Revert
						</Button>
					</div>
					<br />
					<Table striped bordered si ze="sm">
						<tbody>
							<tr>
								<th>Feeds</th>
							</tr>
							<tr>
								<table>
									<tbody>
										<tr>
											<td>Feed Id</td>
											<td>Subject</td>
											<td>Date</td>
											<td>Image</td>
										</tr>
										{row.feeds.map((feed) => (
											<tr>
												<td>{feed.email_id}</td>
												<td>{feed.subject}</td>
												<td>{feed.start_date}</td>
												<td>
													<a href={feed.image_url} target="_blank">
														<svg
															t="1638500915285"
															class="icon"
															viewBox="0 0 1024 1024"
															version="1.1"
															xmlns="http://www.w3.org/2000/svg"
															p-id="3033"
															width="20"
															height="20"
														>
															<path
																d="M512 170.666667C298.666667 170.666667 116.48 303.36 42.666667 490.666667 116.48 677.973333 298.666667 810.666667 512 810.666667s395.52-132.693333 469.333333-320C907.52 303.36 725.333333 170.666667 512 170.666667z m0 533.333333c-117.76 0-213.333333-95.573333-213.333333-213.333333s95.573333-213.333333 213.333333-213.333334 213.333333 95.573333 213.333333 213.333334-95.573333 213.333333-213.333333 213.333333z m0-341.333333c-70.826667 0-128 57.173333-128 128s57.173333 128 128 128 128-57.173333 128-128-57.173333-128-128-128z"
																fill="#727cf5"
																p-id="3034"
															></path>
														</svg>
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</tr>
							<tr>
								<th>{row.db_actions.length} Operation(s)</th>
							</tr>
							<tr>
								{row.db_actions.map((act) => (
									<td>
										<div dangerouslySetInnerHTML={{ __html: act.info }}></div>
									</td>
								))}
							</tr>
						</tbody>
					</Table>
				</>
			);
		},
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	const { SearchBar } = Search;

	return (
		<div>
			<Card>
				<CardBody>
					<ToolkitProvider
						bootstrap4
						keyField="action_id"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									expandRow={expandRow}
									rowStyle={{ fontWeight: "bold" }}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default DataTable;
