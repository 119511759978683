import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Dropdown, Alert } from "react-bootstrap";
import { Row, Col, Card, CardBody } from "reactstrap";
import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";
import { getLunaUrl, getNumericalHeader, axios, rounding } from "../utils";
import { usePapaParse } from "react-papaparse";
import moment from "moment";
import { saveAs } from "file-saver";
import BeatLoader from "react-spinners/BeatLoader";

import Treemap from "./Treemap";
import BillboardRankingChart from "../EmailTrendChart/BillboardRankingChart";

let prefix = window.location.protocol;
var corsAnywhereUrl = prefix + "//cors.ranwalk.goldbeyond.com/"
const api_key = "patjmq0m0AcDAkw9d.16ef1ef1101fc26a23f1f17f38d9721e18752cac42f75208c7f7fa066856d0ff";
const table_key = "apprWV74pA4YbwycG";

const Billboard = ({ uniqueDomains }) => {
	const { readString } = usePapaParse();
	const [allTreemapData, setAllTreemapData] = useState([]);
	const [cacheTimeList, setCacheTimeList] = useState([]);
	const [selectedWeek, setSelectedWeek] = useState("");
	const [selectedCacheTime, setSelectedCacheTime] = useState({ value: 0 });
	const [displayField, setDisplayField] = useState("delta_wow_promo_vol_ratio");
	const [treemapFetching, setTreemapFetching] = useState(false);

	const [indexFields, setIndexFields] = useState([]);
	const [billboardDataFetched, setBillboardDataFetched] = useState(false);
	const [indexDownloading, setIndexDownloading] = useState(false);
	const [ensembleIndexDownloading, setEnsembleIndexDownloading] =
		useState(false);
	const [billboardTimeList, setBillboardTimeList] = useState([]);

	function generateWeeks(num) {
		const currentDate = moment();
		const pastSundays = [];
		for (let i = 0; i < num; i++) {
			const sunday = currentDate.clone().subtract(i, "weeks").startOf("week");
			pastSundays.push(sunday.format("YYYY-MM-DD"));
		}
		weekList = [];
		for (let i = 0; i < pastSundays.length; i++) {
			weekList.push({ value: pastSundays[i], label: pastSundays[i] });
		}
	}

	let weekList = [];
	generateWeeks(8);

	const fetchTreemapData = async () => {
		setTreemapFetching(true);
		await axios
			.get(
				getLunaUrl(
					`v1/emailfeed/alerts/?dtype=industry_dump&week_id=${
						selectedWeek || weekList[0].value
					}`
				),
				getNumericalHeader()
			)
			.then((res) => {
				let data = res.data.results;
				data.sort((a, b) => b.data_id - a.data_id);
				let temp = [];
				let timeList = [];
				let i = 0;
				for (const entry of data) {
					readString(entry.data, {
						header: true,
						complete: (res) => {
							let time = entry.in_dt.substring(0, 19);
							temp = [...temp, { data: res.data, time }];
							timeList.push({ value: i, label: time });
						},
					});
					i++;
				}
				setAllTreemapData(temp);
				setCacheTimeList(timeList);
				setSelectedCacheTime(timeList[0] || { value: 0 });
			})
			.catch((err) => {})
			.finally(() => {
				setTreemapFetching(false);
			});
	};

	const getAirTableData = async () => {
		var fullList = [];
		await axios
			.get(
				`${corsAnywhereUrl}https://api.airtable.com/v0/${table_key}/billboard_list`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
			)
			.then(async (response) => {
				for (let r of response.data.records) {
					fullList.push(r.fields);
				}
				if (response.data.offset) {
					await getNextPageAirTable(response.data.offset, fullList);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		let toberemoved = fullList.filter((e) => e["Symbol"] === "Symbol");
		for (let e of toberemoved) {
			fullList.splice(fullList.indexOf(e), 1);
		}
		setIndexFields(fullList);
	};

	const getNextPageAirTable = async (offset, list) => {
		await axios
      .get(
        `${corsAnywhereUrl}https://api.airtable.com/v0/${table_key}/billboard_list?offset=${offset}`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
      )
			.then(async (response) => {
				for (let r of response.data.records) {
					list.push(r.fields);
				}
				if (response.data.offset) {
					await getNextPageAirTable(response.data.offset, list);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	var JSZip = require("jszip");

	const pivot = (arr) => {
		var mp = new Map();

		function setValue(a, path, val) {
			if (Object(val) !== val) {
				// primitive value
				var pathStr = path.join(".");
				var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
				a[i] = val;
			} else {
				for (var key in val) {
					setValue(a, key === "0" ? path : path.concat(key), val[key]);
				}
			}
			return a;
		}

		var result = arr.map((obj) => setValue([], [], obj));
		return [[...mp.keys()], ...result];
	};

	const toCsv = (arr) => {
		return arr
			.map((row) =>
				row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(",")
			)
			.join("\n");
	};

	const downloadAllIndex = async () => {
		setIndexDownloading(true);
		let zip = new JSZip();
		for (let ind of indexFields) {
			let total = [];
			let ticker = ind["Symbol"];
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind.Domain}&category=${ind.Category}&startDate=2021-10-04`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						let date = moment(data[i].end_date);
						if (date.isAfter("2019-05-01") && date.isBefore("2021-11-08")) {
							let volume =
								(data[i - 1].smoothed_total_volume +
									data[i].smoothed_total_volume) /
								2;
							total.push({
								Ticker: ticker,
								Brand: ind.Brand,
								Category: data[i].category,
								"Current Index": rounding(volume, 2),
								"Week End": date.format("YYYY-MM-DD"),
							});
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let csvContent = toCsv(pivot(total));
			zip.file(
				ticker + "-" + ind.Domain + "-" + ind.Category + ".csv",
				csvContent
			);
		}
		zip.generateAsync({ type: "blob" }).then(function (blob) {
			saveAs(blob, "RandomWalkConfirmationIndices2022.zip");
		});
		setIndexDownloading(false);
	};

	const getAllIndexData = async () => {
		setIndexDownloading(true);
		let total = [];
		for (let ind of indexFields) {
			let ticker = ind["Symbol"];
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind.Domain}&category=${ind.Category}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						let date = moment(data[i].end_date);
						if (date.isAfter("2019-05-01") && date.isBefore("2021-11-08")) {
							let volume =
								(data[i - 1].smoothed_total_volume +
									data[i].smoothed_total_volume) /
								2;
							total.push({
								Ticker: ticker,
								Brand: ind.Brand,
								Category: data[i].category,
								"Current Index": rounding(volume, 2),
								"Week End": date.format("YYYY-MM-DD"),
							});
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		let csvData = total.map((row) => Object.values(row).join(",")).join("\n");
		csvData = Object.keys(total[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "historic-index.csv");
		setIndexDownloading(false);
	};

	const getEnsembleIndexData = async () => {
		setEnsembleIndexDownloading(true);
		let total = [];
		let domains = uniqueDomains
			.filter((d) => d.domain === d.parent_domain)
			.map((d) => d.domain);
		for (let ind of domains) {
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind}&category=ALL`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						total.push({
							Ticker: data[i].ticker,
							Domain: data[i].domain,
							StartDate: data[i].start_date,
							EndDate: data[i].end_date,
							PromotionalVolumeIndex: data[i].adjusted_total_volume,
							DiscountVolumeIndex: data[i].adjusted_is_significant_total_volume,
							NotionalDiscountVolumeIndex:
								data[i].adjusted_harmonic_state_discount_total_volume,
							ZeroPercentVolumeIndex: data[i].adjusted_zero_discount_volume,
							AverageDiscountVolumeIndex: data[i].adjusted_discount_vol,
							SteepDiscountVolumeIndex: data[i].adjusted_is_steep_total_volume,
							EffectiveDiscountIndex: data[i].effective_discount,
							"20PercentileDiscountIndex": data[i].discount_20_percentile,
							"80PercentileDiscountIndex": data[i].discount_80_percentile,
							MaxDiscountIndex: data[i].max_discount,
							CompositePromotionalIndex: data[i].final_index,
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		let csvData = total.map((row) => Object.values(row).join(",")).join("\n");
		csvData = Object.keys(total[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "Promotional-Ensemble-Indices.csv");
		setEnsembleIndexDownloading(false);
	};

	const getBillBoardData = async () => {
		const min_date = moment("2021-10-04");
		let current = moment();
		let diffWeeks = Math.ceil(
			Math.abs(current.diff(min_date)) / (1000 * 60 * 60 * 24) / 7
		);
		let tempWeekList = [];
		for (let i = 0; i < diffWeeks; i++) {
			let tempDate = moment(min_date);
			tempDate.add(i * 7 - 1, "d");
			tempWeekList.push(tempDate.format("YYYY-MM-DD"));
		}
		setBillboardTimeList(tempWeekList);
		setBillboardDataFetched(true);
	};

	useEffect(() => {
		document.title = "Billboard";
		setSelectedWeek(weekList[0].value);
		getAirTableData();
		fetchTreemapData();
		getBillBoardData();
	}, []);

	useEffect(() => {
		fetchTreemapData();
	}, [selectedWeek]);

	return (
		<div>
			<h4 className="page-title">Billboard</h4>
			<div className="card">
				{treemapFetching ? (
					<div className="card-body">
						<h3>Loading Highlights...</h3>
					</div>
				) : (
					<div className="card-body">
						<h2>Industry Highlights</h2>
						<Row>
							<Col md={3}>
								<p className="mb-1 mt-3 font-weight-bold text-muted">
									Time Granularity
								</p>
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										id="dropdown-basic"
										as={CustomToggle}
									>
										{displayField == "delta_yoy_promo_vol_ratio"
											? "yoy"
											: "wow"}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenu} className="task-dropdown">
										<Dropdown.Item
											key={"wow"}
											onClick={() =>
												setDisplayField("delta_wow_promo_vol_ratio")
											}
										>
											wow
										</Dropdown.Item>
										<Dropdown.Item
											key={"yoy"}
											onClick={() =>
												setDisplayField("delta_yoy_promo_vol_ratio")
											}
										>
											yoy
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
							<Col md={3}>
								<p className="mb-1 mt-3 font-weight-bold text-muted">
									Select Week
								</p>
								<Select
									className="react-select"
									classNamePrefix="react-select"
									options={weekList}
									value={{ value: selectedWeek, label: selectedWeek }}
									defaultValue={weekList[0]}
									onChange={(val) => setSelectedWeek(val.value)}
								></Select>
							</Col>
							<Col md={3}>
								<p className="mb-1 mt-3 font-weight-bold text-muted">
									Select Cache Date
								</p>
								<Select
									className="react-select"
									classNamePrefix="react-select"
									options={cacheTimeList}
									defaultValue={cacheTimeList[0]}
									value={selectedCacheTime}
									onChange={(val) => setSelectedCacheTime(val)}
								></Select>
							</Col>
						</Row>
						<br />
						{allTreemapData.length > 0 ? (
							<Treemap
								chartData={allTreemapData}
								deltaField={displayField}
								cacheTime={selectedCacheTime.value}
							></Treemap>
						) : (
							<h2>Currently no data for this week</h2>
						)}
					</div>
				)}
			</div>
			<div className="card">
				{!billboardDataFetched ? (
					<div className="card-body">
						<h3>Loading Ranking...</h3>
					</div>
				) : (
					<div className="card-body">
						<h2>Billboard Ranking</h2>
						<Alert variant="primary">
							<a
								href="https://goldbeyond.atlassian.net/wiki/spaces/MCC/pages/153092097/Billboard+Download+Contents"
								target="_blank"
								rel="noreferrer"
							>
								Documentation
							</a>
							&nbsp;for downloading buttons
						</Alert>
						<Dropdown className="billboard-download">
							<Dropdown.Toggle
								style={{ width: "100%" }}
								variant="outline-primary"
							>
								<i className="dripicons-cloud-download"></i> Historic Index
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "100%" }}>
								<Dropdown.Item onClick={downloadAllIndex}>
									as one zip
								</Dropdown.Item>
								<Dropdown.Item
									onClick={getAllIndexData}
									disabled={indexDownloading}
								>
									as csv
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown className="billboard-download" style={{ width: "25%" }}>
							<Dropdown.Toggle
								style={{ width: "100%" }}
								variant="outline-primary"
							>
								<i className="dripicons-cloud-download"></i> All Promotional
								Ensemble Indices
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ width: "100%" }}>
								<Dropdown.Item
									onClick={getEnsembleIndexData}
									disabled={ensembleIndexDownloading}
								>
									as csv
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						{indexDownloading ? (
							<Row style={{ marginTop: 20 }}>
								downloading in the background
								<BeatLoader
									color="#727cf5"
									loading={indexDownloading}
									size={15}
								/>
							</Row>
						) : (
							""
						)}
						{ensembleIndexDownloading ? (
							<Row
								style={{
									marginTop: 20,
									position: "relative",
									left: "22%",
								}}
							>
								downloading in the background
								<BeatLoader
									color="#727cf5"
									loading={ensembleIndexDownloading}
									size={15}
								/>
							</Row>
						) : (
							""
						)}
						<Row style={{ maxHeight: "1100px", overflowY: "scroll" }}>
							<BillboardRankingChart
								dataFetched={billboardDataFetched}
								weekList={billboardTimeList}
							/>
						</Row>
					</div>
				)}
			</div>
		</div>
	);
};

export default Billboard;
