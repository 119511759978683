import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import "../../staticfiles/css/history.css";
import {
	chartJSGraphDownload,
	chartJSCSVDownload,
	indexColorMapping,
	chartBgc,
	chartWatermark,
} from "../utils";

const HistoryChart = ({
	dataFetched,
	chartData,
	startDate,
	endDate,
	selectedFields,
	selectedCompanies,
	isSmoothed,
	title,
}) => {
	const [displayedData, setDisplayedData] = useState([]);
	const [displayedFields, setDisplayedFields] = useState([]);
	const graphRef = useRef(null);

	const handleGraphDownload = () => {
		let name = "";
		for (const cname of selectedCompanies) {
			name += cname + "_";
		}
		name = name.slice(0, name.length - 1);
		chartJSGraphDownload(
			graphRef.current.chartInstance,
			`${name}-PromotionalIndex-${
				isSmoothed ? "Smoothed" : "Stable"
			}-${endDate}.png`
		);
	};

	const handleCSVDownload = () => {
		let datasets = [];
		const chartDatasets = graphRef.current.chartInstance.data.datasets;

		let dates = displayedData[0].data.map((data) => {
			return data.end_date;
		});
		dates = ["Dates", ...dates];

		for (let i = 0; i < chartDatasets.length; i++) {
			datasets.push([chartDatasets[i].label, ...chartDatasets[i].data]);
		}

		datasets = [dates, ...datasets];
		let name = "";
		for (const cname of selectedCompanies) {
			name += cname + "_";
		}
		name = name.slice(0, name.length - 1);
		chartJSCSVDownload(
			datasets,
			`${name}-PromotionalIndex-${
				isSmoothed ? "Smoothed" : "Stable"
			}-${endDate}.csv`
		);
	};

	useEffect(() => {
		let resData = [];
		for (let i = 0; i < chartData.length; i++) {
			resData.push({
				domain: chartData[i].domain,
				ticker: chartData[i].ticker,
				name: chartData[i].name,
				data: chartData[i].data.filter((oneData) => {
					if (
						new Date(oneData.start_date) >= new Date(startDate) &&
						new Date(oneData.end_date) <= new Date(endDate)
					) {
						return oneData;
					}
				}),
			});
		}

		setDisplayedData(resData);
	}, [chartData, startDate, endDate]);

	useEffect(() => {
		let newFields = [];
		for (let i = 0; i < displayedData.length; i++) {
			for (let j = 0; j < selectedFields.length; j++) {
				newFields.push({
					data: displayedData[i].data.map((data) => data[selectedFields[j].id]),
					label:
						displayedData[i].name +
						" (" +
						displayedData[i].ticker +
						" - " +
						displayedData[i].domain +
						") " +
						"'s " +
						selectedFields[j].formattedName,
					borderColor: indexColorMapping[i + 1][selectedFields[j].id],
				});
			}
		}

		setDisplayedFields(newFields);
	}, [selectedFields, displayedData]);

	if (!dataFetched) {
		return (
			<div style={{ textAlign: "center" }}>
				<h4>Loading...</h4>
			</div>
		);
	}

	if (displayedData.length === 0) {
		return <div></div>;
	}
	if (displayedFields.length === 0) {
		return (
			<Container>
				<Row>
					<Col sm={12}>
						<h3>Select one or more fields to see the history</h3>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Row style={{ marginTop: 20, marginLeft: 30 }}>
					<Col sm={12}>
						<Dropdown style={{ width: "10%" }}>
							<Dropdown.Toggle>
								<i className="dripicons-cloud-download"></i>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={handleGraphDownload}>
									as Image
								</Dropdown.Item>

								<Dropdown.Item onClick={handleCSVDownload}>
									as CSV
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
				<Line
					ref={graphRef}
					className="history-container"
					data={{
						labels: displayedData[0].data.map((data) => {
							return data.end_date;
						}),
						datasets: displayedFields,
					}}
					options={{
						responsive: true,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "month",
									},
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									ticks: {
										callback: function (value, index, values) {
											if (value > 1000000000) {
												return value / 1000000000 + "B";
											} else if (value > 1000000) {
												return value / 1000000 + "M";
											} else if (value > 1000) {
												return value / 1000 + "K";
											} else {
												return value;
											}
										},
									},
									gridLines: {
										borderDash: [8, 4],
									},
								},
							],
						},
						title: {
							display: true,
							text: title,
							fontSize: 15,
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default HistoryChart;
