import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
	formatBigNum,
	chartWatermark,
	chartBgc,
	capitalize,
	chartDataSorter,
} from "../utils";

const HarmonicDiscountChart = ({
	dataFetched,
	isLoading,
	chartData,
	startDate,
	endDate,
	graphRef,
	tickerName,
	domainName,
	categoryName,
	setSelectedDate,
}) => {
	const [displayedDates, setDisplayedDates] = useState([]);
	const [effectiveDiscount, setEffectiveDiscount] = useState([]);
	const [storewideTotalVolume, setStoreWideTotalVolume] = useState([]);
	const [significantTotalVolume, setSignificantTotalVolume] = useState([]);
	const [steepTotalVolume, setSteepTotalVolume] = useState([]);
	const [maxDiscount, setMaxDiscount] = useState([]);
	const [adjustedDiscountVol, setAdjustedDiscountVol] = useState([]);
	const [adjustedTotalVol, setAdjustedTotalVol] = useState([]);
	const [adjustedBadVol, setAdjustedBadVol] = useState([]);
  const [adjustedSpamPercent, setAdjustSpamPercent] = useState([]);
  const [adjustedDeletionPercent, setAdjustedDeletionPercent] = useState([]);
  const [adjustedReadPercent, setAdjustedReadPercent] = useState([]);

	// whether display legend & its corresponding data
	const [legendCache, setLegendCache] = useState({
		"Effective discount": true,
		"Max Discount": true,
		"Total Volume": true,
		"Discount Volume": true,
		"# of Significant Discounts": false,
		"# of Steep Discounts": true,
		"# of Storewide Discounts": true,
		"# of Desperation Discounts": true,
    "Spam%": false,
    "Deletion%": false
	});

	const loadDisplayData = () => {
		let tempEffectiveData = [];
		let tempStorewideData = [];
		let tempSignificantData = [];
		let tempSteepData = [];
		let tempMaxdiscountData = [];
		let tempDateData = [];
		let tempAdjustedDiscVolData = [];
		let tempAdjustedTotalVolData = [];
		let tempAdjustedBadVolData = [];

    let tempAdjustedSpamPercent= [];
    let tempAdjustedDeletionPercent = [];
    let tempAdjustedReadPercent = [];

		chartData.sort(chartDataSorter);

		for (let i = 0; i < chartData.length; i++) {
			if (
				new Date(chartData[i].start_date) >= new Date(startDate) &&
				new Date(chartData[i].end_date) <= new Date(endDate)
			) {
				tempDateData.push(chartData[i].end_date);
				tempEffectiveData.push(chartData[i].effective_discount);
				tempStorewideData.push(
					chartData[i].adjusted_harmonic_is_storewide_total_volume
				);
				tempSignificantData.push(
					chartData[i].adjusted_harmonic_is_significant_total_volume
				);
				tempSteepData.push(
					chartData[i].adjusted_harmonic_is_steep_total_volume
				);
				tempMaxdiscountData.push(chartData[i].max_discount);
				tempAdjustedDiscVolData.push(chartData[i].adjusted_discount_vol);
				tempAdjustedTotalVolData.push(chartData[i].adjusted_total_volume);
				tempAdjustedBadVolData.push(
					chartData[i].adjusted_badwords_total_volume
				);
        tempAdjustedSpamPercent.push(
          chartData[i].adjusted_spam_volume / (chartData[i].adjusted_spam_volume + chartData[i].adjusted_inbox_volume) * 100
        )
        tempAdjustedDeletionPercent.push(
          chartData[i].adjusted_delete_volume / chartData[i].adjusted_total_volume * 100
        )
        tempAdjustedReadPercent.push(
          chartData[i].adjusted_read_volume / chartData[i].adjusted_total_volume * 100
        )
			}
		}

		setDisplayedDates(tempDateData);
		setEffectiveDiscount(tempEffectiveData);
		setStoreWideTotalVolume(tempStorewideData);
		setSignificantTotalVolume(tempSignificantData);
		setMaxDiscount(tempMaxdiscountData);
		setSteepTotalVolume(tempSteepData);
		setAdjustedDiscountVol(tempAdjustedDiscVolData);
		setAdjustedTotalVol(tempAdjustedTotalVolData);
		setAdjustedBadVol(tempAdjustedBadVolData);
    setAdjustSpamPercent(tempAdjustedSpamPercent);
    setAdjustedDeletionPercent(tempAdjustedDeletionPercent);
    setAdjustedReadPercent(tempAdjustedReadPercent);
	};

	useEffect(() => {
		loadDisplayData();
	}, [dataFetched, chartData, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h4 style={{ textAlign: "center" }}>Loading Chart...</h4>;
	}

	return (
		<Col sm={12} style={{ height: 600 }}>
			<React.Fragment>
				<Line
					ref={graphRef}
					className="history-container"
					data={{
						labels: displayedDates,
						datasets: [
							{
								data: effectiveDiscount,
								type: "line",
								label: "Effective Discount",
								borderColor: "#36a2eb",
								yAxisID: "B",
								fill: false,
								borderWidth: 3,
								hidden: legendCache["Effective discount"],
							},
							{
								data: maxDiscount,
								type: "line",
								label: "Max Discount",
								borderColor: "#808080",
								yAxisID: "B",
								fill: false,
								borderWidth: 2,
								hidden: legendCache["Max Discount"],
							},
              {
                data: adjustedSpamPercent,
                type: "line",
								label: "Spam%",
								borderColor: "#FF7F50",
								yAxisID: "B",
								fill: false,
								borderWidth: 3,
								hidden: legendCache["Spam%"],
              },
              {
                data: adjustedDeletionPercent,
                type: "line",
								label: "Deletion%",
								borderColor: "#B22222",
								yAxisID: "B",
								fill: false,
								borderWidth: 3,
								hidden: legendCache["Deletion%"],
              },
              {
                data: adjustedReadPercent,
                type: "line",
								label: "Read%",
								borderColor: "#00b300",
								yAxisID: "B",
								fill: false,
								borderWidth: 3,
								hidden: legendCache["Deletion%"],
              },
							{
								data: adjustedTotalVol,
								type: "bar",
								label: "Total Volume",
								backgroundColor: "#a51a56a4",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["Total Volume"],
							},
							{
								data: adjustedDiscountVol,
								type: "bar",
								label: "Discount Volume",
								backgroundColor: "#4bc0c0",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["Discount Volume"],
							},
							{
								data: significantTotalVolume,
								type: "bar",
								label: "# of Significant Discounts",
								backgroundColor: "#ff9f40",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["# of Significant Discounts"],
							},
							{
								data: steepTotalVolume,
								type: "bar",
								label: "# of Steep Discounts",
								backgroundColor: "#9966ff",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["# of Steep Discounts"],
							},
							{
								data: storewideTotalVolume,
								type: "bar",
								label: "# of Storewide Discounts",
								backgroundColor: "#c9cbcf",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["# of Storewide Discounts"],
							},
							{
								data: adjustedBadVol,
								type: "bar",
								label: "# of Desperation Discounts",
								backgroundColor: "#fa4d56",
								yAxisID: "A",
								borderWidth: 1,
								hidden: legendCache["# of Desperation Discounts"],
							},
						],
					}}
					onElementsClick={(elem) => {
						if (elem[0]) {
							setSelectedDate(
								graphRef.current.props.data.labels[elem[0]._index]
							);
						}
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						title: {
							display: true,
							text: `${domainName} (${tickerName}) ${capitalize(
								categoryName
							)} Harmonic Discount Chart`,
							fontSize: 15,
						},
						scales: {
							xAxes: [
								{
									offset: true,
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "week",
									},
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "right",
									ticks: {
										callback: (value) => formatBigNum(value),
									},
									gridLines: {
										borderDash: [8, 4],
									},
								},
								{
									id: "B",
									type: "linear",
									position: "left",
									ticks: {
										callback: function (value) {
											return value + "%";
										},
									},
									gridLines: {
										borderDash: [8, 4],
									},
									stacked: false,
								},
							],
						},
						legend: {
							onClick: (e, legendItem) => {
								const index = legendItem.datasetIndex;
								const ci = graphRef.current.chartInstance;
								const meta = ci.getDatasetMeta(index);

								const isHidden = legendCache[legendItem.text];
								meta.hidden =
									meta.hidden === null ? !ci.data.datasets[index].hidden : null;
								setLegendCache({
									...legendCache,
									[legendItem.text]: !isHidden,
								});
								ci.update();
							},
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(HarmonicDiscountChart);
