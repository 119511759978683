import React, { Component, useRef, useEffect } from "react";

const { tableau } = window;

function OnePager() {
	const url =
		"https://eu-west-1a.online.tableau.com/t/codatagmbh/views/PromotionalEnsemble_rev/onepager";
	const ref = useRef(null);
	var viz;
	function initViz() {
		viz = new tableau.Viz(ref.current, url, {
			usePublishedSize: true,
			device: "desktop",
		});
	}

	useEffect(() => {
		initViz();
	}, []);

	// function handleDownload() {
	//   window.URL = window.URL || window.webkitURL;

	//   var xhr = new XMLHttpRequest(),
	//   a = document.createElement('a'), file;

	//   xhr.open('GET', 'https://eu-west-1a.online.tableau.com/t/codatagmbh/views/PromotionalEnsemble_Final/onepager.pdf', true);
	//   xhr.responseType = 'blob';
	//   xhr.onload = function () {
	//       file = new Blob([xhr.response], { type : 'application/octet-stream' });
	//       a.href = window.URL.createObjectURL(file);
	//       a.download = 'someName.pdf';  // Set to whatever file name you want
	//       // Now just click the link you created
	//       // Note that you may have to append the a element to the body somewhere
	//       // for this to work in Firefox
	//       a.click();
	//   };
	//   xhr.send();
	// }

	return (
		<div>
			<h4 className="page-title">Tableau Widget</h4>
			<div ref={ref} style={{ width: "65%", margin: "0 auto" }}></div>
			{/* <a href="https://eu-west-1a.online.tableau.com/t/codatagmbh/views/PromotionalEnsemble_Final/onepager.pdf">download</a> */}
			{/* <button onClick={handleDownload}>download</button> */}
		</div>
	);
}

export default OnePager;
