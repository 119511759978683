import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
	formatBigNum,
	chartBgc,
	chartWatermark,
	chartDataSorter,
} from "../utils";
import moment from "moment";

const WebEngagementChart = ({
	dataFetched,
	isLoading,
	chartData,
	startDate,
	endDate,
	graphRef,
	totalData,
	setTotalData,
	prevData,
	setPrevData,
	displayedDates,
	setDisplayedDates,
	title,
}) => {
	useEffect(() => {
		const loadDisplayData = () => {
			let tempTotalData = [];
			let tempPrevData = [];
			let tempDateData = [];
			chartData.sort(chartDataSorter);

			for (let i = 0; i < chartData.length; i++) {
				let prev_start = new Date(startDate);
				prev_start.setFullYear(prev_start.getFullYear() - 1);
				let prev_end = new Date(endDate);
				prev_end.setFullYear(prev_end.getFullYear() - 1);
				prev_end.setDate(prev_end.getDate() + 7);

				if (
					new Date(chartData[i].start_date) >= new Date(startDate) &&
					new Date(chartData[i].end_date) <= new Date(endDate)
				) {
					tempTotalData.push(chartData[i].entry);
					tempDateData.push(chartData[i].end_date);
				}
				if (
					new Date(chartData[i].start_date) >= prev_start &&
					new Date(chartData[i].end_date) <= prev_end
				) {
					tempPrevData.push(chartData[i].entry);
				}
			}
			tempPrevData = tempPrevData.reverse();

			while (tempPrevData.length < tempTotalData.length) {
				tempPrevData.push(null);
			}
			tempPrevData = tempPrevData.reverse();

			let buffer_start = moment(tempDateData[0]).subtract(14, "d");
			let buffer_end = moment(tempDateData[tempDateData.length - 1]).add(
				14,
				"d"
			);
			tempDateData.unshift(buffer_start.format("YYYY-MM-DD"));
			tempDateData.push(buffer_end.format("YYYY-MM-DD"));
			tempTotalData.push(null);
			tempPrevData.push(null);
			tempTotalData.unshift(null);
			tempPrevData.unshift(null);

			setTotalData(tempTotalData);
			setPrevData(tempPrevData);
			setDisplayedDates(tempDateData);
		};
		loadDisplayData();
	}, [dataFetched, chartData, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h3>Chart Loading...</h3>;
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Line
					ref={graphRef}
					className="history-container"
					height={300}
					data={{
						labels: displayedDates,
						datasets: [
							{
								data: totalData,
								label: "Recent year",
								borderColor: "#0000FF",
								pointRadius: 0,
							},
							{
								data: prevData,
								label: "Prior year",
								borderColor: "#00A36C",
								pointRadius: 0,
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "month",
									},
									stacked: true,
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									gridLines: {
										borderDash: [8, 4],
									},
									ticks: {
										callback: (value) => formatBigNum(value),
									},
								},
							],
						},
						title: {
							display: true,
							text: title,
							fontSize: 15,
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(WebEngagementChart);
