import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";
import moment from "moment";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import {
	getLunaUrl,
	getNumericalHeader,
	chartJSGraphDownload,
	chartJSCSVDownload,
	axios,
	capitalize,
} from "../utils";

import "../../staticfiles/css/email-volume.css";
import SearchChart from "./SearchChart";
import SearchTable from "./SearchTable";
import MyTooltip from "../../ReusableComponents/MyTooltip";

const CampaignSearch = ({ uniqueDomains, loaded }) => {
	const [selectedDomains, setSelectedDomains] = useState([]);

	const [startDate, setStartDate] = useState(
		moment().subtract(30, "days").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
	const [chartTitle, setChartTitle] = useState("");

	const [isStorewide, setIsStorewide] = useState(null);
	const [isSignificant, setIsSignificant] = useState(null);
	const [isSteep, setIsSteep] = useState(null);
	const [searchString, setSearchString] = useState("");
	const [timeFilter, setTimeFilter] = useState("30 days");

	const [dataFetched, setDataFetched] = useState(false);
	const [discountChartFetching, setDiscountChartFetching] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [discountChartData, setDiscountChartData] = useState([]);
	const [discountTableData, setDiscountTableData] = useState([]);
	const [chartDisplayedDates, setChartDisplayedDates] = useState([]);
	const [chartVolume, setChartVolume] = useState([]);

	const searchChartRef = useRef(null);
	const sortedDomains = uniqueDomains.toSorted((a, b) =>
		a.domain.localeCompare(b.domain)
	);

	const getDomainData = async () => {
		setDiscountChartFetching(true);
		let domains = selectedDomains.reduce(
			(acc, cur) => acc + cur.domain + ",",
			""
		);
		setChartTitle(getTitle());
		await axios
			.get(
				getLunaUrl(
					`v1/deep-discount/harmonic/?is_global=true&startDate=${startDate}&endDate=${endDate}&domain=${domains.slice(
						0,
						-1
					)}${isStorewide !== null ? `&isStorewide=${isStorewide}` : ""}${
						isSteep !== null ? `&isSteep=${isSteep}` : ""
					}${isSignificant !== null ? `&isSignificant=${isSignificant}` : ""}${
						searchString !== "" ? `&searchString=${searchString}` : ""
					}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				if (response.data.data.length === 0) {
					toast.warn("No Data Fetched");
					setDiscountChartData([]);
					setDiscountTableData([]);
				} else {
					let curData = response.data.data.map((el) => {
						let week = moment(el.email_detail__end_date);
						let day = week.day();
						if (day !== 0) {
							week.day(7);
						}
						return {
							feed_id: el.feed_id,
							subject: el.email_detail__subject,
							image_url: el.email_detail__image_url,
							min_discount_pct: el.harmonic_discount__min_discount_pct,
							max_discount_pct: el.harmonic_discount__max_discount_pct,
							state_discount: el.harmonic_discount__state_discount,
							effective_discount_pct:
								el.harmonic_discount__effective_discount_pct,
							product_type: el.harmonic_discount__product_type,
							is_storewide: el.harmonic_discount__is_storewide,
							is_steep: el.harmonic_discount__is_steep,
							is_significant: el.harmonic_discount__is_significant,
							holiday_tag: el.harmonic_discount__holiday_tag,
							free_ship_condition: el.harmonic_discount__free_ship_condition,
							discount_value: el.campaign_information__discount_value,
							total_volume: el.project__total_volume,
							read_volume: el.project__read_volume,
							delete_volume: el.project__delete_volume,
							start_date: el.email_detail__start_date,
							end_date: el.email_detail__end_date,
							domain: el.company_resume__domain,
							week: week.format("YYYY-MM-DD"),
							group_id: el.group_id,
						};
					});
					setDiscountChartData(curData);
					let tableData = curData.toSorted(
						(a, b) => new Date(b.end_date) - new Date(a.end_date)
					);
					setDiscountTableData(tableData);
				}
			})
			.catch((error) => {
				console.log(error);
				setDiscountChartData([]);
				setDiscountTableData([]);
			})
			.finally(() => {
				setDiscountChartFetching(false);
				setDataFetched(true);
			});
	};

	const handleMultiDomainSelection = (newList, newItem) => {
		setSelectedDomains(newList);
	};

	const handleMultipleDomainRemoval = (list, removedItem) => {
		setSelectedDomains(list);
	};

	const handleSearch = () => {
		if (selectedDomains.length <= 0) {
			toast.warn("Domain cannot be empty!");
			return;
		}

		if (new Date(startDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			return;
		}

		getDomainData();
	};

	const renderSelectionPanel = () => {
		const booleanOptions = ["all", "true", "false"];
		const timeOptions = ["30 days", "60 days", "90 days", "customized"];
		const changeTimeFilter = (option) => {
			setTimeFilter(option);
			if (option !== "customized") {
				setStartDate(
					moment(endDate)
						.subtract(parseInt(option.substring(0, 2)), "days")
						.format("YYYY-MM-DD")
				);
			}
		};
		return (
			<div className="card">
				<div className="card-body">
					{/* <div
						className="task-dropdown-title-sm"
						style={{ width: "15%", marginBottom: 20 }}
					>
						Domain:
						<Dropdown>
							<Dropdown.Toggle
								variant="outline-dark"
								id="dropdown-basic"
								as={CustomToggle}
								className="task-dropdown-selected"
							>
								{selectedDomain.domain}
							</Dropdown.Toggle>

							<Dropdown.Menu as={CustomMenu} className="task-dropdown">
								{sortedDomains.map((domain) => {
									return (
										<Dropdown.Item
											key={domain.domain}
											eventKey={domain.domain}
											onClick={() => handleDomainSelection(domain)}
										>
											{domain.domain}
										</Dropdown.Item>
									);
								})}
							</Dropdown.Menu>
						</Dropdown>
					</div> */}
					<div style={{ width: "50%", marginBottom: 20 }}>
						Domains:
						<Multiselect
							options={sortedDomains}
							onSelect={handleMultiDomainSelection}
							onRemove={handleMultipleDomainRemoval}
							displayValue="domain"
						/>
					</div>

					<div className="search-bar">
						<div className="task-dropdown-title">
							<input
								type="text"
								value={searchString}
								onChange={(e) => setSearchString(e.target.value)}
								className="form-control"
								placeholder="Enter your search string here"
							/>
							{searchString && (
								<span
									className="clear-icon"
									onClick={() => setSearchString("")}
								>
									<i className="dripicons-cross"></i>
								</span>
							)}
							<i className="dripicons-search search-icon"></i>
							<Button
								onClick={handleSearch}
								className="btn btn-primary search-btn"
								style={{ "margin-top": 20 }}
							>
								Search
							</Button>
						</div>
					</div>
					<div className="filter-btn-container">
						<Button
							className="btn btn-light"
							onClick={() => setShowFilters(!showFilters)}
							style={{ "margin-top": 20 }}
						>
							Filters{" "}
							<i
								className={`${
									showFilters
										? " dripicons-chevron-down"
										: "dripicons-chevron-up"
								}`}
							/>
						</Button>
					</div>
					{showFilters && (
						<>
							<div className="filters-container">
								<div className="task-dropdown-title-sm">
									Time Range:
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{timeFilter}
										</Dropdown.Toggle>
										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{timeOptions.map((option) => (
												<Dropdown.Item
													key={option}
													eventKey={option}
													onClick={() => changeTimeFilter(option)}
												>
													{option}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="task-dropdown-title-sm">
									End Date:
									<input
										name="date"
										id="endDateSelect"
										value={endDate}
										onChange={(e) => setEndDate(e.target.value)}
										type="date"
										class="form-control"
									/>
								</div>
								<div className="task-dropdown-title-sm">
									Is Storewide:
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{isStorewide === null ? "All" : String(isStorewide)}
										</Dropdown.Toggle>
										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{booleanOptions.map((option) => (
												<Dropdown.Item
													key={option}
													eventKey={option}
													onClick={() =>
														setIsStorewide(
															option === "all" ? null : option === "true"
														)
													}
												>
													{option}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="task-dropdown-title-sm">
									Is Significant:
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{isSignificant === null ? "All" : String(isSignificant)}
										</Dropdown.Toggle>
										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{booleanOptions.map((option) => (
												<Dropdown.Item
													key={option}
													eventKey={option}
													onClick={() =>
														setIsSignificant(
															option === "all" ? null : option === "true"
														)
													}
												>
													{option}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="task-dropdown-title-sm">
									Is Steep:
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{isSteep === null ? "All" : String(isSteep)}
										</Dropdown.Toggle>
										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{booleanOptions.map((option) => (
												<Dropdown.Item
													key={option}
													eventKey={option}
													onClick={() =>
														setIsSteep(
															option === "all" ? null : option === "true"
														)
													}
												>
													{option}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
							<div className="filters-container">
								{timeFilter === "customized" && (
									<div className="task-dropdown-title-sm">
										Start Date:
										<input
											name="date"
											id="startDateSelect"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
											type="date"
											class="form-control"
										/>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		);
	};

	const getTitle = () => {
		let companies = [];
		for (const domain of selectedDomains) {
			let company = uniqueDomains.find((el) => el.domain === domain.domain);
			let name = `${company.name} (${company.ticker})`;
			companies.push(name);
		}
		let titles = companies.reduce((acc, cur) => acc + cur + ", ", "");
		return `${titles.slice(
			0,
			-2
		)}: Promotional Keyword Inflection: "${capitalize(searchString)}" (${moment(
			startDate
		).format("MMMM YYYY")} ~ ${moment(endDate).format("MMMM YYYY")})`;
	};

	const renderDownloadBtn = (imgDownload, csvDownload) => {
		return (
			<Dropdown className="cloud-download-btn">
				<Dropdown.Toggle style={{ width: "100%" }} variant="outline-primary">
					<i className="dripicons-cloud-download"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ width: "100%" }}>
					<Dropdown.Item onClick={imgDownload}>as Image</Dropdown.Item>
					<Dropdown.Item onClick={csvDownload}>as CSV</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const chartImgDownload = () => {
		if (searchChartRef !== null) {
			chartJSGraphDownload(
				searchChartRef.current.chartInstance,
				chartTitle + ".png"
			);
		}
	};

	const chartCsvDownload = () => {
		let data = [
			["Dates", ...chartDisplayedDates],
			["Volume", ...chartVolume],
		];

		chartJSCSVDownload(data, chartTitle + ".csv");
	};

	useEffect(() => {
		document.title = "Campaign Search";
	}, []);

	if (!loaded) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Campaign Search</h4>
			<MyTooltip
				content={"both chart and table: v1/deep-discount/harmonic/"}
			></MyTooltip>
			{renderSelectionPanel()}
			<Row>
				<Col sm={12} md={12}>
					<div className="card" style={{ overflow: "scroll" }}>
						<div className="card-title"></div>
						<div className="card-body" style={{ minWidth: "800px" }}>
							<SearchChart
								dataFetched={dataFetched}
								isLoading={discountChartFetching}
								chartData={discountChartData}
								graphRef={searchChartRef}
								displayedDates={chartDisplayedDates}
								setDisplayedDates={setChartDisplayedDates}
								volume={chartVolume}
								setVolume={setChartVolume}
								title={chartTitle}
								selectedDomains={selectedDomains}
								startDate={startDate}
								endDate={endDate}
							/>
							{dataFetched
								? renderDownloadBtn(chartImgDownload, chartCsvDownload)
								: ""}
						</div>
					</div>
					<SearchTable
						totalData={discountTableData}
						isLoading={discountChartFetching}
						title={chartTitle}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default CampaignSearch;
