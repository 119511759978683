import React, { useState } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../staticfiles/css/rw-rule-playground.css";

const DeepDiscountTable = ({
	dataFetching,
	totalData,
	appliedData,
	applyRule,
	loadData,
}) => {
	const columns = [
		{
			dataField: "feed_id",
			text: "Campaign_id",
			hidden: true,
		},
		{
			dataField: "last_edited",
			text: "Last_edited_timestamp",
			hidden: true,
		},
		{
			dataField: "editor",
			text: "Last_edited_by",
			hidden: true,
		},
		{
			dataField: "product",
			text: "Product_Type",
			hidden: true,
		},
		{
			dataField: "subject",
			text: "Subject",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "date",
			text: "Date",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "group_id",
			text: "Group_id",
			hidden: true,
		},
		{
			dataField: "volume",
			text: "Volume",
			sort: true,
			formatter: (cell, row) => {
				cell = cell?.toLocaleString();
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "min",
			text: "Min %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "max",
			text: "Max %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "effectivePct",
			text: "Effective %",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "state",
			text: "State",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "category",
			text: "Category",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "state_value",
			text: "State Value",
			sort: true,
		},
		{
			dataField: "storewide",
			text: "Storewide",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "steep",
			text: "Steep",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "significant",
			text: "Significant",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "holiday",
			text: "Holiday",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
		{
			dataField: "freeShip",
			text: "Free Ship",
			sort: true,
			formatter: (cell, row) => {
				return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
			},
		},
	];

	const [displayApplied, setDisplayApplied] = useState(false);

	const toggleApplied = () => {
		setDisplayApplied((d) => !d);
	};

	if (dataFetching) {
		return (
			<Card>
				<CardBody>
					<h4>Fetching Data...</h4>
				</CardBody>
			</Card>
		);
	}

	const expandRow = {
		renderer: (row) => {
			return (
				<>
					<Table striped bordered si ze="sm">
						<tbody>
							<tr>
								<td>Campaign ID</td>
								<td>
									<div dangerouslySetInnerHTML={{ __html: row.feed_id }}></div>
								</td>
							</tr>
							<tr>
								<td>Group ID</td>
								<td>
									<div dangerouslySetInnerHTML={{ __html: row.group_id }}></div>
								</td>
							</tr>
							<tr>
								<td>Product Type</td>
								<td>
									<div dangerouslySetInnerHTML={{ __html: row.product }}></div>
								</td>
							</tr>
							<tr>
								<td>Last edited timestamp</td>
								<td>
									<div
										dangerouslySetInnerHTML={{ __html: row.last_edited }}
									></div>
								</td>
							</tr>
							<tr>
								<td>Last edited by</td>
								<td>
									<div dangerouslySetInnerHTML={{ __html: row.editor }}></div>
								</td>
							</tr>
						</tbody>
					</Table>
				</>
			);
		},
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	const onColumnMatch = ({ searchText, value, column, row }) => {
		if (searchText === "") {
			return true;
		}
		searchText = searchText.trim();
		if (searchText.startsWith(":")) {
			const space = searchText.indexOf(" ");
			const field = searchText.slice(1, space);
			const key = searchText.slice(space + 1);
			if (space === -1) return true;
			if (column.text.toLowerCase().includes(field.toLowerCase())) {
				searchText = key;
			}
		}
		if (value?.toString().toLowerCase().includes(searchText.toLowerCase())) {
			return true;
		}
		return false;
	};

	const { SearchBar } = Search;

	return (
		<div>
			<Card>
				<CardBody className="scroll-table">
					<ToolkitProvider
						bootstrap4
						keyField="feed_id"
						data={displayApplied ? appliedData : totalData}
						columns={columns}
						search={{ onColumnMatch }}
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar
											{...props.searchProps}
											placeholder="Search Any field"
										/>
										<button
											class="btn btn-outline-info rule-btn"
											onClick={loadData}
										>
											Load Data
										</button>
										<button
											class="btn btn-outline-info rule-btn"
											onClick={applyRule}
										>
											Apply Rule
										</button>
										<button
											class="btn btn-outline-info rule-btn"
											onClick={toggleApplied}
										>
											{displayApplied ? "Show All Data" : "Only Applied Data"}
										</button>
									</Col>
								</Row>
								<span style={{ color: "#a6a6a6" }}>
									tips: starts with :field_name to search a specific field e.g.
									:subject e-receipt
								</span>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									expandRow={expandRow}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 10,
										showTotal: true,
									})}
									noDataIndication={() => "No Data Available"}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default DeepDiscountTable;
