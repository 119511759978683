import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { getNumericalHeader, getLunaUrl, axios } from "../utils";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import {
	indexMapping,
	mappingHandler,
} from "../../staticfiles/PromotionIndexingMapping";
import MyTooltip from "../../ReusableComponents/MyTooltip";
import HistoryChart from "./HistoryChart";
import "../../staticfiles/css/chart-panel.css";

const ChartPanel = ({ uniqueDomains, loaded }) => {
	const [dataFetched, setDataFetched] = useState(false);
	const [smoothedDataFetched, setSmoothedDataFetched] = useState(false);

	const [chartData, setChartData] = useState([]);
	const [smoothedChartData, setSmoothedChartData] = useState([]);
	const [selectedDomains, setSelectedDomains] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [allFields, setAllFields] = useState([]);
	const [selectedFields, setSelectedFields] = useState([
		{
			id: "final_index",
			formattedName: mappingHandler(indexMapping, "final_index"),
			// color: Math.floor(Math.random() * 16777215).toString(16),
		},
	]);

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [displayUSData, setDisplayUSData] = useState(false);

	const getDomainData = async (domain, ticker, name, count) => {
		setDataFetched(false);
		await axios
			.get(
				getLunaUrl(
					displayUSData
						? `v1/email-index/query/?domain=${domain}&category=ALL&country=US`
						: `v1/email-index/query/?domain=${domain}&category=ALL`
				),
				getNumericalHeader()
			)
			.then(async (response) => {
				if (response.data.data.length === 0) {
					toast.warn("We currently have no information on this domain.");
					return;
				}
				let tmpChartData = cloneDeep(chartData);
				let dup = tmpChartData.findIndex(
					(data) =>
						data.domain === domain &&
						data.ticker === ticker &&
						data.name === name
				);
				if (dup !== -1) {
					tmpChartData[dup] = {
						...tmpChartData[dup],
						data: response.data.data,
					};
				} else {
					tmpChartData.push({
						domain,
						ticker,
						name,
						data: response.data.data,
					});
				}

				setChartData(tmpChartData);

				if (count === 0) {
					let tempminStartDate = new Date(response.data.data[0].start_date);
					let tempminStartDateIndex = 0;

					let tempmaxStartDate = new Date(response.data.data[0].start_date);
					let tempmaxStartDateIndex = 0;

					let tempminEndDate = new Date(response.data.data[0].end_date);
					let tempminEndDateIndex = 0;

					let tempmaxEndDate = new Date(response.data.data[0].end_date);
					let tempmaxEndDateIndex = 0;

					for (let i = 0; i < response.data.data.length; i++) {
						let newDate = new Date(response.data.data[i].start_date);
						if (tempminStartDate > newDate) {
							tempminStartDate = newDate;
							tempminStartDateIndex = i;
						}
						if (tempmaxStartDate < newDate) {
							tempmaxStartDate = newDate;
							tempmaxStartDateIndex = i;
						}

						let newEnd = new Date(response.data.data[i].end_date);
						if (tempminEndDate > newEnd) {
							tempminEndDate = newEnd;
							tempminEndDateIndex = i;
						}
						if (tempmaxEndDate < newEnd) {
							tempmaxEndDate = newEnd;
							tempmaxEndDateIndex = i;
						}
					}

					let fieldArr = [];
					for (var key in response.data.data[0]) {
						if (typeof response.data.data[0][key] === "number") {
							fieldArr.push(key);
						}
					}

					fieldArr = fieldArr.sort();
					let resArr = [];
					for (let i = 0; i < fieldArr.length; i++) {
						resArr.push({
							name: fieldArr[i],
							formattedName: mappingHandler(indexMapping, fieldArr[i]),
						});
					}
					setAllFields(resArr);

					let newStartDate = new Date(
						response.data.data[tempmaxEndDateIndex].end_date
					);
					var year = newStartDate.getFullYear();
					var month = newStartDate.getMonth();
					var day = newStartDate.getDate();
					var c = new Date(year - 2, month, day);

					month = "" + (c.getMonth() + 1);
					day = "" + c.getDate();
					year = c.getFullYear();

					if (month.length < 2) month = "0" + month;
					if (day.length < 2) day = "0" + day;

					newStartDate = [year, month, day].join("-");

					setminStartDate(response.data.data[tempminStartDateIndex].start_date);
					setmaxStartDate(response.data.data[tempmaxStartDateIndex].start_date);

					if (tempStartDate === "") {
						setStartDate(newStartDate);
						setTempStartDate(newStartDate);
					}

					setminEndDate(response.data.data[tempminEndDateIndex].end_date);
					setmaxEndDate(response.data.data[tempmaxEndDateIndex].end_date);

					if (tempEndDate === "") {
						setEndDate(response.data.data[tempmaxEndDateIndex].end_date);
						setTempEndDate(response.data.data[tempmaxEndDateIndex].end_date);
					}
				}
			});
		setDataFetched(true);
	};

	const getSmoothedData = async (domain, ticker, name) => {
		setSmoothedDataFetched(false);
		await axios
			.get(
				getLunaUrl(
					displayUSData
						? `v1/email-index/query/smoothed/?domain=${domain}&country=US`
						: `v1/email-index/query/smoothed/?domain=${domain}`
				),
				getNumericalHeader()
			)
			.then(async (response) => {
				if (response.data.length === 0) {
					return;
				}
				let res = response.data;
				let smoothed = [];
				for (const data of res) {
					let tmp = {};
					tmp["total_volume"] = data.smoothed_total_volume;
					tmp["final_index"] = data.smoothed_final_index;
					for (const key in data) {
						if (
							key !== "total_volume" &&
							key !== "final_index" &&
							key !== "smoothed_total_volume" &&
							key !== "smoothed_final_index"
						) {
							tmp[key] = data[key];
						}
					}
					smoothed.push(tmp);
				}

				let tmpChartData = cloneDeep(smoothedChartData);
				let dup = tmpChartData.findIndex(
					(data) =>
						data.domain === domain &&
						data.ticker === ticker &&
						data.name === name
				);
				if (dup !== -1) {
					tmpChartData[dup] = {
						...tmpChartData[dup],
						data: smoothed,
					};
				} else {
					tmpChartData.push({
						domain,
						ticker,
						name,
						data: smoothed,
					});
				}

				setSmoothedChartData(tmpChartData);
			})
			.catch((error) => {
				console.log(error);
			});

		setSmoothedDataFetched(true);
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	const handleMultipleFieldsSelection = (newList, selectedItem) => {
		let newField = {
			id: selectedItem.name,
			formattedName: selectedItem.formattedName,
		};
		setSelectedFields([...selectedFields, newField]);
	};

	const handleMultipleFieldsRemoval = (newList, removedItem) => {
		let newFields = selectedFields.filter((field) => {
			return field.id !== removedItem.name;
		});
		setSelectedFields(newFields);
	};

	const handleMultiDomainSelection = async (newList) => {
		if (newList.length > 3) {
			toast.warn(
				"Meet the maximum - You can only select at most 3 domains at once"
			);
			return;
		}
		let newData = [];
		let companies = [];
		for (let i = 0; i < newList.length; i++) {
			newData.push(newList[i]);
			companies.push(newList[i].name);
		}
		setSelectedCompanies(companies);
		await setSelectedDomains(newData);
		getDomainData(
			newList[newList.length - 1].domain,
			newList[newList.length - 1].ticker,
			newList[newList.length - 1].name,
			selectedDomains.length
		);
		getSmoothedData(
			newList[newList.length - 1].domain,
			newList[newList.length - 1].ticker,
			newList[newList.length - 1].name,
			selectedDomains.length
		);
	};

	const handleMultipleDomainRemoval = (newList, removedItem) => {
		let newData = [];
		let companies = [];
		for (let i = 0; i < newList.length; i++) {
			newData.push({
				domain: newList[i].domain,
			});
			companies.push(newList[i].name);
		}

		let newChartData = chartData.filter((data) => {
			return data.domain !== removedItem.domain;
		});

		let newSmoothedData = smoothedChartData.filter((data) => {
			return data.domain !== removedItem.domain;
		});

		if (newList.length === 0) {
			setSelectedFields([
				{
					id: "final_index",
					name: mappingHandler(indexMapping, "final_index"),
				},
			]);
			setSelectedDomains([]);
			setSelectedCompanies([]);
		}

		setChartData(newChartData);
		setSmoothedChartData(newSmoothedData);
		setSelectedDomains(newData);
		setSelectedCompanies(companies);
	};

	const getTitle = () => {
		return `${selectedCompanies.reduce(
			(prev, cur, index) =>
				index == 0
					? `${cur} (${selectedDomains[index].domain})`
					: prev + ", " + `${cur} (${selectedDomains[index].domain})`,
			""
		)} Promotional Indexing (All Categories)`;
	};

	useEffect(() => {
		document.title = "Promotion Indexing";
	}, []);

	useEffect(() => {
		if (selectedDomains.length > 0) {
			for (const dom of selectedDomains) {
				getDomainData(dom.domain, dom.ticker, dom.name, 1);
				getSmoothedData(dom.domain, dom.ticker, dom.name, 1);
			}
		}
	}, [displayUSData]);

	if (!loaded) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Promotional Indexing (Stable)</h4>
			<MyTooltip
				content={
					"1st: v1/email-index/query/, 2nd: v1/email-index/query/smoothed"
				}
			></MyTooltip>
			<Container>
				<Row style={{ marginTop: "15px" }}>
					{/* domain */}
					<Col md={6}>
						<Row style={{ padding: "0 10px" }}>
							<div style={{ fontSize: "18px", fontWeight: "bold" }}>Domain</div>
							<Multiselect
								options={uniqueDomains}
								selectionLimit={3}
								onSelect={handleMultiDomainSelection}
								onRemove={handleMultipleDomainRemoval}
								displayValue="domain"
							/>
						</Row>
					</Col>
					{/* start date */}
					<Col md={3}>
						<Row style={{ padding: "0 10px" }}>
							<div style={{ fontSize: "18px", fontWeight: "bold" }}>
								Start Date
							</div>
							<input
								name="date"
								id="exampleDate"
								value={tempStartDate}
								onChange={(e) => setTempStartDate(e.target.value)}
								onBlur={changeStartDate}
								type="date"
								class="form-control"
								style={{ height: "44px" }}
							></input>
						</Row>
					</Col>
					{/* end date */}
					<Col md={3}>
						<Row style={{ padding: "0 10px" }}>
							<div style={{ fontSize: "18px", fontWeight: "bold" }}>
								End Date
							</div>
							<input
								name="date"
								id="exampleDate"
								value={tempEndDate}
								onChange={(e) => setTempEndDate(e.target.value)}
								onBlur={changeEndDate}
								type="date"
								class="form-control"
								style={{ height: "44px" }}
							></input>
						</Row>
					</Col>
				</Row>
				{dataFetched && smoothedDataFetched && (
					<Row style={{ marginTop: "15px" }}>
						<Form.Check
							type="switch"
							id="us-switch"
							style={{ transform: "scale(1.25)", marginLeft: "20px" }}
							label={displayUSData ? <div>US</div> : <div>Global</div>}
							checked={displayUSData}
							onChange={(e) => setDisplayUSData(e.target.checked)}
						/>
					</Row>
				)}
			</Container>
			<br />

			{selectedDomains.length > 0 ? (
				<Container>
					<Row>
						<Col sm={12}>
							<div className="card" style={{ overflow: "scroll" }}>
								<div className="card-title"></div>
								<div className="card-body" style={{ minWidth: "676px" }}>
									<Row style={{ justifyContent: "center" }}>
										<Col sm={12} className="chart-panel-options">
											{allFields.length > 0 && (
												<Multiselect
													options={allFields}
													selectedValues={[
														{
															name: "final_index",
															formattedName: mappingHandler(
																indexMapping,
																"final_index"
															),
														},
													]}
													onSelect={handleMultipleFieldsSelection}
													onRemove={handleMultipleFieldsRemoval}
													displayValue="formattedName"
												/>
											)}
										</Col>
									</Row>
									<br />
									<Row>
										<HistoryChart
											dataFetched={dataFetched}
											selectedDomains={selectedDomains}
											chartData={chartData}
											startDate={startDate}
											endDate={endDate}
											selectedFields={selectedFields}
											selectedCompanies={selectedCompanies}
											isSmoothed={false}
											title={getTitle()}
										/>
									</Row>
									<br />
									<br />
									<br />
									<br />
									<Row>
										{smoothedDataFetched ? (
											<h4 style={{ marginLeft: 30 }}>Smoothed Index</h4>
										) : (
											""
										)}
										<HistoryChart
											dataFetched={smoothedDataFetched}
											selectedDomains={selectedDomains}
											chartData={smoothedChartData}
											startDate={startDate}
											endDate={endDate}
											selectedFields={selectedFields}
											selectedCompanies={selectedCompanies}
											isSmoothed={true}
											title={getTitle()}
										/>
									</Row>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			) : (
				<Row>
					<Col sm={12}>
						<h4>Please select at least one domain</h4>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default ChartPanel;
