import React, { Component } from "react";
import { axios } from "./utils";
import { getNumericalUrl, getNumericalHeader } from "./utils";
import {
	Container,
	Row,
	Col,
	DropdownButton,
	Dropdown,
	Button,
} from "react-bootstrap";
import Select from "react-select";
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Document, Page, pdfjs } from "react-pdf";
import "react-day-picker/lib/style.css";
import "../staticfiles/custom.css";

const folder = {
	onepager: "onepager",
	quarter: "quarterly_campaign_pdf_buffer",
	trendIncentive: "brand_trend_incentive_pdf_buffer",
	trendSteep: "brand_trend_steep_pdf_buffer",
	engagementVisitYoyIndex: "web_engagement_idx_visits",
	engagementVisitYoyRaw: "web_engagement_raw_visits",
	imageDash: "spotlight_image_dash",
};

const srcUrl = {
	onepager:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/PromotionalEnsemble_rev/onepager",
	[folder.trendIncentive]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/BrandTrendsDashboardSingleChart/MiniChartvar_?:iid=1",
	[folder.quarter]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/QuaterlyEmailCampaigns/QuarterlyCampaigns",
	[folder.trendSteep]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/BrandTrendsDashboardSingleChart/MiniChartvar_?:iid=1",
	[folder.engagementVisitYoyIndex]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/Webengagement_Raw-YoY",
	[folder.engagementVisitYoyRaw]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/views/Webengagement_Raw-YoY",
	[folder.imageDash]:
		"https://eu-west-1a.online.tableau.com/#/site/codatagmbh/workbooks/523940/views",
};

const sourceType = ["visits", "composite", "pageviews", "visit_duration"];

const defaultState = {
	tickerName: "Ticker",
	domainName: "Company",
	cateName: "Category",
	cacheTime: "Cache Time",
	inputDate: "Date", // short string format of input date
	numPages: null,
	choiceList: null, // Ticker Name Category Choice List get from api
	tickerList: [],
	domainList: [],
	cateList: [],
	fileNames: [],
	timestamps: [],
	pdfs: [],
	isTickerLoaded: false,
	displayPDF: null,
	fileName: null,
	sourceType: "visits",
};

const folderNoCategory = [
	folder.trendIncentive,
	folder.trendSteep,
	folder.imageDash,
];
class Downloader extends Component {
	state = {
		tickerName: "Ticker",
		domainName: "Company",
		cateName: "Category",
		cacheTime: "Cache Time",
		inputDate: "Date", // short string format of input date
		numPages: null,
		choiceList: null, // Ticker Name Category Choice List get from api
		tickerList: [],
		domainList: [],
		cateList: [],
		filenames: [],
		timestamps: [],
		pdfs: [],
		isTickerLoaded: false,
		displayPDF: null,
		fileName: null,
		sourceType: "visits",
	};

	getTableauInfo = async () => {
		await axios
			.get(
				getNumericalUrl("v1/emailfeed/company/category_list"),
				getNumericalHeader()
			)
			.then((response) => {
				let choiceList = response.data;
				this.state.tickerList = [];
				for (const ticker in choiceList) {
					if (ticker) {
						this.state.tickerList.push(ticker);
					}
				}
				let sortedList = this.state.tickerList.sort();
				this.setState({
					tickerList: sortedList,
					choiceList: choiceList,
					isTickerLoaded: true,
				});
				this.setDefaultPDF();
			})
			.catch((err) => {
				alert(err.message);
			});
	};

	requestPDFlink = async () => {
		if (this.props.isSummary) {
			return this.requestlinkPdf();
		} else {
			return this.requestlinkImage();
		}
	};

	requestlinkImage = async () => {
		await axios
			.post(
				getNumericalUrl("v1/emailfeed/pdf-download/get-download-link/"),
				{
					name: this.state.domainName,
					ticker: this.state.tickerName,
					category: this.state.cateName,
					folder: this.props.folder,
					source_type: this.state.sourceType,
					use_category: this.props.category,
					use_source_type: this.props.source,
				},
				getNumericalHeader()
			)
			.then((response) => {
				let data = response.data;
				if (data.status === -1) {
					alert("No file found!");
					this.setState({ displayPDF: null, fileName: null });
				} else {
					data.urls.reverse();
					data.filenames.reverse();
					data.timestamps.reverse();
					this.setState({
						pdfs: [...data.urls],
						filenames: [...data.filenames],
						timestamps: data.timestamps.map((el) => el.substring(0, 16)),
						displayPDF: data.urls[0],
						fileName: data.filenames[0],
						cacheTime: data.timestamps[0].substring(0, 16),
					});
				}
			})
			.catch((err) => {
				alert(err.message);
			});
	};

	requestlinkPdf = async () => {
		await axios
			.post(
				getNumericalUrl("v1/emailfeed/pdf-download/emailfeed-one-pager/"),
				{
					name: this.state.domainName,
					ticker: this.state.tickerName,
					category: this.state.cateName,
				},
				getNumericalHeader()
			)
			.then((response) => {
				let data = response.data.history;
				data.sort((a, b) => new Date(b.date) - new Date(a.date));
				if (response.data.status === -1) {
					alert("No file found!");
					this.setState({ displayPDF: null, fileName: null });
				} else {
					this.setState({
						pdfs: [...data.map((el) => el.url)],
						filenames: [...data.map((el) => el.filename)],
						timestamps: [...data.map((el) => el.date)],
						displayPDF: data[0].url,
						fileName: data[0].filename,
						cacheTime: data[0].date,
					});
				}
			})
			.catch((err) => {
				alert(err.message);
			});
	};

	setDefaultPDF = () => {
		// this.setState({domainName: "The Children’s Place", tickerName: 'PLCE', cateName: 'promotional'}, () => {this.requestPDFlink()})
		this.selectTicker("PLCE");
	};

	awaitTicker = () => {
		if (!this.state.isTickerLoaded)
			return [{ value: "Loading...", label: "Loading ..." }];
		else {
			return this.state.tickerList.map((elem, key) => ({
				value: elem,
				label: elem,
			}));
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.folder != this.props.folder) {
			this.componentDidMount();
		}
	}

	componentDidMount() {
		document.title = "Promotional Analysis Ensemble PDF Downloader";
		this.setState(defaultState);
		this.getTableauInfo();
		// pdf worker
		pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}

	// get the total page number of pdf
	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	getHeaderName() {
		if (this.props.isSummary) {
			return "Promotional Analysis Ensemble";
		}
		switch (this.props.folder) {
			case "onepager":
				return "OnePager";
			case folder.trendSteep:
				return "Brand Trend Steep";
			case folder.trendIncentive:
				return "Brand Trend Incentive";
			case folder.quarter:
				return "Quarterly Campaign";
			case folder.engagementVisitYoyIndex:
				return "Web Engagement Visit Yoy Index";
			case folder.engagementVisitYoyRaw:
				return "Web Engagement Visit Yoy Raw";
			case folder.imageDash:
				return "Spotlight Image Dash";
			default:
				return "";
		}
	}

	selectTicker = (name) => {
		const choiceList = this.state.choiceList;
		// this.setState({tickerName: name})
		this.state.domainList = [];
		this.state.cateList = [];
		for (const domain in choiceList[name]) {
			this.state.domainList.push(domain);
		}
		this.state.domainList.sort();
		const domainName = this.state.domainList[0];
		if (folderNoCategory.includes(this.props.folder) && !this.props.isSummary) {
			this.state.cateList.push("ALL");
		}
		choiceList[name][domainName].map((cate) => {
			this.state.cateList.push(cate);
		});
		this.state.cateList.sort();
		const cateName = this.state.cateList.includes("promotional")
			? "promotional"
			: this.state.cateList[0];
		this.setState(
			{ tickerName: name, domainName: domainName, cateName: cateName },
			() => {
				this.requestPDFlink();
			}
		);
	};

	selectDomain = (name) => {
		this.setState({ domainName: name });
		this.state.cateList = [];
		if (folderNoCategory.includes(this.props.folder && !this.props.isSummary)) {
			this.state.cateList.push("ALL");
		}
		const choiceList = this.state.choiceList;
		choiceList[this.state.tickerName][name].map((cate) => {
			this.state.cateList.push(cate);
		});
		this.state.cateList.sort();
		this.setState({ cateName: this.state.cateList[0] }, () =>
			this.requestPDFlink()
		);
	};

	selectCategory = (name) => {
		this.setState({ cateName: name }, () => this.requestPDFlink());
	};

	selectTime = (val) => {
		let ind = this.state.timestamps.indexOf(val);
		this.setState(
			{
				cacheTime: val,
				fileName: this.state.filenames[ind],
				displayPDF: this.state.pdfs[ind],
			},
			() => {
				console.log(this.state.fileName, this.state.displayPDF);
			}
		);
	};

	selectSourceType = (name) => {
		this.setState({ sourceType: name }, () => this.requestPDFlink());
	};

	handleDayChange = (input) => {
		this.setState({ inputDate: input.toLocaleDateString() });
	};

	downloadImg = () => {
		var canvas = document.getElementsByClassName("react-pdf__Page__canvas");
		var url = canvas[0].toDataURL("image/png");
		var link = document.createElement("a");
		var imageName = this.state.fileName;
		link.download = imageName.replace(".pdf", ".png");
		link.href = url;
		link.click();
	};

	render() {
		const propFolder = !this.props.category;
		const propSource = !this.props.source;
		return (
			<div>
				<h4 className="page-title">{this.getHeaderName()} PDF Downloader</h4>
				<Container>
					{/* titles */}
					<Row style={{ fontSize: "18px", fontWeight: "bold" }}>
						<Col md={2}>Cache Time</Col>
						<Col md={2}>Ticker</Col>
						<Col md={3}>Brand</Col>
						<Col md={3} style={{ display: propFolder ? "none" : "inherit" }}>
							Category
						</Col>
						<Col md={3} style={{ display: propSource ? "none" : "inherit" }}>
							Source Type
						</Col>
					</Row>
					{/* dropdown selector */}
					<Row style={{ marginTop: "15px" }}>
						<Col md={2}>
							<Select
								id="dropdown-basic-button"
								placeholder={this.state.cacheTime}
								value={this.state.cacheTime}
								options={this.state.timestamps.map((elem, key) => ({
									value: elem,
									label: elem,
								}))}
								onChange={(e) => this.selectTime(e.value)}
							/>
						</Col>
						<Col md={2}>
							<Select
								id="dropdown-basic-button"
								placeholder={this.state.tickerName}
								value={this.state.tickerName}
								options={this.awaitTicker()}
								onChange={(e) => this.selectTicker(e.value)}
							/>
						</Col>
						{/* domain */}
						<Col md={3}>
							<Select
								id="dropdown-basic-button"
								placeholder={this.state.domainName}
								value={this.state.domainName}
								options={this.state.domainList.map((elem, key) => ({
									value: elem,
									label: elem,
								}))}
								onChange={(e) => this.selectDomain(e.value)}
							/>
						</Col>
						{/* category */}
						<Col md={2} style={{ display: propFolder ? "none" : "inherit" }}>
							<Select
								id="dropdown-basic-button"
								placeholder={this.state.cateName}
								value={this.state.cateName}
								options={this.state.cateList.map((elem, key) => ({
									value: elem,
									label: elem,
								}))}
								onChange={(e) => this.selectCategory(e.value)}
							/>
						</Col>
						{/*src*/}
						<Col md={2} style={{ display: propSource ? "none" : "inherit" }}>
							<Select
								id="dropdown-basic-button"
								placeholder={this.state.sourceType}
								options={sourceType.map((elem, key) => ({
									value: elem,
									label: elem,
								}))}
								value={this.state.sourceType}
								onChange={(e) => this.selectSourceType(e.value)}
							/>
						</Col>
						{/* date */}
						<Col md={1} style={{ textAlign: "right" }}>
							<Button variant="outline-primary" onClick={this.requestPDFlink}>
								Search
							</Button>
						</Col>
						<Col md={2}>
							<DropdownButton
								variant="outline-success"
								id="dropdown-basic-button"
								title="Download"
							>
								<Dropdown.Item href={this.state.displayPDF}>
									{this.props.isSummary ? "PDF File" : "PNG Image"}
								</Dropdown.Item>
								{/*<Dropdown.Item href="javascript:;" onClick={() => this.downloadImg()}>Image</Dropdown.Item>*/}
							</DropdownButton>
						</Col>
					</Row>
					{/* file title and download */}
					<Row style={{ marginTop: "20px", textAlign: "center" }}>
						<Col md={12}>
							<h4>
								{this.state.domainName} ({this.state.tickerName}){" "}
								{propFolder ? "" : this.state.cateName}{" "}
								{propSource ? "" : this.state.sourceType}
							</h4>
						</Col>
					</Row>
					{/* display content */}
					<Row style={{ marginTop: "30px", textAlign: "center" }}>
						<Col md={{ span: 10, offset: 1 }} className="onePager-img">
							{this.props.isSummary ? (
								this.state.displayPDF ? (
									<Document
										file={this.state.displayPDF}
										onLoadSuccess={this.onDocumentLoadSuccess}
									>
										{Array.apply(null, Array(this.state.numPages))
											.map((x, i) => i + 1)
											.map((page) => (
												<Page pageNumber={page} width={1000} />
											))}
									</Document>
								) : (
									"Loading PDF..."
								)
							) : this.state.displayPDF ? (
								<img src={this.state.displayPDF} style={{ width: "100%" }} />
							) : (
								"Loading Image..."
							)}

							{/*<Document file={this.state.displayPDF} onLoadSuccess={this.onDocumentLoadSuccess}>*/}
							{/*  {Array.apply(null, Array(this.state.numPages))*/}
							{/*    .map((x, i)=>i+1)*/}
							{/*    .map(page => <Page pageNumber={page} width={1000} />)}*/}
							{/*</Document>*/}
						</Col>
					</Row>
					<Row
						style={{
							marginBottom: 30,
							textAlign: "center",
							width: "100%",
							display: "flex",
							justifyContent: "center",
						}}
					>
						Source: &nbsp;{" "}
						<a href={srcUrl[this.props.folder]}> {srcUrl[this.props.folder]}</a>
					</Row>
				</Container>
			</div>
		);
	}
}

Downloader.defaultProps = {
	folder: "onepager",
	category: true,
	source: false,
	isSummary: false,
};

export default Downloader;
