import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Line, Bar } from "react-chartjs-2";
import {
	formatBigNum,
	chartWatermark,
	chartBgc,
	chartDataSorter,
} from "../utils";
import moment from "moment";

const YoyWebChart = ({
	dataFetched,
	isLoading,
	chartData,
	startDate,
	endDate,
	graphRef,
	yoyData,
	setYoyData,
	yo2yData,
	setYo2yData,
	yo3yData,
	setYo3yData,
	displayedDates,
	setDisplayedDates,
	title,
}) => {
	useEffect(() => {
		const loadDisplayData = () => {
			let tempYoyData = [];
			let tempYo2yData = [];
			let tempYo3yData = [];
			let tempDateData = [];

			chartData.sort(chartDataSorter);

			for (let i = 0; i < chartData.length; i++) {
				if (
					new Date(chartData[i].start_date) >= new Date(startDate) &&
					new Date(chartData[i].end_date) <= new Date(endDate)
				) {
					tempYoyData.push(chartData[i].yoy);
					tempYo2yData.push(chartData[i].yo2y);
					tempYo3yData.push(chartData[i].yo3y);
					tempDateData.push(chartData[i].end_date);
				}
			}

			let buffer_start = moment(tempDateData[0]).subtract(14, "d");
			let buffer_end = moment(tempDateData[tempDateData.length - 1]).add(
				14,
				"d"
			);
			tempDateData.unshift(buffer_start.format("YYYY-MM-DD"));
			tempDateData.push(buffer_end.format("YYYY-MM-DD"));
			tempYoyData.push(null);
			tempYo2yData.push(null);
			tempYo3yData.push(null);
			tempYoyData.unshift(null);
			tempYo2yData.unshift(null);
			tempYo3yData.unshift(null);

			setYoyData(tempYoyData);
			setYo2yData(tempYo2yData);
			setYo3yData(tempYo3yData);
			setDisplayedDates(tempDateData);
		};
		loadDisplayData();
	}, [dataFetched, chartData, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h3>Chart Loading...</h3>;
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Line
					ref={graphRef}
					height={300}
					className="history-container"
					data={{
						labels: displayedDates,
						datasets: [
							{
								data: yoyData,
								label: "Yoy",
								type: "bar",
								backgroundColor: "#0000FF",
							},
							{
								data: yo2yData,
								label: "Yo2y",
								type: "bar",
								backgroundColor: "#00A36C",
							},
							{
								data: yo3yData,
								label: "Yo3y",
								type: "bar",
								backgroundColor: "#C70039",
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									ticks: {
										callback: (value) => formatBigNum(value),
									},
									gridLines: {
										borderDash: [8, 4],
									},
								},
							],
						},
						title: {
							display: true,
							text: title,
							fontSize: 15,
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(YoyWebChart);
