import React from "react";

export default function MyTooltip({ title = "", content = "" }) {
	return (
		<div className="mytooltip-debug">
			{title}&nbsp;
			<i class="dripicons-information mytooltip-target"></i>
			<div className="tooltiptext">{content}</div>
		</div>
	);
}
