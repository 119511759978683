import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown, Alert, Button, Form } from "react-bootstrap";
import {
	CustomToggle,
	CustomMenu,
} from "../ReusableComponents/TypableDropdown";
import {
	axios,
	getLunaUrl,
	getNumericalHeader,
	chartJSGraphDownload,
	chartJSCSVDownload,
	rounding,
	formatBigNum,
	capitalize,
	processNextpageUrl,
} from "./utils";
import { toast } from "react-toastify";
import moment from "moment";
import { saveAs } from "file-saver";
import { HashLink } from "react-router-hash-link";
import { debounce } from "lodash";
import "../staticfiles/css/email-volume.css";

import DomainPanel from "../ReusableComponents/DomainPanel";
import EmailTrendChart from "./EmailTrendChart/EmailTrendChart";
import EmailIndexTrendChart from "./EmailTrendChart/EmailIndexTrendChart";
import EmailFinalIndexChart from "./EmailTrendChart/EmailFinalIndexChart";
import WebEngagementChart from "./EmailTrendChart/WebEngagementChart";
import DiscountPctChart from "./EmailTrendChart/DiscountPctChart";
import DiscountStateChart from "./EmailTrendChart/DiscountStateChart";
import YoyWebChart from "./EmailTrendChart/YoyWebChart";
import WebCompositeChart from "./EmailTrendChart/WebCompositeChart";

let prefix = window.location.protocol;
var corsAnywhereUrl = prefix + "//cors.ranwalk.goldbeyond.com/"
const api_key = "patjmq0m0AcDAkw9d.16ef1ef1101fc26a23f1f17f38d9721e18752cac42f75208c7f7fa066856d0ff";
const table_key = "apprWV74pA4YbwycG";

const CompositeChart = ({
	uniqueDomains,
	tickers,
	tickerCate,
	loaded,
	earliestFI,
	earliestYoy,
	FIstatus,
	Yoystatus,
	lastSundayFI,
	lastSundayYoy,
}) => {
	const [selectedTicker, setSelectedTicker] = useState("");

	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState({});

	const [allDomainCategories, setAllDomainCategories] = useState({});
	const [domainCategories, setDomainCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");

	const [emailVolumeChartData, setEmailVolumeChartData] = useState([]);
	const [categoryIndexChartData, setCategoryIndexChartData] = useState([]);
	const [webEngagementChartData, setWebEngagementChartData] = useState([]);
	const [derivedWebChartData, setDerivedWebChartData] = useState([]);
	const [yoyWebChartData, setYoyWebChartData] = useState([]);

	const [webOrganicChartData, setWebOrganicChartData] = useState([]);
	const [webTotalChartData, setWebTotalChartData] = useState([]);
	const [webPaidChartData, setWebPaidChartData] = useState([]);

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [dataFetched, setDataFetched] = useState(false);
	const [webLoading, setWebLoading] = useState(false);
	const [derivedWebLoading, setDerivedWebLoading] = useState(false);
	const [yoyWebLoading, setYoyWebLoading] = useState(false);
	const [initialFetched, setInitialFetched] = useState(false);
	const [emailVolumeLoading, setEmailVolumeLoading] = useState(false);
	const [categoryIndexLoading, setCategoryIndexLoading] = useState(false);
	const [discountChartLoading, setDiscountChartLoading] = useState(false);

	const [indexDisplayedDates, setIndexDisplayedDates] = useState([]);
	const [emailDisplayedDates, setEmailDisplayedDates] = useState([]);
	const [webDisplayedDates, setWebDisplayedDates] = useState([]);
	const [derivedWebDates, setDerivedWebDates] = useState([]);
	const [discountDisplayedDates, setDiscountDisplayedDates] = useState([]);
	const [yoyWebDates, setYoyWebDates] = useState([]);

	const [emailVolumeTotalData, setEmailVolumeTotalData] = useState([]);
	const [emailVolumeDeletedData, setEmailVolumeDeletedData] = useState([]);
	const [emailVolumeReadData, setEmailVolumeReadData] = useState([]);
	const [categoryIndexTotalData, setCategoryIndexTotalData] = useState([]);
	const [promotionalData, setPromotionalData] = useState([]);
	const [webEngagementData, setWebEngagementData] = useState([]);
	const [derivedWebData, setDerivedWebData] = useState([]);
	const [prevWebEngagementData, setPrevWebEngagementData] = useState([]);
	const [prevDerivedWebData, setPrevDerivedWebData] = useState([]);
	const [billboardTimeList, setBillboardTimeList] = useState([]);
	const [discountChartData, setDiscountChartData] = useState([]);
	const [yoyData, setYoyData] = useState([]);
	const [yo2yData, setYo2yData] = useState([]);
	const [yo3yData, setYo3yData] = useState([]);

	const [webDomains, setWebDomains] = useState([]);
	const [webSubDomain, setWebSubDomain] = useState("");

	const [discountFirstFrac, setDiscountFirstFrac] = useState([]);
	const [discountSecFrac, setDiscountSecFrac] = useState([]);
	const [discountThirdFrac, setDiscountThirdFrac] = useState([]);
	const [discountFourthFrac, setDiscountFourthFrac] = useState([]);

	const [discountStateFirstFrac, setStateDiscountFirstFrac] = useState([]);
	const [discountStateSecFrac, setStateDiscountSecFrac] = useState([]);
	const [discountStateThirdFrac, setStateDiscountThirdFrac] = useState([]);
	const [discountStateFourthFrac, setStateDiscountFourthFrac] = useState([]);
	const [discountStateFifthFrac, setStateDiscountFifthFrac] = useState([]);

	const [indexFields, setIndexFields] = useState([]);
	const [pctTableData, setPctTableData] = useState([]);
	const [stateTableData, setStateTableData] = useState([]);
	const [billboardDataFetched, setBillboardDataFetched] = useState(false);
	const [indexDownloading, setIndexDownloading] = useState(false);
	const [ensembleIndexDownloading, setEnsembleIndexDownloading] =
		useState(false);

	const [displayPct, setDisplayPct] = useState(true);
	const [displayState, setDisplayState] = useState(true);
	const [displayEmailTrend, setDisplayEmailTrend] = useState(true);
	const [displayEmailIndex, setDisplayEmailIndex] = useState(true);
	const [displayEmailFinal, setDisplayEmailFinal] = useState(true);
	const [displayWeb, setDisplayWeb] = useState(true);
	const [displayDerivedWeb, setDisplayDerivedWeb] = useState(true);
	const [displayYoy, setDisplayYoy] = useState(true);

	const [displayUSData, setDisplayUSData] = useState(false);

	const [webCompositeDisplay, setWebCompositeDisplay] = useState(false);

	const [displayTitle, setDisplayTitle] = useState(false);

	const [granularity, setGran] = useState("weekly");
	const [granYoy, setGranYoy] = useState("weekly");
	const [valueType, setValueType] = useState("index");
	const [valueTypeYoy, setValueTypeYoy] = useState("index");
	const [sourceType, setSourceType] = useState("visits");
	const [sourceTypeYoy, setSourceTypeYoy] = useState("visits");

	const [urlParams, setUrlParams] = useState({});
	const [currentLink, setCurrentLink] = useState("");

	const emailVolumeGraphRef = useRef(null);
	const emailIndexGraphRef = useRef(null);
	const promotionalGraphRef = useRef(null);
	const webEngagementGraphRef = useRef(null);
	const derivedWebGraphRef = useRef(null);
	const discountPctGraphRef = useRef(null);
	const discountStateGraphRef = useRef(null);
	const yoyWebGraphRef = useRef(null);

	const domainRef = useRef("");
	const cateRef = useRef("");

	const sourceList = [
		"total_search",
		"organic_search",
		"paid_search",
		"paid_social",
		"organic_social",
		"total_social",
		"organic_mail",
		"paid_mail",
		"total_mail",
		"organic_ad",
		"paid_ad",
		"total_ad",
		"organic_direct",
		"paid_direct",
		"total_direct",
		"organic_referral",
		"paid_referral",
		"total_referral",
	].sort();
	const extraSourceList = [
		"visits",
		"composite",
		"pageviews",
		"visit_duration",
	];
	const cateList = [
		"search",
		"social",
		"ad",
		"mail",
		"referral",
		"direct",
	].sort();
	const granList = ["monthly", "weekly"];
	const granListYoy = ["monthly", "weekly"];
	const valueList = {
		monthly: { raw: cateList, yoy: cateList, index: cateList },
		weekly: {
			yoy: ["visits", "pageviews", "visit_duration"],
			raw: extraSourceList,
			index: extraSourceList,
		},
	};
	const valueListYoy = {
		monthly: { raw: sourceList },
		weekly: {
			raw: ["visits", "pageviews", "visit_duration", "composite"],
			index: ["visits", "pageviews", "visit_duration", "composite"],
		},
	};

	var JSZip = require("jszip");
	const getNextPageWebEngagement = async (url, allWeb) => {
		await axios
			.get(url, getNumericalHeader())
			.then(async (response) => {
				let tempData;
				for (let i = 0; i < response.data.results.length; i++) {
					tempData = response.data.results[i];
					allWeb.push({
						entry: tempData.entry,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
					});
				}

				if (response.data.next !== null) {
					await getNextPageWebEngagement(
						processNextpageUrl(response.data.next),
						allWeb
					);
				} else {
					if (new Date(tempData.start_date) > new Date(maxStartDate)) {
						setmaxStartDate(tempData.start_date);
					}
					if (new Date(tempData.end_date) > new Date(maxEndDate)) {
						setmaxEndDate(tempData.end_date);
						setEndDate(tempData.end_date);
						setTempEndDate(tempData.end_date);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getNextPageDerivedWeb = async (url, allWeb) => {
		await axios
			.get(url, getNumericalHeader())
			.then(async (response) => {
				const res = response.data.results;
				for (const tempData of res) {
					allWeb.push({
						entry: tempData.entry,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
						granularity: tempData.granularity,
						source_type: tempData.source_type,
						value_type: tempData.value_type,
					});
				}

				if (response.data.next !== null) {
					await getNextPageDerivedWeb(
						processNextpageUrl(response.data.next),
						allWeb
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getNextPageYoyWeb = async (url, allWeb, ids) => {
		await axios
			.get(url, getNumericalHeader())
			.then(async (response) => {
				const res = response.data.results;
				for (let tempData of res) {
					if (
						(tempData.yoy === 0 &&
							tempData.yo2y === 0 &&
							tempData.yo3y === 0) ||
						ids.includes(tempData.id)
					) {
						continue;
					}
					ids.push(tempData.id);
					allWeb.push({
						yoy: tempData.yoy,
						yo2y: tempData.yo2y,
						yo3y: tempData.yo3y,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
					});
				}

				if (response.data.next !== null) {
					await getNextPageYoyWeb(
						processNextpageUrl(response.data.next),
						allWeb,
						ids
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getNextPageAirTable = async (offset, list) => {
		await axios
      .get(
        `${corsAnywhereUrl}https://api.airtable.com/v0/${table_key}/billboard_list?offset=${offset}`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
      )
			.then(async (response) => {
				for (let r of response.data.records) {
					list.push(r.fields);
				}
				if (response.data.offset) {
					await getNextPageAirTable(response.data.offset, list);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getAllIndexData = async () => {
		setIndexDownloading(true);
		let total = [];
		for (let ind of indexFields) {
			let ticker = ind["Symbol"];
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind.Domain}&category=${ind.Category}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						let date = moment(data[i].end_date);
						if (date.isAfter("2019-05-01") && date.isBefore("2021-11-08")) {
							let volume =
								(data[i - 1].smoothed_total_volume +
									data[i].smoothed_total_volume) /
								2;
							total.push({
								Ticker: ticker,
								Brand: ind.Brand,
								Category: data[i].category,
								"Current Index": rounding(volume, 2),
								"Week End": date.format("YYYY-MM-DD"),
							});
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		let csvData = total.map((row) => Object.values(row).join(",")).join("\n");
		csvData = Object.keys(total[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "historic-index.csv");
		setIndexDownloading(false);
	};

	const getEnsembleIndexData = async () => {
		setEnsembleIndexDownloading(true);
		let total = [];
		let domains = uniqueDomains
			.filter((d) => d.domain === d.parent_domain)
			.map((d) => d.domain);
		for (let ind of domains) {
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind}&category=ALL`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						total.push({
							Ticker: data[i].ticker,
							Domain: data[i].domain,
							StartDate: data[i].start_date,
							EndDate: data[i].end_date,
							PromotionalVolumeIndex: data[i].adjusted_total_volume,
							DiscountVolumeIndex: data[i].adjusted_is_significant_total_volume,
							NotionalDiscountVolumeIndex:
								data[i].adjusted_harmonic_state_discount_total_volume,
							ZeroPercentVolumeIndex: data[i].adjusted_zero_discount_volume,
							AverageDiscountVolumeIndex: data[i].adjusted_discount_vol,
							SteepDiscountVolumeIndex: data[i].adjusted_is_steep_total_volume,
							EffectiveDiscountIndex: data[i].effective_discount,
							"20PercentileDiscountIndex": data[i].discount_20_percentile,
							"80PercentileDiscountIndex": data[i].discount_80_percentile,
							MaxDiscountIndex: data[i].max_discount,
							CompositePromotionalIndex: data[i].final_index,
						});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		let csvData = total.map((row) => Object.values(row).join(",")).join("\n");
		csvData = Object.keys(total[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "Promotional-Ensemble-Indices.csv");
		setEnsembleIndexDownloading(false);
	};

	const getWebData = async (domain, loadList = true) => {
		if (domain === undefined || domain === null) {
			return;
		}

		if (loadList) {
			let subDomains = uniqueDomains.filter(
				(dom) => dom.parent_domain === domain && dom.id === dom.sim_category
			);
			setWebDomains(subDomains);
		}
		let allWeb = [];
		await axios
			.get(
				getLunaUrl(
					`v1/web-engagement-derived/?domain=${domain}&source_type=visits&granularity=weekly&value_type=index`
				),
				getNumericalHeader()
			)
			.then(async (response) => {
				let data = response.data.results;
				if (data.length === 0) {
					setWebEngagementChartData([]);
					return;
				}
				let curDomain = data[0].company.domain;
				if (curDomain !== domainRef.current) {
					return;
				}
				let first_start_date = data[0].start_date;
				let first_end_date = data[0].end_date;
				if (new Date(first_start_date) < new Date(minStartDate)) {
					setminStartDate(first_start_date);
				}
				if (new Date(first_end_date) < new Date(minEndDate)) {
					setminEndDate(first_end_date);
				}
				for (let i = 0; i < data.length; i++) {
					let tempData = data[i];
					allWeb.push({
						entry: tempData.entry,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
					});
				}
				if (response.data.next !== null) {
					await getNextPageWebEngagement(
						processNextpageUrl(response.data.next),
						allWeb
					);
				}
				setWebEngagementChartData(allWeb);
			})
			.catch((error) => {
				console.log(error);
				setWebEngagementChartData([]);
			})
			.finally(() => {
				setWebLoading(false);
			});
	};

	const debouncedGetWebData = debounce(getWebData, 1500);

	const getDerivedWebData = async (
		domain,
		gran = granularity,
		vtype = valueType,
		stype = sourceType
	) => {
		if (domain === undefined || domain === null) {
			return;
		}

		if (webCompositeDisplay) {
			getCompositeWebData(domain, gran, vtype, stype);
			return;
		}

		setDerivedWebLoading(true);
		let lst = [];
		await axios
			.get(
				getLunaUrl(
					`v1/web-engagement-derived-calc/?domain=${domain}&pit=NOW&granularity=${gran}&value_type=${vtype}&source_type=${stype}`
				),
				getNumericalHeader()
			)
			.then(async (response) => {
				if (response.data.results.length === 0) {
					setDerivedWebChartData([]);
					return;
				}
				const res = response.data.results;
				let curDomain = res[0].company.domain;
				if (curDomain !== domainRef.current) {
					return;
				}
				for (let i = 0; i < res.length; i++) {
					let tempData = res[i];
					lst.push({
						entry: tempData.entry,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
						granularity: tempData.granularity,
						source_type: tempData.source_type,
						value_type: tempData.value_type,
					});
				}
				if (response.data.next !== null) {
					await getNextPageDerivedWeb(
						processNextpageUrl(response.data.next),
						lst
					);
				}
				setDerivedWebChartData(lst);
			})
			.catch((error) => {
				console.log(error);
				setDerivedWebChartData([]);
			})
			.finally(() => {
				setDerivedWebLoading(false);
			});
	};

	const debouncedGetDerivedWebData = debounce(getDerivedWebData, 1500);

	const getCompositeWebData = async (domain, gran, vtype, stype) => {
		setDerivedWebLoading(true);
		let organicList = [],
			totalList = [],
			paidList = [];
		let prefixes = ["organic_", "total_", "paid_"];
		try {
			let res = await Promise.all(
				prefixes.map((p) =>
					axios.get(
						getLunaUrl(
							`v1/web-engagement-derived-calc/?domain=${domain}&pit=NOW&granularity=${gran}&value_type=${vtype}&source_type=${
								p + stype
							}&size=1000`
						),
						getNumericalHeader()
					)
				)
			);
			res.forEach((v, i) => {
				let data = v.data.results;
				let lst = i === 0 ? organicList : i === 1 ? totalList : paidList;
				for (const tempData of data) {
					lst.push({
						entry: tempData.entry,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
						granularity: tempData.granularity,
						source_type: tempData.source_type,
						value_type: tempData.value_type,
					});
				}
			});
			setWebOrganicChartData(organicList);
			setWebTotalChartData(totalList);
			setWebPaidChartData(paidList);
			setDerivedWebLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getYoyWeb = async (domain) => {
		if (domain === undefined || domain === null) {
			return;
		}

		try {
			let results = await axios.get(
				getLunaUrl(
					`v1/web-engagement-derived/yony/?domain=${domain}&source_type=${sourceTypeYoy}&granularity=${granYoy}&value_type=${valueTypeYoy}`
				),
				getNumericalHeader()
			);
			let res = results.data.results;
			if (res.length === 0) {
				setYoyWebChartData([]);
			}
			let curDomain = res[0].company.domain;
			if (curDomain !== domainRef.current) {
				return;
			}
			let lst = [];
			for (const tempData of res) {
				if (tempData.yoy === 0 && tempData.yo2y === 0 && tempData.yo3y === 0) {
					continue;
				}
				lst.push({
					id: tempData.id,
					yoy: tempData.yoy,
					yo2y: tempData.yo2y,
					yo3y: tempData.yo3y,
					start_date: tempData.start_date,
					end_date: tempData.end_date,
					company: tempData.company,
				});
			}
			while (results.data.next !== null) {
				results = await axios.get(
					processNextpageUrl(results.data.next),
					getNumericalHeader()
				);
				for (let tempData of results.data.results) {
					if (
						tempData.yoy === 0 &&
						tempData.yo2y === 0 &&
						tempData.yo3y === 0
					) {
						continue;
					}
					lst.push({
						id: tempData.id,
						yoy: tempData.yoy,
						yo2y: tempData.yo2y,
						yo3y: tempData.yo3y,
						start_date: tempData.start_date,
						end_date: tempData.end_date,
						company: tempData.company,
					});
				}
			}
			lst = lst.filter((v, i, arr) => i == arr.findIndex((t) => t.id === v.id));
			setYoyWebChartData(lst);
			setYoyWebLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const debouncedGetYoyWeb = debounce(getYoyWeb, 1500);

	const downloadAllIndex = async () => {
		setIndexDownloading(true);
		let zip = new JSZip();
		for (let ind of indexFields) {
			let total = [];
			let ticker = ind["Symbol"];
			await axios
				.get(
					getLunaUrl(
						`v1/email-index/query/smoothed/?domain=${ind.Domain}&category=${ind.Category}&startDate=${startDate}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let data = response.data;
					for (let i = 1; i < data.length; ++i) {
						let date = moment(data[i].end_date);
						if (date.isAfter("2019-05-01") && date.isBefore("2021-11-08")) {
							let volume =
								(data[i - 1].smoothed_total_volume +
									data[i].smoothed_total_volume) /
								2;
							total.push({
								Ticker: ticker,
								Brand: ind.Brand,
								Category: data[i].category,
								"Current Index": rounding(volume, 2),
								"Week End": date.format("YYYY-MM-DD"),
							});
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let csvContent = toCsv(pivot(total));
			zip.file(
				ticker + "-" + ind.Domain + "-" + ind.Category + ".csv",
				csvContent
			);
		}
		zip.generateAsync({ type: "blob" }).then(function (blob) {
			saveAs(blob, "RandomWalkConfirmationIndices2022.zip");
		});
		setIndexDownloading(false);
	};

	const pivot = (arr) => {
		var mp = new Map();

		function setValue(a, path, val) {
			if (Object(val) !== val) {
				// primitive value
				var pathStr = path.join(".");
				var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
				a[i] = val;
			} else {
				for (var key in val) {
					setValue(a, key === "0" ? path : path.concat(key), val[key]);
				}
			}
			return a;
		}

		var result = arr.map((obj) => setValue([], [], obj));
		return [[...mp.keys()], ...result];
	};

	const toCsv = (arr) => {
		return arr
			.map((row) =>
				row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(",")
			)
			.join("\n");
	};

	const selectWebSubDomain = (domain) => {
		setWebSubDomain(domain);
		// getWebData(domain, false);
		debouncedGetWebData(domain, false);
		debouncedGetDerivedWebData(domain);
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	const handleTickerSelection = (newTicker) => {
		let newDomains = uniqueDomains
			.filter((domain) => {
				return (
					domain.domain === domain.parent_domain && domain.ticker === newTicker
				);
			})
			.sort();
		let newDomain = newDomains[0];

		setSelectedTicker(newTicker);
		setSelectedTickerDomains(newDomains);
		setSelectedDomain(newDomain);
		let tmp = allDomainCategories.get(newDomain.name) || [""];
		if (tmp) {
			setDomainCategories(tmp.sort());
			setSelectedCategory(decodeURI(tmp.sort()[0]));
		} else {
			setDomainCategories([]);
			setSelectedCategory("");
		}
	};

	const handleDomainSelection = (newDomain) => {
		setSelectedDomain(newDomain);

		let newCategories = (
			allDomainCategories.get(newDomain.name) || [""]
		).sort();
		setDomainCategories(newCategories || [""]);
		setSelectedCategory(decodeURI(newCategories[0]));
	};

	const emailVolumeImgDownload = () => {
		if (emailVolumeGraphRef !== null) {
			chartJSGraphDownload(
				emailVolumeGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					" Email_Volume_Weekly.png",
				`${selectedDomain.name} (${selectedTicker}) 
					${capitalize(selectedCategory)} Email Volume Trends (Weekly)`
			);
		}
	};

	const emailIndexImgDownload = () => {
		if (emailIndexGraphRef !== null) {
			// let temp = cloneDeep(emailIndexGraphRef.current.chartInstance);
			// temp.options.title = {
			// 	...temp.options.title,
			// 	display: true,
			// };
			chartJSGraphDownload(
				emailIndexGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					" Category_Indexing.png",
				`${selectedDomain.name} (${selectedTicker})
					${capitalize(selectedCategory)} Volume Index Trends (Weekly)`
			);
		}
	};

	const emailFinalIndexImgDownload = () => {
		if (promotionalGraphRef !== null) {
			chartJSGraphDownload(
				promotionalGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					" Promotional_Indexing.png",
				`${selectedDomain.name} (${selectedTicker}) Final Index Trends
				(Weekly, All Categories)`
			);
		}
	};

	const discountPctImgDownload = () => {
		if (discountPctGraphRef !== null) {
			chartJSGraphDownload(
				discountPctGraphRef.current.chartInstance,
				selectedDomain.domain +
					" " +
					selectedCategory +
					" %Promotional_Email_Sale_Announcements_weekly.png",
				`${selectedDomain.name} ${capitalize(selectedCategory)} %Email
					Sale Announcement (Weekly)`
			);
		}
	};

	const discountStateImgDownload = () => {
		if (discountStateGraphRef !== null) {
			chartJSGraphDownload(
				discountStateGraphRef.current.chartInstance,
				selectedDomain.domain +
					" " +
					selectedCategory +
					" $Promotional_Email_Sale_Announcements_weekly.png",
				`${selectedDomain.name} ${capitalize(selectedCategory)} $Email
					Sale Announcement (Weekly)`
			);
		}
	};

	const webEngagementImgDownload = () => {
		if (webEngagementGraphRef !== null) {
			chartJSGraphDownload(
				webEngagementGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					" WebEngagement_weekly.png",
				`${selectedDomain.name} Web Engagement Trends (Weekly)`
			);
		}
	};

	const derivedWebImgDownload = () => {
		if (derivedWebGraphRef !== null) {
			chartJSGraphDownload(
				derivedWebGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					"Derived_WebEngagement.png",
				`${selectedDomain.name} Derived Web Engagement Composite Trends`
			);
		}
	};

	const yoyWebImgDownload = () => {
		if (yoyWebGraphRef !== null) {
			chartJSGraphDownload(
				yoyWebGraphRef.current.chartInstance,
				selectedDomain.ticker +
					" " +
					selectedDomain.domain +
					"WebEngagement_Composite.png",
				`${selectedDomain.name} Yoy Web Engagement Composite Trends`
			);
		}
	};

	const emailVolumeCsvDownload = () => {
		let data = [
			["Dates", ...emailDisplayedDates],
			["Total Volume", ...emailVolumeTotalData],
			["Read Volume", ...emailVolumeReadData],
			["Deleted Volume", ...emailVolumeDeletedData],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.ticker +
				" " +
				selectedDomain.domain +
				" Email_Volume_Weekly.csv"
		);
	};

	const emailIndexCsvDownload = () => {
		let data = [
			["Dates", ...indexDisplayedDates],
			["Total Volume", ...categoryIndexTotalData],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.ticker +
				" " +
				selectedDomain.domain +
				" Category_Indexing.csv"
		);
	};

	const emailFinalIndexCsvDownload = () => {
		let data = [
			["Dates", ...indexDisplayedDates],
			["Final Index", ...promotionalData],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.ticker +
				" " +
				selectedDomain.domain +
				" Promotional_Indexing.csv"
		);
	};

	const discountPctCsvDownload = () => {
		let data = [
			["Dates", ...discountDisplayedDates],
			["1-25% discount", ...discountFirstFrac],
			["26-50% discount", ...discountSecFrac],
			["51-75% discount", ...discountThirdFrac],
			["76-100% discount", ...discountFourthFrac],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.domain +
				" " +
				selectedCategory +
				" %Email_Sale_Announcement_Weekly.csv"
		);
	};

	const discountStateCsvDownload = () => {
		let data = [
			["Dates", ...discountDisplayedDates],
			["$20 off or less", ...discountStateFirstFrac],
			["$21 to $50 off", ...discountStateSecFrac],
			["$51 to $100 off", ...discountStateThirdFrac],
			["$101 to $200 off", ...discountStateFourthFrac],
			["More than $200 off", ...discountStateFifthFrac],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.domain +
				" " +
				selectedCategory +
				" $Email_Sale_Announcement_Weekly.csv"
		);
	};

	const webEngagementCsvDownload = () => {
		let data = [
			["Dates", ...webDisplayedDates],
			["Recent Year", ...webEngagementData],
			["Prior Year", ...prevWebEngagementData],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.ticker +
				" " +
				selectedDomain.domain +
				" WebEngagement_Weekly.csv"
		);
	};

	const derivedWebCsvDownload = () => {
		let data = [
			["Dates", ...derivedWebDates],
			["Recent Year", ...derivedWebData],
			["Prior Year", ...prevDerivedWebData],
		];

		chartJSCSVDownload(
			data,
			selectedDomain.ticker +
				" " +
				selectedDomain.domain +
				"Derived_WebEngagement.csv"
		);
	};

	const handleGran = (gran) => {
		setGran(gran);
		let vtype = Object.keys(valueList[gran])[0] || "";
		setValueType(vtype);
		let stype = valueList[gran][vtype][0] || "";
		setSourceType(stype);
		if (gran === "monthly") setWebCompositeDisplay(true);
		else setWebCompositeDisplay(false);
	};

	const handleGranYoy = (gran) => {
		setGranYoy(gran);
		let vtype = Object.keys(valueListYoy[gran])[0] || "";
		setValueTypeYoy(vtype);
		setSourceTypeYoy(valueListYoy[gran][vtype][0] || "");
	};

	const copyLink = () => {
		// navigator.clipboard.writeText(currentLink);
		// toast.info("link copied to clipboard");
		let textArea = document.createElement("textarea");
		textArea.value = currentLink;
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		let tmp = new Promise((res, rej) => {
			document.execCommand("copy") ? res() : rej();
			textArea.remove();
		});
		tmp
			.then(() => {
				toast.info("link copied to clipboard");
			})
			.catch((err) => {});
	};

	const renderDownloadBtn = (imgDownload, csvDownload) => {
		return (
			<Dropdown className="cloud-download-btn">
				<Dropdown.Toggle style={{ width: "100%" }} variant="outline-primary">
					<i className="dripicons-cloud-download"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ width: "100%" }}>
					<Dropdown.Item onClick={imgDownload}>as Image</Dropdown.Item>
					<Dropdown.Item onClick={csvDownload}>as CSV</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const getDiscountData = async () => {
		setPctTableData([]);
		setStateTableData([]);
		try {
			let { data } = await axios.get(
				getLunaUrl(
					`v1/emailfeed/data/min/?category=${selectedCategory}&domain=${selectedDomain.domain}&startDate=${startDate}&page=2&require_discount=1`
				),
				getNumericalHeader()
			);
			if (data.length <= 0) {
				setDiscountChartData([]);
				setDiscountChartLoading(false);
				return;
			}
			let tmp = [];
			for (let d of data) {
				let week = moment(d.email_detail__end_date);
				let day = week.day();
				if (day !== 0) {
					week.day(7);
				}
				tmp.push({
					subject: d.email_detail__subject,
					start_date: d.email_detail__start_date,
					end_date: d.email_detail__end_date,
					week: week.format("YYYY-MM-DD"),
					total_volume: d.project__total_volume,
					image_url: d.email_detail__image_url,
					state_discount: d.harmonic_discount__state_discount_val,
					effective_discount_pct: d.harmonic_discount__effective_discount_pct,
				});
			}
			setDiscountChartData(tmp);
		} catch (e) {
			console.log(e);
			setDiscountChartData([]);
		} finally {
			setDiscountChartLoading(false);
		}
	};

	const getBillBoardData = async () => {
		const min_date = moment("2021-10-04");
		let current = moment();
		let diffWeeks = Math.ceil(
			Math.abs(current.diff(min_date)) / (1000 * 60 * 60 * 24) / 7
		);
		let tempWeekList = [];
		for (let i = 0; i < diffWeeks; i++) {
			let tempDate = moment(min_date);
			tempDate.add(i * 7 - 1, "d");
			tempWeekList.push(tempDate.format("YYYY-MM-DD"));
		}
		setBillboardTimeList(tempWeekList);
		setBillboardDataFetched(true);
	};

	const getCategoryIndexData = async (domain, category) => {
		await axios
			.get(
				getLunaUrl(
					displayUSData
						? `v1/email-index/query/smoothed/?domain=${domain}&category=${category}&country=US`
						: `v1/email-index/query/smoothed/?domain=${domain}&category=${category}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				let data = response.data;
				let curDomain = data[0].domain;
				let curCate = data[0].category;
				if (curDomain !== domainRef.current || curCate !== cateRef.current) {
					return;
				} else if (data.length === 0) {
					setCategoryIndexChartData([]);
				} else {
					let smoothed = [];
					for (const row of data) {
						smoothed.push({
							...row,
							adjusted_total_volume: row.smoothed_total_volume,
							final_index: row.smoothed_final_index,
						});
					}
					setCategoryIndexChartData(smoothed);
				}
			})
			.catch((e) => {
				console.log(e);
				setCategoryIndexChartData([]);
			})
			.finally(() => {
				setCategoryIndexLoading(false);
			});
	};

	useEffect(() => {
		document.title = "Composite Chart";
		let params = window.location.hash.split("?")[1];
		if (!params) return;
		params = params.split("&");
		let obj = {};
		for (let item of params) {
			let key = item.split("=")[0];
			let value = item.split("=")[1];
			if (key === "category") {
				obj[key] = value.replace("%20", " ");
			} else {
				obj[key] = value;
			}
		}
		setUrlParams(obj);
	}, []);

	useEffect(() => {
		if (!loaded) return;
		const fetchData = async () => {
			await getAirTableData();

			let res = tickerCate;
			let companies = Object.keys(res).sort();
			let domainCategories = new Map();

			for (let i = 0; i < companies.length; i++) {
				let oneCompany = res[companies[i]];
				let domains = Object.keys(oneCompany);

				for (let j = 0; j < domains.length; j++) {
					if (domainCategories.get(domains[j])) {
						domainCategories.set(domains[j], [
							...domainCategories.get(domains[j]),
							...oneCompany[domains[j]],
						]);
					} else {
						domainCategories.set(domains[j], oneCompany[domains[j]]);
					}
				}
			}
			while (companies[0] === "") {
				companies.splice(0, 1);
			}
			let cacheTicker =
				urlParams["ticker"] || sessionStorage.getItem("selectedTicker");
			let initialDomains = uniqueDomains
				.filter((domain) => {
					return (
						domain.domain === domain.parent_domain &&
						domain.ticker === (cacheTicker ? cacheTicker : companies[0])
					);
				})
				.sort();
			let cacheDomain = initialDomains.filter((domain) => {
				return domain.domain === urlParams["domain"];
			})[0];
			let initialDomain =
				cacheDomain ||
				(Object.keys(selectedDomain).length === 0 &&
				sessionStorage.getItem("selectedDomain") !== "{}"
					? JSON.parse(sessionStorage.getItem("selectedDomain"))
					: initialDomains[0]);

			if (selectedTicker === "" && cacheTicker !== "") {
				setSelectedTicker(cacheTicker);
			} else {
				setSelectedTicker(tickers[0]);
			}
			let cacheCate = domainCategories
				.get(initialDomain.name)
				.includes(urlParams["category"]);
			setSelectedTickerDomains(initialDomains);
			setSelectedDomain(initialDomain);
			setAllDomainCategories(domainCategories);
			setDomainCategories(
				(domainCategories.get(initialDomain.name) || [""]).sort()
			);
			setSelectedCategory(
				decodeURI(
					urlParams["category"] ||
						(selectedCategory === "" &&
						sessionStorage.getItem("selectedCategory") !== ""
							? sessionStorage.getItem("selectedCategory")
							: (domainCategories.get(initialDomain.name) || [""]).sort()[0])
				)
			);
			setInitialFetched(true);
		};
		const getAirTableData = async () => {
			var fullList = [];
			await axios
        .get(
          `${corsAnywhereUrl}https://api.airtable.com/v0/${table_key}/billboard_list`, {
            headers: {
              'Authorization': `Bearer ${api_key}`,
              'Access-Control-Allow-Origin': '*',
            }
          }
        )
				.then(async (response) => {
					for (let r of response.data.records) {
						fullList.push(r.fields);
					}
					if (response.data.offset) {
						await getNextPageAirTable(response.data.offset, fullList);
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let toberemoved = fullList.filter((e) => e["Symbol"] === "Symbol");
			for (let e of toberemoved) {
				fullList.splice(fullList.indexOf(e), 1);
			}
			setIndexFields(fullList);
		};
		fetchData();
	}, [loaded]);

	useEffect(() => {
		if (!sessionStorage.getItem("selectedTicker") || selectedTicker !== "") {
			sessionStorage.setItem("selectedTicker", selectedTicker);
		}
	}, [selectedTicker]);

	useEffect(() => {
		let newDomain = JSON.stringify(selectedDomain);
		if (!sessionStorage.getItem("selectedDomain") || newDomain !== "{}") {
			sessionStorage.setItem("selectedDomain", newDomain);
		}
		domainRef.current = selectedDomain.domain;
		setWebSubDomain(selectedDomain.domain);
	}, [selectedDomain]);

	useEffect(() => {
		if (
			!sessionStorage.getItem("selectedCategory") ||
			selectedCategory !== ""
		) {
			sessionStorage.setItem("selectedCategory", selectedCategory);
		}
		cateRef.current = selectedCategory;
	}, [selectedCategory]);

	useEffect(() => {
		const getDomainData = async (domain, category) => {
			if (!domain) {
				return;
			}
			if (!category) {
				setEmailVolumeChartData([]);
				setDiscountChartData([]);
				setBillboardTimeList([]);
				setCategoryIndexChartData([]);
				return;
			}

			setEmailVolumeLoading(true);
			setCategoryIndexLoading(true);
			setDiscountChartLoading(true);
			setWebLoading(true);
			setDerivedWebLoading(true);
			setYoyWebLoading(true);

			let start_date = "";
			await axios
				.get(
					getLunaUrl(
						`${
							displayUSData
								? "v1/emailfeed/weekly-volume/us/batch/"
								: "v1/emailfeed/weekly-volume/batch/"
						}?domain=${domain}&category=${category}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let curDomain = response.data[0].domain.toLowerCase();
					let curCate = response.data[0].category.toLowerCase();
					if (curDomain !== domainRef.current || curCate !== cateRef.current) {
						return;
					} else if (response.data.length === 0) {
						setEmailVolumeChartData([]);
					} else {
						setEmailVolumeChartData(response.data);
						let tempminStartDate = new Date(response.data[0].start_date);
						let tempminStartDateIndex = 0;

						let tempmaxStartDate = new Date(response.data[0].start_date);
						let tempmaxStartDateIndex = 0;

						let tempminEndDate = new Date(response.data[0].end_date);
						let tempminEndDateIndex = 0;

						let tempmaxEndDate = new Date(response.data[0].end_date);
						let tempmaxEndDateIndex = 0;

						for (let i = 0; i < response.data.length; i++) {
							let newDate = new Date(response.data[i].start_date);
							if (tempminStartDate > newDate) {
								tempminStartDate = newDate;
								tempminStartDateIndex = i;
							}
							if (tempmaxStartDate < newDate) {
								tempmaxStartDate = newDate;
								tempmaxStartDateIndex = i;
							}

							let newEnd = new Date(response.data[i].end_date);
							if (tempminEndDate > newEnd) {
								tempminEndDate = newEnd;
								tempminEndDateIndex = i;
							}
							if (tempmaxEndDate < newEnd) {
								tempmaxEndDate = newEnd;
								tempmaxEndDateIndex = i;
							}
						}

						let newStartDate = new Date(
							response.data[tempmaxEndDateIndex].end_date
						);
						var year = newStartDate.getFullYear();
						var month = newStartDate.getMonth();
						var day = newStartDate.getDate();
						var c = new Date(year - 2, month, day);

						month = "" + (c.getMonth() + 1);
						day = "" + c.getDate();
						year = c.getFullYear();

						if (month.length < 2) month = "0" + month;
						if (day.length < 2) day = "0" + day;

						newStartDate = [year, month, day].join("-");

						setminStartDate(response.data[tempminStartDateIndex].start_date);
						setmaxStartDate(response.data[tempmaxStartDateIndex].start_date);

						if (tempStartDate === "") {
							start_date = newStartDate;
							setTempStartDate(newStartDate);
						} else {
							start_date = tempStartDate;
						}
						setStartDate(start_date);

						if (
							minEndDate === "" ||
							new Date(response.data[tempminEndDateIndex].end_date) <
								new Date(minEndDate)
						) {
							setminEndDate(response.data[tempminEndDateIndex].end_date);
						}

						if (
							maxEndDate === "" ||
							new Date(response.data[tempmaxEndDateIndex].end_date) >
								new Date(maxEndDate)
						) {
							setmaxEndDate(response.data[tempmaxEndDateIndex].end_date);

							setEndDate(response.data[tempmaxEndDateIndex].end_date);
							setTempEndDate(response.data[tempmaxEndDateIndex].end_date);
						}
					}
				})
				.catch((e) => {
					console.log(e);
					setEmailVolumeChartData([]);
				})
				.finally(() => {
					setDataFetched(true);
					setEmailVolumeLoading(false);
				});
			getCategoryIndexData(domain, category);
			getBillBoardData();
			getDiscountData();
		};
		const debouncedGetDomainData = debounce(getDomainData, 1500);

		debouncedGetDomainData(selectedDomain.domain, selectedCategory);
		debouncedGetWebData(selectedDomain.domain);
		debouncedGetDerivedWebData(selectedDomain.domain);
		debouncedGetYoyWeb(selectedDomain.domain);
	}, [selectedDomain]);

	useEffect(() => {
		const getDomainData = async (domain, category) => {
			if (!domain) {
				return;
			}
			if (!category) {
				setEmailVolumeChartData([]);
				setDiscountChartData([]);
				setBillboardTimeList([]);
				setCategoryIndexChartData([]);
				return;
			}

			setEmailVolumeLoading(true);
			setCategoryIndexLoading(true);
			setDiscountChartLoading(true);

			let start_date = "";
			await axios
				.get(
					getLunaUrl(
						`${
							displayUSData
								? "v1/emailfeed/weekly-volume/us/batch/"
								: "v1/emailfeed/weekly-volume/batch/"
						}?domain=${domain}&category=${category}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let curDomain = response.data[0].domain.toLowerCase();
					let curCate = response.data[0].category.toLowerCase();
					if (curDomain !== domainRef.current || curCate !== cateRef.current) {
						return;
					} else if (response.data.length === 0) {
						setEmailVolumeChartData([]);
					} else {
						setEmailVolumeChartData(response.data);
						let tempminStartDate = new Date(response.data[0].start_date);
						let tempminStartDateIndex = 0;

						let tempmaxStartDate = new Date(response.data[0].start_date);
						let tempmaxStartDateIndex = 0;

						let tempminEndDate = new Date(response.data[0].end_date);
						let tempminEndDateIndex = 0;

						let tempmaxEndDate = new Date(response.data[0].end_date);
						let tempmaxEndDateIndex = 0;

						for (let i = 0; i < response.data.length; i++) {
							let newDate = new Date(response.data[i].start_date);
							if (tempminStartDate > newDate) {
								tempminStartDate = newDate;
								tempminStartDateIndex = i;
							}
							if (tempmaxStartDate < newDate) {
								tempmaxStartDate = newDate;
								tempmaxStartDateIndex = i;
							}

							let newEnd = new Date(response.data[i].end_date);
							if (tempminEndDate > newEnd) {
								tempminEndDate = newEnd;
								tempminEndDateIndex = i;
							}
							if (tempmaxEndDate < newEnd) {
								tempmaxEndDate = newEnd;
								tempmaxEndDateIndex = i;
							}
						}

						let newStartDate = new Date(
							response.data[tempmaxEndDateIndex].end_date
						);
						var year = newStartDate.getFullYear();
						var month = newStartDate.getMonth();
						var day = newStartDate.getDate();
						var c = new Date(year - 2, month, day);

						month = "" + (c.getMonth() + 1);
						day = "" + c.getDate();
						year = c.getFullYear();

						if (month.length < 2) month = "0" + month;
						if (day.length < 2) day = "0" + day;

						newStartDate = [year, month, day].join("-");

						setminStartDate(response.data[tempminStartDateIndex].start_date);
						setmaxStartDate(response.data[tempmaxStartDateIndex].start_date);

						if (tempStartDate === "") {
							start_date = newStartDate;
							setTempStartDate(newStartDate);
						} else {
							start_date = tempStartDate;
						}
						setStartDate(start_date);

						if (
							minEndDate === "" ||
							new Date(response.data[tempminEndDateIndex].end_date) <
								new Date(minEndDate)
						) {
							setminEndDate(response.data[tempminEndDateIndex].end_date);
						}

						if (
							maxEndDate === "" ||
							new Date(response.data[tempmaxEndDateIndex].end_date) >
								new Date(maxEndDate)
						) {
							setmaxEndDate(response.data[tempmaxEndDateIndex].end_date);

							setEndDate(response.data[tempmaxEndDateIndex].end_date);
							setTempEndDate(response.data[tempmaxEndDateIndex].end_date);
						}
					}
				})
				.catch((e) => {
					console.log(e);
					setEmailVolumeChartData([]);
				})
				.finally(() => {
					setDataFetched(true);
					setEmailVolumeLoading(false);
				});
			getCategoryIndexData(domain, category);
			getBillBoardData();
			getDiscountData();
		};
		const debouncedGetDomainData = debounce(getDomainData, 1500);

		debouncedGetDomainData(selectedDomain.domain, selectedCategory);
	}, [selectedCategory]);

	useEffect(() => {
		const getDomainData = async (domain, category) => {
			if (!domain) {
				return;
			}
			if (!category) {
				setEmailVolumeChartData([]);
				return;
			}

			setEmailVolumeLoading(true);

			await axios
				.get(
					getLunaUrl(
						`${
							displayUSData
								? "v1/emailfeed/weekly-volume/us/batch/"
								: "v1/emailfeed/weekly-volume/batch/"
						}?domain=${domain}&category=${category}`
					),
					getNumericalHeader()
				)
				.then((response) => {
					let curDomain = response.data[0].domain.toLowerCase();
					let curCate = response.data[0].category.toLowerCase();
					if (curDomain !== domainRef.current || curCate !== cateRef.current) {
						return;
					} else if (response.data.length === 0) {
						setEmailVolumeChartData([]);
					} else {
						setEmailVolumeChartData(response.data);
					}
				})
				.catch((e) => {
					console.log(e);
					setEmailVolumeChartData([]);
				})
				.finally(() => {
					setDataFetched(true);
					setEmailVolumeLoading(false);
				});
		};
		getDomainData(selectedDomain.domain, selectedCategory);
		setCategoryIndexLoading(true);
		getCategoryIndexData(selectedDomain.domain, selectedCategory);
	}, [displayUSData]);

	useEffect(() => {
		const link = `${window.location.origin}/#/composite-chart/?ticker=${selectedTicker}&domain=${selectedDomain.domain}&category=${selectedCategory}`;
		setCurrentLink(link);
		setEmailVolumeChartData([]);
		setDiscountChartData([]);
		setBillboardTimeList([]);
		setCategoryIndexChartData([]);
	}, [selectedTicker, selectedDomain, selectedCategory]);

	useEffect(() => {
		debouncedGetDerivedWebData(selectedDomain.domain);
	}, [granularity, sourceType, valueType]);

	useEffect(() => {
		debouncedGetYoyWeb(selectedDomain.domain);
	}, [granYoy, sourceTypeYoy, valueTypeYoy]);

	if (!initialFetched) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Composite Email Volume Trends (Weekly)</h4>
			<DomainPanel
				selectedTicker={selectedTicker}
				selectedDomain={selectedDomain}
				selectedCategory={selectedCategory}
				selectedTickerDomains={selectedTickerDomains}
				uniqueTickers={tickers}
				uniqueDomains={uniqueDomains}
				domainCategories={domainCategories}
				handleTickerSelection={handleTickerSelection}
				handleDomainSelection={handleDomainSelection}
				setSelectedCategory={setSelectedCategory}
				tempStartDate={tempStartDate}
				setTempStartDate={setTempStartDate}
				changeStartDate={changeStartDate}
				tempEndDate={tempEndDate}
				setTempEndDate={setTempEndDate}
				changeEndDate={changeEndDate}
				useDataToggle={true}
				displayUSData={displayUSData}
				onDataToggleChange={setDisplayUSData}
			/>
			<Row>
				<Col sm={12} md={3}>
					<div className="card">
						<div className="card-body composite-nav">
							<h4>Navigation: </h4>
							<p>
								<HashLink to="#EmailVolumeTrends">Email Volume Trends</HashLink>
							</p>
							<p>
								<HashLink to="#IndexTrends">Volume Index Trends</HashLink>
							</p>
							<p>
								<HashLink to="#FinalIndexTrends">Final Index Trends</HashLink>
							</p>
							<p>
								<HashLink to="#%EmailSaleAnnouncement">
									% Email Sale Announcement
								</HashLink>
							</p>
							<p>
								<HashLink to="#$EmailSaleAnnouncement">
									$ Email Sale Announcement
								</HashLink>
							</p>
							<p>
								<HashLink to="#WebEngagementTrends">
									Web Engagement Trends
								</HashLink>
							</p>
							<p>
								<HashLink to="#DerivedWebEngagement">
									Derived Web Engagement Composite Trends
								</HashLink>
							</p>
							<p>
								<HashLink to="#YoyWebEngagement">
									Yoy Web Engagement Composite Trends
								</HashLink>
							</p>
						</div>
					</div>
					<div className="card">
						<div className="card-body composite-nav">
							<h4>Last Updated Datapoints: </h4>
							<p>
								<b>Email Feed: </b>
								<span
									style={{
										color:
											FIstatus === "valid"
												? "green"
												: FIstatus === "late"
												? "red"
												: "black",
										fontWeight: "bold",
									}}
								>
									{earliestFI === "" ? "calculating..." : earliestFI}
								</span>
								{` (should be: ${lastSundayFI})`}
							</p>
							<p>
								<b>Web Engagement: </b>
								<span
									style={{
										color:
											Yoystatus === "valid"
												? "green"
												: Yoystatus === "late"
												? "red"
												: "black",
										fontWeight: "bold",
									}}
								>
									{earliestYoy === "" ? "calculating..." : earliestYoy}
								</span>
								{` (should be: ${lastSundayYoy})`}
							</p>
						</div>
					</div>
					<div className="card">
						<div className="card-body">
							<h4>Current Link: </h4>
							<p id="currentLink">
								{`${window.location.origin}/#/composite-chart/?ticker=${selectedTicker}&domain=${selectedDomain.domain}&category=${selectedCategory}`}
							</p>
							<Button variant="outline-primary" onClick={copyLink}>
								Copy link
							</Button>
						</div>
					</div>
				</Col>

				<Col sm={12} md={9}>
					<div className="card">
						<div className="card-title"></div>
						<div className="card-body" style={{ overflow: "scroll" }}>
							<div style={{ minWidth: "577px" }}>
								<div
									style={{ position: "relative", top: "-70px" }}
									id="EmailVolumeTrends"
								></div>
								<div
									className={
										displayEmailTrend ? "collapsible-active" : "collapsible"
									}
									onClick={() => setDisplayEmailTrend(!displayEmailTrend)}
								></div>
								{displayEmailTrend ? (
									<>
										<Row style={{ justifyContent: "center" }}>
											<EmailTrendChart
												dataFetched={dataFetched}
												isLoading={emailVolumeLoading}
												chartData={emailVolumeChartData}
												startDate={startDate}
												endDate={endDate}
												graphRef={emailVolumeGraphRef}
												displayedDates={emailDisplayedDates}
												setDisplayedDates={setEmailDisplayedDates}
												totalData={emailVolumeTotalData}
												setTotalData={setEmailVolumeTotalData}
												readData={emailVolumeReadData}
												setReadData={setEmailVolumeReadData}
												deletedData={emailVolumeDeletedData}
												setDeletedData={setEmailVolumeDeletedData}
												title={`${
													selectedDomain.name
												} (${selectedTicker}) ${capitalize(
													selectedCategory
												)} Email Volume Trends (Weekly)`}
											/>
										</Row>
										{renderDownloadBtn(
											emailVolumeImgDownload,
											emailVolumeCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="IndexTrends"
								></div>
								<div
									className={
										displayEmailIndex ? "collapsible-active" : "collapsible"
									}
									onClick={() => setDisplayEmailIndex(!displayEmailIndex)}
									id="IndexTrends"
								></div>
								{displayEmailIndex ? (
									<>
										<Row style={{ justifyContent: "center" }}>
											<EmailIndexTrendChart
												dataFetched={dataFetched}
												isLoading={categoryIndexLoading}
												chartData={categoryIndexChartData}
												startDate={startDate}
												endDate={endDate}
												title={`${selectedDomain.name} (${
													selectedDomain.ticker
												}) ${capitalize(
													selectedCategory
												)} Volume Index Trends (Weekly)`}
												displayTitle={displayTitle}
												graphRef={emailIndexGraphRef}
												totalData={categoryIndexTotalData}
												setTotalData={setCategoryIndexTotalData}
												displayedDates={indexDisplayedDates}
												setDisplayedDates={setIndexDisplayedDates}
											/>
										</Row>
										{renderDownloadBtn(
											emailIndexImgDownload,
											emailIndexCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="FinalIndexTrends"
								></div>
								<div
									className={
										displayEmailFinal ? "collapsible-active" : "collapsible"
									}
									onClick={() => setDisplayEmailFinal(!displayEmailFinal)}
								></div>
								{displayEmailFinal ? (
									<>
										<Row style={{ justifyContent: "center" }}>
											<EmailFinalIndexChart
												dataFetched={dataFetched}
												isLoading={categoryIndexLoading}
												chartData={categoryIndexChartData}
												startDate={startDate}
												endDate={endDate}
												graphRef={promotionalGraphRef}
												totalData={promotionalData}
												setTotalData={setPromotionalData}
												displayedDates={indexDisplayedDates}
												setDisplayedDates={setIndexDisplayedDates}
												title={`${selectedDomain.name} (${selectedTicker}) Final Index Trends (Weekly, All Categories)`}
											/>
										</Row>
										{renderDownloadBtn(
											emailFinalIndexImgDownload,
											emailFinalIndexCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="%EmailSaleAnnouncement"
								></div>
								<div
									className={displayPct ? "collapsible-active" : "collapsible"}
									onClick={() => setDisplayPct(!displayPct)}
								></div>
								{displayPct ? (
									<>
										<Row style={{ justifyContent: "center" }}>
											<DiscountPctChart
												startDate={startDate}
												endDate={endDate}
												dataFetched={dataFetched}
												isLoading={discountChartLoading}
												chartData={discountChartData}
												graphRef={discountPctGraphRef}
												displayedDates={discountDisplayedDates}
												setDisplayedDates={setDiscountDisplayedDates}
												setTableData={setPctTableData}
												setDiscountFirstFrac={setDiscountFirstFrac}
												setDiscountSecFrac={setDiscountSecFrac}
												setDiscountThirdFrac={setDiscountThirdFrac}
												setDiscountFourthFrac={setDiscountFourthFrac}
												title={`${selectedDomain.name} ${capitalize(
													selectedCategory
												)} %Email Sale Announcement (Weekly)`}
											/>
											{!discountChartLoading && pctTableData.length > 0 ? (
												<div class="volTable">
													<table border="1">
														<tbody>
															<tr>
																<th>Volume</th>
																<th>Subject</th>
																<th>Effective Discount (%)</th>
															</tr>
															{pctTableData.map((elem, ind) => (
																<tr key={ind}>
																	<td>{formatBigNum(elem.total_volume)}</td>
																	<td>
																		<a
																			href={elem.image_url}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{elem.subject}
																		</a>
																	</td>
																	<td>{elem.effective_discount_pct}</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											) : (
												""
											)}
										</Row>
										{renderDownloadBtn(
											discountPctImgDownload,
											discountPctCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="$EmailSaleAnnouncement"
								></div>
								<div
									className={
										displayState ? "collapsible-active" : "collapsible"
									}
									onClick={() => setDisplayState(!displayState)}
								></div>
								{displayState ? (
									<>
										<Row style={{ justifyContent: "center" }}>
											<DiscountStateChart
												startDate={startDate}
												endDate={endDate}
												dataFetched={dataFetched}
												isLoading={discountChartLoading}
												chartData={discountChartData}
												graphRef={discountStateGraphRef}
												displayedDates={discountDisplayedDates}
												setDisplayedDates={setDiscountDisplayedDates}
												setTableData={setStateTableData}
												setStateDiscountFirstFrac={setStateDiscountFirstFrac}
												setStateDiscountSecFrac={setStateDiscountSecFrac}
												setStateDiscountThirdFrac={setStateDiscountThirdFrac}
												setStateDiscountFourthFrac={setStateDiscountFourthFrac}
												setStateDiscountFifthFrac={setStateDiscountFifthFrac}
												title={`${selectedDomain.name} ${capitalize(
													selectedCategory
												)} $Email Sale Announcement (Weekly)`}
											/>
											{!discountChartLoading && stateTableData.length > 0 ? (
												<div className="volTable">
													<table border="1">
														<tbody>
															<tr>
																<th>Volume</th>
																<th>Subject</th>
																<th>State Discount</th>
															</tr>
															{stateTableData.map((elem, ind) => (
																<tr key={ind}>
																	<td>{formatBigNum(elem.total_volume)}</td>
																	<td>
																		<a
																			href={elem.image_url}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{elem.subject}
																		</a>
																	</td>
																	<td>{elem.state_discount}</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											) : (
												""
											)}
										</Row>
										{renderDownloadBtn(
											discountStateImgDownload,
											discountStateCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="WebEngagementTrends"
								></div>
								<div
									className={displayWeb ? "collapsible-active" : "collapsible"}
									onClick={() => setDisplayWeb(!displayWeb)}
								></div>
								{displayWeb ? (
									<>
										<div className="subDomains">
											<div>Table: luna_main.rest_api_webengagementderived</div>
											SubDomains:
											<Dropdown>
												<Dropdown.Toggle
													variant="outline-dark"
													id="dropdown-basic"
													as={CustomToggle}
													className="task-dropdown-selected"
												>
													{webSubDomain}
												</Dropdown.Toggle>

												<Dropdown.Menu
													as={CustomMenu}
													className="task-dropdown"
												>
													{webDomains.map((domain) => (
														<Dropdown.Item
															key={domain.domain}
															eventKey={domain.domain}
															onClick={() => selectWebSubDomain(domain.domain)}
														>
															{domain.domain}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<br />
										<Row style={{ justifyContent: "center" }}>
											<WebEngagementChart
												dataFetched={dataFetched}
												isLoading={webLoading}
												chartData={webEngagementChartData}
												startDate={startDate}
												endDate={endDate}
												graphRef={webEngagementGraphRef}
												totalData={webEngagementData}
												setTotalData={setWebEngagementData}
												prevData={prevWebEngagementData}
												setPrevData={setPrevWebEngagementData}
												displayedDates={webDisplayedDates}
												setDisplayedDates={setWebDisplayedDates}
												title={`${selectedDomain.name} Web Engagement Trend(Weekly)`}
											/>
										</Row>
										{renderDownloadBtn(
											webEngagementImgDownload,
											webEngagementCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="DerivedWebEngagement"
								></div>
								<div
									className={
										displayDerivedWeb ? "collapsible-active" : "collapsible"
									}
									onClick={() => setDisplayDerivedWeb(!displayDerivedWeb)}
								></div>
								{displayDerivedWeb ? (
									<>
										<div>
											Table: luna_main.rest_api_webengagementderivedcalc
										</div>
										<div className="subOptions-wrapper">
											<div style={{ marginRight: 20 }}>
												Granularity{" "}
												<select
													value={granularity}
													onChange={(e) => handleGran(e.target.value)}
												>
													{granList.map((gran, index) => {
														return (
															<option value={gran} key={index}>
																{gran}
															</option>
														);
													})}
												</select>
											</div>
											<div style={{ marginRight: 20 }}>
												Value Type{" "}
												<select
													value={valueType}
													onChange={(e) => setValueType(e.target.value)}
												>
													{Object.keys(valueList[granularity]).map(
														(v, index) => {
															return (
																<option value={v} key={index}>
																	{v}
																</option>
															);
														}
													)}
												</select>
											</div>
											<div style={{ marginRight: 20 }}>
												Source Type{" "}
												<select
													value={sourceType}
													onChange={(e) => setSourceType(e.target.value)}
												>
													{(valueList[granularity][valueType] || []).map(
														(s, index) => {
															return (
																<option value={s} key={index}>
																	{s}
																</option>
															);
														}
													)}
												</select>
											</div>
										</div>

										<br />
										<Row style={{ justifyContent: "center" }}>
											{webCompositeDisplay ? (
												<WebCompositeChart
													dataFetched={dataFetched}
													isLoading={derivedWebLoading}
													organicData={webOrganicChartData}
													totalData={webTotalChartData}
													paidData={webPaidChartData}
													startDate={startDate}
													endDate={endDate}
													title={`${selectedDomain.name} Derived Web Engagement Composite Trends`}
												></WebCompositeChart>
											) : (
												<WebEngagementChart
													dataFetched={dataFetched}
													isLoading={derivedWebLoading}
													chartData={derivedWebChartData}
													startDate={startDate}
													endDate={endDate}
													graphRef={derivedWebGraphRef}
													totalData={derivedWebData}
													setTotalData={setDerivedWebData}
													prevData={prevDerivedWebData}
													setPrevData={setPrevDerivedWebData}
													displayedDates={derivedWebDates}
													setDisplayedDates={setDerivedWebDates}
													title={`${selectedDomain.name} Derived Web Engagement Composite Trends`}
												/>
											)}
										</Row>
										{renderDownloadBtn(
											derivedWebImgDownload,
											derivedWebCsvDownload
										)}
									</>
								) : (
									""
								)}

								<div
									style={{ position: "relative", top: "-70px" }}
									id="YoyWebEngagement"
								></div>
								<div
									className={displayYoy ? "collapsible-active" : "collapsible"}
									onClick={() => setDisplayYoy(!displayYoy)}
								></div>
								{displayYoy ? (
									<>
										<div>
											Table: luna_main.rest_api_webengagementderivedcalcyony
										</div>
										<div className="subOptions-wrapper">
											<div style={{ marginRight: 20 }}>
												Granularity{" "}
												<select
													value={granYoy}
													onChange={(e) => handleGranYoy(e.target.value)}
												>
													{granListYoy.map((gran, index) => {
														return (
															<option value={gran} key={index}>
																{gran}
															</option>
														);
													})}
												</select>
											</div>
											<div style={{ marginRight: 20 }}>
												Value Type{" "}
												<select
													value={valueTypeYoy}
													onChange={(e) => setValueTypeYoy(e.target.value)}
												>
													{Object.keys(valueListYoy[granYoy]).map(
														(v, index) => {
															return (
																<option value={v} key={index}>
																	{v}
																</option>
															);
														}
													)}
												</select>
											</div>
											<div style={{ marginRight: 20 }}>
												Source Type{" "}
												<select
													value={sourceTypeYoy}
													onChange={(e) => setSourceTypeYoy(e.target.value)}
												>
													{(valueListYoy[granYoy][valueTypeYoy] || []).map(
														(s, index) => {
															return (
																<option value={s} key={index}>
																	{s}
																</option>
															);
														}
													)}
												</select>
											</div>
										</div>

										<br />
										<Row style={{ justifyContent: "center" }}>
											<YoyWebChart
												dataFetched={dataFetched}
												isLoading={yoyWebLoading}
												chartData={yoyWebChartData}
												startDate={startDate}
												endDate={endDate}
												graphRef={yoyWebGraphRef}
												yoyData={yoyData}
												setYoyData={setYoyData}
												yo2yData={yo2yData}
												setYo2yData={setYo2yData}
												yo3yData={yo3yData}
												setYo3yData={setYo3yData}
												displayedDates={yoyWebDates}
												setDisplayedDates={setYoyWebDates}
												title={`${selectedDomain.name} Yoy Web Engagement Composite Trends`}
											/>
										</Row>
										{renderDownloadBtn(yoyWebImgDownload, new Function())}
									</>
								) : (
									""
								)}

								<br />
								<br />
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default CompositeChart;
