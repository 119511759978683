import React, { useState, useEffect, useRef } from "react";
import { axios } from "./utils";
import { Row, Col } from "react-bootstrap";
import {
	getNumericalHeader,
	getNumericalUrl,
	chartJSGraphDownload,
	chartJSCSVDownload,
	capitalize,
} from "./utils";

import DomainPanel from "../ReusableComponents/DomainPanel";
import MyTooltip from "../ReusableComponents/MyTooltip";
import { toast } from "react-toastify";
import "../staticfiles/css/email-volume.css";

import EmailIndexTrendChart from "./EmailTrendChart/EmailIndexTrendChart";
import EmailFinalIndexChart from "./EmailTrendChart/EmailFinalIndexChart";

const EmailCategoryIndexPage = ({
	uniqueDomains,
	tickers,
	tickerCate,
	loaded,
}) => {
	const [selectedDomain, setSelectedDomain] = useState({});
	const [selectedTicker, setSelectedTicker] = useState("");

	const [allDomainCategories, setAllDomainCategories] = useState({});
	const [domainCategories, setDomainCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [chartData, setChartData] = useState([]);

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [displayedDates, setDisplayedDates] = useState([]);
	const [volumeData, setVolumeData] = useState([]);
	const [finalData, setFinalData] = useState([]);

	const [displayUSData, setDisplayUSData] = useState(false);

	const graphRefVolume = useRef(null);
	const graphRefFinal = useRef(null);

	const domainRef = useRef("");
	const cateRef = useRef("");

	const [isLoading, setIsLoading] = useState(false);
	const [initialFetched, setInitialFetched] = useState(false);

	const getDomainData = async (domain, category) => {
		if (domain === undefined || domain === null) {
			return;
		}
		if (category === "") {
			return;
		}
		setIsLoading(true);
		await axios
			.get(
				getNumericalUrl(
					displayUSData
						? `v1/email-index/query/smoothed/?domain=${domain}&category=${category}&country=US`
						: `v1/email-index/query/smoothed/?domain=${domain}&category=${category}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				var data = response.data;
				let curDomain = data[0].domain;
				let curCate = data[0].category;
				if (curDomain !== domainRef.current || curCate !== cateRef.current) {
					return;
				}
				if (data.length === 0) {
					toast.warn(
						"We currently don't have information for this domain and category combination"
					);
					setChartData([]);
					return;
				}

				let tempminStartDate = new Date(data[0].start_date);
				let tempminStartDateIndex = 0;

				let tempmaxStartDate = new Date(data[0].start_date);
				let tempmaxStartDateIndex = 0;

				let tempminEndDate = new Date(data[0].end_date);
				let tempminEndDateIndex = 0;

				let tempmaxEndDate = new Date(data[0].end_date);
				let tempmaxEndDateIndex = 0;

				for (let i = 0; i < data.length; i++) {
					let newDate = new Date(data[i].start_date);
					if (tempminStartDate > newDate) {
						tempminStartDate = newDate;
						tempminStartDateIndex = i;
					}
					if (tempmaxStartDate < newDate) {
						tempmaxStartDate = newDate;
						tempmaxStartDateIndex = i;
					}

					let newEnd = new Date(data[i].end_date);
					if (tempminEndDate > newEnd) {
						tempminEndDate = newEnd;
						tempminEndDateIndex = i;
					}
					if (tempmaxEndDate < newEnd) {
						tempmaxEndDate = newEnd;
						tempmaxEndDateIndex = i;
					}
				}

				let newStartDate = new Date(data[tempmaxEndDateIndex].end_date);
				var year = newStartDate.getFullYear();
				var month = newStartDate.getMonth();
				var day = newStartDate.getDate();
				var c = new Date(year - 2, month, day);

				month = "" + (c.getMonth() + 1);
				day = "" + c.getDate();
				year = c.getFullYear();

				if (month.length < 2) month = "0" + month;
				if (day.length < 2) day = "0" + day;

				newStartDate = [year, month, day].join("-");

				setminStartDate(data[tempminStartDateIndex].start_date);
				setmaxStartDate(data[tempmaxStartDateIndex].start_date);

				if (tempStartDate === "") {
					setStartDate(newStartDate);
					setTempStartDate(newStartDate);
				}

				setminEndDate(data[tempminEndDateIndex].end_date);
				setmaxEndDate(data[tempmaxEndDateIndex].end_date);

				setEndDate(data[tempmaxEndDateIndex].end_date);
				setTempEndDate(data[tempmaxEndDateIndex].end_date);

				let smoothed = [];
				for (const row of data) {
					smoothed.push({
						...row,
						adjusted_total_volume: row.smoothed_total_volume,
						final_index: row.smoothed_final_index,
					});
				}
				setChartData(smoothed);
			})
			.catch((error) => {
				if (error.response.status == 404) {
					setChartData([]);
				}
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	const handleTickerSelection = (newTicker) => {
		let newDomains = uniqueDomains
			.filter((domain) => {
				return (
					domain.domain === domain.parent_domain && domain.ticker === newTicker
				);
			})
			.sort();
		let newDomain = newDomains[0];

		setSelectedTicker(newTicker);
		setSelectedTickerDomains(newDomains);
		setSelectedDomain(newDomain);
		setDomainCategories(
			(allDomainCategories.get(newDomain.name) || [""]).sort()
		);
		setSelectedCategory(
			(allDomainCategories.get(newDomain.name).sort() || [""])[0]
		);
	};

	const handleDomainSelection = async (newDomain) => {
		setSelectedDomain(newDomain);

		let newCategories = (
			allDomainCategories.get(newDomain.name) || [""]
		).sort();
		setDomainCategories(newCategories);
		setSelectedCategory(newCategories[0]);
	};

	const handleGraphDownload = () => {
		if (graphRefVolume !== null) {
			chartJSGraphDownload(
				graphRefVolume.current.chartInstance,
				"Category_Indexing_volume.png"
			);
		}
		if (graphRefFinal !== null) {
			chartJSGraphDownload(
				graphRefFinal.current.chartInstance,
				"Category_Indexing_final.png"
			);
		}
	};

	const handleCSVDownload = () => {
		let data = [
			["Dates", ...displayedDates],
			["Volume Index", ...volumeData],
			["Final Index", ...finalData],
		];

		chartJSCSVDownload(data, "Category_Indexing.csv");
	};

	useEffect(() => {
		document.title = "Category Indexing";
	}, []);

	useEffect(() => {
		if (!loaded) return;
		const fetchData = async () => {
			let res = tickerCate;
			let companies = Object.keys(res).sort();
			let domainCategories = new Map();

			for (let i = 0; i < companies.length; i++) {
				let oneCompany = res[companies[i]];
				let domains = Object.keys(oneCompany);

				for (let j = 0; j < domains.length; j++) {
					if (domainCategories.get(domains[j])) {
						domainCategories.set(domains[j], [
							...domainCategories.get(domains[j]),
							...oneCompany[domains[j]],
						]);
					} else {
						domainCategories.set(domains[j], oneCompany[domains[j]]);
					}
				}
			}
			while (companies[0] === "") {
				companies.splice(0, 1);
			}
			let cacheTicker = sessionStorage.getItem("selectedTicker") || "";
			let initialDomains = uniqueDomains
				.filter((domain) => {
					return (
						domain.domain === domain.parent_domain &&
						domain.ticker === (cacheTicker ? cacheTicker : companies[0])
					);
				})
				.sort();
			let cacheDomain = sessionStorage.getItem("selectedDomain") || "{}";
			let initialDomain =
				Object.keys(selectedDomain).length === 0 && cacheDomain !== "{}"
					? JSON.parse(cacheDomain)
					: initialDomains[0];
			if (selectedTicker === "" && cacheTicker !== "") {
				setSelectedTicker(cacheTicker);
			} else {
				setSelectedTicker(tickers[0]);
			}
			setSelectedTickerDomains(initialDomains);
			setSelectedDomain(initialDomain);
			setAllDomainCategories(domainCategories);
			let curDomainCate = domainCategories.get(initialDomain.name) || [""];
			if (curDomainCate) {
				curDomainCate.sort();
			} else {
				curDomainCate = [];
			}
			setDomainCategories(curDomainCate);
			setSelectedCategory(
				selectedCategory === "" &&
					sessionStorage.getItem("selectedCategory") !== ""
					? sessionStorage.getItem("selectedCategory")
					: (domainCategories.get(initialDomain.name).sort() || [""])[0]
			);
			setInitialFetched(true);
		};
		fetchData();
	}, [loaded]);

	useEffect(() => {
		if (!sessionStorage.getItem("selectedTicker") || selectedTicker != "") {
			sessionStorage.setItem("selectedTicker", selectedTicker);
		}
	}, [selectedTicker]);

	useEffect(() => {
		let newDomain = JSON.stringify(selectedDomain);
		if (!sessionStorage.getItem("selectedDomain") || newDomain !== "{}") {
			sessionStorage.setItem("selectedDomain", newDomain);
		}
		domainRef.current = selectedDomain.domain;
	}, [selectedDomain]);

	useEffect(() => {
		if (
			!sessionStorage.getItem("selectedCategory") ||
			selectedCategory !== ""
		) {
			sessionStorage.setItem("selectedCategory", selectedCategory);
		}
		cateRef.current = selectedCategory;
	}, [selectedCategory]);

	useEffect(() => {
		if (!selectedDomain || !selectedDomain.domain || selectedCategory === "") {
			setChartData([]);
			return;
		}
		getDomainData(selectedDomain.domain, selectedCategory);
	}, [selectedDomain, selectedCategory, displayUSData]);

	if (!initialFetched) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Indexed Email Volume By Category (Weekly) </h4>
			<MyTooltip
				content={"both charts: v1/email-index/query/smoothed"}
			></MyTooltip>
			<DomainPanel
				selectedTicker={selectedTicker}
				selectedDomain={selectedDomain}
				selectedCategory={selectedCategory}
				selectedTickerDomains={selectedTickerDomains}
				uniqueTickers={tickers}
				uniqueDomains={uniqueDomains}
				domainCategories={domainCategories}
				handleTickerSelection={handleTickerSelection}
				handleDomainSelection={handleDomainSelection}
				setSelectedCategory={setSelectedCategory}
				tempStartDate={tempStartDate}
				setTempStartDate={setTempStartDate}
				changeStartDate={changeStartDate}
				tempEndDate={tempEndDate}
				setTempEndDate={setTempEndDate}
				changeEndDate={changeEndDate}
				handleGraphDownload={handleGraphDownload}
				handleCSVDownload={handleCSVDownload}
				useDataToggle={true}
				displayUSData={displayUSData}
				onDataToggleChange={setDisplayUSData}
			/>
			<Row>
				<Col sm={12} md={12}>
					<div className="card">
						<div className="card-title"></div>
						<div className="card-body">
							<h4 style={{ textAlign: "center" }}>
								{selectedDomain.name} ({selectedDomain.ticker}){" "}
								{capitalize(selectedCategory)} Volume Index Trends (Weekly)
							</h4>
							<EmailIndexTrendChart
								dataFetched={true}
								isLoading={isLoading}
								chartData={chartData}
								startDate={startDate}
								endDate={endDate}
								title={""}
								graphRef={graphRefVolume}
								totalData={volumeData}
								setTotalData={setVolumeData}
								displayedDates={displayedDates}
								setDisplayedDates={setDisplayedDates}
							/>
							<br />
							<br />
							<h4 style={{ textAlign: "center" }}>
								{selectedDomain.name} ({selectedDomain.ticker}){" "}
								{capitalize(selectedCategory)} Final Index Trends (Weekly)
							</h4>
							<EmailFinalIndexChart
								dataFetched={true}
								isLoading={isLoading}
								chartData={chartData}
								startDate={startDate}
								endDate={endDate}
								graphRef={graphRefFinal}
								title={""}
								totalData={finalData}
								setTotalData={setFinalData}
								displayedDates={displayedDates}
								setDisplayedDates={setDisplayedDates}
							/>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default EmailCategoryIndexPage;
