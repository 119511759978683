import React from 'react';
import { MDBDataTable } from 'mdbreact';

const CeleryDataTable = (allData) => {

  const activeQueue = Object.entries(allData.allData.active)
  const reservedQueue = Object.entries(allData.allData.reserved)
  const scheduledQueue = Object.entries(allData.allData.scheduled)
  const revokedQueue = Object.entries(allData.allData.revoked)


  const data = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Args',
        field: 'args',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Worker',
        field: 'worker',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Priority',
        field: 'priority',
        sort: 'asc',
        width: 100
      },
      {
        label: 'QueueType',
        field: 'queueType',
        sort: 'asc',
        width: 100
      }
    ],
    rows: []
  };
  activeQueue.map((elem) => {
    elem[1].map(task => {
      data.rows.push({
        name: task.name,
        args: task.args.join(),
        worker: task.worker_pid,
        priority: task.delivery_info.priority,
        queueType: 'active'
      })
    })
  })
  reservedQueue.map((elem) => {
    elem[1].map(task => {
      data.rows.push({
        name: task.name,
        args: task.args.join(),
        worker: task.worker_pid,
        priority: task.delivery_info.priority,
        queueType: 'reserved'
      })
    })
  })
  scheduledQueue.map((elem) => {
    elem[1].map(task => {
      data.rows.push({
        name: task.name,
        args: task.args.join(),
        worker: task.worker_pid,
        priority: task.delivery_info.priority,
        queueType: 'scheduled'
      })
    })
  })
  revokedQueue.map((elem) => {
    elem[1].map(task => {
      data.rows.push({
        name: task.name,
        args: task.args.join(),
        worker: task.worker_pid,
        priority: task.delivery_info.priority,
        queueType: 'revoked'
      })
    })
  })


  return (
    <MDBDataTable
      striped
      bordered
      small
      data={data}
    />
  );
}

export default CeleryDataTable;