import moment from "moment-timezone";
import axios from "axios";

import LogoImg from "../staticfiles/rw_main_logo_blue.png";

let prefix = window.location.protocol;
var airstreamUrl = prefix + "//airstream.ranwalk.goldbeyond.com/";
var numericalUrl = prefix + "//luna.ranwalk.goldbeyond.com/api/";
var lunaUrl = prefix + "//luna.ranwalk.goldbeyond.com/api/";
var lunaWebsiteUrl = prefix + "//luna.ranwalk.goldbeyond.com";

const traffic_api_key = "keyKRDrWkqJqEkMY8";
const traffic_table_key = "appJxgBVRalEW0jju";

const getLunaUrl = (url) => lunaUrl + url;
const getAirstreamUrl = (url) => airstreamUrl + url;
const getNumericalUrl = (url) => numericalUrl + url;
const getLunaWebsiteUrl = (url) => lunaWebsiteUrl + url;

const myaxios = axios.create();

myaxios.defaults.proxy = prefix + "//luna.ranwalk.goldbeyond.com/";
myaxios.interceptors.request.use(
	function (config) {
		let expireDate = localStorage.getItem("RWExpireDate");
		if (expireDate && moment(expireDate).utc() < moment.utc()) {
			localStorage.removeItem("RWLoginToken");
			localStorage.removeItem("airstreamToken");
			localStorage.removeItem("RWExpireDate");
			localStorage.removeItem("username");
			alert("Your credentials have expired, please login again.");
			window.location.href = "/";
			throw new axios.Cancel("Operation canceled by the user.");
		}
		const requestTime = performance.now();
		const timestamp = new Date().toISOString();
		config.metadata = { requestTime, timestamp };
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
myaxios.interceptors.response.use(
	function (response) {
		const { requestTime, timestamp } = response.config.metadata;
		const responseTime = performance.now();
		const timeTaken = responseTime - requestTime;
		const url = response.config.url.split("?");
		const record = {
			url: url[0],
			timestamp: timestamp,
			status_code: response.status,
			request_type: response.config.method.toUpperCase(),
			params: url[1],
			traffic_duration: Number(timeTaken.toFixed(2)),
			user: localStorage.getItem("username"),
		};
		// recordTraffic(record);
		return response;
	},
	function (error) {
		const { requestTime, timestamp } = error.config.metadata;
		const responseTime = performance.now();
		const timeTaken = responseTime - requestTime;
		const url = error.config.url.split("?");

		const record = {
			url: url[0],
			timestamp: timestamp,
			status_code: error.response ? error.response.status : "unknown",
			request_type: error.config.method.toUpperCase(),
			params: url[1],
			traffic_duration: Number(timeTaken.toFixed(2)),
			user: localStorage.getItem("username"),
		};
		// recordTraffic(record);
		return Promise.reject(error);
	}
);

const recordTraffic = async (record) => {
	const headers = {
		"Content-Type": "application/json",
		Authorization: `Bearer ${traffic_api_key}`,
	};
	await axios
		.post(
			`https://api.airtable.com/v0/${traffic_table_key}/rw_api_analytics`,
			{ fields: record },
			{ headers }
		)
		.then((res) => {})
		.catch((err) => {});
};

const getAirstreamHeader = () => ({
	headers: {
		Authorization: "JWT " + localStorage.getItem("airstreamToken"),
	},
});

const getNumericalHeader = () => ({
	headers: {
		Authorization: "JWT " + localStorage.getItem("RWLoginToken"),
	},
});

const processNextpageUrl = (url) => {
	if (prefix == "https:" && !url.startsWith("https")) {
		url = prefix + url.substring(5);
	}
	return url;
};

const chartJSGraphDownload = (chart, fileTitle) => {
	let res = chart.toBase64Image();
	let a = document.createElement("a");
	a.href = res;
	a.download = fileTitle;
	a.click();
	// let canvas = document.createElement("canvas");
	// let link = chart.toBase64Image();
	// let image = new Image();
	// image.onload = () => {
	// 	canvas.width = image.width + 100;
	// 	canvas.height = 500;
	// 	let ctx = canvas.getContext("2d");
	// 	ctx.fillStyle = "#fff";
	// 	ctx.fillRect(0, 0, canvas.width, canvas.height);
	// 	ctx.fillStyle = "#2c2c2c";
	// 	ctx.font = "bold 18px Arial";
	// 	ctx.textAlign = "center";
	// 	ctx.fillText(graphTitle, canvas.width / 2, 40);
	// 	ctx.drawImage(image, 0, 100);
	// 	canvas.toBlob((blob) => {
	// 		let url = URL.createObjectURL(blob);
	// 		let a = document.createElement("a");
	// 		a.href = url;
	// 		a.download = fileTitle;
	// 		a.click();
	// 	});
	// };
	// image.src = link;
};

const chartJSCSVDownload = (data2DArray, graphTitle) => {
	for (const row of data2DArray) {
		row.splice(1, 1);
		row.pop();
	}
	const data = data2DArray[0].map((_, colIndex) =>
		data2DArray.map((row) => row[colIndex])
	);
	var csv = "";
	data.forEach(function (row) {
		csv += row.join(",");
		csv += "\n";
	});

	let a = document.createElement("a");
	a.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
	a.target = "_blank";
	a.download = graphTitle;
	a.click();
};

const rounding = (value, decimalPlaces) => {
	if (value === null) {
		return null;
	} else {
		return Number(
			Math.round(parseFloat(value + "e" + decimalPlaces)) + "e-" + decimalPlaces
		);
	}
};

const colors = ["#6929c4", "#1192e8", "#005d5d", "#9f1853", "#fa4d56"];

const indexColorMapping = {
	1: {
		final_index: "#6929c4",
		discount_20_percentile: "#1192e8",
		adjusted_total_volume: "#005d5d",
		adjusted_zero_discount_volume: "#9f1853",
		adjusted_is_storewide_total_volume: "#fa4d56",
		adjusted_is_steep_total_volume: "#570408",
		adjusted_discount_vol: "#198038",
		adjusted_is_significant_total_volume: "#002d9c",
		discount_80_percentile: "#ee538b",
		effective_discount: "#b28600",
		max_discount: "#009d9a",
		total_campaigns: "#012749",
		adjusted_harmonic_state_discount_total_volume: "#8a3800",
		adjusted_harmonic_promo_budget_value: "#a56eff",
	},

	2: {
		final_index: "#b183e2",
		discount_20_percentile: "#86baf4",
		adjusted_total_volume: "#61a3a1",
		adjusted_zero_discount_volume: "#d27d99",
		adjusted_is_storewide_total_volume: "#ff9791",
		adjusted_is_steep_total_volume: "#ac6d64",
		adjusted_discount_vol: "#79b580",
		adjusted_is_significant_total_volume: "#8980cd",
		discount_80_percentile: "#fa98b7",
		effective_discount: "#d7b269",
		max_discount: "#6ec5c2",
		total_campaigns: "#6a7e9f",
		adjusted_harmonic_state_discount_total_volume: "#c88760",
		adjusted_harmonic_promo_budget_value: "#cda5ff",
	},

	3: {
		final_index: "#eedbff",
		discount_20_percentile: "#cde4ff",
		adjusted_total_volume: "#b1eeec",
		adjusted_zero_discount_volume: "#ffd5e2",
		adjusted_is_storewide_total_volume: "#ffd6d2",
		adjusted_is_steep_total_volume: "#ffd6cf",
		adjusted_discount_vol: "#caebcc",
		adjusted_is_significant_total_volume: "#e5deff",
		discount_80_percentile: "#ffd5e2",
		effective_discount: "#f6e0bd",
		max_discount: "#b2eeeb",
		total_campaigns: "#d2e3ff",
		adjusted_harmonic_state_discount_total_volume: "#ffdac4",
		adjusted_harmonic_promo_budget_value: "#eddcff",
	},
};

const convertTZ = (time, tz) => {
	return moment.utc(time).tz(tz).format().substring(0, 16);
};

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatBigNum = (value) => {
	if (!value) {
		return 0;
	}
	if (value >= 1000000000) {
		return (value / 1000000000).toFixed(1) + "B";
	} else if (value >= 1000000) {
		return (value / 1000000).toFixed(1) + "M";
	} else if (value >= 1000) {
		return Math.round(value / 1000) + "K";
	} else {
		return value;
	}
};

const formatCategoryString = (str) => {
	let cate = str.split(",").filter((c) => c !== "");
	if (cate.length === 0) {
		cate = "";
	} else if (cate.length === 1) {
		cate = cate[0];
	} else {
		let temp = "";
		for (let s of cate) {
			temp += s + ", ";
		}
		cate = temp.slice(0, -2);
	}
	return cate;
};

const toCamelCase = (str) =>
	str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
			idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
		)
		.replace(/\s+/g, "");

const capitalize = (str) => {
	if (!str) return "";
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

const chartBgc = {
	id: "custom_canvas_background_color",
	beforeDraw: (chart) => {
		const ctx = chart.canvas.getContext("2d");
		ctx.save();
		ctx.globalCompositeOperation = "destination-over";
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, chart.width, chart.height);
		ctx.restore();
	},
};

const image = new Image();
image.src = LogoImg;

const chartWatermark = {
	id: "chart_watermark",
	afterDraw: (chart) => {
		const {
			ctx,
			chartArea: { top, bottom, left, right },
		} = chart;
		ctx.save();
		ctx.globalAlpha = 0.5;
		if (image.complete) {
			const image_width = 200;
			const ratio = 577 / 3451;
			const image_height = image_width * ratio;
			ctx.drawImage(image, left + 10, bottom - 40, image_width, image_height);
		} else {
			image.onload = () => chart.draw();
		}
		ctx.restore();
	},
};

const chartDataSorter = (a, b) => {
	if (new Date(a.start_date) < new Date(b.start_date)) {
		return -1;
	} else if (new Date(a.start_date) === new Date(b.start_date)) {
		return 0;
	} else {
		return 1;
	}
};

const getNextSunday = (startDate) => {
	const inputDate = moment(startDate);
	const dayOfWeek = inputDate.day();

	if (dayOfWeek <= 0) {
		return inputDate.format("YYYY-MM-DD");
	} else {
		const daysUntilSunday = 7 - dayOfWeek;
		return inputDate.add(daysUntilSunday, "days").format("YYYY-MM-DD");
	}
};

export {
	getAirstreamUrl,
	getNumericalUrl,
	getLunaWebsiteUrl,
	getLunaUrl,
	getAirstreamHeader,
	getNumericalHeader,
	chartJSGraphDownload,
	chartJSCSVDownload,
	rounding,
	indexColorMapping,
	convertTZ,
	capitalizeFirstLetter,
	formatBigNum,
	formatCategoryString,
	toCamelCase,
	capitalize,
	colors,
	myaxios as axios,
	processNextpageUrl,
	chartBgc,
	chartWatermark,
	chartDataSorter,
	getNextSunday,
};
