import React, { Component } from "react";
import { getNumericalUrl, getNumericalHeader, axios } from "../utils";
import { ListGroup, Container, Row, Col, Card } from "react-bootstrap";
import CeleryDataTable from "./CeleryDataTable";
import { MDBDataTable } from "mdbreact";

import "../../staticfiles/monitor.css";

export default class CeleryMonitor extends Component {
	state = {
		celeryData: {
			registered: {
				"celery@3ce900a8f83d": ["Loading"],
			},
			active: {
				"Currently No worker Online": [],
				Loading: [],
			},
			reserved: {
				"Currently No worker Online": [],
			},
			scheduled: {
				"Currently No worker Online": [],
			},
			revoked: {
				"Currently No worker Online": [],
			},
		}, // real data
		activeNumber: 0,
		reservedNumber: 0,
		scheduledNumber: 0,
		revokedNumber: 0,
		unassignedNumber: 0,
		allData: {
			stat: {
				"celery@3ce900a8f83d": {
					total: {
						"scripts.utils.parse_category_one_domain": 884,
					},
					pid: 1,
					clock: "224471",
					uptime: 114660,
					pool: {
						"max-concurrency": 4,
						processes: [15, 22, 29, 36],
						"max-tasks-per-child": "N/A",
						"put-guarded-by-semaphore": false,
						timeouts: [0, 0],
						writes: {
							total: 884,
							avg: "0.25",
							all: "0.36, 0.21, 0.29, 0.14",
							raw: "316, 187, 258, 123",
							strategy: "fair",
							inqueues: {
								total: 4,
								active: 0,
							},
						},
					},
					broker: {
						hostname: "ec2-44-232-159-130.us-west-2.compute.amazonaws.com",
						userid: null,
						virtual_host: "/",
						port: 6379,
						insist: false,
						ssl: false,
						transport: "redis",
						connect_timeout: 4,
						transport_options: {
							queue_order_strategy: "priority",
						},
						login_method: null,
						uri_prefix: null,
						heartbeat: 120.0,
						failover_strategy: "round-robin",
						alternates: [],
					},
					prefetch_count: 16,
					rusage: {
						utime: 182.393272,
						stime: 23.859426,
						maxrss: 170484,
						ixrss: 0,
						idrss: 0,
						isrss: 0,
						minflt: 84083,
						majflt: 0,
						nswap: 0,
						inblock: 0,
						oublock: 2568,
						msgsnd: 0,
						msgrcv: 0,
						nsignals: 0,
						nvcsw: 575829,
						nivcsw: 216376,
					},
				},
				"celery@856450eea128": {
					total: {
						"scripts.utils.parse_category_one_domain": 318,
					},
					pid: 1,
					clock: "224471",
					uptime: 114617,
					pool: {
						"max-concurrency": 4,
						processes: [12, 19, 26, 33],
						"max-tasks-per-child": "N/A",
						"put-guarded-by-semaphore": false,
						timeouts: [0, 0],
						writes: {
							total: 318,
							avg: "0.25",
							all: "0.41, 0.24, 0.21, 0.14",
							raw: "131, 76, 68, 43",
							strategy: "fair",
							inqueues: {
								total: 4,
								active: 0,
							},
						},
					},
					broker: {
						hostname: "ec2-44-232-159-130.us-west-2.compute.amazonaws.com",
						userid: null,
						virtual_host: "/",
						port: 6379,
						insist: false,
						ssl: false,
						transport: "redis",
						connect_timeout: 4,
						transport_options: {
							queue_order_strategy: "priority",
						},
						login_method: null,
						uri_prefix: null,
						heartbeat: 120.0,
						failover_strategy: "round-robin",
						alternates: [],
					},
					prefetch_count: 16,
					rusage: {
						utime: 189.353606,
						stime: 25.667115,
						maxrss: 169768,
						ixrss: 0,
						idrss: 0,
						isrss: 0,
						minflt: 83235,
						majflt: 17,
						nswap: 0,
						inblock: 9568,
						oublock: 560,
						msgsnd: 0,
						msgrcv: 0,
						nsignals: 0,
						nvcsw: 569465,
						nivcsw: 129154,
					},
				},
			},
			active: {
				"celery@856450eea128": [
					{
						id: "bdaf726e-be45-4983-ac78-3adc977104fe",
						name: "scripts.utils.parse_category_one_domain",
						args: ["loft.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: 1607145859.4797926,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 26,
					},
					{
						id: "651405b7-2e0a-48a7-9fff-70e7efffc121",
						name: "scripts.utils.parse_category_one_domain",
						args: ["jcpenny.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: 1607145859.350166,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 33,
					},
					{
						id: "bd8469d4-bb3e-4ae9-94b9-60cf92492e72",
						name: "scripts.utils.parse_category_one_domain",
						args: ["tractorsupply.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: 1607145859.5319498,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 12,
					},
					{
						id: "e728f3dd-de36-4eff-95eb-ccd046883d67",
						name: "scripts.utils.parse_category_one_domain",
						args: ["brighthorizons.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: 1607145859.550664,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 19,
					},
				],
				"celery@3ce900a8f83d": [
					{
						id: "62f57e17-cee8-4ff4-8551-e3b961421feb",
						name: "scripts.utils.parse_category_one_domain",
						args: ["ebay.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: 1607145858.4704332,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 15,
					},
					{
						id: "7e151d31-62dc-480f-9d61-611ebba480b6",
						name: "scripts.utils.parse_category_one_domain",
						args: ["yelp.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: 1607145859.2335699,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 29,
					},
					{
						id: "4d42dbd6-8fe5-4f70-b14e-0a22faad5734",
						name: "scripts.utils.parse_category_one_domain",
						args: ["kohls.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: 1607145859.418972,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 22,
					},
					{
						id: "3b12e310-1ac0-46f6-8fbd-66d6ad40a496",
						name: "scripts.utils.parse_category_one_domain",
						args: ["1800flowers.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: 1607145859.4879792,
						acknowledged: true,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: 36,
					},
				],
			},
			reserved: {
				"celery@3ce900a8f83d": [
					{
						id: "90e71a65-e79e-452f-b565-d4ceb08840d3",
						name: "scripts.utils.parse_category_one_domain",
						args: ["anntaylor.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "79d8cc88-751c-4d54-846d-6234ce0b11f1",
						name: "scripts.utils.parse_category_one_domain",
						args: ["budsgunshop.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "a3fd180e-58cd-4ac5-9dca-a0420129064a",
						name: "scripts.utils.parse_category_one_domain",
						args: ["levi.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "ff6df0c9-9ca6-4a6a-9ad3-bcdbfa6ff9eb",
						name: "scripts.utils.parse_category_one_domain",
						args: ["wayfair.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "9d460e13-8601-4fde-8d34-c43c450f61be",
						name: "scripts.utils.parse_category_one_domain",
						args: ["bestbuy.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "d92c20fd-4965-4f58-9d06-a77e9d008ea2",
						name: "scripts.utils.parse_category_one_domain",
						args: ["aritzia.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "700b4bc5-1272-4843-abeb-d8b72fba4ed5",
						name: "scripts.utils.parse_category_one_domain",
						args: ["ticketmaster.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "2f2e1460-ee29-4962-8166-cc9da21df361",
						name: "scripts.utils.parse_category_one_domain",
						args: ["jared.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "e2b4714e-348d-46bc-8ed6-e419da846462",
						name: "scripts.utils.parse_category_one_domain",
						args: ["grubhub.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "d4f5012a-229f-45bc-9346-b7a62f86398d",
						name: "scripts.utils.parse_category_one_domain",
						args: ["noom.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "7d92f1ec-ea59-48cb-b027-734713d512af",
						name: "scripts.utils.parse_category_one_domain",
						args: ["turbotax.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "038c5a06-9663-4638-acfd-e02b81c94794",
						name: "scripts.utils.parse_category_one_domain",
						args: ["katespade.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "2fe82b11-a21c-4c96-b3ef-c300fa34c6c5",
						name: "scripts.utils.parse_category_one_domain",
						args: ["big5sportinggoods.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "713c165a-e0be-4c1c-b690-c7e5722d20bb",
						name: "scripts.utils.parse_category_one_domain",
						args: ["whitehouseblackmarket.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "24322cc7-dc31-4f09-9f8d-045bde8f77f1",
						name: "scripts.utils.parse_category_one_domain",
						args: ["smiledirectclub.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@3ce900a8f83d",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
				],
				"celery@856450eea128": [
					{
						id: "83975a52-8e1f-4aac-a0f9-fc2cd73a0027",
						name: "scripts.utils.parse_category_one_domain",
						args: ["nutrisystem.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "54a3a48f-8e28-4e3e-952e-8b4081556edd",
						name: "scripts.utils.parse_category_one_domain",
						args: ["planetfitness.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "0dc48b9b-9c04-492e-bf34-fc1ebb259820",
						name: "scripts.utils.parse_category_one_domain",
						args: ["biglots.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "45f003cd-cc14-4342-bb37-8e9e52e7981a",
						name: "scripts.utils.parse_category_one_domain",
						args: ["anthropologie.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "9eee2081-a32b-45ce-8fb9-dd90746b029f",
						name: "scripts.utils.parse_category_one_domain",
						args: ["francescas.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "1a1b0fca-a31c-4f29-86eb-5775e7020819",
						name: "scripts.utils.parse_category_one_domain",
						args: ["livenation.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "20ae82a8-56ab-4413-ab28-fcf0deed7233",
						name: "scripts.utils.parse_category_one_domain",
						args: ["qvc.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "3be48327-f639-4c63-83a2-6a5dde934222",
						name: "scripts.utils.parse_category_one_domain",
						args: ["fivebelow.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "8427e87f-3d4b-4666-91af-227f19776c8e",
						name: "scripts.utils.parse_category_one_domain",
						args: ["gopro.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "7f44bed9-6aba-47b0-af40-f72a33f28870",
						name: "scripts.utils.parse_category_one_domain",
						args: ["ubereats.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "c1677c54-5ed7-49a3-b0a7-ca999992dd7a",
						name: "scripts.utils.parse_category_one_domain",
						args: ["tiffany.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "210305db-a5e0-4ded-a26e-a4d7af5d5425",
						name: "scripts.utils.parse_category_one_domain",
						args: ["homeadvisor.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "91393502-9080-4ac8-bb91-a7c75c110dbe",
						name: "scripts.utils.parse_category_one_domain",
						args: ["expediamail.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "e7f86066-da8e-48d2-b220-649498553211",
						name: "scripts.utils.parse_category_one_domain",
						args: ["okcupid.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
					{
						id: "05f08caf-1e88-4a60-9cad-c3fd6882190c",
						name: "scripts.utils.parse_category_one_domain",
						args: ["mattel.com", null, null, false],
						kwargs: {},
						type: "scripts.utils.parse_category_one_domain",
						hostname: "celery@856450eea128",
						time_start: null,
						acknowledged: false,
						delivery_info: {
							exchange: "",
							routing_key: "celery",
							priority: 5,
							redelivered: null,
						},
						worker_pid: null,
					},
				],
			},
			scheduled: {
				"celery@3ce900a8f83d": [],
				"celery@856450eea128": [],
			},
			revoked: {
				"celery@3ce900a8f83d": [],
				"celery@856450eea128": [],
			},
			registered: {
				"celery@3ce900a8f83d": [
					"scripts.utils.back_fill_domain",
					"scripts.utils.parse_category_one_domain",
					"scripts.utils.parse_category_one_email_by_id",
					"scripts.utils.parse_discount_one_domain",
					"scripts.utils.rule_run_domain",
				],
				"celery@856450eea128": [
					"scripts.utils.back_fill_domain",
					"scripts.utils.parse_category_one_domain",
					"scripts.utils.parse_category_one_email_by_id",
					"scripts.utils.parse_discount_one_domain",
					"scripts.utils.rule_run_domain",
				],
			},
			active_queues: {
				"celery@3ce900a8f83d": [
					{
						name: "celery",
						exchange: {
							name: "celery",
							type: "direct",
							arguments: null,
							durable: true,
							passive: false,
							auto_delete: false,
							delivery_mode: null,
							no_declare: false,
						},
						routing_key: "celery",
						queue_arguments: null,
						binding_arguments: null,
						consumer_arguments: null,
						durable: true,
						exclusive: false,
						auto_delete: false,
						no_ack: false,
						alias: null,
						bindings: [],
						no_declare: null,
						expires: null,
						message_ttl: null,
						max_length: null,
						max_length_bytes: null,
						max_priority: null,
					},
				],
				"celery@856450eea128": [
					{
						name: "celery",
						exchange: {
							name: "celery",
							type: "direct",
							arguments: null,
							durable: true,
							passive: false,
							auto_delete: false,
							delivery_mode: null,
							no_declare: false,
						},
						routing_key: "celery",
						queue_arguments: null,
						binding_arguments: null,
						consumer_arguments: null,
						durable: true,
						exclusive: false,
						auto_delete: false,
						no_ack: false,
						alias: null,
						bindings: [],
						no_declare: null,
						expires: null,
						message_ttl: null,
						max_length: null,
						max_length_bytes: null,
						max_priority: null,
					},
				],
			},
		}, // dummy data
		canDisplay: false, // 是否ready to 展示数据
		refreshTimeRate: 20, // 刷新时间间隔
		displayTime: 0, //显示的上次更新时间
	};

	// 调用api获取celery数据
	getCeleryStatus = async () => {
		await axios
			.get(getNumericalUrl("v1/celery/stats/"), getNumericalHeader())
			.then((response) => {
				this.setState({ celeryData: response.data });
				if (
					Object.entries(this.state.celeryData.active)
						.map((x) => x[1].length)
						.reduce(this.sumReducer) === 0
				) {
					this.setState({ canDisplay: false });
				} else {
					this.setState({ canDisplay: true });
				}
				this.getActiveTaskNumber();
				this.getReservedTaskNumber();
				this.getScheduledTaskNumber();
				this.getRevokedTaskNumber();
				this.getUnassignedTaskNumber();
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	sumReducer = (a, b) => {
		return a + b;
	};

	// 更新计时
	updateTime = () => {
		const displayTime = this.state.displayTime + 1;
		this.setState({ displayTime: displayTime });
	};

	getActiveTaskNumber = () => {
		const taskNumber = Object.entries(this.state.celeryData.active)
			.map((x) => x[1].length)
			.reduce(this.sumReducer);
		this.setState({ activeNumber: taskNumber });
	};
	getReservedTaskNumber = () => {
		const taskNumber = Object.entries(this.state.celeryData.reserved)
			.map((x) => x[1].length)
			.reduce(this.sumReducer);
		this.setState({ reservedNumber: taskNumber });
	};
	getScheduledTaskNumber = () => {
		const taskNumber = Object.entries(this.state.celeryData.scheduled)
			.map((x) => x[1].length)
			.reduce(this.sumReducer);
		this.setState({ scheduledNumber: taskNumber });
	};
	getRevokedTaskNumber = () => {
		const taskNumber = Object.entries(this.state.celeryData.revoked)
			.map((x) => x[1].length)
			.reduce(this.sumReducer);
		this.setState({ revokedNumber: taskNumber });
	};
	getUnassignedTaskNumber = () => {
		const taskNumber = this.state.celeryData["unassigned"];
		this.setState({ unassignedNumber: taskNumber });
	};

	componentDidMount = () => {
		document.title = "Celery Monitor";
		this.getCeleryStatus();
		this.timer = setInterval(
			() => this.getCeleryStatus(),
			this.state.refreshTimeRate * 1000
		);
		this.displayTimer = setInterval(() => this.updateTime(), 60000);
	};
	componentWillUnmount() {
		this.timer && clearTimeout(this.timer);
		this.displayTimer && clearTimeout(this.displayTimer);
	}

	render() {
		const displayData = this.state.celeryData;
		if (this.state.canDisplay) {
			return (
				<div>
					{/* title */}
					<h4 className="page-title">Worker Status Monitor</h4>
					<Container>
						<Row>
							<Col md={4}>
								{/* 列出所有registered的task */}
								<ListGroup as="ul">
									<ListGroup.Item as="li" active style={{ fontSize: "1.5rem" }}>
										Registered Functions
									</ListGroup.Item>
									{Object.entries(displayData.registered)[0][1].map(
										(elem, key) => (
											<ListGroup.Item as="li" key={key}>
												{elem}
											</ListGroup.Item>
										)
									)}
								</ListGroup>
								<Card style={{ marginTop: "80px" }}>
									<Card.Body>
										<Card.Title>Online Worker:</Card.Title>
										{Object.entries(displayData.active).map((elem, key) => (
											<Card.Text style={{ fontWeight: "bold" }} key={key}>
												{elem[0]}
											</Card.Text>
										))}
										<Card.Subtitle className="mb-2 text-muted">
											Data Updated {this.state.displayTime} minutes ago
										</Card.Subtitle>
										<Card.Text>
											2020 © Random Walk - Ranwalk.com support by Monte Carlo
											Consulting LLC
										</Card.Text>
									</Card.Body>
								</Card>
							</Col>
							{/* 箭头 */}
							<Col
								md={1}
								style={{
									color: "grey",
									fontSize: "1.5rem",
									paddingTop: "10px",
									paddingLeft: "35px",
								}}
							>
								---
							</Col>
							<Col md={{ span: 6 }}>
								{/* 各个队列的task数量 */}
								<ListGroup horizontal style={{ marginBottom: "60px" }}>
									<ListGroup
										as="ul"
										style={{ width: "120px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="success">
											Active
										</ListGroup.Item>
										<ListGroup.Item>{this.state.activeNumber}</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "120px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="info">
											Reserved
										</ListGroup.Item>
										<ListGroup.Item>{this.state.reservedNumber}</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "125px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="warning">
											Scheduled
										</ListGroup.Item>
										<ListGroup.Item>
											{this.state.scheduledNumber}
										</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "125px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="dark">
											Revoked
										</ListGroup.Item>
										<ListGroup.Item>{this.state.revokedNumber}</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "140px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="danger">
											Unassigned
										</ListGroup.Item>
										<ListGroup.Item>
											{this.state.unassignedNumber}
										</ListGroup.Item>
									</ListGroup>
								</ListGroup>
								{/* 展示主要信息的table */}
								<CeleryDataTable allData={displayData}></CeleryDataTable>
							</Col>
						</Row>
					</Container>
				</div>
			);
		} else {
			return (
				// 默认界面
				<div>
					{/* <Jumbotron style={{backgroundColor: '#e9ecef'}}>
              <h2 style={{margin: '0 auto', width: '25rem'}}>Monte Carlo Worker Status</h2>
            </Jumbotron> */}
					<h4 className="page-title">Worker Status Monitor</h4>
					<Container>
						<Row>
							<Col md={4}>
								{/* 列出所有registered的task */}
								<ListGroup as="ul">
									<ListGroup.Item as="li" active style={{ fontSize: "1.5rem" }}>
										Registered Functions
									</ListGroup.Item>
									{Object.entries(displayData.registered)[0][1].map(
										(elem, key) => (
											<ListGroup.Item
												as="li"
												key={key}
												className="monitor-list"
											>
												{elem}
											</ListGroup.Item>
										)
									)}
								</ListGroup>

								<Card style={{ marginTop: "80px" }}>
									<Card.Body>
										<Card.Title>Online Worker:</Card.Title>
										{Object.entries(displayData.active).map((elem, key) => (
											<Card.Text style={{ fontWeight: "bold" }} key={key}>
												{elem[0]}
											</Card.Text>
										))}
										<Card.Subtitle className="mb-2 text-muted">
											Data Updated {this.state.displayTime} minutes ago
										</Card.Subtitle>
										<Card.Text>
											2020 © Random Walk - Ranwalk.com support by Monte Carlo
											Consulting LLC
										</Card.Text>
									</Card.Body>
								</Card>
							</Col>
							{/* 箭头 */}
							<Col
								md={1}
								style={{
									color: "grey",
									fontSize: "1.5rem",
									paddingTop: "10px",
									paddingLeft: "35px",
								}}
							>
								---
							</Col>
							<Col md={{ span: 6 }}>
								{/* 各个队列的task数量 */}
								<ListGroup
									horizontal
									className="monitor-status-list"
									style={{ marginBottom: "60px" }}
								>
									<ListGroup
										as="ul"
										style={{ width: "120px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="success">
											Active
										</ListGroup.Item>
										<ListGroup.Item>0</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "120px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="info">
											Reserved
										</ListGroup.Item>
										<ListGroup.Item>0</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "125px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="warning">
											Scheduled
										</ListGroup.Item>
										<ListGroup.Item>0</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "125px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="dark">
											Revoked
										</ListGroup.Item>
										<ListGroup.Item>0</ListGroup.Item>
									</ListGroup>
									<ListGroup
										as="ul"
										style={{ width: "140px", fontSize: "1.2rem" }}
									>
										<ListGroup.Item as="li" variant="danger">
											Unassigned
										</ListGroup.Item>
										<ListGroup.Item>0</ListGroup.Item>
									</ListGroup>
								</ListGroup>
								{/* 展示主要信息的table */}
								<MDBDataTable striped bordered small />
							</Col>
						</Row>
					</Container>
				</div>
			);
		}
	}
}
