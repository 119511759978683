import React, { Component } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { getAirstreamUrl, getNumericalUrl, axios } from "./utils";
import { toast } from "react-toastify";
import moment from "moment";
import "../staticfiles/login.css";

export default class Login extends Component {
	state = {
		username: "",
		pwd: "",
		token: "",
		airstreamToken: "",
	};

	redirectApp = async () => {
		try {
			let res1 = await axios.post(getNumericalUrl("auth/jwt/"), {
				username: this.state.username,
				password: this.state.pwd,
			});

			let res2 = await axios.post(getAirstreamUrl("permission/jwt/auth/"), {
				username: this.state.username,
				password: this.state.pwd,
			});

			this.setState({ token: res1.data.token });
			localStorage.setItem("RWLoginToken", res1.data.token);
			localStorage.setItem("username", this.state.username);
			localStorage.setItem("airstreamToken", res2.data.token);
			let expire = moment();
			expire.add(7, "d");
			localStorage.setItem("RWExpireDate", expire.format());
			toast.success("Login Success");
			var newpage = this.props.url;
			this.props.history.push(newpage);
		} catch (e) {
			toast.error("Username or Password is incorrect!");
		}
	};

	handleUsernameChange = (e) => {
		this.setState({ username: e.target.value });
	};
	handlePwdChange = (e) => {
		this.setState({ pwd: e.target.value });
	};

	render() {
		return (
			<div className="body-green">
				<div className="page-wrapper">
					<header id="header" className="header">
						<div className="container">
							<div className="branding">
								<h1 className="logo">
									<a href="/">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="currentColor"
											class="bi bi-files"
											viewBox="0 0 16 16"
										>
											<path
												fillRule="evenodd"
												d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"
											/>
											<path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
										</svg>
										<span class="text-highlight">RW</span>
										<span class="text-bold">APP</span>
									</a>
								</h1>
							</div>

							<Breadcrumb className="breadcrumb">
								<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
								<Breadcrumb.Item active>Quick Start</Breadcrumb.Item>
							</Breadcrumb>

							<div className="top-search-box">
								<form
									className="form-inline search-form justify-content-center"
									action=""
									method="get"
								>
									<div className="form-group">
										<input
											type="text"
											placeholder="Search..."
											name="search"
											className="form-control search-input"
										/>
										<button
											type="submit"
											className="btn search-btn"
											value="Search"
										>
											<svg
												className="svg-inline--fa fa-search fa-w-16"
												aria-hidden="true"
												focusable="false"
												data-prefix="fas"
												data-icon="search"
												role="img"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
												data-fa-i2svg=""
												style={{ width: "14px", height: "14px" }}
											>
												<path
													fill="currentColor"
													d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
												></path>
											</svg>
										</button>
									</div>
								</form>
							</div>
						</div>
					</header>

					<section class="cards-section text-center">
						<div class="container">
							<h2 class="title">User Authentication</h2>
							<div class="intro">
								<p>
									The following content is proprietary and confidential property
									of Random Walk Financial. These contents are intended solely
									for analysis by Random Walk clients. By logging in you are
									indicating you are a client of random walk financial. We use
									tracking tools to monitor unauthorized access which is
									prohibited.
								</p>
							</div>

							<div className="container">
								<div id="cards-wrapper" className="cards-wrapper row">
									<div className="offset-md-2 col-sm-8">
										<div className="card item item-pink item-1">
											<div className="item-inner card-subtitle">
												<p>
													Your account doesn't have access to this page. To
													proceed, please login with an account that has access.
												</p>

												<form method="post" action="/accounts/login/">
													<input
														type="hidden"
														name="csrfmiddlewaretoken"
														value="HBw7bk17Fu5zTpMpa8rPzE4YVzOZlXcEBEiUxdR8GBH0xVL0XaYKhi6F2W0lbhxB"
													/>
													<div className="row form-group">
														<label
															className="col-sm-3 col-form-label"
															for="id_username"
														>
															Username:
														</label>
														<div className="col-sm-9">
															<input
																className="form-control"
																type="text"
																name="username"
																onChange={(e) => this.handleUsernameChange(e)}
																autoFocus=""
																autoCapitalize="none"
																autocomplete="username"
																maxLength="150"
																required=""
																id="id_username"
															/>
														</div>
													</div>
													<div className="row form-group">
														<label
															className="col-sm-3 col-form-label"
															for="id_password"
														>
															Password:
														</label>
														<div className="col-sm-9">
															<input
																className="form-control"
																type="password"
																name="password"
																onChange={(e) => this.handlePwdChange(e)}
																autocomplete="current-password"
																required=""
																id="id_password"
															/>
														</div>
													</div>

													<Button
														class="btn btn-primary mt-3"
														onClick={this.redirectApp}
													>
														Login
													</Button>
													<input
														type="hidden"
														name="next"
														value="/chart/deep-discount/"
													/>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* <section class="cards-section text-center">
                    <div class="container">
                        <h2 class="title">User Authentication</h2>
                        <div class="intro">
                            <p>The following content is proprietary and confidential property of Random Walk Financial. These contents are intended solely for analysis by Random Walk clients. By logging in you are indicating you are a client of random walk financial. We use tracking tools to monitor unauthorized access which is prohibited.</p>
                        </div>
                        <div id="cards-wrapper" class="cards-wrapper row">
                            <div class="item item-pink item-1 offset-2 col-8">
                                <div class="item-inner">
                                    <p>Your account doesn't have access to this page. To proceed,
                                        please login with an account that has access.
                                    </p>
                                    <form method="post" action="/accounts/login/">
                                        <input type="hidden" name="csrfmiddlewaretoken" value="HBw7bk17Fu5zTpMpa8rPzE4YVzOZlXcEBEiUxdR8GBH0xVL0XaYKhi6F2W0lbhxB"/>
                                        <div class="form-group">
                                            <label><label for="id_username">Username:</label></label>
                                            <input type="text" name="username" onChange={(e)=>this.handleUsernameChange(e)} autoFocus="" autoCapitalize="none" autocomplete="username" maxLength="150" required="" id="id_username"/>
                                        </div>
                                        <div class="form-group">
                                            <label><label for="id_password">Password:</label></label>
                                            <input type="password" name="password" onChange={(e)=>this.handlePwdChange(e)} autocomplete="current-password" required="" id="id_password"/>
                                        </div>
                                        <Button class="btn btn-primary mt-3" onClick={this.redirectApp}>Login</Button>
                                        <input type="hidden" name="next" value="/chart/deep-discount/"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </section> */}

				<footer id="footer" className="footer">
					<div className="container">
						<div className="row">
							<div className="text-center">
								<small className="copyright">
									©2020 <a href="http://ranwalk.com/">RANDOM WALK FINANCIAL</a>.
									ALL RIGHTS RESERVED.
								</small>
							</div>
							<div className="pull-right">
								<small className="copyright">
									Support by{" "}
									<a
										href="monte.carlo@goldbeyond.com"
										target="_blank"
										rel="noreferrer"
									>
										Monte Carlo Consulting LLC
									</a>
									.
								</small>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
