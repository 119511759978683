import React, { useEffect, memo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getAirstreamUrl, getAirstreamHeader, axios } from "../utils";

const SpecificTask = memo(
	({ displayParent, returnOverview, selectedTask, graphStatus }) => {
		const reExecute = () => {
			axios
				.post(
					getAirstreamUrl(`scheduler/execution/tasks/${selectedTask.task_id}/`),
					{
						status: 0,
					},
					getAirstreamHeader()
				)
				.then((response) => {
					alert("Success");
					window.location.reload();
				})
				.catch((error) => {
					alert("Error: ", error);
				});
		};

		if (selectedTask == null) {
			return (
				<div className="specific-task-wrapper">
					<Row>
						<button
							class="btn btn-outline-info"
							onClick={() => returnOverview(false)}
						>
							Return to Overview
						</button>
					</Row>
				</div>
			);
		}

		return (
			<div>
				<div className="specific-task-wrapper">
					<Row>
						<button
							class="btn btn-outline-info"
							onClick={() => returnOverview(false)}
						>
							Return to Overview
						</button>
					</Row>

					<div className="specific-task-section">
						{graphStatus === 1 &&
						(selectedTask.status === 3 ||
							(selectedTask.auto_report === false &&
								selectedTask.status === 1 &&
								selectedTask.cmd_log !== null)) ? (
							<div>
								<Row>
									<button class="btn btn-outline-info" onClick={reExecute}>
										Re-execute Task
									</button>
								</Row>
							</div>
						) : (
							<div></div>
						)}

						{selectedTask.task_id && (
							<div>
								<Row>
									<p className="card-title">Task Id: {selectedTask.task_id}</p>
								</Row>
							</div>
						)}

						{selectedTask.parent && (
							<div>
								<Row>
									<p className="card-title">Parent: {selectedTask.parent}</p>
								</Row>
							</div>
						)}

						{selectedTask.start_time && (
							<div>
								<Row>
									<p className="card-title">
										Start Time: {selectedTask.start_time}
									</p>
								</Row>
							</div>
						)}

						{selectedTask.end_time && (
							<div>
								<Row>
									<p className="card-title">
										End Time: {selectedTask.end_time}
									</p>
								</Row>
							</div>
						)}

						{selectedTask.auto_report !== undefined && (
							<div>
								<Row>
									<p className="card-title">
										Auto Report: {selectedTask.auto_report.toString()}
									</p>
								</Row>
							</div>
						)}

						{selectedTask.is_py_func !== undefined && (
							<div>
								<Row>
									{selectedTask.is_py_func === 0 ? (
										<p className="card-title">Python Function: Offline</p>
									) : selectedTask.is_py_func === 1 ? (
										<p className="card-title">Python Function: Python</p>
									) : (
										<p className="card-title">Python Function: System</p>
									)}
								</Row>
							</div>
						)}

						{selectedTask.command !== "" && (
							<div>
								<Row>
									<p className="card-title">Command: {selectedTask.command}</p>
								</Row>
							</div>
						)}
					</div>

					<div className="specific-task-section">
						<Row>
							{selectedTask.status === null ? (
								<div></div>
							) : displayParent ? (
								<h3 className="card-title">
									Status: Null(this is a current graph)
								</h3>
							) : selectedTask.status === 0 ? (
								<h3 className="card-title">Status: Schedueld</h3>
							) : selectedTask.status === 1 ? (
								<h3 className="card-title">Status: Running</h3>
							) : selectedTask.status === 2 ? (
								<h3 className="card-title">Status: Finished</h3>
							) : (
								<div>
									<h3 className="card-title">Status: Failed</h3>
									<h5 className="card-title">Error Message: </h5>
									<p style={{ whiteSpace: "pre-line" }}>
										{JSON.stringify(selectedTask.errors.error)}
										{JSON.stringify(selectedTask.errors.error)}
									</p>
								</div>
							)}
						</Row>
					</div>

					<div className="specific-task-section">
						{selectedTask.key && (
							<Row>
								<h3 className="card-title">Key: {selectedTask.key} </h3>
							</Row>
						)}

						{selectedTask.name && (
							<Row>
								<h3 className="card-title">Name: {selectedTask.name} </h3>
							</Row>
						)}
					</div>

					<div className="specific-task-section">
						{selectedTask.description && (
							<div>
								<Row>
									<h5 className="card-title">Description: </h5>
								</Row>

								<Row>
									<p className="card-text">{selectedTask.description}</p>
								</Row>
							</div>
						)}
					</div>

					<div className="specific-task-section">
						{selectedTask.results && (
							<div>
								<Row>
									<h5 className="card-title">Results: </h5>
								</Row>

								<Row>
									<p className="card-text">
										{JSON.stringify(selectedTask.results.result)}
									</p>
								</Row>
							</div>
						)}
					</div>

					<div className="specific-task-section">
						{selectedTask.cmd_log && (
							<div>
								<Row>
									<h5 className="card-title">Cmd Log: </h5>
								</Row>

								<Row>
									<p className="card-text" style={{ whiteSpace: "pre-line" }}>
										{selectedTask.cmd_log}
									</p>
								</Row>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
);

export default SpecificTask;
