import React, { useEffect, useState } from "react";
import { getLunaUrl, getNumericalHeader, axios } from "../utils";
import DataTable from "./DataTable";
import { toast } from "react-toastify";
import style from "./index.module.css";

const ActionLog = ({ uniqueDomains, tickers, loaded }) => {
	const [domains, setDomains] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState("");
	const [selectedTicker, setSelectedTicker] = useState("");
	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [logData, setLogData] = useState([]);
	const [total, setTotal] = useState("");
	const [filtered, setFiltered] = useState("");

	const [user, setUser] = useState("");
	const [feedId, setFeedId] = useState("");
	const [groupId, setGroupId] = useState("");
	const [type, setType] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [hideSys, setHideSys] = useState(true);
	const [pageSize, setPageSize] = useState(30);
	const [start, setStart] = useState(0);

	const [loadingData, setLoading] = useState(false);

	const getUserLog = async (s = 0) => {
		setLoading(true);
		const payload = {
			columns: [
				{
					data: null,
					name: "",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "user",
					name: "user",
					orderable: true,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "type",
					name: "type",
					orderable: true,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "time",
					name: "time",
					orderable: true,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "feed_ids",
					name: "feed_ids",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "group_ids",
					name: "group_ids",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "domain",
					name: "domain",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.min_discount_pct",
					name: "discount.min_discount_pct",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.max_discount_pct",
					name: "discount.max_discount_pct",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.effective_discount_pct",
					name: "discount.effective_discount_pct",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.state_discount",
					name: "discount.state_discount",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.product_type",
					name: "discount.product_type",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.is_storewide",
					name: "discount.is_storewide",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.is_steep",
					name: "discount.is_steep",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.is_significant",
					name: "discount.is_significant",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.holiday_tag",
					name: "discount.holiday_tag",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
				{
					data: "discount.free_ship_condition",
					name: "discount.free_ship_condition",
					orderable: false,
					search: {
						regex: false,
						value: "",
					},
					searchable: true,
				},
			],
			domain: selectedDomain,
			ticker: selectedTicker,
			draw: 0,
			feed_id: feedId,
			group_id: groupId,
			ignore_sys: hideSys,
			length: pageSize,
			order: [
				{
					column: 3,
					dir: "desc",
				},
			],
			search: {},
			start: s,
			t_start: startDate,
			t_end: endDate,
			type: type,
			user: user,
		};
		await axios
			.post(
				getLunaUrl(`v1/emailfeed/log/datatable/`),
				payload,
				getNumericalHeader()
			)
			.then((res) => {
				setTotal(res.data.recordsTotal);
				setFiltered(res.data.recordsFiltered);
				let tmp = res.data.data;
				tmp = tmp.map((record) => {
					return {
						...record,
						feed_ids: record.feed_ids.substring(1, record.feed_ids.length - 1),
						min_discount_pct: record.discount.min_discount_pct,
						max_discount_pct: record.discount.max_discount_pct,
						effective_discount_pct: record.discount.effective_discount_pct,
						state_discount: record.discount.state_discount,
						product_type: record.discount.product_type,
						is_storewide: record.discount.is_storewide,
						is_steep: record.discount.is_steep,
						is_significant: record.discount.is_significant,
						holiday_tag: record.discount.holiday_tag,
						free_ship_condition: record.discount.free_ship_condition,
					};
				});
				setLogData(tmp);
			})
			.catch((err) => {
				toast.error("An error occurred, please refresh the page and try again");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const changePage = (dir) => {
		let s = start;
		s += pageSize * dir;
		setStart(s);
		getUserLog(s);
	};

	useEffect(() => {
		if (!loaded) return;
		const fetch = () => {
			let domains = uniqueDomains;
			domains
				.filter((domain) => {
					return domain.domain === domain.parent_domain;
				})
				.sort();
			setDomains(domains);
		};
		fetch();
		getUserLog();
	}, [loaded]);

	useEffect(() => {
		if (selectedTicker === "") {
			setSelectedDomain("");
			return;
		}
		if (domains.length > 0) {
			let tmp = domains.filter((dom) => dom.ticker === selectedTicker).sort();
			setSelectedTickerDomains(tmp);
			setSelectedDomain(tmp[0].domain);
		}
	}, [selectedTicker]);

	useEffect(() => {
		document.title = "User Action Log";
	}, []);

	return (
		<div>
			<h4 className="page-title">User Action Log</h4>
			<div className="card">
				<div className="card-title"></div>
				<div className="card-body">
					<table style={{ width: "100%" }} cellPadding="5">
						<tbody>
							<tr>
								<td>
									<label for="select-ticker-input">Ticker</label>
								</td>
								<td>
									<select
										id="select-ticker-input"
										value={selectedTicker}
										onChange={(e) => setSelectedTicker(e.target.value)}
									>
										<option value="" selected>
											all
										</option>
										{tickers.map((tk) => {
											return <option value={tk}>{tk}</option>;
										})}
									</select>
								</td>
								<td>
									<label for="select-domain-input">Domain</label>
								</td>
								<td>
									<select
										id="select-domain-input"
										value={selectedDomain}
										onChange={(e) => setSelectedDomain(e.target.value)}
									>
										<option value="" selected>
											all
										</option>
										{selectedTicker && selectedTickerDomains
											? selectedTickerDomains.map((domain) => {
													return (
														<option value={domain.domain}>
															{domain.domain}
														</option>
													);
											  })
											: domains.map((domain) => {
													return (
														<option value={domain.domain}>
															{domain.domain}
														</option>
													);
											  })}
									</select>
								</td>
							</tr>
							<tr>
								<td>
									<label for="select-user-input">User</label>
								</td>
								<td>
									<input
										type="text"
										id="select-user-input"
										value={user}
										onChange={(e) => setUser(e.target.value)}
									/>
								</td>
								<td>
									<label for="select-feed-input">Feed Id</label>
								</td>
								<td>
									<input
										type="text"
										id="select-feed-input"
										value={feedId}
										onChange={(e) => setFeedId(e.target.value)}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<label for="select-group-input">Group Id</label>
								</td>
								<td>
									<input
										type="text"
										id="select-group-input"
										value={groupId}
										onChange={(e) => setGroupId(e.target.value)}
									/>
								</td>
								<td>
									<label for="select-type-input">Type</label>
								</td>
								<td>
									<select
										id="select-type-input"
										value={type}
										onChange={(e) => setType(e.target.value)}
									>
										<option value="E">edit</option>
										<option value="R">release</option>
										<option value="G">regroup</option>
										<option value="P">group</option>
										<option value="T">revert</option>
										<option value="" selected>
											all
										</option>
									</select>
								</td>
								<td>
									<button
										type="submit"
										id="search-commit"
										style={{ fontSize: 16 }}
										onClick={() => getUserLog()}
									>
										Search
									</button>
								</td>
							</tr>
							<tr>
								<td>
									<label for="select-startDate-input">Start Date</label>
								</td>
								<td>
									<input
										type="date"
										id="select-startDate-input"
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
									/>
								</td>
								<td>
									<label for="select-endDate-input">End Date</label>
								</td>
								<td>
									<input
										type="date"
										id="select-endDate-input"
										value={endDate}
										onChange={(e) => setEndDate(e.target.value)}
									/>
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{ marginLeft: 5, marginTop: 10 }}>
						Hide System Log{" "}
						<input
							type="checkbox"
							id="select-sys-input"
							checked={hideSys}
							onChange={(e) => setHideSys(e.currentTarget.checked)}
						/>
					</div>
					<div style={{ marginLeft: 5, marginTop: 10 }}>
						Show{" "}
						<select
							value={pageSize}
							onChange={(e) => setPageSize(e.target.value)}
						>
							<option value={20}>20</option>
							<option value={30}>30</option>
							<option value={50}>50</option>
						</select>{" "}
						Entries
					</div>
					<br />
					<br />
					<DataTable dataFetching={loadingData} totalData={logData} />
					{loadingData ? (
						""
					) : (
						<div>
							<div style={{ float: "left" }}>
								Showing {start + 1} to {Math.min(start + pageSize, filtered)} of{" "}
								{filtered} entries (filtered from {total} total entries)
							</div>
							<div style={{ float: "right" }}>
								{start === 0 ? (
									""
								) : (
									<span
										className={style.pageButton}
										onClick={() => changePage(-1)}
									>
										Previous
									</span>
								)}
								{start + pageSize >= filtered ? (
									""
								) : (
									<span
										className={style.pageButton}
										onClick={() => changePage(1)}
									>
										Next
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ActionLog;
