import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { getNumericalHeader, getLunaUrl, axios } from "../utils";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const SearchTable = ({ totalData, isLoading, title }) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [editFeedId, setEditFeedId] = useState("");
	const [editMin, setEditMin] = useState("");
	const [editMax, setEditMax] = useState("");
	const [editEffective, setEditEffective] = useState("");
	const [editState, setEditState] = useState("");
	const [editStateVal, setEditStateVal] = useState("");
	const [editProduct, setEditProduct] = useState("");
	const [editHoliday, setEditHoliday] = useState("");
	const [editShip, setEditShip] = useState("");
	const [editStorewide, setEditStorewide] = useState(false);
	const [editSteep, setEditSteep] = useState(false);
	const [editSignificant, setEditSignificant] = useState(false);
	const [editClearance, setEditClearance] = useState(false);
	const [editApplyall, setEditApplyall] = useState(false);

	const [initDDfields, setInitDDfields] = useState({});

	const columns = [
		{
			dataField: "feed_id",
			hidden: true,
		},
		{
			dataField: "product_type",
			hidden: true,
		},
		{
			dataField: "holiday_tag",
			hidden: true,
		},
		{
			dataField: "free_ship_condition",
			hidden: true,
		},
		{
			dataField: "image_url",
			hidden: true,
		},
		{
			dataField: "subject",
			text: "Subject",
			sort: true,
		},
		{
			dataField: "domain",
			text: "Domain",
			sort: true,
		},
		{
			dataField: "start_date",
			text: "Start Date",
			sort: true,
		},
		{
			dataField: "end_date",
			text: "End Date",
			sort: true,
		},
		{
			dataField: "total_volume",
			text: "Volume",
			sort: true,
			formatter: (cell, row) => {
				return cell.toLocaleString();
			},
		},
		{
			dataField: "min_discount_pct",
			text: "Min %",
			sort: true,
		},
		{
			dataField: "max_discount_pct",
			text: "Max %",
			sort: true,
		},
		{
			dataField: "effective_discount_pct",
			text: "Effective %",
			sort: true,
		},
		{
			dataField: "state_discount",
			text: "State",
			sort: true,
		},
		{
			dataField: "is_storewide",
			text: "Storewide",
			sort: true,
		},
		{
			dataField: "is_steep",
			text: "Steep",
			sort: true,
		},
		{
			dataField: "is_significant",
			text: "Significant",
			sort: true,
		},
	];

	const addEditRecord = (row) => {
		setEditFeedId(row.feed_id);
		setEditMin(row.min_discount_pct);
		setEditMax(row.max_discount_pct);
		setEditEffective(row.effective_discount_pct);
		setEditState(row.state_discount);
		setEditProduct(row.product_type);
		setEditHoliday(row.holiday_tag);
		setEditShip(row.free_ship_condition);
		setEditStateVal(row.state_discount_val);
		setEditStorewide(row.is_storewide);
		setEditSteep(row.is_steep);
		setEditSignificant(row.is_significant);
		setEditClearance(row.is_clearance);

		let initFields = initDDfields;
		initFields["min_discount_pct"] = row.min_discount_pct;
		initFields["max_discount_pct"] = row.max_discount_pct;
		initFields["effective_discount_pct"] = row.effective_discount_pct;
		initFields["state_discount"] = row.state_discount;
		initFields["product_type"] = row.product_type;
		initFields["holiday_tag"] = row.holiday_tag;
		initFields["free_ship_condition"] = row.free_ship_condition;
		initFields["state_discount_val"] = row.state_discount_val;
		setInitDDfields(initFields);
	};

	const updateDeepDiscount = async () => {
		let args = {},
			args_original = {};
		args["feed_id"] = editFeedId;
		args["min_discount_pct"] = Number(editMin);
		args["max_discount_pct"] = Number(editMax);
		args["effective_discount_pct"] = Number(editEffective);
		args["state_discount"] = editState;
		args["product_type"] = editProduct;
		args["holiday_tag"] = editHoliday;
		args["free_ship_condition"] = editShip;
		args["is_storewide"] = editStorewide;
		args["is_steep"] = editSteep;
		args["is_significant"] = editSignificant;
		args["is_clearance"] = editClearance;
		args["state_discount_val"] = editStateVal;

		let id_str = "data[" + editFeedId + "]";
		args_original[id_str + "[feed_list]"] = editFeedId;
		args_original[id_str + "[feed_id]"] = editFeedId;
		args_original[id_str + "[deep_discount__min_discount_pct]"] = editMin;
		args_original[id_str + "[deep_discount__max_discount_pct]"] = editMax;
		args_original[id_str + "[deep_discount__effective_discount_pct]"] =
			editEffective;
		args_original[id_str + "[deep_discount__state_discount]"] = editState;
		args_original[id_str + "[deep_discount__product_type]"] = editProduct;
		args_original[id_str + "[deep_discount__holiday_tag]"] = editHoliday;
		args_original[id_str + "[deep_discount__free_ship_condition]"] = editShip;
		args_original[id_str + "[deep_discount__is_storewide]"] = editStorewide;
		args_original[id_str + "[deep_discount__is_steep]"] = editSteep;
		args_original[id_str + "[deep_discount__is_significant]"] = editSignificant;
		args_original[id_str + "[deep_discount__is_clearance]"] = editClearance;
		args_original[id_str + "[deep_discount__apply_to_all]"] = editApplyall;
		args_original[id_str + "[deep_discount__state_discount_val]"] =
			editStateVal;
		args_original["action"] = "edit";
		let params = new URLSearchParams(args_original).toString();

		await axios
			.post(getLunaUrl(`v1/deep-discount/edit-luna`), params, {
				headers: {
					Authorization: "JWT " + localStorage.getItem("RWLoginToken"),
					"content-type": "application/x-www-form-urlencoded",
				},
			})
			.then((response) => {
				toast.warn(
					"Please go back to Airstream to backfill the harmonic discount"
				);
				setShowEditModal(false);
				let toModify = {};
				for (const key in initDDfields) {
					if (args[key] != initDDfields[key]) {
						toModify[key + "_nlp"] = initDDfields[key];
						toModify[key] = args[key];
					}
				}
				for (let entry of totalData) {
					if (entry.feed_id === editFeedId) {
						for (const f in toModify) {
							entry[f] = toModify[f];
						}
						break;
					}
				}
			})
			.catch((e) => {
				console.log(e);
			});

		await axios
			.post(
				getLunaUrl(
					`v1/harmonic-discount/cached/update/?feed_id=${editFeedId}&apply_all=${editApplyall}`
				),
				args,
				getNumericalHeader()
			)
			.then((response) => {
				toast.success("success");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const downloadData = () => {
		let csvData = totalData
			.map((row) => Object.values(row).join(","))
			.join("\n");
		csvData = Object.keys(totalData[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, title + " Table Data.csv");
	};

	if (totalData.length === 0) {
		return (
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={3}>No Data</Col>
						</Row>
					</h4>
				</CardBody>
			</Card>
		);
	}

	const expandRow = {
		renderer: (row) => {
			return (
				<>
					<Table striped bordered si ze="sm">
						<tbody>
							<tr>
								<td>Campaign ID</td>
								<td>
									<a
										target="_blank"
										href={
											"http://luna.ranwalk.goldbeyond.com/api/v1/emailfeed/data/" +
											row.feed_id +
											"/"
										}
									>
										{row.feed_id}
									</a>
								</td>
							</tr>
							<tr>
								<td>Email Image</td>
								<td>
									<a href={row.image_url} target="_blank">
										<svg
											t="1638500915285"
											class="icon"
											viewBox="0 0 1024 1024"
											version="1.1"
											xmlns="http://www.w3.org/2000/svg"
											p-id="3033"
											width="20"
											height="20"
										>
											<path
												d="M512 170.666667C298.666667 170.666667 116.48 303.36 42.666667 490.666667 116.48 677.973333 298.666667 810.666667 512 810.666667s395.52-132.693333 469.333333-320C907.52 303.36 725.333333 170.666667 512 170.666667z m0 533.333333c-117.76 0-213.333333-95.573333-213.333333-213.333333s95.573333-213.333333 213.333333-213.333334 213.333333 95.573333 213.333333 213.333334-95.573333 213.333333-213.333333 213.333333z m0-341.333333c-70.826667 0-128 57.173333-128 128s57.173333 128 128 128 128-57.173333 128-128-57.173333-128-128-128z"
												fill="#727cf5"
												p-id="3034"
											></path>
										</svg>
									</a>
								</td>
							</tr>
							<tr>
								<td>Free Ship</td>
								<td>{row.free_ship_condition}</td>
							</tr>
						</tbody>
					</Table>
					<Button
						variant="primary"
						onClick={() => {
							addEditRecord(row);
							setShowEditModal(true);
						}}
					>
						Edit Deep Discount
					</Button>
				</>
			);
		},
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	const { SearchBar } = Search;

	if (isLoading) {
		return (
			<div className="card" style={{ overflow: "scroll" }}>
				<div className="card-title"></div>
				<div className="card-body" style={{ minWidth: "800px" }}>
					<h4 style={{ textAlign: "center" }}>Loading Table...</h4>
				</div>
			</div>
		);
	}
	return (
		<div>
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Record</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 600, overflowY: "scroll" }}>
					<Form>
						<Form.Group className="mb-3" controlId="edit_campagin_id">
							<Form.Label size="sm">Campaign ID</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editFeedId}
								readOnly
								onChange={(e) => setEditFeedId(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_min">
							<Form.Label size="sm">Min %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editMin}
								onChange={(e) => setEditMin(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_max">
							<Form.Label size="sm">Max %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editMax}
								onChange={(e) => setEditMax(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_effective">
							<Form.Label size="sm">Effective %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editEffective}
								onChange={(e) => setEditEffective(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_state">
							<Form.Label size="sm">State</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editState}
								onChange={(e) => setEditState(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_product">
							<Form.Label size="sm">Product</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editProduct}
								onChange={(e) => setEditProduct(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_holiday">
							<Form.Label size="sm">Holiday</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editHoliday}
								onChange={(e) => setEditHoliday(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_freeship">
							<Form.Label size="sm">Free Ship</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editShip}
								onChange={(e) => setEditShip(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_storewide">
							<Form.Label size="sm">Storewide</Form.Label>
							<Form.Check
								defaultChecked={editStorewide}
								onChange={(e) => {
									setEditStorewide(!editStorewide);
								}}
								type="switch"
								id="storewide-sw"
								label={editStorewide ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_steep">
							<Form.Label size="sm">Steep</Form.Label>
							<Form.Check
								defaultChecked={editSteep}
								onChange={(e) => {
									setEditSteep(!editSteep);
								}}
								type="switch"
								id="steep-sw"
								label={editSteep ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_significant">
							<Form.Label size="sm">Significant</Form.Label>
							<Form.Check
								defaultChecked={editSignificant}
								onChange={(e) => {
									setEditSignificant(!editSignificant);
								}}
								type="switch"
								id="significant-sw"
								label={editSignificant ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_applyall">
							<Form.Label size="sm">Apply to All</Form.Label>
							<Form.Check
								defaultChecked={editApplyall}
								onChange={(e) => {
									setEditApplyall(!editApplyall);
								}}
								type="switch"
								id="applyall-sw"
								label={editApplyall ? "Y" : "N"}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEditModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => updateDeepDiscount()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			<Card>
				<CardBody>
					<ToolkitProvider
						bootstrap4
						keyField="feed_id"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
										<Button
											variant="outline-primary"
											onClick={downloadData}
											style={{ marginLeft: 20, marginBottom: 5 }}
										>
											Download CSV
										</Button>
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									expandRow={expandRow}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 50,
										showTotal: true,
									})}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default SearchTable;
