import React, { Component } from "react";
import { getNumericalUrl, getNumericalHeader } from "./utils";
import { axios } from "./utils";
import "../staticfiles/custom.css";
import { Container, Row, Col } from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
const unique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export default class CategoryMap extends Component {
	state = {
		tickerList: [],
		domainList: [],
		categoryList: [],
		fetchedList: [],
		editList: [],
		choiceList: [],
		ruleList: [],
		effectiveRuleList: [],
		newCategoryList: [],
		checked_category: [],
		searchDomain: "",
		records: [],
		ignoreList: [],
		target: "",
		category_id: -1,
		added: 1,
		createMode: 1,
		patternAdd: "",
		categoryAdd: "",
		implemented: false,
		loaded: false,
		changed: 0,
		last_saved: "N/A",
		history: [],
		set_id: 0,
		histary_start: 0,
		page_size: 10,
	};
	componentDidMount = async () => {
		this.getTableauInfo();
		this.getMapList();
		this.getHistory();
		window.debug_c = this;
	};
	loadCategory = async (category_id) => {
		if (!this.state.createMode) {
			if (this.state.changed) {
				await this.saveChange();
			}
		}
		const obj = this.state.newCategoryList.filter(
			(ele) => ele.category_id === category_id
		)[0];
		if (!obj) {
			alert("Load Error");
		} else {
			this.setState({
				target: obj.category,
				category_id: category_id,
				ruleList: obj.rules,
				ignoreList: obj.exclude,
				added: obj.added || 0,
				createMode: 0,
				changed: 0,
			});
		}
	};
	saveChange = async (add) => {
		let category_id = this.state.category_id;
		if (add) {
			category_id =
				this.state.newCategoryList
					.map((ele) => ele.category_id)
					.concat([0])
					.sort((a, b) => b - a)[0] + 1;
		}
		const obj = {
			category_id: category_id,
			category: this.state.target,
			rules: this.state.ruleList,
			exclude: this.state.ignoreList,
			added: this.state.added,
		};
		const lst = this.state.newCategoryList;
		if (add) {
			lst.push(obj);
			this.setState(
				{ createMode: 0, changed: 0, newCategoryList: lst },
				this.setCreate
			);
		} else {
			this.setState(
				{
					createMode: 0,
					changed: 0,
					newCategoryList: lst
						.map((ele) => (ele.category_id === obj.category_id ? obj : ele))
						.map((ele, key) => {
							ele.category_id = key + 1;
							return ele;
						}),
				},
				this.setCreate
			);
		}
	};
	checkExcluded = (domain, category, lst) => {
		const iglst = lst || this.state.ignoreList;
		return (
			iglst.filter((ele) => ele.domain === domain && ele.category === category)
				.length > 0
		);
	};
	str2Reg = (text) => {
		// let str = text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
		try {
			let str = text;
			return new RegExp(str);
		} catch (e) {
			let str = text.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
			return new RegExp(str);
		}
	};
	getSearchResults = (keys) => {
		let lst = this.state.categoryList;
		const filteredKeys = keys.filter((e) => e);
		if (filteredKeys.length > 0) {
			lst = lst.filter((ele) => {
				const includes = filteredKeys
					.filter((e) => e)
					.map((pattern) => {
						const regrex = this.str2Reg(pattern);
						return ele.category.search(regrex) >= 0;
					})
					.filter((e) => e);
				return includes.length > 0;
			});
		}

		lst = lst.map((ele) => {
			return {
				excluded: this.checkExcluded(ele.domain, ele.category) ? 1 : 0,
				...ele,
			};
		});
		return lst;
	};
	getExclusiveResultsIgnore = (keys, key) => {
		const res_1 = this.getSearchResults([key]);
		const res_2 = this.getSearchResults(keys).filter((e) => !e.excluded);
		return res_1.filter(
			(e) =>
				res_2.filter((t) => t.domain === e.domain && t.category === e.category)
					.length === 0
		);
	};

	removeIgnore = (domain, category) => {
		this.setState((prevState) => ({
			ignoreList: prevState.ignoreList.filter(
				(ele) => !(ele.domain === domain && ele.category === category)
			),
			changed: 1,
		}));
	};
	addIgnore = (domain, category) => {
		const lst = this.state.ignoreList;
		lst.push({
			domain: domain,
			category: category,
		});
		this.setState({ ignoreList: lst, changed: 1 });
	};
	removeIgnoreAll = () => {
		this.setState({ ignoreList: [], changed: 1 });
	};
	addIgnoreAll = () => {
		this.setState({ ignoreList: this.state.categoryList, changed: 1 });
	};
	getFinalMappingList = () => {
		let lst = [];
		this.state.newCategoryList.map((ele) => {
			const lst2 = this.getSearchResults(ele.rules.map((e) => e.pattern))
				.filter((e) => !this.checkExcluded(e.domain, e.category, ele.exclude))
				.map((e) => ({
					domain: e.domain,
					from_category: e.category,
					to_category: ele.category,
				}));
			lst2.map((e) => {
				lst.push([e.from_category, e.to_category, e.domain]);
			});
		});
		return lst;
	};
	columns = [
		{
			key: "domain",
			text: "Domain",
			className: "domain",
			sortable: true,
		},
		{
			key: "category",
			text: "Category",
			className: "domain",
			sortable: true,
		},
		{
			key: "excluded",
			text: "Choice",
			sortable: true,
			cell: (record, index) => {
				return (
					<React.Fragment>
						<input
							type="checkbox"
							checked={!record.excluded}
							onChange={(e) => {
								if (e.target.checked) {
									this.removeIgnore(record.domain, record.category);
								} else {
									this.addIgnore(record.domain, record.category);
								}
							}}
						/>
					</React.Fragment>
				);
			},
		},
	];
	columnsPattern = [
		{
			key: "pattern",
			text: "Pattern",
			className: "domain",
			sortable: true,
		},
		{
			key: "category",
			text: "Category",
			className: "domain",
			sortable: true,
		},
	];
	columnsCategory = [
		{
			key: "category_id",
			text: "ID",
			className: "domain",
			sortable: true,
		},
		{
			key: "name",
			text: "Category",
			className: "domain",
			sortable: true,
		},
	];
	config = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		button: {
			excel: false,
			print: false,
		},
	};
	getTableauInfo = async () => {
		await axios
			.get(getNumericalUrl("v1/emailfeed/category/list/"), getNumericalHeader())
			.then((response) => {
				let choiceList = response.data;
				let domainList = choiceList.map((ele) => ele.domain).filter(unique);

				this.setState({
					categoryList: choiceList.sort(
						(a, b) => a.category?.localeCompare(b.category) || 0
					),
					domainList: domainList.sort((a, b) => a?.localeCompare(b) || 0),
					choiceList: choiceList,
				});
			})
			.catch((err) => {
				alert(err.message);
			});
	};
	getMapList = async (implemented, uid) => {
		let url = getNumericalUrl("v1/emailfeed/category-mapping/ruleset/");
		if (uid) {
			url += "?map_id=" + uid;
		} else if (implemented) {
			url += "?implemented=1";
		}
		await axios
			.get(url, getNumericalHeader())
			.then((response) => {
				this.setState({
					newCategoryList: response.data.data,
					implemented: response.data.implemented,
					loaded: true,
					last_saved: response.data.last_edited,
					set_id: response.data.id,
				});
			})
			.catch((err) => {
				alert(err.message);
			});
		this.setCreate();
	};
	getHistory = async (implemented) => {
		let url = getNumericalUrl("v1/emailfeed/category-mapping/history/");
		await axios
			.get(url, getNumericalHeader())
			.then((response) => {
				this.setState({
					history: response.data,
				});
			})
			.catch((err) => {
				alert(err.message);
			});
		this.setCreate();
	};
	postResult = async (implement) => {
		const data = JSON.stringify(this.state.newCategoryList);
		const payload = { json_data: data };
		if (implement) {
			payload.json_data_map = this.getFinalMappingList();
		}
		await axios
			.post(
				getNumericalUrl("v1/emailfeed/category-mapping/ruleset/"),
				payload,
				getNumericalHeader()
			)
			.then((response) => {
				alert("Success");
				this.getHistory();
				this.getMapList();
			})
			.catch((err) => alert("Error"));
	};
	updateList = (e, key, eid, val) => {
		this.setState((prevState) => ({
			editList: prevState.editList.map((ele) => {
				if (ele.map_id === eid) {
					ele[key] = val;
				}
				return ele;
			}),
		}));
	};
	addPattern = () => {
		const ptn = this.state.patternAdd;
		if (!ptn) {
			alert("No Empty String");
			return;
		}
		if (this.state.ruleList.filter((ele) => ele.pattern === ptn).length > 0) {
			alert("Pattern Exist");
			return;
		}
		const lst = this.state.ruleList;
		const oldKeys = lst.map((e) => e.pattern);
		if (oldKeys.length > 0) {
			const newDelete = this.getExclusiveResultsIgnore(oldKeys);
			const ignoreList = this.state.ignoreList;
			newDelete.map((e) =>
				ignoreList.push({
					domain: e.domain,
					category: e.category,
				})
			);
			this.setState({
				ignoreList: ignoreList,
			});
		}
		lst.push({ pattern: ptn });
		this.setState({ patternAdd: "", ruleList: lst, changed: 1 });
	};
	removePattern = (ptn) => {
		this.setState({
			ruleList: this.state.ruleList.filter((ele) => ele.pattern !== ptn),
			changed: 1,
		});
	};
	handleBlue = (e) => {
		const val = e.target.value;
		if (!val) {
			e.target.focus();
			// alert("No empty String")
		}
	};
	renderHistoryList = () => {
		const start = this.state.histary_start;
		const end = start + this.state.page_size;
		return (
			<table>
				<thead>
					<td>ID </td>
					<td>User</td>
					<td>Time</td>
					<td>Active</td>
					<td>Action</td>
				</thead>
				{this.state.history.slice(start, end).map((ele) => (
					<tr className={ele.rule_set_id === this.state.set_id ? "loaded" : ""}>
						<td>{ele.rule_set_id}</td>
						<td>{ele.author}</td>
						<td>
							{ele.in_dt.substring(0, 10) + "\n" + ele.in_dt.substring(11, 16)}
						</td>
						<td>{ele.implemented ? "True" : "False"}</td>
						<td>
							<button
								onClick={() => {
									this.getMapList(null, ele.rule_set_id);
								}}
							>
								Load
							</button>
						</td>
					</tr>
				))}
			</table>
		);
	};
	renderPatternTable = () => {
		return (
			<table>
				<thead>
					<td>
						Pattern(Exact Match or{" "}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://en.wikipedia.org/wiki/Regular_expression#Syntax"
						>
							RegExp
						</a>
						)
					</td>
					<td>Action</td>
				</thead>
				{this.state.ruleList.map((ele, key) => {
					return (
						<tr>
							<td>
								<input
									type="text"
									placeholder="No Empty String!"
									value={ele.pattern}
									onChange={(e) => {
										const lst = this.state.ruleList;
										lst[key].pattern = e.target.value;
										this.setState({ ruleList: lst, changed: 1 });
									}}
									onBlur={this.handleBlue}
								/>
							</td>
							<td>
								<button onClick={() => this.removePattern(ele.pattern)}>
									Delete
								</button>
							</td>
						</tr>
					);
				})}
				<tr>
					<td>
						<input
							type="text"
							value={this.state.patternAdd}
							onChange={(e) => this.setState({ patternAdd: e.target.value })}
						/>
					</td>
					<td>
						<button onClick={this.addPattern}>add</button>
					</td>
				</tr>
			</table>
		);
	};
	handleAddCategoty = () => {
		const name = this.state.target;
		if (!name) {
			alert("No Empty String");
			return;
		}
		if (
			this.state.newCategoryList.filter((ele) => ele.category === name).length >
			0
		) {
			alert("Category Exist");
			return;
		}
		this.saveChange(1);
	};
	setCreate = () => {
		this.setState({
			target: "",
			category_id: -1,
			ruleList: [],
			ignoreList: [],
			added: 1,
			createMode: 1,
			categoryAdd: "",
		});
	};
	renderCategoryList = () => {
		return (
			<table>
				<thead>
					<td>Name</td>
					<td>Action</td>
					<td></td>
				</thead>
				{this.state.newCategoryList.map((ele) => {
					const editing =
						!this.state.createMode &&
						this.state.category_id === ele.category_id;
					return (
						<tr>
							<td>
								{editing ? (
									<input
										type="text"
										value={this.state.target}
										onChange={(e) => {
											this.setState({
												target: e.target.value,
												changed: 1,
											});
										}}
									/>
								) : (
									ele.category
								)}
							</td>
							{editing ? (
								<td>
									<button onClick={() => this.saveChange(0)}>Save</button>
								</td>
							) : (
								<td>
									<button onClick={() => this.loadCategory(ele.category_id)}>
										Load
									</button>
								</td>
							)}
							<td>
								<button
									onClick={() =>
										this.setState((prevState) => ({
											newCategoryList: prevState.newCategoryList.filter(
												(ca) => ca.category_id !== ele.category_id
											),
										}))
									}
								>
									Delete
								</button>
							</td>
						</tr>
					);
				})}
				{this.state.createMode ? (
					<tr>
						<td>
							<input
								type="text"
								value={this.state.target}
								onChange={(e) => this.setState({ target: e.target.value })}
							/>
						</td>
						<td>
							<button onClick={this.handleAddCategoty}>Add</button>
						</td>
					</tr>
				) : (
					<tr>
						<td></td>
						<td>
							<button onClick={this.setCreate}>Add New</button>
						</td>
					</tr>
				)}
			</table>
		);
	};
	pageHistory = (delta, target) => {
		let new_start = Math.max(
			0,
			this.state.histary_start + delta * this.state.page_size
		);
		new_start = Math.min(
			new_start,
			this.state.history.length -
				(this.state.history.length % this.state.page_size)
		);
		this.setState({ histary_start: new_start });
	};
	render() {
		const loaded = this.state.loaded;
		const filteredLst = this.getSearchResults(
			this.state.ruleList.map((e) => e.pattern).concat([this.state.patternAdd])
		);
		return (
			<div>
				<Container
					style={{ display: loaded ? "initial" : "none" }}
					className="cate-map-container"
				>
					<Row>
						<h3>Category Mapping</h3>
					</Row>
					<Row>
						<div class="alert-implement">
							{this.state.set_id ? "Loaded Rule ID:" + this.state.set_id : ""}
						</div>
						<div className="alert-implement">
							Last Saved On {this.state.last_saved} (UTC)
						</div>
						<div class="alert-implement">
							{!this.state.implemented
								? "This Version of Rules is not active "
								: ""}
						</div>
					</Row>
					<Row>
						<Col md={4}>
							<Row>
								<h2>History</h2>
							</Row>
							<Row className="as-react-table rule-table pattern-table">
								{this.renderHistoryList()}
							</Row>
							<Row className="map-button">
								{`Showing ${this.state.histary_start}-${
									this.state.histary_start + this.state.page_size
								} out of ${this.state.history.length} records`}
							</Row>
							<Row className="map-button">
								<button onClick={() => this.pageHistory(-1)}>Prev</button>
								<button onClick={() => this.pageHistory(1)}>Next</button>
							</Row>
						</Col>
						<Col md={4}>
							<Row>
								<h2>Groups</h2>
							</Row>
							<Row className="as-react-table rule-table pattern-table">
								{this.renderCategoryList()}
							</Row>
							<Row className="map-button">
								<button onClick={() => this.postResult(1)}>Apply to DB</button>
								<button onClick={() => this.getMapList(1)}>
									Revert to Active Version
								</button>
							</Row>
						</Col>
						<Col md={4}>
							<Row>
								<h2>Patterns</h2>
							</Row>
							<Row className="as-react-table rule-table pattern-table">
								{this.renderPatternTable()}
							</Row>
						</Col>
					</Row>
					<Row>
						<h2>
							Computed Mapping for{" "}
							{this.state.createMode ? "New Group" : this.state.target}
						</h2>
					</Row>
					<Row className="map-select-all">
						<button onClick={this.removeIgnoreAll}>Select All</button>
						<button onClick={this.addIgnoreAll}>Deselect All</button>
					</Row>
					<Row className="search-result-container">
						<ReactDatatable
							// className="table-bordered table-striped map-search-result"
							config={this.config}
							records={filteredLst}
							columns={this.columns}
						/>
						{/*</Row>*/}
						{/*/!*<Row className="map-button-control"><button onClick={()=>this.postResult()}>Save</button>*!/*/}
						{/*/!*<button onClick={()=>this.getMapList()}>Revert</button>*!/*/}
						{/*<hr></hr>*/}
						{/*</Row>*/}
						{/*<hr></hr>*/}
						{/*<Row className="map-button-control">*/}
					</Row>
					<Row>
						{filteredLst.filter((e) => !e.excluded).length} Mappings Selected
					</Row>
					{/*</Row>*/}
				</Container>
			</div>
		);
	}
}
