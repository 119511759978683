import React, { useState, useEffect } from "react";
import { Dropdown, Row, Col, Modal } from "react-bootstrap";
import "../../staticfiles/css/dagDashboard.css";
import { useParams } from "react-router-dom";
import {
	getAirstreamUrl,
	getAirstreamHeader,
	convertTZ,
	axios,
	processNextpageUrl,
} from "../utils";
import PieChart from "./PieChart";
import SpecificTask from "./SpecificTask";
import DagChart from "./DagChart";
import ResultChart from "./ResultChart";
import DomainChart from "./DomainChart";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";

const TaskDashBoard = () => {
	let params = useParams();
	const [id, setID] = useState("");
	const [parentGraphs, setParentGraphs] = useState([]);
	const [displayParent, setDisplayParent] = useState(false);

	const [allGraphs, setAllGraphs] = useState([]);
	// default is '[B] EmailFeed Multiple Domains'
	const [dagId, setDagId] = useState(7);
	const [graphId, setGraphId] = useState(-1);
	const [selectedGraph, setSelectedGraph] = useState(null);
	const [graphLoaded, setGraphLoaded] = useState(false);
	const [graphExecuting, setGraphExecuting] = useState(false);
	const [initialFetched, setFetched] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [taskTimeSheet, setTaskTimeSheet] = useState(new Map());
	const [selectedTaskTimeSheet, setSelectedTaskTimeSheet] = useState([]);
	const [selectedTaskDates, setSelectedTaskDates] = useState([]);
	const [failedDates, setFailedDates] = useState([]);
	const [finishedDates, setFinishedDates] = useState([]);
	const [runningDates, setRunningDates] = useState([]);
	const [displayedTimesheet, setDisplayedTimesheet] = useState("");

	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showExecuteModal, setShowExecuteModal] = useState(false);
	const [executeKwargs, setExecuteKwargs] = useState({});

	const [selectExecution, setSelectExecution] = useState(false);
	const [selectExecutionList, setSelectExecutionList] = useState([]);
	const [selectedResultKey, setSelectedResultKey] = useState(null);
	const [isNodeClicked, setIsNodeClicked] = useState(false);
	const [executor, setExecutor] = useState("");
	const [note, setNote] = useState("");
	const [domains, setDomains] = useState("");
	const [graphStatus, setGraphStatus] = useState(-1);
	const [tasks, setTasks] = useState([]);
	const [edges, setEdges] = useState([]);
	const [executeIds, setExecuteIds] = useState("");
	const [executeNote, setExecuteNote] = useState("");
	const [selectedNode, setSelectedNode] = useState(null);
	const [selectedTask, setSelectedTask] = useState(null);
	const [selectedResult, setSelectedResult] = useState({});

	const [metricsKeys, setMKeys] = useState([]);
	const [selectedMKey, setSelectedMKey] = useState("");

	// cache current graph selection
	const saveStorage = () => {
		localStorage.setItem("oldGraph", JSON.stringify(selectedGraph));
		localStorage.setItem("oldTime", JSON.stringify(displayedTimesheet));
		localStorage.setItem("oldParent", JSON.stringify(displayParent));
	};

	// sort the graphs based on their names
	const sortGraphArr = (lst) => {
		lst.sort((a, b) => {
			if (a.name.charAt(0) !== "[") {
				return 1;
			} else if (b.name.charAt(0) !== "[") {
				return -1;
			}
			return a.name < b.name ? -1 : 1;
		});
	};

	// fetch the dropdown list data for dag graph selection
	const fetchDagList = async () => {
		await axios
			.get(getAirstreamUrl("scheduler/dag/list/"), getAirstreamHeader())
			.then((response) => {
				let tempParent = response.data.results;
				sortGraphArr(tempParent);
				setParentGraphs(tempParent);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// fetch all the graphs for current dag id
	const fetchGraphData = async (curDagId) => {
		let allResponseGraphs = [];
		let url = null;

		if (!curDagId) {
			let oldDag = JSON.parse(localStorage.getItem("oldGraph"));
			if (oldDag) {
				curDagId = oldDag.dag || oldDag.graph_id;
			} else {
				curDagId = dagId;
			}
		}

		setGraphLoaded(false);
		await axios
			.get(
				getAirstreamUrl(
					`scheduler/execution/list/?dag_id=${curDagId}&page_size=1000`
				),
				getAirstreamHeader()
			)
			.then((response) => {
				if (response.data.next) {
					url = processNextpageUrl(response.data.next).substring(22);
				}
				allResponseGraphs = response.data.results.reverse();
			})
			.catch((err) => {
				console.log(err);
			});

		while (url !== null) {
			await axios
				.get(getAirstreamUrl(url), getAirstreamHeader())
				.then((response) => {
					if (response.data.next !== null && response.data.next !== undefined) {
						url = processNextpageUrl(response.data.next).substring(22);
					} else {
						url = null;
					}

					allResponseGraphs = [
						...response.data.results.reverse(),
						...allResponseGraphs,
					];
				})
				.catch((error) => {
					console.log(error);
				});
		}
		setAllGraphs(allResponseGraphs);
		loadInformation(allResponseGraphs);
	};

	// get all the necessary data
	const getInformation = async () => {
		fetchDagList();
		fetchGraphData();
	};

	// process data after graph fetched
	const loadInformation = (allResponseGraphs) => {
		let timesheet = taskTimeSheet;

		allResponseGraphs.forEach((graph) => {
			if (graph.dag == null) {
				return;
			}

			if (timesheet.has(graph.dag)) {
				timesheet.set(graph.dag, [
					graph.launch_time.substring(0, 16),
					...timesheet.get(graph.dag),
				]);
			} else {
				timesheet.set(graph.dag, [graph.launch_time.substring(0, 16)]);
			}
		});
		setTaskTimeSheet(timesheet);

		let tmpSelectedGraph;

		if (!initialFetched && id !== "") {
			tmpSelectedGraph = allResponseGraphs.filter(
				(graph) => graph.graph_id === parseInt(id)
			)[0];
		} else if (!initialFetched && localStorage.getItem("oldGraph")) {
			const oldGraph = JSON.parse(localStorage.getItem("oldGraph"));
			const oldParent = localStorage.getItem("oldParent") == "true";

			const exactGraph = allResponseGraphs.filter((graph) => {
				return graph.graph_id === oldGraph.graph_id;
			})[0];
			tmpSelectedGraph = oldParent ? oldGraph : exactGraph;
			setDisplayParent(oldParent);
		} else {
			tmpSelectedGraph = allResponseGraphs[allResponseGraphs.length - 1];
		}
		setGraphId(tmpSelectedGraph.graph_id);
		setDagId(tmpSelectedGraph.dag);
		setSelectedGraph(tmpSelectedGraph);

		if (!initialFetched && id !== "") {
			setSelectedTaskTimeSheet(timesheet.get(tmpSelectedGraph.dag));
			setDisplayedTimesheet(timesheet.get(tmpSelectedGraph.dag)[0]);
		} else {
			setSelectedTaskTimeSheet(
				timesheet.get(tmpSelectedGraph.dag || tmpSelectedGraph.graph_id)
			);
			if (!initialFetched && localStorage.getItem("oldGraph")) {
				const oldTime = JSON.parse(localStorage.getItem("oldTime"));
				setDisplayedTimesheet(oldTime);
			} else {
				setDisplayedTimesheet(timesheet.get(tmpSelectedGraph.dag)[0]);
			}
		}

		setGraphLoaded(true);
		setFetched(true);
	};

	// data for pie chart
	const getPieGraphData = () => {
		if (!selectedGraph)
			return [
				{ label: "Finished", value: 0 },
				{ label: "Failed", value: 0 },
				{ label: "Running", value: 0 },
				{ label: "Scheduled", value: 0 },
			];
		return [
			{
				label: "Finished",
				value: selectedGraph.success_count,
			},
			{ label: "Failed", value: selectedGraph.fail_count },
			{
				label: "Running",
				value: selectedGraph.running_count,
			},
			{
				label: "Scheduled",
				value:
					selectedGraph.task_count -
					selectedGraph.success_count -
					selectedGraph.running_count -
					selectedGraph.fail_count,
			},
		];
	};

	// initial load ⬇️
	useEffect(() => {
		document.title = "Airstream";
		setID(params.id || "");
	}, []);

	useEffect(() => {
		getInformation();
	}, []);

	// getting specific graph data
	useEffect(() => {
		const fetchData = async () => {
			if (!initialFetched && id !== "") return;
			if (graphId == -1) return;
			if (displayParent) {
				await axios
					.get(getAirstreamUrl(`scheduler/dag/${dagId}/`), getAirstreamHeader())
					.then((response) => {
						setTasks(response.data.tasks);
						setEdges(response.data.edges);
						setGraphStatus(response.data.status);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				await axios
					.get(
						getAirstreamUrl(`scheduler/execution/${graphId}/`),
						getAirstreamHeader()
					)
					.then((response) => {
						setTasks(response.data.tasks);
						let tmpEdges = response.data.edges;
						tmpEdges.sort((a, b) => a.task_from - b.task_from);
						setEdges(tmpEdges);
						setExecutor(response.data.executor);
						setNote(response.data.note);
						setDomains(response.data.kwargs?.domains || "");
						setGraphStatus(response.data.status);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		};
		fetchData();
	}, [selectExecution, displayParent, graphId]);

	// handle time selection when use url param id
	useEffect(() => {
		if (!initialFetched || id === "" || allGraphs.length === 0) return;
		let lst = allGraphs.filter((g) => g.graph_id === parseInt(id));
		let dt = lst[0].launch_time;
		handleSelectTime(dt);
	}, [initialFetched]);

	// filter dates to display on calendar
	useEffect(() => {
		if (!selectedTaskTimeSheet || selectedTaskTimeSheet.length === 0) return;
		let tempList = allGraphs.filter((e) => {
			let latestTime =
				selectedTaskTimeSheet.filter(
					(time) => time.substring(0, 10) === e.launch_time.substring(0, 10)
				)[0] || null;
			return (
				e.name == selectedGraph.name &&
				latestTime == e.launch_time.substring(0, 16)
			);
		});
		let tempDates = tempList.map((e) =>
			moment(e.launch_time.substring(0, 16)).toDate()
		);
		setSelectedTaskDates(tempDates);

		let failed = tempList
			.filter((e) => e.status == 3)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setFailedDates(failed);
		let finished = tempList
			.filter((e) => e.status == 2)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setFinishedDates(finished);
		let running = tempList
			.filter((e) => e.status == 1)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setRunningDates(running);
	}, [selectedTaskTimeSheet]);

	// convert date type
	useEffect(() => {
		setSelectedDate(moment(displayedTimesheet).toDate());
	}, [displayedTimesheet]);

	const updateKwargs = (key, val) => {
		let newKwargs = {
			...executeKwargs,
			[key]: val,
		};
		if (val == null) {
			delete newKwargs[key];
		}
		setExecuteKwargs(newKwargs);
	};

	// when click 'new task'
	const handleSelectParent = () => {
		let newParentGraph;

		if (selectedGraph.dag) {
			newParentGraph = parentGraphs.filter((graph) => {
				return graph.graph_id === selectedGraph.dag;
			})[0];
		} else {
			newParentGraph = parentGraphs.filter((graph) => {
				return graph.graph_id === selectedGraph.graph_id;
			})[0];
		}
		setGraphId(newParentGraph.graph_id);
		setSelectedGraph(newParentGraph);
		setIsNodeClicked(false);
		setDisplayParent(true);
		setSelectedNode(null);
		setSelectedTask(null);

		setSelectExecutionList([]);
		setSelectExecution(false);
	};

	// when select a dag from dropdown list
	const handleSelectTask = (newSelectedGraph) => {
		fetchGraphData(newSelectedGraph.graph_id || newSelectedGraph.dag);

		setIsNodeClicked(false);
		setSelectedNode(null);
		setSelectedTask(null);
		setSelectedResultKey(null);
		setSelectExecutionList([]);
		setSelectExecution(false);
	};

	// when select a time in calendar
	const handleSelectCalendar = (date) => {
		let latestTime = selectedTaskTimeSheet.filter(
			(time) =>
				moment(time).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
		)[0];
		handleSelectTime(latestTime);
	};

	// handle a specific time selection
	const handleSelectTime = (time, index) => {
		let tmp = allGraphs.sort((a, b) => b.graph_id - a.graph_id);
		let matchedGraphs = tmp.filter(
			(g) => g.launch_time.substring(0, 16) == time
		);
		let newGraph = index ? tmp[index] : matchedGraphs[0];
		setDisplayedTimesheet(newGraph.launch_time.substring(0, 16));
		setGraphId(newGraph.graph_id);
		setDagId(newGraph.dag);
		setSelectedGraph(newGraph);
		setIsNodeClicked(false);

		setSelectedNode(null);
		setSelectedTask(null);
		setSelectedResultKey(null);
		setSelectExecutionList([]);
		setSelectExecution(false);
		setDisplayParent(false);
	};

	const executeGraph = async (key, kwargs, partial_ids, note) => {
		if (allGraphs[allGraphs.length - 1].status == 1) {
			toast.warn("The last job is still running!");
			return;
		}
		setGraphExecuting(true);
		await axios
			.post(
				getAirstreamUrl("scheduler/execution/key/"),
				{
					key: key,
					partial_ids: partial_ids,
					kwargs: kwargs,
					note: note,
				},
				getAirstreamHeader()
			)
			.then(async () => {
				toast.success("Success");
				// await saveStorage();
				// window.location.reload();
			})
			.catch((err) => console.log(err));

		await getInformation();
		handleSelectTime(
			allGraphs[allGraphs.length - 1].launch_time.substring(0, 16)
		);
		setGraphExecuting(false);
		setShowExecuteModal(false);
	};

	const cancelGraph = async (graphId) => {
		await axios
			.post(
				getAirstreamUrl(`scheduler/execution/${graphId}/cancel/`),
				{},
				getAirstreamHeader()
			)
			.then(async (resp) => {
				await saveStorage();

				toast.success("Success");
				window.location.reload();
			})
			.catch((err) => console.log(err));

		setShowCancelModal(false);
	};

	const refreshPage = async () => {
		await saveStorage();
		window.location.reload();
	};

	const parseIds = (ids) => {
		try {
			const lst = JSON.parse(ids);
			if (lst.length > 0) {
				return lst;
			}
		} catch (e) {}
		return null;
	};

	const executeSelectedTasks = () => {
		setExecuteIds(JSON.stringify(selectExecutionList[0]));
		setShowExecuteModal(true);
	};

	if (!graphLoaded) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}

	let graphFiltered = parentGraphs.filter((parentGraph) => {
		return parentGraph.graph_id === selectedGraph.dag;
	});
	let selectedGraphName =
		graphFiltered.length > 0 && graphFiltered[0].name
			? graphFiltered[0].name
			: selectedGraph.name;

	return (
		<div className="task-visualization-wrapper">
			<Modal
				className="dag-modal"
				show={showCancelModal}
				onHide={() => setShowCancelModal(false)}
			>
				<Row>
					<h3>Are you sure you want to kill this task flow?</h3>
				</Row>

				<Row className="modal-btns">
					<button
						className="btn btn-primary"
						onClick={() => cancelGraph(selectedGraph.graph_id)}
					>
						Yes
					</button>
					<button
						className="btn btn-secondary"
						onClick={() => setShowCancelModal(false)}
					>
						No
					</button>
				</Row>
			</Modal>

			<Modal
				className="dag-modal"
				show={showExecuteModal}
				onHide={() => setShowExecuteModal(false)}
			>
				<Row>
					<h3>Are you sure you want to execute this task flow?</h3>
				</Row>
				{selectedGraph?.expected_kwargs ? (
					<React.Fragment>
						<Row>Expected keys: {selectedGraph?.expected_kwargs}</Row>
						{selectedGraph?.expected_kwargs?.map((ele, ind) => (
							<Row key={ind}>
								<span>{ele}</span>
								<textarea
									type="text"
									onChange={(e) => updateKwargs(ele, e.target.value || null)}
									style={{ width: "80%", marginLeft: 32 }}
								/>
							</Row>
						))}
					</React.Fragment>
				) : (
					""
				)}
				<Row>
					<span>Partial Ids</span>
					<input
						type="text"
						value={executeIds}
						onChange={(e) => setExecuteIds(e.target.value)}
						style={{ width: "80%", marginLeft: 23 }}
					/>
				</Row>
				<Row>
					<span>Purpose/Note</span>
					<textarea
						type="text"
						value={executeNote}
						placeholder="SYSTEM"
						onChange={(e) => setExecuteNote(e.target.value)}
						style={{ width: "80%" }}
					/>
				</Row>
				<Row>
					Will execute following tasks:{" "}
					{parseIds(executeIds) ? JSON.stringify(parseIds(executeIds)) : "ALL"}
				</Row>
				<Row>
					Will execute with on following parameters:{" "}
					{JSON.stringify(executeKwargs)}
				</Row>

				<Row className="modal-btns">
					<button
						className="btn btn-primary"
						disabled={!executeNote || graphExecuting}
						onClick={() => {
							executeGraph(
								selectedGraph.execution_key,
								executeKwargs,
								parseIds(executeIds),
								executeNote
							);
						}}
					>
						Yes
					</button>
					<button
						className="btn btn-secondary"
						onClick={() => setShowExecuteModal(false)}
					>
						No
					</button>
				</Row>
			</Modal>

			<div className="dag-title">
				<h4 className="page-title">Airstream</h4>
				<button
					className="title-btn btn btn-outline-secondary"
					onClick={() => refreshPage()}
				>
					Refresh Page
				</button>
			</div>

			<Row>
				<Col sm={12} md={6}>
					<div className="card">
						<div className="card-header">
							<h1>DAG</h1>
						</div>

						<div className="card-body">
							<div className="btn-group task-dashboard-btn task-dropdown-wrapper">
								<Dropdown className="task-dropdown-field">
									<Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
										{selectedGraphName}
									</Dropdown.Toggle>

									<Dropdown.Menu className="task-dropdown">
										{parentGraphs.map((graph) => {
											return (
												<Dropdown.Item
													key={graph.graph_id}
													onClick={() => handleSelectTask(graph)}
												>
													{graph.name}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>

								<DatePicker
									id="airstream-calendar"
									onChange={(date) => handleSelectCalendar(date)}
									selected={selectedDate}
									includeDates={selectedTaskDates}
									highlightDates={[
										{ "datepicker-failed": failedDates },
										{ "datepicker-finished": finishedDates },
										{ "datepicker-running": runningDates },
									]}
									inline
								/>

								<Dropdown className="task-dropdown-field">
									{displayParent ? (
										<Dropdown.Toggle variant="outline-dark">
											New task
										</Dropdown.Toggle>
									) : (
										<Dropdown.Toggle variant="outline-dark">
											{displayedTimesheet ? displayedTimesheet : "New task"}
										</Dropdown.Toggle>
									)}

									<Dropdown.Menu className="task-dropdown">
										<Dropdown.Item
											key={selectedGraph.name + "-parent"}
											onClick={handleSelectParent}
										>
											New task
										</Dropdown.Item>

										{selectedTaskTimeSheet.map((time, index) => {
											if (
												selectedDate &&
												time.substring(0, 10) !==
													moment(selectedDate).format().substring(0, 10)
											) {
												return "";
											}
											return (
												<Dropdown.Item
													key={selectedGraph.name + "-" + time + "-" + index}
													onClick={() => handleSelectTime(time, index)}
												>
													{time}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</div>

							<DagChart
								tasks={tasks}
								edges={edges}
								displayParent={displayParent}
								selectExecution={selectExecution}
								selectExecutionList={selectExecutionList}
								setSelectExecutionList={setSelectExecutionList}
								graphId={graphId}
								setIsNodeClicked={setIsNodeClicked}
								setSelectedNode={setSelectedNode}
								setSelectedTask={setSelectedTask}
								setSelectedResult={setSelectedResult}
								setSelectedResultKey={setSelectedResultKey}
								setGraphStatus={setGraphStatus}
								setExecutor={setExecutor}
								setNote={setNote}
								setDomains={setDomains}
							/>
						</div>
					</div>
				</Col>

				<Col sm={12} md={6} className="specific-task-container">
					<div className="card">
						<div className="card-header">
							{isNodeClicked ? (
								<div>
									<h2>Task: {selectedNode}</h2>
								</div>
							) : (
								<div>
									<h2>Overview </h2>
								</div>
							)}
						</div>

						<div className="card-body task-detail">
							{isNodeClicked ? (
								<div>
									<SpecificTask
										displayParent={displayParent}
										selectedTask={selectedTask}
										selectedResult={selectedResult}
										returnOverview={setIsNodeClicked}
										graphStatus={graphStatus}
									/>
								</div>
							) : (
								<div>
									{displayParent || !displayedTimesheet ? (
										<div>
											<div className="execution-header">
												<h2>Execution Key: {selectedGraph.execution_key}</h2>
											</div>
											<div>
												{selectedGraph.execution_key ? (
													<div>
														<Row>
															<Col>
																<button
																	className="btn btn-outline-dark"
																	onClick={() => setShowExecuteModal(true)}
																>
																	Execute
																</button>
															</Col>
														</Row>

														<Row>
															<Col>
																<h2>Or</h2>
															</Col>
														</Row>

														<Row>
															<Col>
																<button
																	className="btn btn-outline-dark"
																	onClick={() => setSelectExecution(true)}
																>
																	Select Tasks and Execute
																</button>
															</Col>
														</Row>

														{selectExecution && (
															<div>
																<Row className="execution-list">
																	<Col>
																		<h4>
																			You select tasks with ids:{" "}
																			{selectExecutionList.toString()}
																		</h4>
																	</Col>
																</Row>

																<Row>
																	<Col>
																		<button
																			className="btn btn-primary"
																			onClick={executeSelectedTasks}
																		>
																			Execute Selected Task
																		</button>
																	</Col>
																</Row>

																<Row>
																	<Col>
																		<button
																			className="btn btn-primary select-button"
																			onClick={() => setSelectExecution(false)}
																		>
																			Cancel
																		</button>
																	</Col>
																</Row>
															</div>
														)}
													</div>
												) : (
													""
												)}
											</div>
										</div>
									) : (
										<div>
											<div>
												<button
													className="btn btn-outline-dark"
													onClick={() => setShowCancelModal(true)}
												>
													Kill
												</button>
											</div>
											<br />
											<h2>
												Status:
												{selectedGraph.status === 0
													? "Scheduled"
													: selectedGraph.status === 1
													? "Running"
													: selectedGraph.status === 2
													? "Completed"
													: "Failed"}
											</h2>
											<br />

											<div>
												Executor: {executor} <br />
												<br />
												Note: {note} <br />
												<br />
												Domains: {domains} <br />
												<br />
												Graph ID: {graphId}
											</div>

											<div className="task-svg-wrapper">
												<PieChart
													data={getPieGraphData()}
													outerRadius={150}
													innerRadius={90}
												></PieChart>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>

			<Row
				className={selectedTask !== null ? "result-active" : "result-hidden"}
			>
				<Col sm={12}>
					<div className="card">
						<div className="card-header">
							<h1>Metrics</h1>
						</div>

						<div className="card-body">
							{selectedTask?.results?.result &&
							selectedTask.results.result instanceof Array &&
							selectedTask.results.result.length !== 0 ? (
								<div>
									<div style={{ width: 300 }}>
										<Dropdown className="task-dropdown-field">
											<Dropdown.Toggle
												variant="outline-dark"
												id="dropdown-basic"
												as={CustomToggle}
											>
												{selectedMKey}
											</Dropdown.Toggle>

											<Dropdown.Menu as={CustomMenu} className="task-dropdown">
												{metricsKeys &&
													metricsKeys.length > 0 &&
													metricsKeys.map((key, ind) => {
														return (
															<Dropdown.Item
																key={ind}
																eventKey={key}
																onClick={() => setSelectedMKey(key)}
															>
																{key}
															</Dropdown.Item>
														);
													})}
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div>
										<DomainChart
											selectedTask={selectedTask}
											selectedMKey={selectedMKey}
											setMKeys={setMKeys}
											setSelectedMKey={setSelectedMKey}
										/>
									</div>
								</div>
							) : selectedTask?.results?.result &&
							  selectedTask.key !== "" &&
							  Object.keys(selectedTask.results.result).length !== 0 &&
							  typeof selectedTask.results.result !== "string" ? (
								<div>
									<ResultChart selectedTask={selectedTask} />
								</div>
							) : (
								<div>
									<h3>New taskly no results for this task</h3>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default TaskDashBoard;
