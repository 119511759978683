import React, { Component } from "react";
import Dashboard from "./Dashboard";
import Login from "./Login";
import { ErrorBoundary } from "react-error-boundary";
import "../staticfiles/login.css";

export default class App extends Component {
	ErrorFallback() {
		return (
			<h3>Something went wrong...Please refresh your browser and try again</h3>
		);
	}

	render() {
		if (
			localStorage.getItem("RWLoginToken") != null &&
			localStorage.getItem("RWExpireDate") != null
		) {
			return (
				<ErrorBoundary FallbackComponent={this.ErrorFallback}>
					<React.StrictMode>
						<Dashboard></Dashboard>
					</React.StrictMode>
				</ErrorBoundary>
			);
		}
		return (
			<div>
				<Login history={this.props.history} url="/"></Login>
			</div>
		);
	}
}
