import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { getAirstreamUrl, getAirstreamHeader, colors, axios } from "../utils";

const DomainChart = ({
	selectedTask,
	setMKeys,
	selectedMKey,
	setSelectedMKey,
}) => {
	const [times, setTimes] = useState([]);
	const [results, setResults] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			await axios
				.get(
					getAirstreamUrl(
						`scheduler/execution/report-history/?key=${selectedTask.key}&start=0&end=10`
					),
					getAirstreamHeader()
				)
				.then((response) => {
					let tempTimes = [];
					let d = response.data;
					let stats = {};
					for (let i = 0; i < d.length; i++) {
						if (!d[i].results || !d[i].results.result) {
							continue;
						}
						tempTimes.unshift(d[i].start_time);
						let data = d[i].results.result;
						for (const res of data) {
							let tmpK = "";
							let tmpData = {};
							for (const k in res) {
								if (typeof res[k] == "string" || k.includes("id"))
									tmpK += res[k] + " ";
								else if (typeof res[k] == "number") {
									if (k in tmpData) tmpData[k] += res[k];
									else {
										tmpData[k] = res[k];
									}
								} else if (typeof res[k] == "boolean") {
									let v = res[k] ? 1 : 0;
									if (k in tmpData) tmpData[k] += v;
									else {
										tmpData[k] = v;
									}
								}
							}
							if (!(tmpK in stats)) {
								stats[tmpK] = {};
								for (const f in tmpData) {
									stats[tmpK][f] = [tmpData[f]];
								}
							} else {
								for (const f in tmpData) {
									if (stats[tmpK][f].length > i) {
										stats[tmpK][f][i] += tmpData[f];
									} else {
										for (let j = stats[tmpK][f].length; j < i; j++) {
											stats[tmpK][f].push(0);
										}
										stats[tmpK][f].push(tmpData[f]);
									}
								}
							}
						}
					}
					setTimes(tempTimes);
					setResults(stats);
					setMKeys(Object.keys(stats));
					setSelectedMKey(Object.keys(stats)[0]);
				})
				.catch((err) => {
					alert(err.message);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		fetchData();
	}, [selectedTask]);

	if (loading) {
		return <h4>Loading...</h4>;
	} else {
		return (
			<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
				{selectedMKey &&
					Object.keys(results[selectedMKey] || {}).map((key, ind) => (
						<div key={ind}>
							<Line
								className="history-container"
								height={300}
								data={{
									labels: times,
									datasets: [
										{
											data: results[selectedMKey][key],
											label: key,
											borderColor: colors[ind % colors.length],
											steppedLine: true,
										},
									],
								}}
								options={{
									responsive: true,
									maintainAspectRatio: false,
									scales: {
										xAxes: [
											{
												gridLines: {
													display: false,
												},
												type: "time",
												ticks: {
													source: "labels",
													minRotation: 30,
												},
											},
										],
										yAxes: [
											{
												id: "A",
												position: "left",
												ticks: {
													callback: function (value, index, values) {
														if (value > 1000000000) {
															return value / 1000000000 + "B";
														} else if (value > 1000000) {
															return value / 1000000 + "M";
														} else if (value > 1000) {
															return value / 1000 + "K";
														} else {
															return value;
														}
													},
												},
												gridLines: {
													borderDash: [8, 4],
												},
											},
										],
									},
								}}
							/>
						</div>
					))}
			</div>
		);
	}
};

export default DomainChart;
