import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../staticfiles/css/rw-rule-playground.css";
import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css";

const RuleTable = ({
	dataFetching,
	totalData,
	loadRule,
	deleteRule,
	toggleWindow,
}) => {
	const columns = [
		{
			dataField: "operations",
			text: "Operations",
			isDummyField: true,
			formatter: (cell, row) => {
				return (
					<div>
						{/* <Link to={"/rule-edit/" + row?.rule_id} target="_blank">
							<i class="mdi mdi-pencil micon"></i>
						</Link> */}
						<a
							onClick={() => toggleWindow(row.rule_id)}
							href="javascript: void(0);"
						>
							<i class="mdi mdi-pencil micon"></i>
						</a>
						<br />
						<br />
						<a
							class="deleteRule"
							onClick={() => deleteRule(row?.rule_id)}
							href="javascript: void(0);"
						>
							<i class="mdi mdi-delete micon"></i>
						</a>
					</div>
				);
			},
		},
		{
			dataField: "rule_id",
			text: "ID",
			sort: true,
		},
		{
			dataField: "is_global",
			text: "Is Global",
			sort: true,
		},
		{
			dataField: "type",
			text: "Type",
			sort: true,
		},
		{
			dataField: "start_date",
			text: "Start Date",
			sort: true,
		},
		{
			dataField: "end_date",
			text: "End Date",
			sort: true,
		},
		{
			dataField: "condition",
			text: "Condition",
			hidden: true,
		},
		{
			dataField: "parsedCondition",
			text: "Condition",
			formatter: (cell, row) => {
				return <FolderTree data={cell} showCheckbox={false} readOnly />;
			},
		},
		{
			dataField: "command",
			text: "Command",
			formatter: (cell) => {
				return (
					<div className="resizable">
						<pre>{cell}</pre>
					</div>
				);
			},
		},
		{
			dataField: "in_dt",
			text: "Create Date",
			sort: true,
			formatter: (cell) => {
				return cell?.substring(0, 16);
			},
		},
		{
			dataField: "last_applied",
			text: "Last Apply Date",
			sort: true,
			formatter: (cell) => {
				return cell?.substring(0, 16);
			},
		},
		{
			dataField: "priority",
			text: "Priority",
			sort: true,
		},
		{
			dataField: "created_by",
			text: "Created By",
			sort: true,
		},
	];

	if (dataFetching) {
		return (
			<Card>
				<CardBody>
					<h4>Fetching Data...</h4>
				</CardBody>
			</Card>
		);
	}

	const expandRow = {
		renderer: (row, rowIndex) => (
			<Table striped bordered si ze="sm">
				<tbody>
					<tr>
						<td>Condition(Raw)</td>
						<td>{row.condition}</td>
					</tr>
				</tbody>
			</Table>
		),
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	const { SearchBar } = Search;

	return (
		<div>
			<Card>
				<CardBody className="scroll-table">
					<ToolkitProvider
						bootstrap4
						keyField="rule_id"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
										<button
											class="btn btn-outline-info rule-btn"
											onClick={loadRule}
										>
											Load Rule
										</button>
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 10,
										showTotal: true,
									})}
									noDataIndication={() => "No Data Available"}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default RuleTable;
