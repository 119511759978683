import React, { useState, useEffect, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";

const Treemap = ({ chartData, deltaField, cacheTime }) => {
	const [displayData, setDisplayData] = useState([]);

	/**
	 * red(negative), green(positive),
	 * darker color(left) to lighter color(right),
	 * larger value(left) to smaller value(right)
	 **/
	const colorMapRed = ["#570707", "#7b342c", "#9d5b52", "#9d5b52"];
	const colorMapGreen = ["#114d03", "#3a662a", "#5c804d", "#7f9b72"];
	let treemapOptions = {
		// title: {
		// 	text: "Weekly Industry Highlights",
		// 	left: "center",
		// },
		tooltip: {
			show: true,
			formatter: ({ name }) => {
				return `${name}`;
			},
		},
		series: [
			{
				name: "Weekly Highlights",
				type: "treemap",
				data: displayData.map(({ name, value, color }) => ({
					name,
					value,
					itemStyle: {
						color: color,
					},
				})),
				label: {
					show: true,
					formatter: "{b}",
				},
				itemStyle: {
					borderColor: "#fff",
					borderWidth: 2,
				},
				emphasis: {
					label: {
						show: true,
					},
				},
			},
		],
		// visualMap: {
		// 	type: "piecewise",
		// 	pieces: [
		// 		{ max: -50, color: "#570707" },
		// 		{ min: -50, max: 0, color: "#85200c" },
		// 		{ min: 0, max: 50, color: "#329107" },
		// 		{ min: 50, color: "#54e712" },
		// 	],
		// },
	};

	const normalize = (posMin, posMax, negMin, negMax, val) => {
		let min = val >= 0 ? posMin : negMin;
		let max = val >= 0 ? posMax : negMax;
		return Math.abs(val - min) / Math.abs(max - min);
	};

	// allocate color based on value ranking
	const processData = (data, map) => {
		return data.map((row, index) => {
			// delta in percentage
			let delta = Math.round(Number(row[deltaField]) * 100 * 100) / 100;
			let color;
			switch (true) {
				case index <= data.length / 4:
					color = map[0];
					break;
				case index <= data.length / 2:
					color = map[1];
					break;
				case index <= (3 * data.length) / 4:
					color = map[2];
					break;
				default:
					color = map[3];
			}
			let deltaStr = String(delta);
			if (delta > 0) {
				deltaStr = "+" + deltaStr;
			}
			deltaStr += "%";
			let name = row.name + "\n" + deltaStr;
			return {
				name: name,
				value: Number(row.curr_year_vol_pct), // determines size of the block
				rank: index,
				color: color,
			};
		});
	};

	useEffect(() => {
		if (chartData.length <= cacheTime) return;
		let tmp = chartData[cacheTime].data.filter((el) => el.id); // filter out invalid data
		// let posMin = Infinity,
		// 	posMax = 0;
		// let negMin = 0,
		// 	negMax = -Infinity;
		// for (const el of tmp) {
		// 	if (el[deltaField] >= 0) {
		// 		posMin = Math.min(posMin, el[deltaField]);
		// 		posMax = Math.max(posMax, el[deltaField]);
		// 	}
		// 	if (el[deltaField] <= 0) {
		// 		negMin = Math.min(negMin, el[deltaField]);
		// 		negMax = Math.max(negMax, el[deltaField]);
		// 	}
		// }
		let posData = tmp.filter((row) => row[deltaField] >= 0);
		let negData = tmp.filter((row) => row[deltaField] < 0);
		posData.sort((a, b) => b[deltaField] - a[deltaField]);
		negData.sort((a, b) => Math.abs(b[deltaField]) - Math.abs(a[deltaField]));

		posData = processData(posData, colorMapGreen);
		negData = processData(negData, colorMapRed);
		tmp = [...posData, ...negData];
		setDisplayData(tmp);
	}, [chartData, deltaField, cacheTime]);

	return (
		<>
			<ReactEcharts
				echarts={echarts}
				option={treemapOptions}
				style={{ height: "500px", width: "100%" }}
			/>
		</>
	);
};

export default Treemap;
