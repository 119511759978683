import React, { Component } from "react";
import barcode from "../staticfiles/plugins/hyper/images/barcode.png";
import { Redirect } from "react-router-dom";

export default class Topbar extends Component {
	state = {
		dropdownDisplay: "0",
		isMobileMenu: false,
		isLogout: false,
	};

	showDropdown = () => {
		if (this.state.dropdownDisplay === "0") {
			this.setState({ dropdownDisplay: "84.7px" });
		} else {
			this.setState({ dropdownDisplay: "0" });
		}
	};

	handleLogout = () => {
		localStorage.removeItem("RWLoginToken");
		localStorage.removeItem("airstreamToken");
		localStorage.removeItem("RWExpireDate");
		localStorage.removeItem("username");
		this.setState({ isLogout: true });
	};

	toggleMobileMenu = () => {
		this.setState({ isMobileMenu: !this.state.isMobileMenu });
	};

	render() {
		if (this.state.isLogout) {
			return <Redirect to="/"></Redirect>;
		} else {
			return (
				<div>
					<div className="navbar-custom topnav-navbar-dark" sytle="z-index: 9;">
						<ul className="list-unstyled topbar-right-menu float-right mb-0">
							<li className="notification-list">
								<a
									className="nav-link right-bar-toggle"
									href="javascript: void(0);"
								>
									<i className="dripicons-gear noti-icon"></i>
								</a>
							</li>
							<li className="dropdown notification-list">
								<a
									className="nav-link dropdown-toggle nav-user arrow-none mr-0"
									data-toggle="dropdown"
									href="javascript: void(0);"
									role="button"
									aria-haspopup="false"
									aria-expanded="false"
								>
									<span className="account-user-avatar">
										<img
											src={barcode}
											alt="user-image"
											className="rounded-circle"
										/>
									</span>
									<span className="userBar" onClick={this.showDropdown}>
										<span className="account-user-name">
											{localStorage.getItem("username")
												? localStorage.getItem("username")
												: "Username"}
										</span>
										<span className="account-position">Admin</span>
									</span>
								</a>
								<div
									style={{
										maxHeight: this.state.dropdownDisplay,
										overflow: "hidden",
										transition: "max-height .05s ease-in",
										border: "1px solid #e4eaf2",
										boxShadow: "0 0 35px 0 rgba(154,161,171,.15)",
									}}
									x-placement="bottom-end"
								>
									<div class="dropdown-header noti-title">
										<h6 class="text-overflow m-0">Welcome !</h6>
									</div>
									<a
										href="javascript:void(0)"
										onClick={this.handleLogout}
										class="dropdown-item notify-item"
									>
										<i class="mdi mdi-logout mr-1"></i>
										<span>Logout</span>
									</a>
								</div>
							</li>
						</ul>
						{/* <button className="button-menu-mobile open-left disable-btn" onClick={this.toggleMobileMenu}>
              <i className="mdi mdi-menu"></i>
            </button> */}
					</div>
					{/* {
            this.state.isMobileMenu?<LeftSideBar isToggled={this.state.isMobileMenu} handleClose={this.toggleMobileMenu}></LeftSideBar>:<div></div>
          } */}
				</div>
			);
		}
	}
}
