import React, { useEffect } from "react";

const UpdatePage = () => {
	useEffect(() => {
		document.title = "Software Updates";
	});
	const patches = [
		{
			title: "New Beta Feature",
			date: "July 24th, 2023",
			version: "v1.31.0",
			changes: [
				"1. Added Billboard (beta) section",
				"2. Added Search (beta) section",
				"3. Several Bug fixes",
			],
		},
		{
			title: "Feature Improvements",
			date: "April 24th, 2023",
			version: "v1.30.0",
			changes: [
				"1. Added Cache Time for PDF onepager",
				"2. Added Collapse button for left side bar",
				"3. Added API traffic recording",
				"4. Added clearance signal in rule playground",
				"5. Several bug fixes",
			],
		},
		{
			title: "New Feature",
			date: "April 1st, 2023",
			version: "v1.29.0",
			changes: [
				"1. Added Billboard (weekly highlights) page",
				"2. Improved peformance of composite chart",
				"3. Several bug fixes",
			],
		},
		{
			title: "Feature improvements",
			date: "February 22nd, 2023",
			version: "v1.28.0",
			changes: [
				"1. Added All Promotional Ensemble Indices CSV download",
				"2. Various issue fix",
			],
		},
		{
			title: "Feature improvements",
			date: "February 2nd, 2023",
			version: "v1.27.0",
			changes: [
				"1. Added data validation for charts",
				"2. Added watermarks to all charts",
				"3. Improved harmonic discount loading time",
				"4. Minor bug fix",
			],
		},
		{
			title: "Feature improvements",
			date: "January 16th, 2023",
			version: "v1.26.0",
			changes: [
				"1. Several bug fix",
				"2. Improve volume and final index charts",
			],
		},
		{
			title: "Performance improvements",
			date: "January 6th, 2023",
			version: "v1.25.0",
			changes: [
				"1. Optimised loading time of airstream page",
				"2. adapt http request to https",
				"3. minor improvements",
			],
		},
		{
			title: "Feature improvements",
			date: "December 26th, 2022",
			version: "v1.24.0",
			changes: [
				"1. Added airstream task for emailfeed subscription",
				"2. bug fix",
			],
		},
		{
			title: "New Feature",
			date: "December 19th, 2022",
			version: "v1.23.0",
			changes: ["1. Added EmailFeed Subscription page"],
		},
		{
			title: "New Feature",
			date: "November 30th, 2022",
			version: "v1.22.0",
			changes: [
				"1. Added History table for Harmonic Discount",
				"2. Improved Harmonic Discount display",
			],
		},
		{
			title: "Features Improvements",
			date: "October 31st, 2022",
			version: "v1.21.0",
			changes: [
				"1. Added Expire time for login",
				"2. Updated Harmonic Discount table",
				"3. Minor bug fix",
			],
		},
		{
			title: "Performance Improvements",
			date: "October 10th, 2022",
			version: "v1.20.0",
			changes: ["1. Updated harmonic Api"],
		},
		{
			title: "Features Improvements",
			date: "August 31th, 2022",
			version: "v1.19.0",
			changes: [
				"1. Reforged web engagement composite chart",
				"2. Updated Last endpoints check",
			],
		},
		{
			title: "Features Improvements",
			date: "August 24th, 2022",
			version: "v1.18.0",
			changes: [
				"1. Added a navigation board for composite Chart",
				"2. Added OpenAPI pages",
				"3. Added Last endpoints check",
				"4. Improve overall performance",
			],
		},
		{
			title: "Features Improvements",
			date: "August 15th, 2022",
			version: "v1.17.0",
			changes: [
				"1. Added Emailfeed Onepager",
				"2. Added Test Rule to Rule creation panel",
				"3. Added params link support for Composite Chart",
				"4. Removed redundant filters from Web Engagement chart",
			],
		},
		{
			title: "Features Improvements",
			date: "June 29th, 2022",
			version: "v1.16.0",
			changes: [
				"1. Added User Data Log",
				"2. Increased Airstream user experience",
				"3. Minor bug fix",
			],
		},
		{
			title: "Features Improvements",
			date: "June 15th, 2022",
			version: "v1.15.0",
			changes: [
				"1. Added email testing for Rule Playground",
				"2. Updated rule creation and editing",
				"3. Increased overall performance",
				"4. Minor bug fix",
			],
		},
		{
			title: "New Features",
			date: "June 1st, 2022",
			version: "v1.14.0",
			changes: [
				"1. Added a new version of Rule Playground",
				"2. Added a derived Web Engagement chart in Composite Chart",
				"3. Minor bug fix",
			],
		},
		{
			title: "Quality Improvements",
			date: "April 27th, 2022",
			version: "v1.13.0",
			changes: [
				"1. Updated Category Indexing charts",
				"2. Adjust Composite Chart UI",
				"3. Several improvements on user experience",
				"4. Bug Fixes",
			],
		},
		{
			title: "New Features",
			date: "April 6th, 2022",
			version: "v1.12.0",
			changes: [
				"1. Added Smoothed Index Chart for Promotional Indexing",
				"2. Added Sub Domain Selection for Web Engagement Chart",
				"3. Removed beta version of Promotional Indexing",
				"4. Added new UI for popup window",
				"5. Improved Overall UI display",
				"6. Bug Fixes",
			],
		},
		{
			title: "New Charts",
			date: "March 10th, 2022",
			version: "v1.11.0",
			changes: [
				"1. Added two Email Sale Announcement charts",
				"2. Added Group ID to Harmonic Discount Table",
				"3. Improved user experience",
			],
		},
		{
			title: "New features",
			date: "Jan 26th, 2022",
			version: "v1.10.0",
			changes: [
				"1. Added edit function for deep discount",
				"2. Improved the display of Airstream",
			],
		},
		{
			title: "Quality Improvements",
			date: "Dec 30th, 2021",
			version: "v1.9.0",
			changes: [
				"1. Added two download buttons for historic index data in Composite Chart page",
				"2. Improved the refresh behavior of Airstream page",
				"3. Minor bug fix",
			],
		},
		{
			title: "Quality Improvements",
			date: "Dec 29th, 2021",
			version: "v1.8.0",
			changes: [
				"1. Rebuilt the LeftSideBar to be more responsive and suited for mobile devices",
				"2. Added a current index (2w MA) history data download button in Composite Chart",
				"3. Added a time range selection for Harmonic Discount Table",
				"4. Minor bug fix",
			],
		},
		{
			title: "Visualization Improvements",
			date: "Dec 13th, 2021",
			version: "v1.7.0",
			changes: [
				"1. Added a new calendar module for Airstream page",
				"2. Added some new fields for Promotion Indexing Chart and Harmonic Discount Table",
				"3. Minor bug fix",
			],
		},
		{
			title: "Quality Improvements",
			date: "Dec 06th, 2021",
			version: "v1.6.0",
			changes: [
				"1. Improved fetching speed of Harmonic Discount Table",
				"2. Optimised mobile version UI of several charts",
				"3. Optimised mobile version UI of side bar",
				"4. fixed some minor display issues",
			],
		},
		{
			title: "A New Table & Details Update",
			date: "Nov 29th, 2021",
			version: "v1.5.0",
			changes: [
				"1. Added a new table in Harmonic Discount page",
				"2. Switched timezone in Airstream page to Los_Angeles",
				"3. Fixed the color set of promotion indexing chart",
			],
		},
		{
			title: "Campaign Discount Page Updates",
			date: "Nov 11th, 2021",
			version: "v1.4.0",
			changes: [
				"1. Added a new chart 'Harmonic Discount Chart' (React version) to Campaign Discount React Page",
				"2. Fixed some minor issues on Web Engagement chart",
			],
		},
		{
			title: "Chart Updates",
			date: "Oct 20th, 2021",
			version: "v1.3.0",
			changes: [
				"1. Added a new table 'Billboard Ranking chart' to Composite Chart page",
				"2. Improved the display of Email and Web Engagement charts",
			],
		},
		{
			title: "Chart Updates",
			date: "Sep 22th, 2021",
			version: "v1.2.0",
			changes: [
				"1. Added a new chart 'WebEngagement trend-weekly' to Composite Chart page",
				"2. Fixed a bug that emailVolume Chart doesn't display data whenever emailIndex has no data",
			],
		},
		{
			title: "Chart UI Updates",
			date: "August 17th, 2021",
			version: "v1.1.0",
			changes: [
				"1. Improved the UI for Promotional Indexing, Category Indexing, and Promotional Email Volume Trends(Weekly)",
				"2. Fixed the error that maximum end date may be incorrect in Promotional Email Volume Trends(Weekly)",
				"3. Fixed the error that Current is not displayed as the first option in the dropdown for Airstream tasks",
			],
		},
		{
			title: "Email Feed Weekly Updates",
			date: "August 15th, 2021",
			version: "v1.0.6",
			changes: [
				"1. Improved the UI for Email Feed Weekly Indexing so no domain or category will be covered",
				"2. Added download as CSV option for Promotion Indexing and Email Category Indexing pages",
			],
		},
		{
			title: "Data Visualization Updates",
			date: "August 11st, 2021",
			version: "v1.0.5",
			changes: [
				"1. Email Volume Trend now allows users to export the selected data into both an image and a csv file",
				"2. The x-axis of Email Volume Trend and Promotional Indexing graphs now only displays the month, which makes the graphs easier to read.",
				"3. The y-axis of Email Volume Trend and Promotional Indexing graphs now contains suffix, which makes the graphs easier to read.",
			],
		},
		{
			title: "Email Feed Weekly Page",
			date: "August 3rd, 2021",
			version: "v1.0.4",
			changes: [
				"1. Created an Email Feed Weekly Page that displays the historical data of email data for various domains",
				"2. Changed Airstream graph dropdown option texts",
			],
		},
		{
			title: "Airstream Page Updates",
			date: "July 30th, 2021",
			version: "v1.0.3",
			changes: [
				"1. Fixed the bug that multiple tasks within the same category may be displayed in the dropdown selection",
				"2. Allowed users to now look for all historical tasks in the server instead of just the latest 50 tasks",
			],
		},
		{
			title: "Updates",
			date: "July 25th, 2021",
			version: "v1.0.2",
			changes: [
				"1. Fixed bugs that cause website to crash when user opens certain task detail in airstream page",
				"2. Added extra safety check on Promotion Analysis Ensemble page to make sure data is clean",
				"3. Fixed bugs that cause user to fail to get data on airstream page due to authorization",
			],
		},
		{
			title: "Promotion Indexing Page Updates",
			date: "July 18th, 2021",
			version: "v1.0.1",
			changes: [
				"1. Enabled multiselection for domains and typing for dates",
				"2. Formatted Chart UI",
				"3. Fixed issues with loading domains",
			],
		},
		{
			title: "Promotion Indexing Page Additions",
			date: "July 13th, 2021",
			version: "v1.0",
			changes: [
				"1. Allow users to visualize the historical trends of 12 parameters for 400+ domains",
				"2. Allow users to compare parameters across domains on one graph",
				"3. Allow users to download the graph in png format",
			],
		},
		{
			title: "Keyword Additions",
			date: "May 2, 2020",
			version: "v0.9",
			changes: [
				"1. Detect significant discount by keywords - e.g. 'sale' and 'markdown'",
				"2. Detect new subscriber by keywords - e.g. 'welcome' and 'sign up'",
				"3. Detect order by original edatasrouce classification",
				"4. Combine new products with manually tagged products",
			],
		},
	];

	return (
		<div>
			<div class="row">
				<div class="col-12">
					<div class="timeline">
						<div class="timeline-show mb-3 text-center">
							<h5 class="m-0 time-show-name">Latest version</h5>
						</div>

						{patches.map((patch, ind) => {
							return ind % 2 == 0 ? (
								/* left block */
								<React.Fragment key={ind}>
									<div>
										<div class="timeline-lg-item timeline-item-left">
											<div class="timeline-box timeline-box-left">
												<span class="arrow-alt"></span>
												<span class="timeline-icon">
													<i class="mdi mdi-adjust"></i>
												</span>
												<h4 class="mt-0 mb-1 font-16">{patch.title}</h4>
												<p class="text-muted">
													<small>{patch.date}</small>
												</p>
												{patch.changes.map((ch, i) => (
													<p key={"p" + i}>{ch}</p>
												))}
											</div>
										</div>
									</div>

									<div class="timeline-show mb-3 text-center">
										<h5 class="m-0 time-show-name">{patch.version}</h5>
									</div>
								</React.Fragment>
							) : (
								/* right block */
								<React.Fragment key={ind}>
									<div>
										<div class="timeline-lg-item timeline-box-left">
											<div class="timeline-desk">
												<div class="timeline-box">
													<span class="arrow"></span>
													<span class="timeline-icon">
														<i class="mdi mdi-adjust"></i>
													</span>
													<h4 class="mt-0 mb-1 font-16">{patch.title}</h4>
													<p class="text-muted">
														<small>{patch.date}</small>
													</p>
													{patch.changes.map((ch, i) => (
														<p key={"p" + i}>{ch}</p>
													))}
												</div>
											</div>
										</div>
									</div>

									<div class="timeline-show mb-3 text-center">
										<h5 class="m-0 time-show-name">{patch.version}</h5>
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdatePage;
