import React, { useState } from "react";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import { getNumericalHeader, getLunaUrl, axios } from "../utils";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

const HarmonicDiscountTable = ({
	dataFetching,
	startDate,
	endDate,
	totalData,
	categories,
	getDiscountTableData,
	lastSundayFI,
}) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [showEditCateModal, setShowEditCateModal] = useState(false);
	const [editFeedId, setEditFeedId] = useState("");
	const [cachedHarmonicId, setCachedHarmonicId] = useState("");
	const [editCategory, setEditCategory] = useState([]);
	const [editMin, setEditMin] = useState("");
	const [editMax, setEditMax] = useState("");
	const [editEffective, setEditEffective] = useState("");
	const [editState, setEditState] = useState("");
	const [editStateVal, setEditStateVal] = useState("");
	const [editProduct, setEditProduct] = useState("");
	const [editHoliday, setEditHoliday] = useState("");
	const [editShip, setEditShip] = useState("");
	const [editBadwords, setEditBadwords] = useState("");
	const [editStorewide, setEditStorewide] = useState(false);
	const [editSteep, setEditSteep] = useState(false);
	const [editSignificant, setEditSignificant] = useState(false);
	const [editClearance, setEditClearance] = useState(false);
	const [editApplyall, setEditApplyall] = useState(false);

	const [displayAllHis, setDisplayAllHis] = useState(
		localStorage.getItem("RWdisplayAllHis") === "true"
	);

	const [initDDfields, setInitDDfields] = useState({});

	const columns = [
		{
			dataField: "cached_harmonic_id",
			hidden: true,
		},
		{
			dataField: "feed_id",
			hidden: true,
		},
		{
			dataField: "product_type",
			hidden: true,
		},
		{
			dataField: "holiday_tag",
			hidden: true,
		},
		{
			dataField: "free_ship_condition",
			hidden: true,
		},
		{
			dataField: "last_update",
			hidden: true,
		},
		{
			dataField: "email_image",
			hidden: true,
		},
		{
			dataField: "has_rule",
			hidden: true,
		},
		{
			dataField: "min_discount_pct_rule",
			hidden: true,
		},
		{
			dataField: "max_discount_pct_rule",
			hidden: true,
		},
		{
			dataField: "effective_discount_pct_rule",
			hidden: true,
		},
		{
			dataField: "state_discount_rule",
			hidden: true,
		},
		{
			dataField: "product_type_rule",
			hidden: true,
		},
		{
			dataField: "is_storewide_rule",
			hidden: true,
		},
		{
			dataField: "is_steep_rule",
			hidden: true,
		},
		{
			dataField: "is_significant_rule",
			hidden: true,
		},
		{
			dataField: "holiday_tag_rule",
			hidden: true,
		},
		{
			dataField: "free_ship_condition_rule",
			hidden: true,
		},
		{
			dataField: "min_discount_pct_nlp",
			hidden: true,
		},
		{
			dataField: "max_discount_pct_nlp",
			hidden: true,
		},
		{
			dataField: "effective_discount_pct_nlp",
			hidden: true,
		},
		{
			dataField: "state_discount_nlp",
			hidden: true,
		},
		{
			dataField: "product_type_nlp",
			hidden: true,
		},
		{
			dataField: "is_storewide_nlp",
			hidden: true,
		},
		{
			dataField: "is_steep_nlp",
			hidden: true,
		},
		{
			dataField: "is_significant_nlp",
			hidden: true,
		},
		{
			dataField: "holiday_tag_nlp",
			hidden: true,
		},
		{
			dataField: "free_ship_condition_nlp",
			hidden: true,
		},
		{
			dataField: "nlp_editor",
			hidden: true,
		},
		{
			dataField: "is_clearance",
			hidden: true,
		},
		{
			dataField: "group_id",
			hidden: true,
		},
		{
			dataField: "subject",
			text: "Subject",
			sort: true,
		},
		{
			dataField: "category",
			text: "Category",
			sort: true,
		},
		{
			dataField: "start_date",
			text: "Start Date",
			sort: true,
		},
		{
			dataField: "end_date",
			text: "End Date",
			sort: true,
		},
		{
			dataField: "total_volume",
			text: "Volume",
			sort: true,
			formatter: (cell, row) => {
				return cell.toLocaleString();
			},
		},
		{
			dataField: "min_discount_pct",
			text: "Min %",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.min_discount_pct_rule &&
					row.min_discount_pct_rule !== null &&
					row.min_discount_pct_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.min_discount_pct_nlp}</strike> {cell}(
							{row.min_discount_pct_rule})
						</div>
					);
				} else if (
					row.min_discount_pct_rule &&
					row.min_discount_pct_rule !== null
				) {
					return (
						<div style={{ color: "red" }}>
							{cell}({row.min_discount_pct_rule})
						</div>
					);
				} else if (row.min_discount_pct_nlp !== cell) {
					return (
						<div>
							<strike>{row.min_discount_pct_nlp}</strike> {cell} (
							{row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "max_discount_pct",
			text: "Max %",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.max_discount_pct_rule &&
					row.max_discount_pct_rule !== null &&
					row.max_discount_pct_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.max_discount_pct_nlp}</strike> {cell} (
							{row.max_discount_pct_rule})
						</div>
					);
				} else if (
					row.max_discount_pct_rule &&
					row.max_discount_pct_rule !== null
				) {
					return (
						<div style={{ color: "red" }}>
							{cell}({row.max_discount_pct_rule})
						</div>
					);
				} else if (row.max_discount_pct_nlp !== cell) {
					return (
						<div>
							<strike>{row.min_discount_pct_nlp}</strike> {cell} (
							{row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "effective_discount_pct",
			text: "Effective %",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.effective_discount_pct_rule &&
					row.effective_discount_pct_rule !== null &&
					row.effective_discount_pct_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.effective_discount_pct_nlp}</strike> {cell} (
							{row.effective_discount_pct_rule})
						</div>
					);
				} else if (
					row.effective_discount_pct_rule &&
					row.effective_discount_pct_rule !== null
				) {
					return (
						<div style={{ color: "red" }}>
							{cell}({row.effective_discount_pct_rule})
						</div>
					);
				} else if (row.effective_discount_pct_nlp !== cell) {
					return (
						<div>
							<strike>{row.effective_discount_pct_nlp}</strike> {cell} (
							{row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "state_discount",
			text: "State",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.state_discount_rule &&
					row.state_discount_rule !== null &&
					row.state_discount_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.state_discount_nlp}</strike> {cell} (
							{row.state_discount_rule})
						</div>
					);
				} else if (
					row.state_discount_rule &&
					row.state_discount_rule !== null
				) {
					return (
						<div style={{ color: "red" }}>
							{cell}({row.state_discount_rule})
						</div>
					);
				} else if (row.state_discount_nlp !== cell) {
					return (
						<div>
							<strike>{row.state_discount_nlp}</strike> {cell} ({row.nlp_editor}
							)
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "state_discount_val",
			text: "State Discount Value",
			sort: true,
		},
		{
			dataField: "is_storewide",
			text: "Storewide",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.is_storewide_rule &&
					row.is_storewide_rule !== null &&
					row.is_storewide_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.is_significant_nlp ? "true" : "false"}</strike>{" "}
							{row.is_storewide ? "true" : "false"} ({row.is_storewide_rule})
						</div>
					);
				} else if (row.is_storewide_rule && row.is_storewide_rule !== null) {
					return (
						<div style={{ color: "red" }}>
							{row.is_storewide ? "true" : "false"} ({row.is_storewide_rule})
						</div>
					);
				} else if (row.is_storewide_nlp !== cell) {
					return (
						<div>
							<strike>{row.is_storewide_nlp ? "true" : "false"}</strike>{" "}
							{row.is_storewide ? "true" : "false"} ({row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "is_steep",
			text: "Steep",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.is_steep_rule &&
					row.is_steep_rule !== null &&
					row.is_steep_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.is_steep_nlp ? "true" : "false"}</strike>{" "}
							{row.is_steep ? "true" : "false"} ({row.is_steep_rule})
						</div>
					);
				} else if (row.is_steep_rule && row.is_steep_rule !== null) {
					return (
						<div style={{ color: "red" }}>
							{row.is_steep ? "true" : "false"} ({row.is_steep_rule})
						</div>
					);
				} else if (row.is_steep_nlp !== cell) {
					return (
						<div>
							<strike>{row.is_steep_nlp ? "true" : "false"}</strike>{" "}
							{row.is_steep ? "true" : "false"} ({row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "is_significant",
			text: "Significant",
			sort: true,
			formatter: (cell, row) => {
				if (
					row.is_significant_rule &&
					row.is_significant_rule !== null &&
					row.is_significant_nlp !== cell
				) {
					return (
						<div style={{ color: "red" }}>
							<strike>{row.is_significant_nlp ? "true" : "false"}</strike>{" "}
							{row.is_significant ? "true" : "false"} ({row.is_significant_rule}
							)
						</div>
					);
				} else if (
					row.is_significant_rule &&
					row.is_significant_rule !== null
				) {
					return (
						<div style={{ color: "red" }}>
							{row.is_significant ? "true" : "false"} ({row.is_significant_rule}
							)
						</div>
					);
				} else if (row.is_significant_nlp !== cell) {
					return (
						<div>
							<strike>{row.is_significant_nlp ? "true" : "false"}</strike>{" "}
							{row.is_significant ? "true" : "false"} ({row.nlp_editor})
						</div>
					);
				} else {
					return cell;
				}
			},
		},
		{
			dataField: "is_clearance",
			text: "Clearance",
			sort: true,
		},
		{
			dataField: "nlp_badwords",
			text: "Desperation",
			sort: true,
		},
	];

	const addEditRecord = (row) => {
		setEditFeedId(row.feed_id);
		setCachedHarmonicId(row.cached_harmonic_id);
		setEditMin(row.min_discount_pct);
		setEditMax(row.max_discount_pct);
		setEditEffective(row.effective_discount_pct);
		setEditState(row.state_discount);
		setEditProduct(row.product_type);
		setEditHoliday(row.holiday_tag);
		setEditShip(row.free_ship_condition);
		setEditBadwords(row.nlp_badwords);
		setEditStateVal(row.state_discount_val);
		setEditStorewide(row.is_storewide);
		setEditSteep(row.is_steep);
		setEditSignificant(row.is_significant);
		setEditClearance(row.is_clearance);

		let initFields = initDDfields;
		initFields["min_discount_pct"] = row.min_discount_pct;
		initFields["max_discount_pct"] = row.max_discount_pct;
		initFields["effective_discount_pct"] = row.effective_discount_pct;
		initFields["state_discount"] = row.state_discount;
		initFields["product_type"] = row.product_type;
		initFields["holiday_tag"] = row.holiday_tag;
		initFields["free_ship_condition"] = row.free_ship_condition;
		initFields["nlp_badwords"] = row.nlp_badwords;
		initFields["state_discount_val"] = row.state_discount_val;
		setInitDDfields(initFields);
	};

	const changeEditCategory = (cate) => {
		if (editCategory.includes(cate)) {
			editCategory.splice(editCategory.indexOf(cate), 1);
		} else {
			editCategory.push(cate);
		}
	};

	const addEditCate = (row) => {
		let cate = row.category.split(",");
		cate.forEach((c, i) => (cate[i] = c.trim()));
		setEditCategory(cate);
		setEditFeedId(row.feed_id);
	};

	const updateDeepDiscount = async () => {
		let args = {},
			args_original = {};
		args["min_discount_pct"] = Number(editMin);
		args["max_discount_pct"] = Number(editMax);
		args["effective_discount_pct"] = Number(editEffective);
		args["state_discount"] = editState;
		args["product_type"] = editProduct;
		args["holiday_tag"] = editHoliday;
		args["free_ship_condition"] = editShip;
		args["nlp_badwords"] = editBadwords;
		args["is_storewide"] = editStorewide;
		args["is_steep"] = editSteep;
		args["is_significant"] = editSignificant;
		args["is_clearance"] = editClearance;
		args["state_discount_val"] = editStateVal;

		let id_str = "data[" + editFeedId + "]";
		args_original[id_str + "[feed_list]"] = editFeedId;
		args_original[id_str + "[feed_id]"] = editFeedId;
		args_original[id_str + "[deep_discount__min_discount_pct]"] = editMin;
		args_original[id_str + "[deep_discount__max_discount_pct]"] = editMax;
		args_original[id_str + "[deep_discount__effective_discount_pct]"] =
			editEffective;
		args_original[id_str + "[deep_discount__state_discount]"] = editState;
		args_original[id_str + "[deep_discount__product_type]"] = editProduct;
		args_original[id_str + "[deep_discount__holiday_tag]"] = editHoliday;
		args_original[id_str + "[deep_discount__free_ship_condition]"] = editShip;
		args_original[id_str + "[deep_discount__badwords]"] = editBadwords;
		args_original[id_str + "[deep_discount__is_storewide]"] = editStorewide;
		args_original[id_str + "[deep_discount__is_steep]"] = editSteep;
		args_original[id_str + "[deep_discount__is_significant]"] = editSignificant;
		args_original[id_str + "[deep_discount__is_clearance]"] = editClearance;
		args_original[id_str + "[deep_discount__apply_to_all]"] = editApplyall;
		args_original[id_str + "[deep_discount__state_discount_val]"] =
			editStateVal;
		args_original["action"] = "edit";
		let params = new URLSearchParams(args_original).toString();

		await axios
			.post(getLunaUrl(`v1/deep-discount/edit-luna`), params, {
				headers: {
					Authorization: "JWT " + localStorage.getItem("RWLoginToken"),
					"content-type": "application/x-www-form-urlencoded",
				},
			})
			.then((response) => {
				toast.warn(
					"Please go back to Airstream to backfill the harmonic discount"
				);
				setShowEditModal(false);
				let toModify = {};
				for (const key in initDDfields) {
					if (args[key] != initDDfields[key]) {
						toModify[key + "_nlp"] = initDDfields[key];
						toModify[key] = args[key];
					}
				}
				for (let entry of totalData) {
					if (entry.feed_id === editFeedId) {
						for (const f in toModify) {
							entry[f] = toModify[f];
						}
						break;
					}
				}
			})
			.catch((e) => {
				console.log(e);
			});

		await axios
			.post(
				getLunaUrl(
					`v1/harmonic-discount/cached${
						displayAllHis ? "" : "/weekly"
					}/update/?feed_id=${cachedHarmonicId}&apply_all=${editApplyall}`
				),
				args,
				getNumericalHeader()
			)
			.then((response) => {
				toast.success("success");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const updateCategory = async () => {
		let cate_str = editCategory.map((c) => c).join(",");
		cate_str = "," + cate_str + ",";
		let args_cate = {
			email_category: cate_str,
		};
		let args = {
			feed_id: editFeedId,
			category: cate_str,
			apply_to_all: editApplyall,
			email_category: cate_str,
		};

		await axios
			.post(
				getLunaUrl(`v1/emailfeed/update-category/`),
				args,
				getNumericalHeader()
			)
			.then((response) => {
				toast.success("success");
				let str = editCategory
					.map((c, i) => {
						if (i !== 0) {
							return " " + c;
						} else {
							return c;
						}
					})
					.join(",");
				for (let entry of totalData) {
					if (entry.feed_id === editFeedId) {
						entry.category = str;
						break;
					}
				}

				setShowEditCateModal(false);
			})
			.catch((e) => {
				console.log(e);
			});

		await axios
			.post(
				getLunaUrl(
					`v1/harmonic-discount/cached/update/?feed_id=${editFeedId}&apply_all=${editApplyall}`
				),
				args_cate,
				getNumericalHeader()
			)
			.then((response) => {})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleSwitch = () => {
		if (displayAllHis) {
			let earliest = moment(lastSundayFI);
			earliest.subtract(16, "w");
			if (moment(startDate).isBefore(earliest)) {
				alert("Please switch to All History data access");
				return;
			}
		}
		getDiscountTableData(!displayAllHis);
		setDisplayAllHis(!displayAllHis);
		localStorage.setItem("RWdisplayAllHis", !displayAllHis);
	};

	if (dataFetching) {
		return (
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={3}>Selected Time: {startDate + " ~ " + endDate}</Col>
						</Row>
					</h4>
					<h4>Fetching Data...</h4>
				</CardBody>
			</Card>
		);
	} else if (totalData.length === 0) {
		return (
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={3}>Selected Time:</Col>
							<Col style={{ lineHeight: "20px" }}>
								<Form.Check
									type="switch"
									id="table-switch"
									label={
										displayAllHis ? (
											<div className="mytooltip">
												All History&nbsp;
												<i class="dripicons-information mytooltip-target"></i>
												<div className="tooltiptext">
													Update on Friday, with all history
												</div>
											</div>
										) : (
											<div className="mytooltip">
												Fast Weekly&nbsp;
												<i class="dripicons-information mytooltip-target"></i>
												<div className="tooltiptext">
													Update on Monday, with only recent 16 weeks
												</div>
											</div>
										)
									}
									checked={displayAllHis}
									onChange={(e) => handleSwitch()}
								/>
							</Col>
						</Row>
					</h4>
				</CardBody>
			</Card>
		);
	}

	const expandRow = {
		renderer: (row) => {
			return (
				<>
					<Table striped bordered si ze="sm">
						<tbody>
							<tr>
								<td>Campaign ID</td>
								<td>
									<a
										target="_blank"
										href={
											"http://luna.ranwalk.goldbeyond.com/api/v1/emailfeed/data/" +
											row.feed_id +
											"/"
										}
									>
										{row.feed_id}
									</a>
								</td>
							</tr>
							<tr>
								<td>Last Update</td>
								<td>{row.last_update}</td>
							</tr>
							<tr>
								<td>Email Image</td>
								<td>
									{row.email_image && (
										<a href={row.email_image} target="_blank">
											<svg
												t="1638500915285"
												class="icon"
												viewBox="0 0 1024 1024"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												p-id="3033"
												width="20"
												height="20"
											>
												<path
													d="M512 170.666667C298.666667 170.666667 116.48 303.36 42.666667 490.666667 116.48 677.973333 298.666667 810.666667 512 810.666667s395.52-132.693333 469.333333-320C907.52 303.36 725.333333 170.666667 512 170.666667z m0 533.333333c-117.76 0-213.333333-95.573333-213.333333-213.333333s95.573333-213.333333 213.333333-213.333334 213.333333 95.573333 213.333333 213.333334-95.573333 213.333333-213.333333 213.333333z m0-341.333333c-70.826667 0-128 57.173333-128 128s57.173333 128 128 128 128-57.173333 128-128-57.173333-128-128-128z"
													fill="#727cf5"
													p-id="3034"
												></path>
											</svg>
										</a>
									)}
								</td>
							</tr>
							<tr>
								<td>Group ID</td>
								<td>{row.group_id}</td>
							</tr>
							{row.product_type_rule &&
							row.product_type_rule !== null &&
							row.product_type_nlp !== row.product_type ? (
								<tr>
									<td>Product</td>
									<td>
										<strike>{row.product_type_nlp}</strike>
										{row.product_type}({row.product_type_rule})
									</td>
								</tr>
							) : row.product_type_rule && row.product_type_rule !== null ? (
								<tr>
									<td>Product</td>
									<td>
										{row.product_type}({row.product_type_rule})
									</td>
								</tr>
							) : row.product_type_nlp !== row.product_type ? (
								<tr>
									<td>Product</td>
									<td>
										<strike>{row.product_type_nlp}</strike> {row.product_type}
									</td>
								</tr>
							) : (
								<tr>
									<td>Product</td>
									<td>{row.product_type}</td>
								</tr>
							)}
							{row.free_ship_condition_rule &&
							row.free_ship_condition_rule !== null &&
							row.free_ship_condition_nlp !== row.free_ship_condition ? (
								<tr>
									<td>Free Ship</td>
									<td>
										<strike>{row.free_ship_condition_nlp}</strike>{" "}
										{row.free_ship_condition}({row.free_ship_condition_rule})
									</td>
								</tr>
							) : row.free_ship_condition_rule &&
							  row.free_ship_condition_rule !== null ? (
								<tr>
									<td>Free Ship</td>
									<td>
										{row.free_ship_condition}({row.free_ship_condition_rule})
									</td>
								</tr>
							) : row.free_ship_condition_nlp !== row.free_ship_condition ? (
								<tr>
									<td>Free Ship</td>
									<td>
										<strike>{row.free_ship_condition_nlp}</strike>{" "}
										{row.free_ship_condition}
									</td>
								</tr>
							) : (
								<tr>
									<td>Free Ship</td>
									<td>{row.free_ship_condition}</td>
								</tr>
							)}
						</tbody>
					</Table>
					<Button
						variant="primary"
						onClick={() => {
							addEditRecord(row);
							setShowEditModal(true);
						}}
					>
						Edit Deep Discount
					</Button>
					<Button
						variant="primary"
						style={{ marginLeft: 20 }}
						onClick={() => {
							addEditCate(row);
							setShowEditCateModal(true);
						}}
					>
						Edit Category
					</Button>
				</>
			);
			/* <div>
        <p className="mt-2" style={{borderBottom: '1px solid #808080'}}>{`Campaign ID: ${row.feed_id}`}</p>
        <p className="mt-2">{`Last Update: ${row.last_update}`}</p>
        {row.product_type_rule&&row.product_type_rule!==null?
          <p className="mt-2" style={{color:'red'}}>Product: {row.product_type}({row.product_type_rule})</p>:
          <p className="mt-2">Product: {row.product_type}</p>
        }
        {row.holiday_tag_rule&&row.holiday_tag_rule!==null?
          <p className="mt-2" style={{color:'red'}}>Holiday: {row.holiday_tag}({row.holiday_tag_rule})</p>:
          <p className="mt-2">Holiday: {row.holiday_tag}</p>
        }
        {row.free_ship_condition_rule&&row.free_ship_condition_rule!==null?
          <p className="mt-2" style={{color:'red'}}>Free Ship: {row.free_ship_condition}({row.free_ship_condition_rule})</p>:
          <p className="mt-2">Free Ship: {row.free_ship_condition}</p>
        }
      </div> */
		},
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	// const groupExpandRow = {
	// 	renderer: (row) => {
	// 		return (
	// 			<ToolkitProvider
	// 				bootstrap4
	// 				keyField="feed_id"
	// 				data={totalData.filter((data) => data.group_id === row.group_id)}
	// 				columns={columns}
	// 				search={{ onColumnMatch: expandMatch }}
	// 			>
	// 				{(props) => (
	// 					<React.Fragment>
	// 						<Row>
	// 							<Col>
	// 								<SearchBar {...props.searchProps} />
	// 							</Col>
	// 						</Row>
	// 						<BootstrapTable
	// 							{...props.baseProps}
	// 							bordered={false}
	// 							expandRow={expandRow}
	// 							pagination={paginationFactory({
	// 								sizePerPage: 5,
	// 								showTotal: true,
	// 								disablePageTitle: true,
	// 							})}
	// 							wrapperClasses="table-responsive"
	// 						/>
	// 					</React.Fragment>
	// 				)}
	// 			</ToolkitProvider>
	// 		);
	// 	},
	// 	showExpandColumn: true,
	// 	// expanded: uniqueGroupIds.map(groupId => groupId.group_id),
	// 	expandHeaderColumnRenderer: ({ isAnyExpands }) => {
	// 		return isAnyExpands ? (
	// 			<i className="dripicons-minus" />
	// 		) : (
	// 			<i className="dripicons-plus" />
	// 		);
	// 	},
	// 	expandColumnRenderer: ({ expanded }) => {
	// 		return expanded ? (
	// 			<i className="dripicons-minus" />
	// 		) : (
	// 			<i className="dripicons-plus" />
	// 		);
	// 	},
	// };

	// const onColumnMatch = ({ searchText, value, column, row }) => {
	// 	// if (keyword !== searchText) { setKeyword(searchText) }
	// 	if (searchText === "") {
	// 		return true;
	// 	}
	// 	let filtered = totalData.filter(
	// 		(data) =>
	// 			data.subject.toLowerCase().includes(searchText) ||
	// 			data.category.toLowerCase().includes(searchText)
	// 	);
	// 	if (filtered.length > 0) {
	// 		for (let f of filtered) {
	// 			if (f.group_id === value) {
	// 				return true;
	// 			}
	// 		}
	// 	}

	// 	return false;
	// };

	// const expandMatch = ({ searchText, value, column, row }) => {
	// 	// searchText = keyword;
	// 	if (
	// 		row.subject.toLowerCase().includes(searchText) ||
	// 		row.category.toLowerCase().includes(searchText)
	// 	) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// };

	const { SearchBar } = Search;

	return (
		<div>
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Edit Record</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 600, overflowY: "scroll" }}>
					<Form>
						<Form.Group className="mb-3" controlId="edit_campagin_id">
							<Form.Label size="sm">Campaign ID</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editFeedId}
								readOnly
								onChange={(e) => setEditFeedId(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_min">
							<Form.Label size="sm">Min %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editMin}
								onChange={(e) => setEditMin(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_max">
							<Form.Label size="sm">Max %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editMax}
								onChange={(e) => setEditMax(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_effective">
							<Form.Label size="sm">Effective %</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editEffective}
								onChange={(e) => setEditEffective(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_state">
							<Form.Label size="sm">State</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editState}
								onChange={(e) => setEditState(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_product">
							<Form.Label size="sm">Product</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editProduct}
								onChange={(e) => setEditProduct(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_holiday">
							<Form.Label size="sm">Holiday</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editHoliday}
								onChange={(e) => setEditHoliday(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_freeship">
							<Form.Label size="sm">Free Ship</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editShip}
								onChange={(e) => setEditShip(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_badwords">
							<Form.Label size="sm">Desperation</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={editBadwords}
								onChange={(e) => setEditBadwords(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_storewide">
							<Form.Label size="sm">Storewide</Form.Label>
							<Form.Check
								defaultChecked={editStorewide}
								onChange={(e) => {
									setEditStorewide(!editStorewide);
								}}
								type="switch"
								id="storewide-sw"
								label={editStorewide ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_steep">
							<Form.Label size="sm">Steep</Form.Label>
							<Form.Check
								defaultChecked={editSteep}
								onChange={(e) => {
									setEditSteep(!editSteep);
								}}
								type="switch"
								id="steep-sw"
								label={editSteep ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_significant">
							<Form.Label size="sm">Significant</Form.Label>
							<Form.Check
								defaultChecked={editSignificant}
								onChange={(e) => {
									setEditSignificant(!editSignificant);
								}}
								type="switch"
								id="significant-sw"
								label={editSignificant ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_clearance">
							<Form.Label size="sm">Clearance</Form.Label>
							<Form.Check
								defaultChecked={editClearance}
								onChange={(e) => {
									setEditClearance(!editClearance);
								}}
								type="switch"
								id="clearance-sw"
								label={editClearance ? "Y" : "N"}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_applyall">
							<Form.Label size="sm">Apply to All</Form.Label>
							<Form.Check
								defaultChecked={editApplyall}
								onChange={(e) => {
									setEditApplyall(!editApplyall);
								}}
								type="switch"
								id="applyall-sw"
								label={editApplyall ? "Y" : "N"}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEditModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => updateDeepDiscount()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showEditCateModal}
				onHide={() => setShowEditCateModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Category</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 600, overflowY: "scroll" }}>
					<Form>
						<Form.Group className="mb-3" controlId="edit_cate">
							{categories.map((c) => {
								return (
									<Form.Check
										defaultChecked={editCategory.includes(c)}
										onChange={(e) => changeEditCategory(e.target.value)}
										type="checkbox"
										label={c}
										id={`cate-${c}`}
										value={c}
									/>
								);
							})}
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_cate_applyall">
							<Form.Label size="sm">Apply to All</Form.Label>
							<Form.Check
								defaultChecked={editApplyall}
								onChange={(e) => {
									setEditApplyall(!editApplyall);
								}}
								type="switch"
								id="applyall-sw"
								label={editApplyall ? "Y" : "N"}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => setShowEditCateModal(false)}
					>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => updateCategory()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={3}>
								Selected Time: {""}
								{startDate + " ~ " + endDate}
							</Col>
							<Col style={{ lineHeight: "20px" }}>
								<Form.Check
									type="switch"
									id="table-switch"
									label={
										displayAllHis ? (
											<div className="mytooltip">
												All History&nbsp;
												<i class="dripicons-information mytooltip-target"></i>
												<div className="tooltiptext">
													Update on Friday, with all history
												</div>
											</div>
										) : (
											<div className="mytooltip">
												Fast Weekly&nbsp;
												<i class="dripicons-information mytooltip-target"></i>
												<div className="tooltiptext">
													Update on Monday, with only recent 16 weeks
												</div>
											</div>
										)
									}
									checked={displayAllHis}
									onChange={(e) => handleSwitch()}
								/>
							</Col>
						</Row>
					</h4>
					<ToolkitProvider
						bootstrap4
						keyField="cached_harmonic_id"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									expandRow={expandRow}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 5,
										showTotal: true,
									})}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default HarmonicDiscountTable;
