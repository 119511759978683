import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

import "../../staticfiles/css/rule-playground.css";

const RegularExpressionRules = () => {
	return (
		<div>
			<Accordion defaultActiveKey="1">
				<Card>
					<Card.Header>
						<Accordion.Toggle as={Button} variant="link" eventKey="0">
							REGULAR RULE EXPRESSIONS
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<div class="row collapse show" id="ruleCards">
								<div class="expression card mt-3 mb-0 border-primary border">
									<div class="card-body">
										<h5 class="card-title text-primary">Distance</h5>
										<blockquote class="card-bodyquote mb-0">
											<ul>
												<li>
													An arbitrary number of words separated by the common
													delimiters such as " ",".", etc.
												</li>
												<li>Format</li>
												<ul>
													<li>
														[[DIST:word_a,&lt;a,b&gt;,word_b]] where a,b are the
														lower and upper bounds of words between word_a and
														word_b. a,b have defaults set as 0 and positive
														infinity
													</li>
													<li>
														e.g. [[DIST:&lt;3&gt;]] will match any three words.
														[[DIST:this,&lt;3,5&gt;,alpha]] will match three to
														five words between 'this' and 'alpha'.
														[[DIST:&lt;3,&gt;]] will match more than three words
													</li>
												</ul>
											</ul>
										</blockquote>
									</div>
								</div>

								<div class="expression card mt-3 mb-0 border-primary border">
									<div class="card-body">
										<h5 class="card-title text-primary">Number</h5>
										<blockquote class="card-bodyquote mb-0">
											<ul>
												<li>An arbitrary number</li>
												<li>Format</li>
												<ul>
													<li>[[NUM(name of variable)condition]] </li>
													<li>
														e.g. [[NUM]] will match any number; [[NUM&gt;30]]
														will match any number larger than
														30;[[NUM(x)&gt;30]] will match the numbers in the
														same way as the case before, and additionally store
														it as variable 'x'
													</li>
												</ul>
											</ul>
										</blockquote>
									</div>
								</div>

								<div class="expression card mt-3 mb-0 border-primary border">
									<div class="card-body">
										<h5 class="card-title text-primary">Regular Expression</h5>

										<blockquote class="card-bodyquote mb-0">
											<ul>
												<li>
													All formats of standard regular expressions are
													supported with the exception of grouping '()', as a
													result, special characters '[](){}' etc, will need to
													be escaped.
												</li>
												<li>
													e.g. if you want to math "100%(1.0) increase" and
													"100%(1.0)", you should write "[[NUM]]%\([[NUM]]\)(?:
													increase)?"
												</li>
												<li>
													Cheat Sheet
													<ul>
														<li>
															<a href="https://en.wikipedia.org/wiki/Regular_expression#Syntax">
																Cheat Sheet 1
															</a>
														</li>
														<li>
															<a href="https://www.rexegg.com/regex-quickstart.html#ref">
																Cheat Sheet 2
															</a>
														</li>
													</ul>
												</li>
											</ul>
										</blockquote>
									</div>
								</div>

								<div class="expression card mt-3 mb-0 border-primary border">
									<div class="card-body">
										<h5 class="card-title text-primary">Math Operations</h5>

										<blockquote class="card-bodyquote mb-0">
											<ul>
												<li>
													The math operations can be performed in the rule.
													Currently the system support for average and division.
												</li>
												<li>AVG(a, b, ...),DIV(x,y),DIVPCT(x,y)</li>
												<ul>
													<li>
														e.g. when user wants to compute the efficient
														discount based on the min_discount and the
														max_discount, set the effective discount as AVG(a,
														b), where a and b are the variables extracted from
														NUM(x).
													</li>
													<li>*DIVPCT is the ration in percentage</li>
												</ul>
											</ul>
										</blockquote>
									</div>
								</div>

								<div class="expression card mt-3 mb-0 border-primary border">
									<div class="card-body">
										<h5 class="card-title text-primary">Boolean Expressions</h5>

										<blockquote class="card-bodyquote mb-0">
											<ul>
												<li>
													Boolean Expression can be used to set the value of a
													boolean field (e.g. is_significant) to the truth value
													of a expression (e.g. x&gt;10)
												</li>
												<li>==,&gt;,&lt;,&gt;=,&lt;=</li>
												<ul>
													<li>
														e.g. when a user wants to set is_sigificant to TRUE
														if DIV(x,y)&gt;20, they should check "is_variable"
														in the command section and input "DIV(x,y)&gt;20"
													</li>
												</ul>
											</ul>
										</blockquote>
									</div>
								</div>
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</div>
	);
};

export default RegularExpressionRules;
