export const indexMapping = {
	adjusted_discount_vol: "Adjusted Discount Index",
	adjusted_is_significant_total_volume: "Significant Discount Index",
	adjusted_is_steep_total_volume: "Steep Discount Index",
	adjusted_is_storewide_total_volume: "Storewide Discount Index",
	adjusted_total_volume: "Total Volume Index",
	adjusted_zero_discount_volume: "Zero Discount Index",
	discount_20_percentile: "Discount 20th Percentile Index",
	discount_80_percentile: "Discount 80th Percentile Index",
	effective_discount: "Effective Discount Index",
	final_index: "Final Discount Index",
	max_discount: "Max Discount Index",
	total_campaigns: "Total Discount Campaigns Index",
	adjusted_harmonic_state_discount_total_volume: "State Discount Volume Index",
	adjusted_harmonic_promo_budget_value: "Promotional Budget Volume Index",
	adjusted_badwords_total_volume: "Desperation Index",
	adjusted_is_clearance_total_volume: "Is Clearance Index",
};

export const mappingHandler = (indexMapping, index) => {
	if (indexMapping[index]) {
		return indexMapping[index];
	} else {
		console.log(index);
		return "";
	}
};
