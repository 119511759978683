import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (props) => {
	if (localStorage.getItem("RWLoginToken") == null) {
		return <Redirect to="/"></Redirect>;
	} else {
		return (
			<Route
				exact={props.exact}
				path={props.path}
				component={props.component}
			/>
		);
	}
};

export default PrivateRoute;
