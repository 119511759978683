import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown, Form } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { saveAs } from "file-saver";
import {
	getLunaUrl,
	getNumericalHeader,
	chartJSGraphDownload,
	formatCategoryString,
	axios,
	processNextpageUrl,
} from "../utils";

import DomainPanel from "../../ReusableComponents/DomainPanel";
import MyTooltip from "../../ReusableComponents/MyTooltip";
import "../../staticfiles/css/email-volume.css";
import HarmonicDiscountChart from "./HarmonicDiscountChart";
import HarmonicDiscountTable from "./HarmonicDiscountTable";

const CampaignDiscount = ({
	uniqueDomains,
	tickerCate,
	loaded,
	lastSundayFI,
}) => {
	const [uniqueTickers, setUniqueTickers] = useState([]);
	const [selectedTicker, setSelectedTicker] = useState("");

	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState({});

	const [allDomainCategories, setAllDomainCategories] = useState({});
	const [domainCategories, setDomainCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");
	const [harmonicStartDate, setHarmonicStartDate] = useState("");
	const [harmonicStartDateValid, setHarmonicStartDateValid] = useState("");
	const [harmonicEndDate, setHarmonicEndDate] = useState("");
	const [harmonicEndDateValid, setHarmonicEndDateValid] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [dataFetched, setDataFetched] = useState(false);
	const [initialFetched, setInitialFetched] = useState(false);
	const [discountChartFetching, setDiscountChartFetching] = useState(false);
	const [discountTableFetching, setDiscountTableFetching] = useState(false);
	const [discountChartData, setDiscountChartData] = useState([]);
	const [discountTableData, setDiscountTableData] = useState([]);
	const [uniqueGroupIds, setUniqueGroupIds] = useState([]);
	const [selectedDate, setSelectedDate] = useState("");

	const [displayUSData, setDisplayUSData] = useState(false);

	const discountChartRef = useRef(null);
	const domainRef = useRef("");
	const cateRef = useRef("");

	const getDomainData = async (domain, category) => {
		if (domain === undefined || domain === null) {
			return;
		}
		if (category === "") {
			return;
		}
		setDiscountChartFetching(true);
		setDiscountTableData([]);
		await axios
			.get(
				getLunaUrl(
					`${
						displayUSData
							? "v1/emailfeed/weekly-volume/us/batch/"
							: "v1/emailfeed/weekly-volume/batch/"
					}?domain=${domain}&category=${category}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				let curDomain = response.data[0].domain;
				let curCate = response.data[0].category;
				if (curDomain !== domainRef.current || curCate !== cateRef.current) {
					return;
				} else if (response.data.length === 0) {
					toast.warn(
						"Harmonic Discount Chart currently don't have information for this domain and category combination"
					);
					setDiscountChartData([]);
				} else {
					let tempminStartDate = new Date(response.data[0].start_date);
					let tempminStartDateIndex = 0;

					let tempmaxStartDate = new Date(response.data[0].start_date);
					let tempmaxStartDateIndex = 0;

					let tempminEndDate = new Date(response.data[0].end_date);
					let tempminEndDateIndex = 0;

					let tempmaxEndDate = new Date(response.data[0].end_date);
					let tempmaxEndDateIndex = 0;

					for (let i = 0; i < response.data.length; i++) {
						let newDate = new Date(response.data[i].start_date);
						if (tempminStartDate > newDate) {
							tempminStartDate = newDate;
							tempminStartDateIndex = i;
						}
						if (tempmaxStartDate < newDate) {
							tempmaxStartDate = newDate;
							tempmaxStartDateIndex = i;
						}

						let newEnd = new Date(response.data[i].end_date);
						if (tempminEndDate > newEnd) {
							tempminEndDate = newEnd;
							tempminEndDateIndex = i;
						}
						if (tempmaxEndDate < newEnd) {
							tempmaxEndDate = newEnd;
							tempmaxEndDateIndex = i;
						}
					}

					let newStartDate = new Date(
						response.data[tempmaxEndDateIndex].end_date
					);
					var year = newStartDate.getFullYear();
					var month = newStartDate.getMonth();
					var day = newStartDate.getDate();
					var c = new Date(year - 1, month, day);

					month = "" + (c.getMonth() + 1);
					day = "" + c.getDate();
					year = c.getFullYear();

					if (month.length < 2) month = "0" + month;
					if (day.length < 2) day = "0" + day;

					newStartDate = [year, month, day].join("-");

					setminStartDate(response.data[tempminStartDateIndex].start_date);
					setmaxStartDate(response.data[tempmaxStartDateIndex].start_date);

					if (tempStartDate === "") {
						setStartDate(newStartDate);
						setTempStartDate(newStartDate);
					}

					setminEndDate(response.data[tempminEndDateIndex].end_date);
					setmaxEndDate(response.data[tempmaxEndDateIndex].end_date);

					setEndDate(response.data[tempmaxEndDateIndex].end_date);
					setTempEndDate(response.data[tempmaxEndDateIndex].end_date);

					setDiscountChartData(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setDiscountChartFetching(false);
				setDataFetched(true);
			});
	};

	const debouncedGetDomainData = debounce(getDomainData, 1500);

	const getNextDiscountTable = async (url, list) => {
		await axios
			.get(url, getNumericalHeader())
			.then(async (response) => {
				let results = response.data.results;
				for (let i = 0; i < results.length; i++) {
					let cate = formatCategoryString(results[i].email_category);
					let entry = {
						subject: results[i].email_subject,
						category: cate,
						end_date: results[i].email_end_date,
						total_volume: results[i].project_total_volume,
						min_discount_pct: results[i].min_discount_pct,
						max_discount_pct: results[i].max_discount_pct,
						effective_discount_pct: results[i].effective_discount_pct,
						state_discount: results[i].state_discount,
						product_type: results[i].product_type,
						is_storewide: results[i].is_storewide,
						is_steep: results[i].is_steep,
						is_significant: results[i].is_significant,
						is_clearance: results[i].is_clearance,
						holiday_tag: results[i].holiday_tag,
						free_ship_condition: results[i].free_ship_condition,
						feed_id: results[i].feed_id,
						rule_discount_id: results[i].rule_discount_id,
						last_update: results[i].last_update,
						has_rule: results[i].rule_discount_id === null ? false : true,
						is_significant_nlp: results[i].nlp_is_significant,
						is_steep_nlp: results[i].nlp_is_steep,
						is_storewide_nlp: results[i].nlp_is_storewide,
						max_discount_pct_nlp: results[i].nlp_max_discount_pct,
						min_discount_pct_nlp: results[i].nlp_min_discount_pct,
						product_type_nlp: results[i].nlp_product_type,
						state_discount_nlp: results[i].nlp_state_discount,
						effective_discount_pct_nlp: results[i].nlp_effective_discount_pct,
						free_ship_condition_nlp: results[i].nlp_free_ship_condition,
						holiday_tag_nlp: results[i].nlp_holiday_tag,
					};
					if (entry.has_rule) {
						entry["min_discount_pct_rule"] =
							results[i].sys_min_discount_pct_rule;
						entry["max_discount_pct_rule"] =
							results[i].sys_max_discount_pct_rule;
						entry["effective_discount_pct_rule"] =
							results[i].sys_effective_discount_pct_rule;
						entry["state_discount_rule"] = results[i].sys_state_discount_rule;
						entry["product_type_rule"] = results[i].sys_product_type_rule;
						entry["is_storewide_rule"] = results[i].sys_is_storewide_rule;
						entry["is_steep_rule"] = results[i].sys_is_steep_rule;
						entry["is_significant_rule"] = results[i].sys_is_significant_rule;
						entry["holiday_tag_rule"] = results[i].sys_holiday_tag_rule;
						entry["free_ship_condition_rule"] =
							results[i].sys_free_ship_condition_rule;
					}
					list.push(entry);
				}

				if (response.data.next !== null) {
					await getNextDiscountTable(
						processNextpageUrl(response.data.next),
						list
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getGroupId = async (totalData) => {
		let feedIdList = totalData.map((data) => data.feed_id);

		await axios
			.post(
				getLunaUrl(`v1/emailfeed/group_ids/`),
				{ feed_ids: feedIdList },
				getNumericalHeader()
			)
			.then((response) => {
				let ids = response.data;
				let unique_groups = [...new Set(ids.map((id) => id.group__group_id))];
				unique_groups = unique_groups.map((groupId) => {
					return { group_id: groupId };
				});
				unique_groups.push({ group_id: "independent" });
				setUniqueGroupIds(unique_groups);
				for (let data of totalData) {
					let groupid = ids.filter((id) => id.feed_id === data.feed_id);
					data["group_id"] =
						groupid.length > 0 ? groupid[0].group__group_id : "independent";
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getAllDiscountData = async (num, startDate, endDate, db_year, list) => {
		let requests = [];
		for (let i = 2; i <= num; ++i) {
			let tempRequest = axios.get(
				getLunaUrl(
					`v1/harmonic-discount/cached/list/?domain=${selectedDomain.domain}&startDate=${startDate}&endDate=${endDate}&category=${selectedCategory}&db_year=${db_year}&page=${i}`
				),
				getNumericalHeader()
			);
			requests.push(tempRequest);
		}

		await axios
			.all(requests)
			.then(
				axios.spread((...responses) => {
					for (let r of responses) {
						let results = r.data.results;
						for (let i = 0; i < results.length; i++) {
							let cate = results[i].email_category
								.split(",")
								.filter((c) => c !== "");
							if (cate.length === 0) {
								cate = "";
							} else if (cate.length === 1) {
								cate = cate[0];
							} else {
								let temp = "";
								for (let s of cate) {
									temp += s + ", ";
								}
								cate = temp.slice(0, -2);
							}
							let entry = {
								cached_harmonic_id: results[i].cached_harmonic_id,
								subject: results[i].email_subject,
								category: cate,
								end_date: results[i].email_end_date,
								state_discount_val: results[i].state_discount_val,
								total_volume: results[i].project_total_volume,
								min_discount_pct: results[i].min_discount_pct,
								max_discount_pct: results[i].max_discount_pct,
								effective_discount_pct: results[i].effective_discount_pct,
								state_discount: results[i].state_discount,
								product_type: results[i].product_type,
								is_storewide: results[i].is_storewide,
								is_steep: results[i].is_steep,
								is_significant: results[i].is_significant,
								is_clearance: results[i].is_clearance,
								holiday_tag: results[i].holiday_tag,
								free_ship_condition: results[i].free_ship_condition,
								email_image: results[i].email_image_url,
								feed_id: results[i].feed_id,
								rule_discount_id: results[i].rule_discount_id,
								last_update: results[i].last_update,
								has_rule: results[i].rule_discount_id === null ? false : true,
								is_significant_nlp: results[i].nlp_is_significant,
								is_steep_nlp: results[i].nlp_is_steep,
								is_storewide_nlp: results[i].nlp_is_storewide,
								max_discount_pct_nlp: results[i].nlp_max_discount_pct,
								min_discount_pct_nlp: results[i].nlp_min_discount_pct,
								product_type_nlp: results[i].nlp_product_type,
								state_discount_nlp: results[i].nlp_state_discount,
								effective_discount_pct_nlp:
									results[i].nlp_effective_discount_pct,
								free_ship_condition_nlp: results[i].nlp_free_ship_condition,
								holiday_tag_nlp: results[i].nlp_holiday_tag,
							};
							if (entry.has_rule) {
								entry["min_discount_pct_rule"] =
									results[i].sys_min_discount_pct_rule;
								entry["max_discount_pct_rule"] =
									results[i].sys_max_discount_pct_rule;
								entry["effective_discount_pct_rule"] =
									results[i].sys_effective_discount_pct_rule;
								entry["state_discount_rule"] =
									results[i].sys_state_discount_rule;
								entry["product_type_rule"] = results[i].sys_product_type_rule;
								entry["is_storewide_rule"] = results[i].sys_is_storewide_rule;
								entry["is_steep_rule"] = results[i].sys_is_steep_rule;
								entry["is_significant_rule"] =
									results[i].sys_is_significant_rule;
								entry["holiday_tag_rule"] = results[i].sys_holiday_tag_rule;
								entry["free_ship_condition_rule"] =
									results[i].sys_free_ship_condition_rule;
							}
							list.push(entry);
						}
					}
				})
			)
			.catch((errors) => {
				console.log(errors);
			});
	};

	const getDiscountTableData = async (isAllHis) => {
		if (!validateHarmonicDate()) {
			return;
		}
		let discountList = [];
		let end_date;
		let start_date;
		if (selectedDate !== "") {
			end_date = moment(selectedDate);
			start_date = moment(selectedDate);
			start_date.subtract(6, "d");
			setHarmonicStartDate(start_date.format("YYYY-MM-DD"));
			setHarmonicEndDate(end_date.format("YYYY-MM-DD"));
		} else {
			start_date = moment(harmonicStartDate);
			end_date = moment(harmonicEndDate);
		}
		if (!isAllHis) {
			let earliest = moment(lastSundayFI);
			earliest.subtract(16, "w");
			if (start_date.isBefore(earliest)) {
				alert("Please switch to All History data access");
				return;
			}
		}
		let start_year = start_date.year();
		let end_year = end_date.year();
		start_date = start_date.format("YYYY-MM-DD");
		end_date = end_date.format("YYYY-MM-DD");
		setHarmonicStartDateValid(start_date);
		setHarmonicEndDateValid(end_date);
		setDiscountTableFetching(true);
		if (isAllHis) {
			if (start_year !== end_year) {
				await getAllHistData(start_date, end_date, start_year, discountList);
			}
			await getAllHistData(start_date, end_date, end_year, discountList);
		} else {
			let queryUrl = `v1/harmonic-discount/cached/weekly/list/?domain=${selectedDomain.domain}&startDate=${start_date}&endDate=${end_date}&category=${selectedCategory}`;
			await axios
				.get(getLunaUrl(queryUrl), getNumericalHeader())
				.then(async (response) => {
					let results = response.data.results;
					for (let i = 0; i < results.length; i++) {
						let cate = results[i].email_category
							.split(",")
							.filter((c) => c !== "");
						if (cate.length === 0) {
							cate = "";
						} else if (cate.length === 1) {
							cate = cate[0];
						} else {
							let temp = "";
							for (let s of cate) {
								temp += s + ", ";
							}
							cate = temp.slice(0, -2);
						}
						let entry = {
							cached_harmonic_id: results[i].cached_harmonic_id,
							subject: results[i].email_subject,
							category: cate,
							start_date: results[i].email_start_date,
							end_date: results[i].email_end_date,
							total_volume: results[i].project_total_volume,
							min_discount_pct: results[i].min_discount_pct,
							max_discount_pct: results[i].max_discount_pct,
							effective_discount_pct: results[i].effective_discount_pct,
							state_discount: results[i].state_discount,
							state_discount_val: results[i].state_discount_val,
							product_type: results[i].product_type,
							is_storewide: results[i].is_storewide,
							is_steep: results[i].is_steep,
							is_significant: results[i].is_significant,
							is_clearance: results[i].is_clearance,
							holiday_tag: results[i].holiday_tag,
							free_ship_condition: results[i].free_ship_condition,
							email_image: results[i].email_image_url,
							feed_id: results[i].feed_id,
							nlp_discount_id: results[i].nlp_discount_id,
							nlp_editor: results[i].nlp_editor,
							nlp_badwords: results[i].nlp_badwords,
							rule_discount_id: results[i].rule_discount_id,
							last_update: results[i].last_update,
							has_rule: results[i].rule_discount_id === null ? false : true,
							is_significant_nlp: results[i].nlp_is_significant,
							is_steep_nlp: results[i].nlp_is_steep,
							is_storewide_nlp: results[i].nlp_is_storewide,
							max_discount_pct_nlp: results[i].nlp_max_discount_pct,
							min_discount_pct_nlp: results[i].nlp_min_discount_pct,
							product_type_nlp: results[i].nlp_product_type,
							state_discount_nlp: results[i].nlp_state_discount,
							effective_discount_pct_nlp: results[i].nlp_effective_discount_pct,
							free_ship_condition_nlp: results[i].nlp_free_ship_condition,
							holiday_tag_nlp: results[i].nlp_holiday_tag,
						};
						if (entry.has_rule) {
							entry["min_discount_pct_rule"] =
								results[i].sys_min_discount_pct_rule;
							entry["max_discount_pct_rule"] =
								results[i].sys_max_discount_pct_rule;
							entry["effective_discount_pct_rule"] =
								results[i].sys_effective_discount_pct_rule;
							entry["state_discount_rule"] = results[i].sys_state_discount_rule;
							entry["product_type_rule"] = results[i].sys_product_type_rule;
							entry["is_storewide_rule"] = results[i].sys_is_storewide_rule;
							entry["is_steep_rule"] = results[i].sys_is_steep_rule;
							entry["is_significant_rule"] = results[i].sys_is_significant_rule;
							entry["holiday_tag_rule"] = results[i].sys_holiday_tag_rule;
							entry["free_ship_condition_rule"] =
								results[i].sys_free_ship_condition_rule;
						}
						discountList.push(entry);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		await getGroupId(discountList);
		setDiscountTableData(discountList);
		setSelectedDate("");
		setDiscountTableFetching(false);
	};

	const getAllHistData = async (
		start_date,
		end_date,
		db_year,
		discountList
	) => {
		let queryUrl = `v1/harmonic-discount/cached/list/?domain=${selectedDomain.domain}&startDate=${start_date}&endDate=${end_date}&category=${selectedCategory}&db_year=${db_year}`;

		await axios
			.get(getLunaUrl(queryUrl), getNumericalHeader())
			.then(async (response) => {
				let results = response.data.results;
				for (let i = 0; i < results.length; i++) {
					let cate = results[i].email_category
						.split(",")
						.filter((c) => c !== "");
					if (cate.length === 0) {
						cate = "";
					} else if (cate.length === 1) {
						cate = cate[0];
					} else {
						let temp = "";
						for (let s of cate) {
							temp += s + ", ";
						}
						cate = temp.slice(0, -2);
					}
					let entry = {
						cached_harmonic_id: results[i].cached_harmonic_id,
						subject: results[i].email_subject,
						category: cate,
						start_date: results[i].email_start_date,
						end_date: results[i].email_end_date,
						total_volume: results[i].project_total_volume,
						min_discount_pct: results[i].min_discount_pct,
						max_discount_pct: results[i].max_discount_pct,
						effective_discount_pct: results[i].effective_discount_pct,
						state_discount: results[i].state_discount,
						state_discount_val: results[i].state_discount_val,
						product_type: results[i].product_type,
						is_storewide: results[i].is_storewide,
						is_steep: results[i].is_steep,
						is_significant: results[i].is_significant,
						is_clearance: results[i].is_clearance,
						holiday_tag: results[i].holiday_tag,
						free_ship_condition: results[i].free_ship_condition,
						email_image: results[i].email_image_url,
						feed_id: results[i].feed_id,
						nlp_discount_id: results[i].nlp_discount_id,
						nlp_editor: results[i].nlp_editor,
            nlp_badwords: results[i].nlp_badwords,
						rule_discount_id: results[i].rule_discount_id,
						last_update: results[i].last_update,
						has_rule: results[i].rule_discount_id === null ? false : true,
						is_significant_nlp: results[i].nlp_is_significant,
						is_steep_nlp: results[i].nlp_is_steep,
						is_storewide_nlp: results[i].nlp_is_storewide,
						max_discount_pct_nlp: results[i].nlp_max_discount_pct,
						min_discount_pct_nlp: results[i].nlp_min_discount_pct,
						product_type_nlp: results[i].nlp_product_type,
						state_discount_nlp: results[i].nlp_state_discount,
						effective_discount_pct_nlp: results[i].nlp_effective_discount_pct,
						free_ship_condition_nlp: results[i].nlp_free_ship_condition,
						holiday_tag_nlp: results[i].nlp_holiday_tag,
					};
					if (entry.has_rule) {
						entry["min_discount_pct_rule"] =
							results[i].sys_min_discount_pct_rule;
						entry["max_discount_pct_rule"] =
							results[i].sys_max_discount_pct_rule;
						entry["effective_discount_pct_rule"] =
							results[i].sys_effective_discount_pct_rule;
						entry["state_discount_rule"] = results[i].sys_state_discount_rule;
						entry["product_type_rule"] = results[i].sys_product_type_rule;
						entry["is_storewide_rule"] = results[i].sys_is_storewide_rule;
						entry["is_steep_rule"] = results[i].sys_is_steep_rule;
						entry["is_significant_rule"] = results[i].sys_is_significant_rule;
						entry["holiday_tag_rule"] = results[i].sys_holiday_tag_rule;
						entry["free_ship_condition_rule"] =
							results[i].sys_free_ship_condition_rule;
					}
					discountList.push(entry);
				}
				if (response.data.next !== null) {
					let page_num = Math.ceil(response.data.count / 50);
					await getAllDiscountData(
						page_num,
						start_date,
						end_date,
						db_year,
						discountList
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const validateHarmonicDate = () => {
		let startDate = harmonicStartDate;
		let endDate = harmonicEndDate;

		if (new Date(startDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			return false;
		}

		if ((new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24 > 30) {
			toast.warn(
				"you cannot set a start date that is 30 days earlier than the end date"
			);
			return false;
		}

		if (new Date(startDate) < new Date(minStartDate)) {
			toast.warn("the earliest date is: " + minStartDate);
			return false;
		}

		if (new Date(endDate) > new Date(maxEndDate)) {
			toast.warn("the latest date is: " + maxEndDate);
			return false;
		}

		return true;
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	const handleTickerSelection = (newTicker) => {
		let newDomains = uniqueDomains
			.filter((domain) => {
				return (
					domain.ticker === newTicker && domain.parent_domain === domain.domain
				);
			})
			.sort();
		let newDomain = newDomains[0];

		setSelectedTicker(newTicker);
		setSelectedTickerDomains(newDomains);
		setSelectedDomain(newDomain);

		let currentCategories = (
			allDomainCategories.get(newDomain.name) || [""]
		).sort();
		let currCate = currentCategories.filter((c) => c.includes("promotional"));
		if (currCate.length === 0) {
			currCate = currentCategories[0];
		} else {
			currCate = currCate[0];
			currentCategories.splice(
				0,
				0,
				currentCategories.splice(currentCategories.indexOf(currCate), 1)[0]
			);
		}

		setDomainCategories(currentCategories || [""]);
		setSelectedCategory(currCate);
	};

	const handleDomainSelection = (newDomain) => {
		setSelectedDomain(newDomain);

		let currentCategories = (
			allDomainCategories.get(newDomain.name) || [""]
		).sort();
		let currCate = currentCategories.filter((c) => c.includes("promotional"));
		if (currCate.length === 0) {
			currCate = currentCategories[0];
		} else {
			currCate = currCate[0];
			currentCategories.splice(
				0,
				0,
				currentCategories.splice(currentCategories.indexOf(currCate), 1)[0]
			);
		}

		setDomainCategories(currentCategories || [""]);
		setSelectedCategory(currCate);
	};

	const renderDownloadBtn = (imgDownload, csvDownload) => {
		return (
			<Dropdown className="cloud-download-btn">
				<Dropdown.Toggle style={{ width: "100%" }} variant="outline-primary">
					<i className="dripicons-cloud-download"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ width: "100%" }}>
					<Dropdown.Item onClick={imgDownload}>as Image</Dropdown.Item>
					<Dropdown.Item onClick={csvDownload}>as CSV</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const imgDownload = () => {
		if (discountChartRef !== null) {
			chartJSGraphDownload(
				discountChartRef.current.chartInstance,
				selectedTicker +
					" " +
					selectedDomain.domain +
					" " +
					selectedCategory +
					" Harmonic Discount Chart.png"
			);
		}
	};

	const csvDownload = () => {
		const cols = [
			{ data: "end_date", name: "Date" },
			{ data: "effective_discount", name: "Effective discount" },
			{ data: "max_discount", name: "Max Discount" },
			{ data: "adjusted_total_volume", name: "Total Volume" },
			{ data: "adjusted_discount_vol", name: "Discount Volume" },
			{
				data: "adjusted_harmonic_is_significant_total_volume",
				name: "Significant Discounts",
			},
			{
				data: "adjusted_harmonic_is_steep_total_volume",
				name: "Steep Discounts",
			},
			{
				data: "adjusted_harmonic_is_storewide_total_volume",
				name: "Storewide Discounts",
			},
			{ data: "adjusted_badwords_total_volume", name: "Desperation Discounts" },
		];

		const orderFields = (item, orderArray) => {
			let orderedItem = {};
			orderArray.forEach((fieldName) => {
				if (item.hasOwnProperty(fieldName)) {
					orderedItem[fieldName] = item[fieldName];
				}
			});
			return orderedItem;
		};

		if (discountChartRef !== null) {
			let csvData = discountChartData
				.map((row) =>
					orderFields(
						row,
						cols.map((f) => f.data)
					)
				)
				.map((row) => Object.values(row).join(","))
				.join("\n");
			csvData = cols.map((f) => f.name).join(",") + "\n" + csvData;
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
			saveAs(
				blob,
				selectedTicker +
					" " +
					selectedDomain.domain +
					" " +
					selectedCategory +
					" Harmonic Discount.csv"
			);
		}
	};

	useEffect(() => {
		document.title = "Harmonic Discount";
	}, []);

	useEffect(() => {
		if (!loaded) return;
		const fetchData = () => {
			let res = tickerCate;
			let companies = Object.keys(res).sort();
			let domainCategories = new Map();

			for (let i = 0; i < companies.length; i++) {
				let oneCompany = res[companies[i]];
				let domains = Object.keys(oneCompany);

				for (let j = 0; j < domains.length; j++) {
					if (domainCategories.get(domains[j])) {
						domainCategories.set(domains[j], [
							...domainCategories.get(domains[j]),
							...oneCompany[domains[j]],
						]);
					} else {
						domainCategories.set(domains[j], oneCompany[domains[j]]);
					}
				}
			}
			while (companies[0] === "") {
				companies.splice(0, 1);
			}
			let cacheTicker = sessionStorage.getItem("selectedTicker");
			let cacheDomain = sessionStorage.getItem("selectedDomain");
			let initialDomains = uniqueDomains
				.filter((domain) => {
					return (
						domain.ticker === (cacheTicker ? cacheTicker : companies[0]) &&
						domain.parent_domain === domain.domain
					);
				})
				.sort();
			let initialDomain =
				Object.keys(selectedDomain).length === 0 &&
				cacheDomain &&
				cacheDomain != "{}"
					? JSON.parse(cacheDomain)
					: initialDomains[0];

			setUniqueTickers(companies);
			if (selectedTicker === "" && cacheTicker && cacheTicker !== "{}") {
				setSelectedTicker(cacheTicker);
			} else {
				setSelectedTicker(companies[0]);
			}
			setSelectedTickerDomains(initialDomains);
			setSelectedDomain(initialDomain);
			setAllDomainCategories(domainCategories);
			setDomainCategories(
				(domainCategories.get(initialDomain?.name) || [""]).sort()
			);
			let cacheCate = sessionStorage.getItem("selectedCategory");
			setSelectedCategory(
				selectedCategory === "" && cacheCate
					? cacheCate
					: domainCategories.get(initialDomain.name).sort()[0]
			);
			setInitialFetched(true);
		};
		fetchData();
	}, [loaded]);

	useEffect(() => {
		if (selectedDate === "") return;
		let displayHis = localStorage.getItem("RWdisplayAllHis") === "true";
		getDiscountTableData(displayHis);
	}, [selectedDate]);

	useEffect(() => {
		if (!sessionStorage.getItem("selectedTicker") || selectedTicker !== "") {
			sessionStorage.setItem("selectedTicker", selectedTicker);
		}
	}, [selectedTicker]);

	useEffect(() => {
		let newDomain = JSON.stringify(selectedDomain);
		if (!sessionStorage.getItem("selectedDomain") || newDomain !== "{}") {
			sessionStorage.setItem("selectedDomain", newDomain);
		}
		domainRef.current = selectedDomain.domain;
	}, [selectedDomain]);

	useEffect(() => {
		if (
			!sessionStorage.getItem("selectedCategory") ||
			selectedCategory !== ""
		) {
			sessionStorage.setItem("selectedCategory", selectedCategory);
		}
		cateRef.current = selectedCategory;
	}, [selectedCategory]);

	useEffect(() => {
		if (!selectedDomain?.domain || selectedCategory === "") {
			setDiscountChartData([]);
			return;
		}
		debouncedGetDomainData(selectedDomain.domain, selectedCategory);
	}, [selectedDomain, selectedCategory, displayUSData]);

	if (!initialFetched) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Harmonic Discount</h4>
			<MyTooltip
				content={
					"chart: v1/emailfeed/weekly-volume/batch/, table: v1/harmonic-discount/cached/weekly/list/"
				}
			></MyTooltip>
			<DomainPanel
				selectedTicker={selectedTicker}
				selectedDomain={selectedDomain}
				selectedCategory={selectedCategory}
				selectedTickerDomains={selectedTickerDomains}
				uniqueTickers={uniqueTickers}
				uniqueDomains={uniqueDomains}
				domainCategories={domainCategories}
				handleTickerSelection={handleTickerSelection}
				handleDomainSelection={handleDomainSelection}
				setSelectedCategory={setSelectedCategory}
				tempStartDate={tempStartDate}
				setTempStartDate={setTempStartDate}
				changeStartDate={changeStartDate}
				tempEndDate={tempEndDate}
				setTempEndDate={setTempEndDate}
				changeEndDate={changeEndDate}
				useDataToggle={true}
				displayUSData={displayUSData}
				onDataToggleChange={setDisplayUSData}
			/>
			<Row>
				<Col sm={12} md={12}>
					<div className="card" style={{ overflow: "scroll" }}>
						<div className="card-title"></div>
						<div className="card-body" style={{ minWidth: "800px" }}>
							<HarmonicDiscountChart
								dataFetched={dataFetched}
								isLoading={discountChartFetching}
								chartData={discountChartData}
								startDate={startDate}
								endDate={endDate}
								graphRef={discountChartRef}
								tickerName={selectedTicker}
								domainName={selectedDomain.name}
								categoryName={selectedCategory}
								setSelectedDate={setSelectedDate}
							/>
							{renderDownloadBtn(imgDownload, csvDownload)}
						</div>
					</div>
					<div className="card">
						<div className="card-title"></div>
						<div className="card-body">
							<Row>
								<Col md={5}>
									Start Date:{" "}
									<input
										name="date"
										id="harmonicStartDate"
										value={harmonicStartDate}
										onChange={(e) => setHarmonicStartDate(e.target.value)}
										type="date"
										class="form-control"
									></input>
								</Col>
								<Col md={5}>
									End Date:{" "}
									<input
										name="date"
										id="harmonicEndDate"
										value={harmonicEndDate}
										onChange={(e) => setHarmonicEndDate(e.target.value)}
										type="date"
										class="form-control"
									></input>
								</Col>
								<Col md={2}>
									<br />
									<button
										className="downloadButton"
										onClick={() =>
											getDiscountTableData(
												localStorage.getItem("RWdisplayAllHis") === "true"
											)
										}
									>
										fetch
									</button>
								</Col>
							</Row>
						</div>
					</div>
					<HarmonicDiscountTable
						dataFetching={discountTableFetching}
						startDate={harmonicStartDateValid}
						endDate={harmonicEndDateValid}
						totalData={discountTableData}
						categories={domainCategories}
						getDiscountTableData={getDiscountTableData}
						lastSundayFI={lastSundayFI}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default CampaignDiscount;
