import React, { useState, useEffect, useRef } from "react";
import { axios } from "./utils";
import { Row, Col, Container, Dropdown } from "react-bootstrap";
import {
	CustomToggle,
	CustomMenu,
} from "../ReusableComponents/TypableDropdown";
import {
	getLunaUrl,
	getNumericalHeader,
	chartJSGraphDownload,
	chartJSCSVDownload,
} from "./utils";
import { toast } from "react-toastify";
import "../staticfiles/css/email-volume.css";

import EmailTrendChart from "./EmailTrendChart/EmailTrendChart";

const EmailVolumePage = ({ uniqueDomains, tickers, tickerCate, loaded }) => {
	const [selectedTicker, setSelectedTicker] = useState("");
	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState({});

	const [allDomainCategories, setAllDomainCategories] = useState({});
	const [domainCategories, setDomainCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");

	const [chartData, setChartData] = useState([]);

	const [minStartDate, setminStartDate] = useState("");
	const [maxStartDate, setmaxStartDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [tempStartDate, setTempStartDate] = useState("");

	const [minEndDate, setminEndDate] = useState("");
	const [maxEndDate, setmaxEndDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [tempEndDate, setTempEndDate] = useState("");

	const [dataFetched, setDataFetched] = useState(false);
	const [initialFetched, setInitialFetched] = useState(false);

	const [displayedDates, setDisplayedDates] = useState([]);
	const [totalData, setTotalData] = useState([]);
	const [deletedData, setDeletedData] = useState([]);
	const [readData, setReadData] = useState([]);

	const graphRef = useRef(null);

	const getDomainData = async (domain, category) => {
		if (domain === undefined || domain === null) {
			return;
		}
		if (category === "") {
			return;
		}

		await axios
			.get(
				getLunaUrl(
					`v1/emailfeed/weekly-volume/batch/?domain=${domain}&category=${category}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				if (response.data.length === 0) {
					toast.warn(
						"We currently don't have information for this domain and category combination"
					);
					return;
				}

				let tempminStartDate = new Date(response.data[0].start_date);
				let tempminStartDateIndex = 0;

				let tempmaxStartDate = new Date(response.data[0].start_date);
				let tempmaxStartDateIndex = 0;

				let tempminEndDate = new Date(response.data[0].end_date);
				let tempminEndDateIndex = 0;

				let tempmaxEndDate = new Date(response.data[0].end_date);
				let tempmaxEndDateIndex = 0;

				for (let i = 0; i < response.data.length; i++) {
					let newDate = new Date(response.data[i].start_date);
					if (tempminStartDate > newDate) {
						tempminStartDate = newDate;
						tempminStartDateIndex = i;
					}
					if (tempmaxStartDate < newDate) {
						tempmaxStartDate = newDate;
						tempmaxStartDateIndex = i;
					}

					let newEnd = new Date(response.data[i].end_date);
					if (tempminEndDate > newEnd) {
						tempminEndDate = newEnd;
						tempminEndDateIndex = i;
					}
					if (tempmaxEndDate < newEnd) {
						tempmaxEndDate = newEnd;
						tempmaxEndDateIndex = i;
					}
				}

				let newStartDate = new Date(
					response.data[tempmaxEndDateIndex].end_date
				);
				var year = newStartDate.getFullYear();
				var month = newStartDate.getMonth();
				var day = newStartDate.getDate();
				var c = new Date(year - 2, month, day);

				month = "" + (c.getMonth() + 1);
				day = "" + c.getDate();
				year = c.getFullYear();

				if (month.length < 2) month = "0" + month;
				if (day.length < 2) day = "0" + day;

				newStartDate = [year, month, day].join("-");

				setminStartDate(response.data[tempminStartDateIndex].start_date);
				setmaxStartDate(response.data[tempmaxStartDateIndex].start_date);

				if (tempStartDate === "") {
					setStartDate(newStartDate);
					setTempStartDate(newStartDate);
				}

				setminEndDate(response.data[tempminEndDateIndex].end_date);
				setmaxEndDate(response.data[tempmaxEndDateIndex].end_date);

				setEndDate(response.data[tempmaxEndDateIndex].end_date);
				setTempEndDate(response.data[tempmaxEndDateIndex].end_date);

				setChartData(response.data);
				setDataFetched(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const changeStartDate = () => {
		let newDate = tempStartDate;

		if (new Date(newDate) > new Date(endDate)) {
			toast.warn("you cannot set a start date that is later than the end date");
			setTempStartDate(startDate);
			return;
		}

		if (new Date(newDate) < new Date(minStartDate)) {
			toast.warn("the earlies start date is: " + minStartDate);
			setTempStartDate(minStartDate);
			setStartDate(minStartDate);
			return;
		}

		if (new Date(newDate) > new Date(maxStartDate)) {
			toast.warn("the latest start date is: " + maxStartDate);
			setTempStartDate(maxStartDate);
			setStartDate(maxStartDate);
			return;
		}

		setStartDate(newDate);
	};

	const changeEndDate = () => {
		let newDate = tempEndDate;

		if (new Date(newDate) < new Date(startDate)) {
			toast.warn(
				"you cannot set an end date that is earlier than the start date"
			);
			setTempEndDate(endDate);
			return;
		}

		if (new Date(newDate) < new Date(minEndDate)) {
			toast.warn("the earlies end date is: " + minEndDate);
			setEndDate(minEndDate);
			setTempEndDate(minEndDate);
			return;
		}

		if (new Date(newDate) > new Date(maxEndDate)) {
			toast.warn("the latest end date is: " + maxEndDate);
			setEndDate(maxEndDate);
			setTempEndDate(maxEndDate);
			return;
		}

		setEndDate(newDate);
	};

	const handleTickerSelection = (newTicker) => {
		let newDomains = uniqueDomains
			.filter((domain) => {
				return domain.ticker === newTicker;
			})
			.sort();
		let newDomain = newDomains[0];

		setSelectedTicker(newTicker);
		setSelectedTickerDomains(newDomains);
		setSelectedDomain(newDomain);
		setDomainCategories(allDomainCategories.get(newDomain.name).sort());
		setSelectedCategory(allDomainCategories.get(newDomain.name).sort()[0]);
	};

	const handleDomainSelection = (newDomain) => {
		setSelectedDomain(newDomain);

		let newCategories = allDomainCategories.get(newDomain.name).sort();
		setDomainCategories(newCategories);
		setSelectedCategory(newCategories[0]);
	};

	const handleGraphDownload = () => {
		if (graphRef !== null) {
			chartJSGraphDownload(
				graphRef.current.chartInstance,
				"Email_Volume_Weekly.png"
			);
		}
	};

	const handleCSVDownload = () => {
		let data = [
			["Dates", ...displayedDates],
			["Total Volume", ...totalData],
			["Read Volume", ...readData],
			["Deleted Volume", ...deletedData],
		];

		chartJSCSVDownload(data, "Email_Volume_Weekly.csv");
	};

	useEffect(() => {
		document.title = "Email Volume Trends";
	}, []);

	useEffect(() => {
		if (!loaded) return;
		const fetchData = async () => {
			let res = tickerCate;
			let companies = Object.keys(res).sort();
			let domainCategories = new Map();

			for (let i = 0; i < companies.length; i++) {
				let oneCompany = res[companies[i]];
				let domains = Object.keys(oneCompany);

				for (let j = 0; j < domains.length; j++) {
					if (domainCategories.get(domains[j])) {
						domainCategories.set(domains[j], [
							...domainCategories.get(domains[j]),
							...oneCompany[domains[j]],
						]);
					} else {
						domainCategories.set(domains[j], oneCompany[domains[j]]);
					}
				}
			}

			let initialDomains = uniqueDomains
				.filter((domain) => {
					return domain.ticker === companies[0];
				})
				.sort();
			let initialDomain = initialDomains[0];

			setSelectedTicker(companies[0]);
			setSelectedTickerDomains(initialDomains);
			setSelectedDomain(initialDomain);
			setAllDomainCategories(domainCategories);
			setDomainCategories(domainCategories.get(initialDomain.name).sort());
			setSelectedCategory(domainCategories.get(initialDomain.name).sort()[0]);
			setInitialFetched(true);
		};
		fetchData();
	}, [loaded]);

	useEffect(() => {
		getDomainData(selectedDomain.domain, selectedCategory);
	}, [selectedDomain, selectedCategory]);

	if (!initialFetched) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="page-title">Promotional Email Volume Trends (Weekly)</h4>

			<Row>
				<Col sm={12} md={3}>
					<div className="card">
						<div className="card-title"></div>
						<div className="card-body">
							<Row>
								<Col sm={12} className="task-dropdown-title">
									Ticker:{" "}
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12}>
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{selectedTicker}
										</Dropdown.Toggle>

										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{tickers.map((ticker) => {
												return (
													<Dropdown.Item
														key={ticker}
														eventKey={ticker}
														onClick={() => handleTickerSelection(ticker)}
													>
														{ticker}
													</Dropdown.Item>
												);
											})}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12} className="task-dropdown-title">
									Domain:{" "}
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12}>
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
											className="task-dropdown-selected"
										>
											{selectedDomain.domain}
										</Dropdown.Toggle>

										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{selectedTicker && selectedTickerDomains
												? selectedTickerDomains.map((domain) => {
														return (
															<Dropdown.Item
																key={domain.domain}
																eventKey={domain.domain}
																onClick={() => handleDomainSelection(domain)}
															>
																{domain.domain}
															</Dropdown.Item>
														);
												  })
												: uniqueDomains.map((domain) => {
														return (
															<Dropdown.Item
																key={domain.domain}
																eventKey={domain.domain}
																onClick={() => handleDomainSelection(domain)}
															>
																{domain.domain}
															</Dropdown.Item>
														);
												  })}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12} className="task-dropdown-title">
									Category:{" "}
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12}>
									<Dropdown>
										<Dropdown.Toggle
											variant="outline-dark"
											id="dropdown-basic"
											as={CustomToggle}
										>
											{selectedCategory}
										</Dropdown.Toggle>

										<Dropdown.Menu as={CustomMenu} className="task-dropdown">
											{domainCategories.map((category) => {
												return (
													<Dropdown.Item
														key={category}
														eventKey={category}
														onClick={() => setSelectedCategory(category)}
													>
														{category}
													</Dropdown.Item>
												);
											})}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12} className="task-dropdown-title">
									Start Date:{" "}
								</Col>
							</Row>
							<br />
							<Row>
								<Col sm={12}>
									<input
										name="date"
										id="exampleDate"
										value={tempStartDate}
										onChange={(e) => setTempStartDate(e.target.value)}
										onBlur={changeStartDate}
										type="date"
										class="form-control"
									></input>
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12} className="task-dropdown-title">
									End Date:
								</Col>
							</Row>
							<br />
							<Row>
								<Col sm={12}>
									<input
										name="date"
										id="exampleDate"
										value={tempEndDate}
										onChange={(e) => setTempEndDate(e.target.value)}
										onBlur={changeEndDate}
										type="date"
										class="form-control"
									></input>
								</Col>
							</Row>
							<br />

							<Row>
								<Col sm={12} className="task-dropdown-title">
									Download Graph:
								</Col>
							</Row>
							<br />
							<Row>
								<Col sm={12}>
									<Dropdown style={{ width: "100%" }}>
										<Dropdown.Toggle style={{ width: "100%" }}>
											Download
										</Dropdown.Toggle>

										<Dropdown.Menu style={{ width: "100%" }}>
											<Dropdown.Item onClick={handleGraphDownload}>
												as Image
											</Dropdown.Item>

											<Dropdown.Item onClick={handleCSVDownload}>
												as CSV
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
						</div>
					</div>
				</Col>

				<Col sm={12} md={9}>
					<div className="card">
						<div className="card-title"></div>
						<div className="card-body">
							<Row>
								<EmailTrendChart
									dataFetched={dataFetched}
									chartData={chartData}
									startDate={startDate}
									endDate={endDate}
									graphRef={graphRef}
									displayedDates={displayedDates}
									setDisplayedDates={setDisplayedDates}
									totalData={totalData}
									setTotalData={setTotalData}
									readData={readData}
									setReadData={setReadData}
									deletedData={deletedData}
									setDeletedData={setDeletedData}
									tickerName={selectedTicker}
									domainName={selectedDomain.name}
									categoryName={selectedCategory}
								/>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default EmailVolumePage;
