import React, { useEffect, useState } from "react";
import { CustomToggle, CustomMenu } from "./TypableDropdown";
import { Dropdown, Button, Form } from "react-bootstrap";
import { axios, getLunaUrl, getNumericalHeader } from "../components/utils";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

export default function DomainPanel({
	selectedTicker,
	selectedDomain,
	selectedCategory,
	selectedTickerDomains,
	uniqueTickers,
	uniqueDomains,
	domainCategories,
	handleTickerSelection,
	handleDomainSelection,
	setSelectedCategory,
	tempStartDate,
	setTempStartDate,
	changeStartDate,
	tempEndDate,
	setTempEndDate,
	changeEndDate,
	useDataToggle = false,
	displayUSData = false,
	onDataToggleChange,
}) {
	const getEnsembleIndexData = async () => {
		toast.success("Start Downloading!");
		let total = [];
		let flag = false;
		await axios
			.get(
				getLunaUrl(
					`v1/email-index/query/smoothed/?domain=${selectedDomain.domain}&category=${selectedCategory}`
				),
				getNumericalHeader()
			)
			.then((response) => {
				let data = response.data;
				for (let i = 1; i < data.length; ++i) {
					total.push({
						Ticker: data[i].ticker,
						Domain: data[i].domain,
						StartDate: data[i].start_date,
						EndDate: data[i].end_date,
						PromotionalVolumeIndex: data[i].adjusted_total_volume,
						DiscountVolumeIndex: data[i].adjusted_is_significant_total_volume,
						NotionalDiscountVolumeIndex:
							data[i].adjusted_harmonic_state_discount_total_volume,
						ZeroPercentVolumeIndex: data[i].adjusted_zero_discount_volume,
						AverageDiscountVolumeIndex: data[i].adjusted_discount_vol,
						SteepDiscountVolumeIndex: data[i].adjusted_is_steep_total_volume,
						EffectiveDiscountIndex: data[i].effective_discount,
						"20PercentileDiscountIndex": data[i].discount_20_percentile,
						"80PercentileDiscountIndex": data[i].discount_80_percentile,
						MaxDiscountIndex: data[i].max_discount,
						CompositePromotionalIndex: data[i].final_index,
					});
				}
			})
			.catch((error) => {
				if (error.response.status == 404) {
					toast.error("No Data for current domain!");
					flag = true;
				}
			});
		if (flag) return;
		let csvData = total.map((row) => Object.values(row).join(",")).join("\n");
		csvData = Object.keys(total[0]).join(",") + "\n" + csvData;
		const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
		saveAs(blob, "Promotional-Ensemble-Indices.csv");
	};

	useEffect(() => {
		if (useDataToggle) {
			onDataToggleChange(displayUSData);
		}
	}, [displayUSData]);

	return (
		<div className="card">
			<div className="card-body grid-wrapper">
				<div className="task-dropdown-title">
					Ticker: {selectedDomain.is_deprecated ? (<span style={{color: "red"}}>Deprecated</span> ): ("")}
					<Dropdown>
						<Dropdown.Toggle
							variant="outline-dark"
							id="dropdown-basic"
							as={CustomToggle}
							className="task-dropdown-selected"
						>
							{selectedTicker}
						</Dropdown.Toggle>

						<Dropdown.Menu as={CustomMenu} className="task-dropdown">
							{uniqueTickers.map((ticker) => {
								return (
									<Dropdown.Item
										key={ticker}
										eventKey={ticker}
										onClick={() => handleTickerSelection(ticker)}
									>
										{ticker}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="task-dropdown-title">
					Domain:
					<Dropdown>
						<Dropdown.Toggle
							variant="outline-dark"
							id="dropdown-basic"
							as={CustomToggle}
							className="task-dropdown-selected"
						>
							{selectedDomain.domain}
						</Dropdown.Toggle>

						<Dropdown.Menu as={CustomMenu} className="task-dropdown">
							{selectedTicker && selectedTickerDomains
								? selectedTickerDomains.map((domain) => {
										return (
											<Dropdown.Item
												key={domain.domain}
												eventKey={domain.domain}
												onClick={() => handleDomainSelection(domain)}
											>
												{domain.domain}
											</Dropdown.Item>
										);
								  })
								: uniqueDomains.map((domain) => {
										return (
											<Dropdown.Item
												key={domain.domain}
												eventKey={domain.domain}
												onClick={() => handleDomainSelection(domain)}
											>
												{domain.domain}
											</Dropdown.Item>
										);
								  })}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="task-dropdown-title">
					Category:
					<Dropdown>
						<Dropdown.Toggle
							variant="outline-dark"
							id="dropdown-basic"
							as={CustomToggle}
						>
							{selectedCategory}
						</Dropdown.Toggle>

						<Dropdown.Menu as={CustomMenu} className="task-dropdown">
							{domainCategories.map((category) => {
								return (
									<Dropdown.Item
										key={category}
										eventKey={category}
										onClick={() => setSelectedCategory(category)}
									>
										{category}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="task-dropdown-title">
					Start Date:
					<input
						name="date"
						id="startDateSelect"
						value={tempStartDate}
						onChange={(e) => setTempStartDate(e.target.value)}
						onBlur={changeStartDate}
						type="date"
						class="form-control"
					/>
				</div>
				<div className="task-dropdown-title">
					End Date:
					<input
						name="date"
						id="endDateSelect"
						value={tempEndDate}
						onChange={(e) => setTempEndDate(e.target.value)}
						onBlur={changeEndDate}
						type="date"
						class="form-control"
					/>
				</div>
				<div className="task-dropdown-title">
					Promotional Ensemble Index: <br />
					<Button style={{ width: "80%" }} onClick={getEnsembleIndexData}>
						Download
						{/* <Dropdown.Menu>
							{handleGraphDownload && (
								<Dropdown.Item onClick={handleGraphDownload}>
									as Image
								</Dropdown.Item>
							)}

							{handleCSVDownload && (
								<Dropdown.Item onClick={handleCSVDownload}>
									as CSV
								</Dropdown.Item>
							)}
						</Dropdown.Menu> */}
					</Button>
				</div>
				{useDataToggle ? (
					<div className="task-dropdown-title">
						<Form.Check
							type="switch"
							id="us-switch"
							style={{ transform: "scale(1.25)", marginLeft: "30px" }}
							label={displayUSData ? <div>US</div> : <div>Global</div>}
							checked={displayUSData}
							onChange={(e) => onDataToggleChange(e.target.checked)}
						/>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
}
