import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { getAirstreamUrl, getAirstreamHeader, colors, axios } from "../utils";

const ResultChart = ({ selectedTask }) => {
	const [times, setTimes] = useState([]);
	const [results, setResults] = useState([]);
	const [displayData, setDisplayData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			await axios
				.get(
					getAirstreamUrl(
						`scheduler/execution/report-history/?key=${selectedTask.key}&start=0&end=10`
					),
					getAirstreamHeader()
				)
				.then((response) => {
					let tempTimes = [];
					response.data.sort((a, b) => a.task_id - b.task_id);
					let d = response.data;
					let stats = [];
					for (let i = 0; i < d.length; i++) {
						if (!d[i].results || !d[i].results.result) {
							continue;
						}
						tempTimes.unshift(d[i].start_time);
						let data = {};
						for (const k in d[i].results.result) {
							let val = d[i].results.result[k];
							if (typeof val === "boolean") {
								val = val ? 1 : 0;
							}
							data[k] = val;
						}
						stats.unshift(data);
					}
					setTimes(tempTimes);
					setResults(stats);
					let displayData = {};
					for (const k in stats[0]) {
						let tmp = [];
						for (let res of stats) {
							tmp.push(res[k]);
						}
						displayData[k] = tmp;
					}
					setDisplayData(displayData);
					setLoading(true);
				})
				.catch((err) => {
					alert(err.message);
				})
				.finally(() => {
					setLoading(false);
				});
		};
		fetchData();
	}, [selectedTask]);

	if (loading) return <h4>Loading...</h4>;
	return (
		<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
			{results.length > 0 &&
				Object.keys(displayData).map((key, ind) => (
					<div key={ind}>
						<Line
							className="history-container"
							height={300}
							data={{
								labels: times,
								datasets: [
									{
										data: displayData[key],
										label: key,
										borderColor: colors[ind % colors.length],
										steppedLine: true,
									},
								],
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								scales: {
									xAxes: [
										{
											gridLines: {
												display: false,
											},
											type: "time",
											ticks: {
												source: "labels",
												minRotation: 30,
											},
										},
									],
									yAxes: [
										{
											id: "A",
											position: "left",
											ticks: {
												callback: function (value, index, values) {
													if (value > 1000000000) {
														return value / 1000000000 + "B";
													} else if (value > 1000000) {
														return value / 1000000 + "M";
													} else if (value > 1000) {
														return value / 1000 + "K";
													} else {
														return value;
													}
												},
											},
											gridLines: {
												borderDash: [8, 4],
											},
										},
									],
								},
							}}
						/>
					</div>
				))}
		</div>
	);
};

export default ResultChart;
