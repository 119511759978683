import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import { getAirstreamHeader, getAirstreamUrl, axios } from "../utils";
import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import DagChart from "../TaskDashboard/DagChart";
import SpecificTask from "../TaskDashboard/SpecificTask";
import PieChart from "../TaskDashboard/PieChart";

export default function AirstreamJob({ uniqueDomains }) {
	const execution_key = "execute_new_datasource_HIDDEN"; // fixed for Ed Scraper

	const [dagKey, setDagKey] = useState(0);
	const [graphList, setGraphList] = useState([]); // all unique graphs
	const [graphName, setGraphName] = useState("");
	const [graphId, setGraphId] = useState(0);
	const [selectedGraph, setSelectedGraph] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [displayParent, setDisplayParent] = useState(false);
	const [parentGraph, setParentGraph] = useState(null);

	// all available times in date form
	const [availableDates, setAvailableDates] = useState([]);
	// all available times in string form
	const [availableTimeList, setAvailableTimeList] = useState([]);
	// current time string list in the dropdown menu
	const [selectedTimeList, setSelectedTimeList] = useState([]);
	// selected time in date form
	const [selectedDate, setSelectedDate] = useState(null);
	// selected time in string form
	const [selectedTime, setSelectedTime] = useState("");

	// for highlighting in calendar ⬇️
	const [failedDates, setFailedDates] = useState([]);
	const [finishedDates, setFinishedDates] = useState([]);
	const [runningDates, setRunningDates] = useState([]);

	// charts related ⬇️
	const [tasks, setTasks] = useState([]);
	const [edges, setEdges] = useState([]);
	const [selectExecution, setSelectExecution] = useState(false);
	const [graphExecuting, setGraphExecuting] = useState(false);
	const [selectExecutionList, setSelectExecutionList] = useState([]);
	const [isNodeClicked, setIsNodeClicked] = useState(false);
	const [selectedNode, setSelectedNode] = useState(null);
	const [selectedTask, setSelectedTask] = useState(null);
	const [selectedResult, setSelectedResult] = useState({});
	const [selectedResultKey, setSelectedResultKey] = useState(null);
	const [graphStatus, setGraphStatus] = useState(-1);
	const [executor, setExecutor] = useState("");
	const [note, setNote] = useState("");
	const [domains, setDomains] = useState("");

	// modal related
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showExecuteModal, setShowExecuteModal] = useState(false);
	const [executeNote, setExecuteNote] = useState("");
	const [executeDomains, setExecuteDomains] = useState("");
	const [executeIds, setExecuteIds] = useState("");

	// fetch required data from apis
	const getDagData = async () => {
		let curdagKey = "";
		let curSelectedTime = "";

		setIsLoading(true);
		await axios
			.get(
				getAirstreamUrl(`scheduler/dag/list/?key=${execution_key}`),
				getAirstreamHeader()
			)
			.then((res) => {
				let data = res.data.results;
				let id = data.length > 0 ? data[0].graph_id : 0;
				setDagKey(id);
				curdagKey = id;
			})
			.catch((err) => {
				console.log(err);
			});

		await axios
			.get(getAirstreamUrl(`scheduler/dag/${curdagKey}/`), getAirstreamHeader())
			.then((res) => {
				// use 'substring(0,16)' on date string to ignore timezone
				setSelectedDate(
					moment(res.data.last_launched.substring(0, 16)).toDate()
				);
				setParentGraph(res.data);
				curSelectedTime = res.data.last_launched.substring(0, 16);
			})
			.catch((err) => {
				console.log(err);
			});

		await axios
			.get(
				getAirstreamUrl(`scheduler/execution/list/?dag_id=${curdagKey}`),
				getAirstreamHeader()
			)
			.then((res) => {
				let data = res.data.results;
				setGraphList([...data]);
				setAvailableDates(
					data.map((el) => moment(el.launch_time.substring(0, 16)).toDate())
				);
				let timelist = data.map((el) => ({
					launch_time: el.launch_time.substring(0, 16),
					graph_id: el.graph_id,
				}));
				setAvailableTimeList(timelist);
				let curDate = curSelectedTime.substring(0, 10);
				setSelectedTimeList(
					timelist.filter(
						(time) => time.launch_time.substring(0, 10) === curDate
					)
				);
				handleSelectTime(curSelectedTime, 0, data);
				identifyDateType(data);
			})
			.catch((err) => console.log(err));
	};

	// identify different highlights for calendar
	const identifyDateType = (data) => {
		let failed = data
			.filter((e) => e.status == 3)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setFailedDates(failed);
		let finished = data
			.filter((e) => e.status == 2)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setFinishedDates(finished);
		let running = data
			.filter((e) => e.status == 1)
			.map((e) => moment(e.launch_time.substring(0, 16)).toDate());
		setRunningDates(running);
	};

	// when select time on calendar
	const handleSelectCalendar = (date) => {
		let timelist = availableTimeList.filter(
			(time) =>
				moment(time.launch_time).format().substring(0, 10) ===
				moment(date).format().substring(0, 10)
		);
		setSelectedTimeList(timelist);
		setSelectedDate(moment(timelist[0].launch_time).toDate());
		handleSelectTime(timelist[0].launch_time, timelist[0].graph_id);
		setDisplayParent(false);
	};

	// when select time in dropdown list
	const handleSelectTime = (time, id, list) => {
		setSelectedTime(time);
		list = list ?? graphList;
		let matchedGraphs = id
			? list.filter(
					(g) => g.launch_time.substring(0, 16) == time && g.graph_id == id
			  )
			: list.filter((g) => g.launch_time.substring(0, 16) == time);
		let curGraph = matchedGraphs[0];
		setGraphId(curGraph.graph_id);
		setSelectedGraph(curGraph);
		setDisplayParent(false);
		resetGraph();
	};

	// reset various status when switching graph
	const resetGraph = () => {
		setIsNodeClicked(false);
		setSelectedNode(null);
		setSelectedTask(null);
		setSelectedResultKey(null);
		setSelectExecutionList([]);
		setSelectExecution(false);
	};

	// to kill current task
	const cancelGraph = async (graphId) => {
		await axios
			.post(
				getAirstreamUrl(`scheduler/execution/${graphId}/cancel/`),
				{},
				getAirstreamHeader()
			)
			.then((res) => {
				toast.success("Task Killed!");
			})
			.catch((err) => console.log(err));

		setShowCancelModal(false);
	};

	// data for pie chart
	const getPieGraphData = () => {
		if (!selectedGraph)
			return [
				{ label: "Finished", value: 0 },
				{ label: "Failed", value: 0 },
				{ label: "Running", value: 0 },
				{ label: "Scheduled", value: 0 },
			];
		return [
			{
				label: "Finished",
				value: selectedGraph.success_count,
			},
			{ label: "Failed", value: selectedGraph.fail_count },
			{
				label: "Running",
				value: selectedGraph.running_count,
			},
			{
				label: "Scheduled",
				value:
					selectedGraph.task_count -
					selectedGraph.success_count -
					selectedGraph.running_count -
					selectedGraph.fail_count,
			},
		];
	};

	const executeTask = () => {
		setShowExecuteModal(true);
	};

	const parseIds = (ids) => {
		try {
			const lst = JSON.parse(ids);
			if (lst.length > 0) {
				return lst;
			}
		} catch (e) {}
		return null;
	};

	const executeGraph = async () => {
		if (graphList[0].status == 1) {
			toast.warn("The last job is still running!");
			return;
		}
		setGraphExecuting(true);
		await axios
			.post(
				getAirstreamUrl("scheduler/execution/key/"),
				{
					key: execution_key,
					partial_ids: parseIds(executeIds),
					kwargs: { domains: executeDomains },
					note: executeNote,
				},
				getAirstreamHeader()
			)
			.then((res) => {
				toast.success("Executed!");
			})
			.catch((err) => console.log(err));

		getDagData();
		setGraphExecuting(false);
		setShowExecuteModal(false);
	};

	// when click 'new task'
	const handleSelectParent = () => {
		setGraphId(parentGraph.graph_id);
		setSelectedGraph(parentGraph);
		setIsNodeClicked(false);
		setDisplayParent(true);
		setSelectedNode(null);
		setSelectedTask(null);

		setSelectExecutionList([]);
		setSelectExecution(false);
	};

	const executeSelectedTasks = () => {
		setExecuteIds(JSON.stringify(selectExecutionList[0]));
		setShowExecuteModal(true);
	};

	// functions for rendering ⬇️

	const renderCancelModal = () => {
		return (
			<Modal
				className="dag-modal"
				show={showCancelModal}
				onHide={() => setShowCancelModal(false)}
			>
				<Row>
					<h3>Are you sure you want to kill this task flow?</h3>
				</Row>

				<Row className="modal-btns">
					<button
						className="btn btn-primary"
						onClick={() => cancelGraph(graphId)}
					>
						Yes
					</button>
					<button
						className="btn btn-secondary"
						onClick={() => setShowCancelModal(false)}
					>
						No
					</button>
				</Row>
			</Modal>
		);
	};

	const renderExecuteModal = () => {
		return (
			<Modal
				className="dag-modal"
				show={showExecuteModal}
				onHide={() => setShowExecuteModal(false)}
			>
				<Row>
					<h3>Are you sure you want to execute this task flow?</h3>
				</Row>
				<Row>Expected keys: domains</Row>
				<Row>
					<span>Domains</span>
					<textarea
						type="text"
						value={executeDomains}
						readOnly
						className="execute-modal-input"
					/>
				</Row>
				<Row>
					<span>Partial Ids</span>
					<input
						type="text"
						value={executeIds}
						onChange={(e) => setExecuteIds(e.target.value)}
						style={{ width: "80%", marginLeft: 23 }}
					/>
				</Row>
				<Row>
					<span>Purpose/Note</span>
					<textarea
						type="text"
						value={executeNote}
						placeholder="SYSTEM"
						onChange={(e) => setExecuteNote(e.target.value)}
						className="execute-modal-input"
					/>
				</Row>
				<Row>
					Will execute following tasks:{" "}
					{parseIds(executeIds) ? JSON.stringify(parseIds(executeIds)) : "ALL"}
				</Row>
				<Row className="modal-btns">
					<button
						className="btn btn-primary"
						disabled={!executeNote || graphExecuting}
						onClick={executeGraph}
					>
						Confirm
					</button>
					<button
						className="btn btn-secondary"
						onClick={() => setShowExecuteModal(false)}
					>
						Cancel
					</button>
				</Row>
			</Modal>
		);
	};

	const renderTimeListDropdown = () => {
		return (
			<Dropdown>
				<Dropdown.Toggle
					variant="outline-dark"
					id="dropdown-basic"
					as={CustomToggle}
					className="task-dropdown-selected"
				>
					{displayParent ? "new task" : selectedTime}
				</Dropdown.Toggle>

				<Dropdown.Menu as={CustomMenu} className="task-dropdown">
					<Dropdown.Item onClick={handleSelectParent}>New task</Dropdown.Item>
					{selectedTimeList.map((time, index) => {
						return (
							<Dropdown.Item
								key={index}
								eventKey={index}
								onClick={() =>
									handleSelectTime(time.launch_time, time.graph_id)
								}
							>
								{time.launch_time}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const renderStatusOverview = () => {
		return (
			<div>
				<div>
					<button className="btn btn-primary" onClick={executeTask}>
						Execute Task
					</button>
					<br />
					<br />
					<button
						className="btn btn-outline-dark"
						onClick={() => setShowCancelModal(true)}
					>
						Kill
					</button>
				</div>
				<br />
				<h2>
					Status:
					{graphStatus === 0
						? "Scheduled"
						: graphStatus === 1
						? "Running"
						: graphStatus === 2
						? "Completed"
						: "Failed"}
				</h2>
				<br />

				<div>
					Executor: {executor} <br />
					<br />
					Note: {note} <br />
					<br />
					Domains: {domains} <br />
					<br />
					Graph ID: {graphId}
				</div>

				<div className="task-svg-wrapper">
					<PieChart
						data={getPieGraphData()}
						outerRadius={150}
						innerRadius={90}
					></PieChart>
				</div>
			</div>
		);
	};

	const renderParentExecutionPanel = () => {
		return (
			<div>
				<div className="execution-header">
					<h2>Execution Key: {selectedGraph.execution_key}</h2>
				</div>
				<div>
					{selectedGraph.execution_key ? (
						<div>
							<Row>
								<Col>
									<button
										className="btn btn-outline-dark"
										onClick={() => setShowExecuteModal(true)}
									>
										Execute
									</button>
								</Col>
							</Row>

							<Row>
								<Col>
									<h2>Or</h2>
								</Col>
							</Row>

							<Row>
								<Col>
									<button
										className="btn btn-outline-dark"
										onClick={() => setSelectExecution(true)}
									>
										Select Tasks and Execute
									</button>
								</Col>
							</Row>

							{selectExecution && (
								<div>
									<Row className="execution-list">
										<Col>
											<h4>
												You select tasks with ids:{" "}
												{selectExecutionList.toString()}
											</h4>
										</Col>
									</Row>

									<Row>
										<Col>
											<button
												className="btn btn-primary"
												onClick={executeSelectedTasks}
											>
												Execute Selected Task
											</button>
										</Col>
									</Row>

									<Row>
										<Col>
											<button
												className="btn btn-primary select-button"
												onClick={() => setSelectExecution(false)}
											>
												Cancel
											</button>
										</Col>
									</Row>
								</div>
							)}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		);
	};

	useEffect(() => {
		getDagData();
	}, []);

	useEffect(() => {
		// for domains autofill when executing task
		let str = uniqueDomains
			.slice(1)
			.reduce((prev, cur) => prev + "," + cur, uniqueDomains[0]);
		setExecuteDomains(str);
	}, [uniqueDomains]);

	useEffect(() => {
		const getExecDetails = async () => {
			setIsLoading(true);
			if (displayParent) {
				await axios
					.get(
						getAirstreamUrl(`scheduler/dag/${graphId}/`),
						getAirstreamHeader()
					)
					.then((response) => {
						setTasks(response.data.tasks);
						setEdges(response.data.edges);
						setGraphStatus(response.data.status);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				await axios
					.get(
						getAirstreamUrl(`scheduler/execution/${graphId}/`),
						getAirstreamHeader()
					)
					.then((res) => {
						setGraphName(res.data.name);
						setTasks([...res.data.tasks]);
						let tmpEdges = res.data.edges;
						tmpEdges.sort((a, b) => a.task_from - b.task_from);
						setEdges(tmpEdges);
						setExecutor(res.data.executor);
						setNote(res.data.note);
						setDomains(res.data.kwargs?.domains || "");
						setGraphStatus(res.data.status);
					})
					.catch((e) => console.log(e));
			}
			setIsLoading(false);
		};

		if (graphId) {
			getExecDetails();
		}
	}, [graphId]);

	if (isLoading) {
		return <h3>Loading Airstream Job...</h3>;
	}
	return (
		<div>
			{renderCancelModal()}
			{renderExecuteModal()}
			<Card>
				<CardBody className="airstream-container">
					<Row>
						<Col md={2}>
							<h4 className="header-title">Airstream Job</h4>
						</Col>
					</Row>
					<Row>
						{/* left component */}
						<Col md={6}>
							<h3>DAG</h3>
							<div className="btn-group task-dashboard-btn task-dropdown-wrapper">
								{/* render calendar */}
								<DatePicker
									id="emailfeed-calendar"
									onChange={(date) => handleSelectCalendar(date)}
									selected={selectedDate}
									includeDates={availableDates}
									highlightDates={[
										{ "datepicker-failed": failedDates },
										{ "datepicker-finished": finishedDates },
										{ "datepicker-running": runningDates },
									]}
									inline
								/>
								{renderTimeListDropdown()}
							</div>
							<DagChart
								tasks={tasks}
								edges={edges}
								displayParent={displayParent}
								selectExecution={selectExecution}
								selectExecutionList={selectExecutionList}
								setSelectExecutionList={setSelectExecutionList}
								graphId={graphId}
								setIsNodeClicked={setIsNodeClicked}
								setSelectedNode={setSelectedNode}
								setSelectedTask={setSelectedTask}
								setSelectedResult={setSelectedResult}
								setSelectedResultKey={setSelectedResultKey}
								setGraphStatus={setGraphStatus}
								setExecutor={setExecutor}
								setNote={setNote}
								setDomains={setDomains}
								isLoading={isLoading}
							/>
						</Col>
						{/* right component */}
						<Col md={6}>
							<h3>
								{isNodeClicked ? (
									<div>
										<h2>Task: {selectedNode}</h2>
									</div>
								) : (
									<div>
										<h2>Overview</h2>
									</div>
								)}
							</h3>
							<div>
								{isNodeClicked ? (
									<SpecificTask
										displayParent={false}
										selectedTask={selectedTask}
										selectedResult={selectedResult}
										returnOverview={setIsNodeClicked}
										graphStatus={graphStatus}
									/>
								) : displayParent ? (
									renderParentExecutionPanel()
								) : (
									renderStatusOverview()
								)}
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</div>
	);
}
