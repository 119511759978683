import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
	formatBigNum,
	chartBgc,
	chartWatermark,
	chartDataSorter,
} from "../utils";
import moment from "moment";

const DiscountStateChart = ({
	startDate,
	endDate,
	dataFetched,
	isLoading,
	chartData,
	graphRef,
	displayedDates,
	setDisplayedDates,
	setTableData,
	setStateDiscountFirstFrac,
	setStateDiscountSecFrac,
	setStateDiscountThirdFrac,
	setStateDiscountFourthFrac,
	setStateDiscountFifthFrac,
	title,
}) => {
	const [firstFraction, setFirst] = useState([]);
	const [secondFraction, setSecond] = useState([]);
	const [thirdFraction, setThird] = useState([]);
	const [fourthFraction, setFourth] = useState([]);
	const [fifthFraction, setFifth] = useState([]);

	useEffect(() => {
		const loadDisplayData = () => {
			let tempDateData = [];
			chartData.sort(chartDataSorter);

			for (let i = 0; i < chartData.length; i++) {
				if (
					new Date(chartData[i].start_date) >= new Date(startDate) &&
					new Date(chartData[i].end_date) <= new Date(endDate)
				) {
					tempDateData.push(chartData[i].week);
				}
			}
			let dates = tempDateData.filter((value, index, arr) => {
				return arr.indexOf(value) === index;
			});

			let firstFraction = [];
			let secondFraction = [];
			let thirdFraction = [];
			let fourthFraction = [];
			let fifthFraction = [];
			for (let data of chartData) {
				let cur = data.state_discount;
				let ind = dates.indexOf(data.week);
				let vol = data.total_volume;
				if (cur <= 20 && cur > 0) {
					firstFraction[ind]
						? (firstFraction[ind] += vol)
						: (firstFraction[ind] = vol);
				} else if (cur <= 50 && cur > 20) {
					secondFraction[ind]
						? (secondFraction[ind] += vol)
						: (secondFraction[ind] = vol);
				} else if (cur <= 100 && cur > 50) {
					thirdFraction[ind]
						? (thirdFraction[ind] += vol)
						: (thirdFraction[ind] = vol);
				} else if (cur <= 200 && cur > 100) {
					fourthFraction[ind]
						? (fourthFraction[ind] += vol)
						: (fourthFraction[ind] = vol);
				} else if (cur > 200) {
					fifthFraction[ind]
						? (fifthFraction[ind] += vol)
						: (fifthFraction[ind] = vol);
				} else {
					if (!firstFraction[ind]) firstFraction[ind] = 0;
					if (!secondFraction[ind]) secondFraction[ind] = 0;
					if (!thirdFraction[ind]) thirdFraction[ind] = 0;
					if (!fourthFraction[ind]) fourthFraction[ind] = 0;
					if (!fifthFraction[ind]) fifthFraction[ind] = 0;
				}
			}

			let buffer_start = moment(dates[0]).subtract(14, "d");
			let buffer_end = moment(dates[tempDateData.length - 1]).add(14, "d");
			dates.unshift(buffer_start.format("YYYY-MM-DD"));
			dates.push(buffer_end.format("YYYY-MM-DD"));

			firstFraction.push(null);
			firstFraction.unshift(null);
			secondFraction.push(null);
			secondFraction.unshift(null);
			thirdFraction.push(null);
			thirdFraction.unshift(null);
			fourthFraction.push(null);
			fourthFraction.unshift(null);
			fifthFraction.push(null);
			fifthFraction.unshift(null);

			setDisplayedDates(dates);
			setFirst(firstFraction);
			setSecond(secondFraction);
			setThird(thirdFraction);
			setFourth(fourthFraction);
			setFifth(fifthFraction);

			setStateDiscountFirstFrac(firstFraction);
			setStateDiscountSecFrac(secondFraction);
			setStateDiscountThirdFrac(thirdFraction);
			setStateDiscountFourthFrac(fourthFraction);
			setStateDiscountFifthFrac(fifthFraction);
		};
		loadDisplayData();
	}, [dataFetched, chartData, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h3>Chart Loading...</h3>;
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Line
					ref={graphRef}
					className="history-container"
					height={300}
					data={{
						labels: displayedDates,
						datasets: [
							{
								data: firstFraction,
								type: "bar",
								label: "$20 off or less",
								backgroundColor: "#d4e9b3",
							},
							{
								data: secondFraction,
								type: "bar",
								label: "$21 to $50 off",
								backgroundColor: "#92be9c",
							},
							{
								data: thirdFraction,
								type: "bar",
								label: "$51 to $100 off",
								backgroundColor: "#eeb29c",
							},
							{
								data: fourthFraction,
								type: "bar",
								label: "$101 to $200 off",
								backgroundColor: "#d58585",
							},
							{
								data: fifthFraction,
								type: "bar",
								label: "More than $200 off",
								backgroundColor: "#737373",
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "month",
									},
									stacked: true,
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									gridLines: {
										borderDash: [8, 4],
									},
									ticks: {
										callback: (value) => formatBigNum(value),
									},
									stacked: true,
								},
							],
						},
						title: {
							display: true,
							text: title,
							fontSize: 15,
						},
						onHover: function (evt, elem) {
							if (!evt || !elem[0]) {
								return;
							}
							let label = this.getElementAtEvent(evt)[0]._datasetIndex;
							let index = elem[0]._index;
							label = graphRef.current.props.data.datasets[label].label;
							// let range = [];
							// switch (label) {
							// 	case "$20 off or less":
							// 		range = [1, 20];
							// 		break;
							// 	case "$21 to $50 off":
							// 		range = [21, 50];
							// 		break;
							// 	case "$51 to $100 off":
							// 		range = [51, 100];
							// 		break;
							// 	case "$101 to $200 off":
							// 		range = [101, 200];
							// 		break;
							// 	case "More than $200 off":
							// 		range = [200, Number.MAX_SAFE_INTEGER];
							// 		break;
							// 	default:
							// 		break;
							// }
							let date = graphRef.current.props.data.labels[index];
							let selected = chartData.filter(
								(element) => element.week === date && element.state_discount > 0
							);
							selected.sort((a, b) => b.total_volume - a.total_volume);
							setTableData(selected);
						},
						tooltips: {
							mode: "index",
							callbacks: {
								label: function (tooltipItem, data) {
									let value = formatBigNum(tooltipItem.yLabel);
									let label =
										data.datasets[tooltipItem.datasetIndex].label || "";
									if (label) {
										label += ": ";
										label += value;
									}
									return label;
								},
							},
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(DiscountStateChart);
