import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Modal, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getNumericalHeader, getLunaUrl, axios } from "../utils";
import { toast } from "react-toastify";

const PendingQTable = ({
	totalData,
	getData,
	selectedName,
	selectedDomain,
	isLoading,
}) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const [nameEdit, setNameEdit] = useState("");
	const [domainEdit, setDomainEdit] = useState("");
	const [labelEdit, setLabelEdit] = useState("");
	const [sendingDomainEdit, setSendingDomainEdit] = useState("");
	const [subjectEdit, setSubjectEdit] = useState("");
	const [addrEdit, setAddrEdit] = useState("");
	const [filterEdit, setFilterEdit] = useState("");
	const [displayHist, setDisplayHist] = useState(false);

	const { SearchBar } = Search;

	const columns = [
		{
			dataField: "id",
			hidden: true,
		},
		{
			dataField: "name",
			hidden: true,
		},
		{
			dataField: "task",
			text: "Task",
			sort: true,
		},
		{
			dataField: "fshort",
			text: "Fshort",
			sort: true,
		},
		{
			dataField: "domain",
			text: "Domain",
			sort: true,
		},
		{
			dataField: "subject",
			text: "Subject",
			sort: true,
		},
		{
			dataField: "status",
			text: "Status",
			sort: true,
		},
		{
			dataField: "label",
			text: "Label",
			sort: true,
		},
		{
			dataField: "sending_domain",
			text: "Sending Domain",
			sort: true,
		},
		{
			dataField: "from_address",
			text: "From Address",
			sort: true,
		},
		{
			dataField: "filter_subject",
			text: "Filter Subject",
			sort: true,
		},
		{
			dataField: "active_sending_domain",
			text: "Active sending Domain",
			sort: true,
		},
		{
			dataField: "in_dt",
			text: "in_dt",
			sort: true,
		},
		{
			dataField: "out_dt",
			text: "out_dt",
			sort: true,
		},
		{
			dataField: "editor",
			text: "editor",
			sort: true,
		},
	];

	const handleEdit = () => {
		setNameEdit(selectedName);
		setDomainEdit(selectedDomain);
	};

	// create new feed
	const createPendingQ = async () => {
		if (!labelEdit || !sendingDomainEdit) {
			toast.warn("Label and Sending Domain must not be empty!");
			return;
		}
		let body = {
			web_domain: { name: nameEdit, domain: domainEdit },
			label: labelEdit,
			sending_domain: sendingDomainEdit,
			subject: subjectEdit,
			from_address: addrEdit,
			filter_subject: filterEdit,
		};

		if (
			!window.confirm(
				`Are you sure you want to create this pending queue?\n\nName: ${nameEdit}\nDomain: ${domainEdit}\nLabel: ${labelEdit}\nSending Domain: ${sendingDomainEdit}\nSubject: ${subjectEdit}\nFrom Address: ${addrEdit}\nExclude Subject Keywords:${filterEdit}`
			)
		) {
			return;
		}

		await axios
			.post(getLunaUrl("v1/ed-scraper/pendingq/"), body, getNumericalHeader())
			.then((res) => {
				toast.success("Created!");
			})
			.catch((e) => toast.error("Failed: " + JSON.stringify(e.response.data)));

		getData();
		setShowEditModal(false);
	};

	const handleDelete = async (id) => {
		if (!window.confirm("Are you sure you want to delete this domain?")) {
			return;
		}
		await axios
			.delete(getLunaUrl(`v1/ed-scraper/pendingq/${id}/`), getNumericalHeader())
			.then((res) => {
				toast.success("Deleted!");
			})
			.catch((e) => console.error(e));

		getData();
	};

	const handleSwitch = () => {
		getData(!displayHist);
		setDisplayHist(!displayHist);
	};

	const expandRow = {
		renderer: (row) => {
			return (
				<>
					<Button
						variant="primary"
						style={{ marginLeft: 20 }}
						onClick={() => handleDelete(row.id)}
					>
						Delete
					</Button>
				</>
			);
		},
		showExpandColumn: true,
		expandHeaderColumnRenderer: ({ isAnyExpands }) => {
			return isAnyExpands ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
		expandColumnRenderer: ({ expanded }) => {
			return expanded ? (
				<i className="dripicons-minus" />
			) : (
				<i className="dripicons-plus" />
			);
		},
	};

	if (isLoading) {
		return (
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={1}>Pending Queue</Col>
							<Col md={2} style={{ lineHeight: "20px" }}>
								<Form.Check
									type="switch"
									id="table-switch"
									label={displayHist ? <div>History</div> : <div>Current</div>}
									checked={displayHist}
									onChange={(e) => handleSwitch()}
								/>
							</Col>
						</Row>
					</h4>
					<br />
					<h4>Loading Pending Queue...</h4>
				</CardBody>
			</Card>
		);
	}
	return (
		<div>
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Create Pending Queue</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 600, overflowY: "scroll" }}>
					<Form>
						<Form.Group className="mb-3" controlId="edit_name">
							<Form.Label size="sm">Name*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={nameEdit}
								required
								readOnly
								onChange={(e) => setNameEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_domain">
							<Form.Label size="sm">Domain*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={domainEdit}
								required
								readOnly
								onChange={(e) => setDomainEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_label">
							<Form.Label size="sm">Label*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								required
								value={labelEdit}
								onChange={(e) => setLabelEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_sending_domain">
							<Form.Label size="sm">Sending Domain*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								required
								value={sendingDomainEdit}
								onChange={(e) => setSendingDomainEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_subject">
							<Form.Label size="sm">Subject</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={subjectEdit}
								onChange={(e) => setSubjectEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_addr">
							<Form.Label size="sm">From Address</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={addrEdit}
								onChange={(e) => setAddrEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_filter">
							<Form.Label size="sm">Exclude Subject Keywords</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={filterEdit}
								onChange={(e) => setFilterEdit(e.target.value)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEditModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => createPendingQ()}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
			<Card>
				<CardBody>
					<h4 className="header-title">
						<Row>
							<Col md={1}>Pending Queue</Col>
							<Col md={2} style={{ lineHeight: "20px" }}>
								<Form.Check
									type="switch"
									id="table-switch"
									label={displayHist ? <div>History</div> : <div>Current</div>}
									checked={displayHist}
									onChange={(e) => handleSwitch()}
								/>
							</Col>
							<Col>
								<Button
									variant="primary"
									style={{ marginLeft: 20 }}
									onClick={() => {
										handleEdit();
										setShowEditModal(true);
									}}
								>
									New Feed
								</Button>
							</Col>
						</Row>
					</h4>
					<ToolkitProvider
						bootstrap4
						keyField="id"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									expandRow={expandRow}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 25,
										showTotal: true,
									})}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default PendingQTable;
