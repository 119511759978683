import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import {
	CustomToggle,
	CustomMenu,
} from "../../ReusableComponents/TypableDropdown";
import {
	getLunaUrl,
	getAirstreamUrl,
	getNumericalHeader,
	getAirstreamHeader,
	axios,
} from "../utils";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import EmailListTable from "./EmailListTable";
import PendingQTable from "./PendingQTable";
import AirstreamJob from "./AirstreamJob";

let prefix = window.location.protocol;
var corsAnywhereUrl = prefix + "//cors.ranwalk.goldbeyond.com/"
const api_key = "patjmq0m0AcDAkw9d.16ef1ef1101fc26a23f1f17f38d9721e18752cac42f75208c7f7fa066856d0ff";
const property_list_key = "appXE6yjNajItKdHz";
const email_list_key = "appJh1s79O7jBrg3D";

const EmailfeedSubscription = () => {
	const [tickers, setTickers] = useState([]);
	const [emailList, setEmailList] = useState([]);
	const [propertyList, setPropertyList] = useState([]);
	const [selectedTickerDomains, setSelectedTickerDomains] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [pendingQ, setPendingQ] = useState([]);
	const [uniqueDomains, setUniqueDomains] = useState([]);
	const [emailLoading, setEmailLoading] = useState(false);
	const [propertyLoading, setPropertyLoading] = useState(false);
	const [pendingQLoading, setpendingQLoading] = useState(false);
	const [selectedTicker, setSelectedTicker] = useState("");
	const [selectedDomain, setSelectedDomain] = useState("");
	const [showEditModal, setShowEditModal] = useState(false);
	const [domainEdit, setDomainEdit] = useState("");
	const [tickerEdit, setTickerEdit] = useState("");
	const [sectorEdit, setSectorEdit] = useState("");
	const [brandEdit, setBrandEdit] = useState("");
	const [dagId, setDagId] = useState(0);

	const getPendingQData = async (isHist = false) => {
		setpendingQLoading(true);
		await axios
			.get(
				getLunaUrl(`v1/ed-scraper/pendingq/${isHist ? "?history=true" : ""}`),
				getNumericalHeader()
			)
			.then((res) => {
				let list = [];
				let domains = [];
				for (const r of res.data.results) {
					list.push({
						...r,
						name: r.web_domain.name,
						domain: r.web_domain.domain,
					});
					domains.push(r.web_domain.domain);
				}
				setPendingQ(list);
				if (!isHist) {
					setUniqueDomains([...new Set(domains)]);
				}
				setpendingQLoading(false);
			})
			.catch((e) => console.log(e));
	};

	const getEmailListData = async () => {
		let fullList = [];
		setEmailLoading(true);
		await axios
			.get(
				`${corsAnywhereUrl}https://api.airtable.com/v0/${email_list_key}/email_list`, {
        headers: {
          'Authorization': `Bearer ${api_key}`,
          'Access-Control-Allow-Origin': '*',
        }
      })
			.then(async (response) => {
				for (let r of response.data.records) {
					fullList.push({
						...r.fields,
						createdTime: r.createdTime.substring(0, 16),
					});
				}
				if (response.data.offset) {
					await getNextPageEmailList(response.data.offset, fullList);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setEmailList(fullList);
		setEmailLoading(false);
	};

	const getNextPageEmailList = async (offset, list) => {
		await axios
			.get(
				`${corsAnywhereUrl}https://api.airtable.com/v0/${email_list_key}/email_list?offset=${offset}`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
       })
			.then(async (response) => {
				for (let r of response.data.records) {
					list.push({
						...r.fields,
						createdTime: r.createdTime.substring(0, 16),
					});
				}
				if (response.data.offset) {
					await getNextPageEmailList(response.data.offset, list);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getPropertyListData = async () => {
		let fullList = [];
		setPropertyLoading(true);
		await axios
			.get(
				`${corsAnywhereUrl}https://api.airtable.com/v0/${property_list_key}/property_list`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
        })
			.then(async (response) => {
				for (let r of response.data.records) {
					fullList.push(r.fields);
				}
				if (response.data.offset) {
					await getNextPagePropertyList(response.data.offset, fullList);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		let uniqueTickers = new Set(fullList.map((el) => el.ticker));
		uniqueTickers = Array.from(uniqueTickers);
		uniqueTickers.sort();
		setTickers(uniqueTickers);
		setPropertyList(fullList);
		setPropertyLoading(false);
	};

	const getNextPagePropertyList = async (offset, list) => {
		await axios
			.get(
				`${corsAnywhereUrl}https://api.airtable.com/v0/${property_list_key}/property_list?offset=${offset}`, {
          headers: {
            'Authorization': `Bearer ${api_key}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
			)
			.then(async (response) => {
				for (let r of response.data.records) {
					list.push(r.fields);
				}
				if (response.data.offset) {
					await getNextPagePropertyList(response.data.offset, list);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleTickerSelection = (ticker) => {
		setSelectedTicker(ticker);
		if (ticker !== "") {
			let filtered = propertyList.filter((el) => el.ticker === ticker);
			filtered.sort((a, b) => a.web_domain - b.web_domain);
			setSelectedTickerDomains(filtered);
			handleDomainSelection(filtered[0]);
		}
	};

	const handleDomainSelection = (domain) => {
		setSelectedDomain(domain);
	};

	const loadAirtables = () => {
		getEmailListData();
		getPropertyListData();
	};

	// create new domain
	const createCompany = async () => {
		if (!domainEdit || !tickerEdit || !sectorEdit || !brandEdit) {
			toast.warn("No field should be empty!");
			return;
		}

		let body = {
			web_domain: domainEdit,
			ticker: tickerEdit,
			sector: sectorEdit,
			brand_name: brandEdit,
		};

		if (
			!window.confirm(
				`Are you sure you want to create this company?\n\nDomain: ${body.web_domain}\nTicker: ${body.ticker}\nSector: ${body.sector}\nBrand Name: ${body.brand_name}`
			)
		) {
			return;
		}
		await axios
			.post(
				getLunaUrl("v1/ed-scraper/property-list/"),
				body,
				getNumericalHeader()
			)
			.then((res) => {
				toast.success("Created!");
				loadAirtables();
				setShowEditModal(false);
			})
			.catch((err) => {
				toast.error(err.response.data.error);
			});
	};

	const getDagData = async () => {
		await axios
			.get(
				getAirstreamUrl(
					"scheduler/dag/list/?key=execute_new_datasource_HIDDEN"
				),
				getAirstreamHeader()
			)
			.then((res) => {
				let data = res.data.results;
				setDagId(data.length > 0 ? data[0].graph_id : 0);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		document.title = "EmailFeed Subscription";
	}, []);

	useEffect(() => {
		loadAirtables();
		getPendingQData();
		getDagData();
	}, []);

	useEffect(() => {
		handleTickerSelection(tickers[0]);
	}, [propertyList]);

	useEffect(() => {
		if (selectedDomain && emailList.length > 0) {
			let filtered = emailList.filter(
				(el) => el.web_domain === selectedDomain.web_domain
			);
			setTableData(filtered);
		}
	}, [emailList, selectedDomain]);

	if (emailLoading || propertyLoading) {
		return (
			<div className="loader-wrapper">
				<div className="loader"></div>
			</div>
		);
	}
	return (
		<div>
			<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Create Company</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: 600, overflowY: "scroll" }}>
					<Form>
						<Form.Group className="mb-3" controlId="edit_name">
							<Form.Label size="sm">Domain*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={domainEdit}
								required
								onChange={(e) => setDomainEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_domain">
							<Form.Label size="sm">Ticker*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								value={tickerEdit}
								required
								onChange={(e) => setTickerEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_label">
							<Form.Label size="sm">Sector*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								required
								value={sectorEdit}
								onChange={(e) => setSectorEdit(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="edit_sending_domain">
							<Form.Label size="sm">Brand Name*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								required
								value={brandEdit}
								onChange={(e) => setBrandEdit(e.target.value)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEditModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => createCompany()}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
			<h4 className="page-title">EmailFeed Subscription</h4>
			<a
				href="https://goldbeyond.atlassian.net/wiki/spaces/MCC/pages/177668123/ED+Scraper+User+Guide"
				target="_blank"
			>
				User Guide {"<-"} Please read when you first use this tool.
			</a>
			<Row>
				<Col sm={12} md={12}>
					<div className="card">
						<h4
							className="header-title"
							style={{ marginTop: 20, marginLeft: 23 }}
						>
							Domain List:
						</h4>
						<div className="card-body grid-wrapper">
							<div className="task-dropdown-title">
								Ticker:
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										id="dropdown-basic"
										as={CustomToggle}
										className="task-dropdown-selected"
									>
										{selectedTicker}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenu} className="task-dropdown">
										{tickers.map((ticker) => {
											return (
												<Dropdown.Item
													key={ticker}
													eventKey={ticker}
													onClick={() => handleTickerSelection(ticker)}
												>
													{ticker}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="task-dropdown-title">
								Domain:
								<Dropdown>
									<Dropdown.Toggle
										variant="outline-dark"
										id="dropdown-basic"
										as={CustomToggle}
										className="task-dropdown-selected"
									>
										{selectedDomain.web_domain}
									</Dropdown.Toggle>

									<Dropdown.Menu as={CustomMenu} className="task-dropdown">
										{selectedTickerDomains.map((domain) => {
											return (
												<Dropdown.Item
													key={domain.web_domain}
													eventKey={domain.key}
													onClick={() => handleDomainSelection(domain)}
												>
													{domain.web_domain}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="task-dropdown-title">
								Brand Name:
								<div className="content">{selectedDomain.brand_name}</div>
							</div>
							<div className="task-dropdown-title">
								Sector:
								<div className="content">{selectedDomain.sector}</div>
							</div>
							<div className="task-dropdown-title">
								<Button
									variant="primary"
									onClick={() => {
										setShowEditModal(true);
									}}
								>
									New Domain
								</Button>
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<EmailListTable
				totalData={tableData}
				loadAirtables={loadAirtables}
			></EmailListTable>
			{/* <a
				href={window.location.origin + "/#/taskdashboard/airstream/" + dagId}
				target="_blank"
			>
				Execute this airstream job
			</a> */}
			<PendingQTable
				totalData={pendingQ}
				getData={getPendingQData}
				selectedName={selectedDomain.brand_name}
				selectedDomain={selectedDomain.web_domain}
				isLoading={pendingQLoading}
			></PendingQTable>
			<AirstreamJob uniqueDomains={uniqueDomains}></AirstreamJob>
		</div>
	);
};

export default EmailfeedSubscription;
