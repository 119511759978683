import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
	ProSidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarContent,
} from "react-pro-sidebar";
import { getLunaWebsiteUrl } from "./utils";
import "../staticfiles/css/leftsidebar.css";
import "react-pro-sidebar/dist/css/styles.css";

export default class LeftSideBar extends Component {
	state = {
		backfillDisplay: "240.7px",
		tableauDisplay: "240.7px",
		airstreamDisplay: "240.7px",
		promotionDisplay: "240.7px",
		playgroundDisplay: "240.7px",
		isToggled: false,
	};

	handleBackfill = () => {
		if (this.state.backfillDisplay === "0") {
			this.setState({ backfillDisplay: "240.7px" });
		} else {
			this.setState({ backfillDisplay: "0" });
		}
	};

	handleTableau = () => {
		if (this.state.tableauDisplay === "240.7px") {
			this.setState({ tableauDisplay: "0" });
		} else {
			this.setState({ tableauDisplay: "240.7px" });
		}
	};

	handleAirstream = () => {
		if (this.state.airstreamDisplay === "240.7px") {
			this.setState({ airstreamDisplay: "0" });
		} else {
			this.setState({ airstreamDisplay: "240.7px" });
		}
	};

	handlePromotion = () => {
		if (this.state.promotionDisplay === "240.7px") {
			this.setState({ promotionDisplay: "0" });
		} else {
			this.setState({ promotionDisplay: "240.7px" });
		}
	};

	handlePlayground = () => {
		if (this.state.playgroundDisplay === "240.7px") {
			this.setState({ playgroundDisplay: "0" });
		} else {
			this.setState({ playgroundDisplay: "240.7px" });
		}
	};

	handleClose = () => {
		if (this.props.handleClose !== null) {
			this.props.handleClose();
		}
	};

	render() {
		return (
			<ProSidebar
				style={{ position: "fixed" }}
				collapsed={this.props.isCollapsed}
				breakPoint="md"
				toggled={false}
				onToggle={this.props.toggleMenu}
			>
				{this.props.isCollapsed ? (
					<i
						class="bi bi-list-ul collapse-btn-collapsed"
						onClick={this.props.collapseMenu}
					></i>
				) : (
					<SidebarHeader
						style={{
							height: 80,
							position: "relative",
							borderBottom: "none",
							marginBottom: "30px",
						}}
					>
						<NavLink
							to="/chart/deep-discount/"
							className="logo text-center logo-light text-white"
						>
							<span className="logo-lg">
								<h1>RW APP</h1>
							</span>
						</NavLink>
						<i
							class="bi bi-list-ul collapse-btn"
							onClick={this.props.collapseMenu}
						></i>
						<NavLink to="/rw-updates" target="_blank">
							<p className="version-num">v {process.env.REACT_APP_VERSION}</p>
						</NavLink>
						<p style={{ position: "absolute", bottom: "-45px", left: "20px" }}>
							Navigation
						</p>
					</SidebarHeader>
				)}
				<SidebarContent>
					<Menu iconShape="square">
						<SubMenu
							title="Tableau"
							defaultOpen={false}
							icon={<i className="uil-presentation-lines-alt"></i>}
						>
							<MenuItem>
								<NavLink to="/downloader/promotional-analysis-ensemble/">
									Promotional Analysis Ensemble
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/downloader/quarter/">Quarterly Campaign</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/downloader/trend-incentive/">
									Brand Trend Incentive
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/downloader/trend-steep/">
									Brand Trend Steep
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/downloader/engagement-visits-yoy/index/">
									Engagement Index
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/downloader/engagement-visits-yoy/raw/">
									Engagement Raw
								</NavLink>
							</MenuItem>
						</SubMenu>
						<SubMenu
							title="Charts"
							defaultOpen={true}
							icon={<i className="uil-chart"></i>}
						>
							<MenuItem>
								<NavLink to="/composite-chart">Composite Chart</NavLink>
							</MenuItem>
							{/* <MenuItem>
								<NavLink to="/chart/deep-discount/">Campaign Discount</NavLink>
							</MenuItem> */}
							<MenuItem>
								<NavLink to="/chart/deep-discount-react/">
									Harmonic Discount
								</NavLink>
							</MenuItem>
							{/* <MenuItem>
                <NavLink to="/promotion-indexing-beta/">
                  Promotion Indexing (beta version)
                </NavLink>
              </MenuItem> */}
							<MenuItem>
								<NavLink to="/promotion-indexing-stable/">
									Promotion Indexing
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/promotion-indexing-by-category/">
									Category Indexing
								</NavLink>
							</MenuItem>
							{/* <MenuItem>
								<NavLink to="/email-volume-trend/">Email Volume Trends</NavLink>
							</MenuItem> */}
						</SubMenu>
						<MenuItem icon={<i className="uil-exchange"></i>}>
							<NavLink to="/category-map/">Category Map</NavLink>
						</MenuItem>
						<MenuItem icon={<i className="uil-document"></i>}>
							<NavLink to="/emailfeed-data">Emailfeed Onepager</NavLink>
						</MenuItem>
						<SubMenu
							title="Playgrounds"
							defaultOpen={false}
							icon={<i class="uil-football-ball"></i>}
						>
							<MenuItem>
								<NavLink to="/rw-rule-playground/">Rule Playground</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/emailfeed-sub/">EmailFeed Subscription</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/search/">Campaign Search</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/rule-result/">System Rule Result</NavLink>
							</MenuItem>
							{/* <MenuItem>
								<NavLink to="/rule-playground/">
									Rule Playground (Legacy)
								</NavLink>
							</MenuItem> */}
						</SubMenu>
						<SubMenu
							title="Logs"
							defaultOpen={false}
							icon={<i className="uil-cloud-computing"></i>}
						>
							<MenuItem>
								<NavLink to="/log/">Action Log</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/backfill-summary/text-annotation/">
									Text Annotation
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/backfill-summary/category/">
									Email Category
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/backfill-summary/discount/">
									Deep Discount
								</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/backfill/">Backfill Details</NavLink>
							</MenuItem>
						</SubMenu>
						<SubMenu
							title="Montior"
							defaultOpen={true}
							icon={<i className="uil-desktop"></i>}
						>
							<MenuItem>
								<NavLink to="/monitor/">Celery Monitor</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/taskdashboard/">Airstream</NavLink>
							</MenuItem>
						</SubMenu>
						<SubMenu
							title="Beta"
							defaultOpen={true}
							icon={<i className="uil-flask"></i>}
						>
							<MenuItem>
								<NavLink to="/billboard">Billboard</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink to="/campaignSearch">Search</NavLink>
							</MenuItem>
						</SubMenu>
						{this.props.isCollapsed ? (
							""
						) : (
							<p style={{ padding: "8px 35px 8px 30px" }}>Documentation</p>
						)}
						<MenuItem icon={<i className="uil-document"></i>}>
							<NavLink to="/rw-updates" target="_blank">
								Software Updates
							</NavLink>
						</MenuItem>
						<MenuItem icon={<i className="uil-document"></i>}>
							<a href={getLunaWebsiteUrl("/document/all-api/")} target="_blank">
								Luna API
							</a>
						</MenuItem>
						<MenuItem icon={<i className="uil-document"></i>}>
							<a
								href={getLunaWebsiteUrl("/document/client-api/")}
								target="_blank"
							>
								Client API
							</a>
						</MenuItem>
						<MenuItem icon={<i className="uil-document"></i>}>
							<a href={getLunaWebsiteUrl("/document/start/")} target="_blank">
								Email Feed API
							</a>
						</MenuItem>
					</Menu>
				</SidebarContent>
			</ProSidebar>
		);
	}
}
