import React, { useState, useEffect, useRef, memo } from "react";
import * as d3 from "d3";
import * as dagreD3 from "dagre-d3";

// Create a new directed graph
var g = new dagreD3.graphlib.Graph().setGraph({});

const DagChart = memo(
	({
		tasks,
		edges,
		displayParent,
		graphId,
		setIsNodeClicked,
		setSelectedNode,
		setSelectedTask,
		setSelectedResult,
		setSelectedResultKey,
		selectExecution,
		setSelectExecutionList,
		isLoading,
	}) => {
		/**
		 * Store the tasks and edges for current graph
		 */
		const [graphSVG, setSVG] = useState("");
		const [graphInner, setInner] = useState("");

		/**
		 * A boolean to store if the use makes changes to the graph. This
		 * is necessary because we only want to update the graph if a new graph
		 * is formed
		 */
		const [graphModification, setGraphModification] = useState(false);

		/**
		 * The previous states for the graph. This must be stored because
		 * we can only update the graph once both the tasks and edges have
		 * been set to the new state.
		 */
		const previousValues = useRef({ tasks, edges });

		const center = () => {
			var initialScale = 0.75;
			var zoom = d3.zoom().on("zoom", function (e) {
				graphInner.attr("transform", e.transform);
			});
			graphSVG.call(zoom.transform, d3.zoomIdentity.scale(initialScale));
		};

		/**
		 * Removing old graph from canvas
		 */
		useEffect(() => {
			setGraphModification(true);
			d3.select("#svg-canvas").selectAll("*").remove();
		}, [selectExecution, graphId]);

		/**
		 * Create a new graph everytime the tasks and edges changes
		 */
		useEffect(() => {
			if (tasks.length === 0) return;
			if (
				isLoading !== undefined ||
				(graphModification &&
					previousValues.current.tasks !== tasks &&
					previousValues.current.edges !== edges)
			) {
				setGraphModification(false);
				g = new dagreD3.graphlib.Graph().setGraph({});
				tasks.forEach((task) => {
					var value = { task };
					value.label = task.name;
					value.rx = value.ry = 5;
					g.setNode(task.task_id, value);

					// scheduled = gray, running = orange, success = green, failed = red
					if (displayParent) {
						g.node(task.task_id).style = "fill: #9fb4c2";
					} else {
						if (task.status === 0) {
							g.node(task.task_id).style = "fill: #9fb4c2";
						} else if (task.status === 1) {
							g.node(task.task_id).style = "fill: #FFA500";
						} else if (task.status === 2) {
							g.node(task.task_id).style = "fill: #4ce060";
						} else {
							g.node(task.task_id).style = "fill: #f54242";
						}
					}
				});
				edges.forEach((edge) => {
					g.setEdge(edge.task_from, edge.task_to, { label: "" });
				});

				// Create the renderer
				var render = new dagreD3.render();

				// Set up an SVG group so that we can translate the final graph.
				var svg = d3.select("svg"),
					inner = svg.append("g");

				svg
					.append("circle")
					.attr("cx", "70%")
					.attr("cy", 20)
					.attr("r", 6)
					.style("fill", "#9fb4c2");
				svg
					.append("circle")
					.attr("cx", "70%")
					.attr("cy", 40)
					.attr("r", 6)
					.style("fill", "#FFA500");
				svg
					.append("circle")
					.attr("cx", "70%")
					.attr("cy", 60)
					.attr("r", 6)
					.style("fill", "#4ce060");
				svg
					.append("circle")
					.attr("cx", "70%")
					.attr("cy", 80)
					.attr("r", 6)
					.style("fill", "#f54242");
				svg
					.append("text")
					.attr("x", "72%")
					.attr("y", 20)
					.text("Scheduled")
					.style("font-size", "15px")
					.attr("alignment-baseline", "middle");
				svg
					.append("text")
					.attr("x", "72%")
					.attr("y", 40)
					.text("Running")
					.style("font-size", "15px")
					.attr("alignment-baseline", "middle");
				svg
					.append("text")
					.attr("x", "72%")
					.attr("y", 60)
					.text("Finished")
					.style("font-size", "15px")
					.attr("alignment-baseline", "middle");
				svg
					.append("text")
					.attr("x", "72%")
					.attr("y", 80)
					.text("Failed")
					.style("font-size", "15px")
					.attr("alignment-baseline", "middle");

				setSVG(svg);
				setInner(inner);
				// Set up zoom support
				var zoom = d3.zoom().on("zoom", function (e) {
					inner.attr("transform", e.transform);
				});
				svg.call(zoom);

				if (tasks.length !== 0) {
					// Run the renderer. This is what draws the final graph.
					render(inner, g);
				}

				let tempExecutionList = [];

				const handleClick = (e, node) => {
					if (selectExecution) {
						if (tempExecutionList.includes(parseInt(node))) {
							tempExecutionList = tempExecutionList.filter((execution) => {
								return execution !== parseInt(node);
							});
						} else {
							tempExecutionList = [...tempExecutionList, parseInt(node)];
						}

						setSelectExecutionList([tempExecutionList]);
					} else {
						setIsNodeClicked(true);
						const task = tasks.filter((task) => task.task_id == node)[0];
						setSelectedNode(task.name);
						setSelectedTask(task);
						setSelectedResultKey(null);
						setSelectedResult(null);
					}
				};

				inner.selectAll("g.node").on("click", handleClick);
				//   .on("mouseover", handleMouseOver)
				//   .on("mouseout", handleMouseOut);

				// Center the graph
				var initialScale = 0.75;
				svg.call(
					zoom.transform,
					d3.zoomIdentity
						//.translate((svg.attr("width") - g.graph().width * initialScale) / 2, 20)
						.scale(initialScale)
				);

				if (g.graph() !== null || g.graph() !== undefined) {
					svg.attr("height", g.graph().height * initialScale + 40);
				} else {
					svg.attr("height", 40);
				}

				/**
				 * Reset the conditions
				 */
				previousValues.current = { tasks, edges };
				setGraphModification(false);
			}
		}, [selectExecution, tasks, edges, isLoading]);

		return (
			<>
				<button style={{ marginBottom: 20 }} onClick={center}>
					Center
				</button>
				<svg id="svg-canvas" className="task-flow-dashboard" />
			</>
		);
	}
);

export default DagChart;
