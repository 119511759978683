import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../Dashboard";
import App from "../App";
import { createBrowserHistory } from "history";
import { ToastContainer, Zoom } from "react-toastify";

const customHistory = createBrowserHistory();

const BasicRoute = () => (
	<>
		<HashRouter history={customHistory}>
			<Switch>
				<Route exact path="/" component={App} />
				<Dashboard>
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
				</Dashboard>
			</Switch>
		</HashRouter>
		<ToastContainer
			containerId="toastapp"
			position="top-center"
			transition={Zoom}
			style={{ fontSize: "18px", fontWeight: "bold" }}
			draggable={false}
			hideProgressBar={true}
			autoClose={3000}
		/>
	</>
);

export default BasicRoute;
