import React, { useMemo, useEffect } from "react";
import * as d3 from "d3";

const PieChart = (props) => {
	const { data, outerRadius, innerRadius } = props;

	const margin = {
		top: 50,
		right: 50,
		bottom: 50,
		left: 50,
	};

	const width = 2 * outerRadius + margin.left + margin.right;
	const height = 2 * outerRadius + margin.top + margin.bottom;

	const colorScale = d3
		.scaleSequential()
		.interpolator(d3.interpolateCool)
		.domain([0, data.length]);

	useEffect(() => {
		drawChart();
	}, []);

	useMemo(() => {
		drawChart();
	}, [data[0].value, data[1].value, data[2].value]);

	function drawChart() {
		// Remove the old svg
		d3.select("#pie-container").select("svg").remove();

		// Create new svg
		const svg = d3
			.select("#pie-container")
			.append("svg")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		const arcGenerator = d3
			.arc()
			.innerRadius(innerRadius)
			.outerRadius(outerRadius);

		const pieGenerator = d3
			.pie()
			.padAngle(0)
			.value((d) => d.value);

		const arc = svg.selectAll().data(pieGenerator(data)).enter();

		const sectionColor = (i) => {
			if (data[i].label === "Finished") {
				return "#4ce060";
			}
			if (data[i].label === "Scheduled") {
				return "#9fb4c2";
			}
			if (data[i].label === "Running") {
				return "#FFA500";
			}
			return "#f54242";
		};

		// // Append arcs
		arc
			.append("path")
			.attr("d", arcGenerator)
			.style("fill", (_, i) => sectionColor(i))
			.style("stroke", "#ffffff")
			.style("stroke-width", 0);

		// Append text labels
		arc
			.append("text")
			.attr("text-anchor", "middle")
			.attr("alignment-baseline", "middle")
			.text((d) => {
				if (d.data.value !== 0) {
					return d.data.label;
				}
			})
			.style("fill", "#3e4039")
			.style("font family", 'Nunito", sans-serif')
			.attr("transform", (d) => {
				const [x, y] = arcGenerator.centroid(d);
				return `translate(${x}, ${y})`;
			});
	}

	return <div id="pie-container" />;
};

export default React.memo(PieChart);
