import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import {
	formatBigNum,
	chartWatermark,
	chartBgc,
	chartDataSorter,
} from "../utils";
import moment from "moment";

const EmailIndexTrendChart = ({
	dataFetched,
	isLoading,
	chartData,
	startDate,
	endDate,
	title,
	graphRef,
	totalData,
	setTotalData,
	displayedDates,
	setDisplayedDates,
}) => {
	useEffect(() => {
		const loadDisplayData = () => {
			let tempTotalData = [];
			let tempDateData = [];

			chartData.sort(chartDataSorter);

			for (let i = 0; i < chartData.length; i++) {
				if (
					new Date(chartData[i].start_date) >= new Date(startDate) &&
					new Date(chartData[i].end_date) <= new Date(endDate)
				) {
					tempTotalData.push(chartData[i].adjusted_total_volume);
					tempDateData.push(chartData[i].end_date);
				}
			}

			let buffer_start = moment(tempDateData[0]).subtract(14, "d");
			let buffer_end = moment(tempDateData[tempDateData.length - 1]).add(
				14,
				"d"
			);
			tempDateData.unshift(buffer_start.format("YYYY-MM-DD"));
			tempDateData.push(buffer_end.format("YYYY-MM-DD"));
			tempTotalData.push(null);
			tempTotalData.unshift(null);

			setTotalData(tempTotalData);
			setDisplayedDates(tempDateData);
		};
		loadDisplayData();
	}, [chartData, startDate, endDate]);

	if (!dataFetched) {
		return <div></div>;
	} else if (isLoading) {
		return <h3 style={{ textAlign: "center" }}>Chart Loading...</h3>;
	}

	return (
		<Col sm={12}>
			<React.Fragment>
				<Line
					ref={graphRef}
					className="history-container"
					height={300}
					data={{
						labels: displayedDates,
						datasets: [
							{
								data: totalData,
								label: "Total",
								borderColor: "#0000FF",
								pointRadius: 0,
							},
						],
					}}
					options={{
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							xAxes: [
								{
									gridLines: {
										display: false,
									},
									type: "time",
									time: {
										unit: "month",
									},
								},
							],
							yAxes: [
								{
									id: "A",
									type: "linear",
									position: "left",
									ticks: {
										callback: (value) => formatBigNum(value),
									},
									gridLines: {
										borderDash: [8, 4],
									},
								},
							],
						},
						title: {
							text: title,
							fontSize: 15,
							display: true,
						},
					}}
					plugins={[chartBgc, chartWatermark]}
				/>
			</React.Fragment>
		</Col>
	);
};

export default React.memo(EmailIndexTrendChart);
