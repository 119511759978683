import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
	getNumericalHeader,
	getLunaUrl,
	processNextpageUrl,
	axios,
} from "./utils";
import { toast } from "react-toastify";
import moment from "moment";

//app components
import LeftSideBar from "./LeftSideBar";
import Topbar from "./Topbar";
import Footer from "./Footer";
import CeleryMonitor from "./CeleryMonitor/CeleryMonitor";
import OnePager from "./OnePager";
import Downloader from "./Downloader";
import CategoryMap from "./CategoryMap";
import TaskDashBoard from "./TaskDashboard";
import RulePlayground from "./RulePlayground";
import UpdatePage from "./UpdatePage";
import ChartPanel from "./PromotionIndexing";
import EmailVolumePage from "./EmailVolumePage";
import EmailCategoryIndexPage from "./EmailCategoryIndexPage";
import CompositeChart from "./CompositeChart";
import CampaignDiscount from "./HarmonicDiscount";
import RuleEdit from "./RulePlayground/RuleEdit";
import ActionLog from "./ActionLog";
import EmailfeedOnepager from "./EmailfeedOnepager";
import EmailfeedSubscription from "./EmailfeedSubscription";
import Billboard from "./Billboard";
import CampaignSearch from "./CampaignSearch";

import { getLunaWebsiteUrl } from "./utils";

//css
import "../staticfiles/css/common.css";
import "../staticfiles/plugins/datatables/css/datatables.min.css";
import "../staticfiles/plugins/datatables/css/editor.dataTables.min.css";
// import '../staticfiles/plugins/hyper/css/app-dark.css'
import "../staticfiles/plugins/hyper/css/app.css";
import "../staticfiles/plugins/hyper/css/icons.min.css";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";

export default class Dashboard extends Component {
	state = {
		isCollapsed: false,
		isMobile: false,
		isToggled: false,
		domains: [],
		tkrs: [],
		tickerCate: {},
		dataFetched: false,
		domainFetched: false,
		earliestFI: "",
		earliestYoy: "",
		FIstatus: "idle",
		Yoystatus: "idle",
		lastSundayFI: "",
		lastSundayYoy: "",
	};

	componentDidMount() {
		window.$ = $;
	}

	fetchDomains = async () => {
		let allDomains = [];
		let tkrs = [];
		await axios
			.get(
				getLunaUrl("v1/emailfeed/company/?page_size=1000"),
				getNumericalHeader()
			)
			.then(async (response) => {
				for (let i = 0; i < response.data.results.length; i++) {
					let tempData = response.data.results[i];
					allDomains.push({
						name: tempData.name,
						domain: tempData.domain,
						parent_domain: tempData.parent_domain,
						id: tempData.id,
						ticker: tempData.ticker,
						sim_category: tempData.sim_category,
            is_deprecated: tempData.is_deprecated
					});
					if (!tkrs.includes(tempData.ticker)) {
						tkrs.push(tempData.ticker);
					}
				}
				if (response.data.next !== null) {
					await this.getNextPageDomains(
						processNextpageUrl(response.data.next),
						allDomains
					);
				}
				this.setState({ domains: allDomains, tkrs: tkrs.sort() });
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.setState({ domainFetched: true });
			});
	};

	getNextPageDomains = async (url, allDomains, tkrs) => {
		await axios
			.get(url, getNumericalHeader())
			.then(async (response) => {
				for (let i = 0; i < response.data.results.length; i++) {
					let tempData = response.data.results[i];
					allDomains.push({
						name: tempData.name,
						domain: tempData.domain,
						id: tempData.id,
						ticker: tempData.ticker,
						parent_domain: tempData.parent_domain,
						sim_category: tempData.sim_category,
					});
					if (!tkrs.includes(tempData.ticker)) {
						tkrs.push(tempData.ticker);
					}
				}
				if (response.data.next !== null) {
					await this.getNextPageDomains(
						processNextpageUrl(response.data.next),
						allDomains,
						tkrs
					);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	fetchTickerCate = async () => {
		await axios
			.get(
				getLunaUrl("v1/emailfeed/company/category_list"),
				getNumericalHeader()
			)
			.then((res) => {
				let tmp = {};
				Object.assign(tmp, res.data);
				this.setState({ tickerCate: tmp });
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.setState({ dataFetched: true });
			});
	};

	checkLastUpdate = async () => {
		const domains = [
			"macys.com",
			"amazon.com",
			"bestbuy.com",
			"bedbathandbeyond.com",
			"zumiez.com",
		];
		let endpoints = domains.map((domain) =>
			getLunaUrl(`v1/email-index/query/?domain=${domain}`)
		);
		try {
			let yoyEndpoints = [];
			for (let domain of domains) {
				await this.getYoyEndpoint(domain, yoyEndpoints);
			}

			let lastSunday = moment()
				.tz("America/Los_Angeles")
				.day(0)
				.format("YYYY-MM-DD");

			let res = await Promise.all(
				endpoints.map((ept) => axios.get(ept, getNumericalHeader()))
			);
			let lastSundayFI = moment(lastSunday);
			if (moment().tz("America/Los_Angeles").day() < 2) {
				lastSundayFI.subtract(7, "d");
			}
			let lastSundayYoy = moment(lastSunday);
			if (moment().tz("America/Los_Angeles").day() < 3) {
				lastSundayYoy.subtract(7, "d");
			}
			let emailFlag = false;
			let earliestFI = Infinity;
			res.forEach((r) => {
				let arr = r.data.data;
				let lastPoint = moment(arr[arr.length - 1].end_date);
				if (
					lastPoint.format("YYYY-MM-DD") !== lastSunday &&
					moment().tz("America/Los_Angeles").day() >= 2
				) {
					emailFlag = true;
				}
				earliestFI = Math.min(earliestFI, lastPoint.valueOf());
			});
			if (emailFlag) {
				toast.warn("Email Feed Last Updated datapoint is not Sunday!");
			}
			let yoyres = await Promise.all(
				yoyEndpoints.map((ept) => axios.get(ept, getNumericalHeader()))
			);
			let webFlag = false;
			let earliestYoy = Infinity;
			yoyres.forEach((r) => {
				let arr = r.data.results;
				let lastPoint = moment(arr[arr.length - 1].end_date);
				if (
					lastPoint.format("YYYY-MM-DD") !== lastSunday &&
					moment().tz("America/Los_Angeles").day() >= 3
				) {
					webFlag = true;
				}
				earliestYoy = Math.min(earliestYoy, lastPoint.valueOf());
			});
			if (webFlag) {
				toast.warn("WebEngagement Last Updated datapoint is not Sunday!");
			}

			earliestYoy =
				earliestYoy === Infinity
					? lastSunday
					: moment(earliestYoy).format("YYYY-MM-DD");
			earliestFI =
				earliestFI === Infinity
					? lastSunday
					: moment(earliestFI).format("YYYY-MM-DD");
			this.setState({
				earliestFI,
				earliestYoy,
				FIstatus: emailFlag ? "late" : "valid",
				Yoystatus: webFlag ? "late" : "valid",
				lastSundayFI: lastSundayFI.format("YYYY-MM-DD"),
				lastSundayYoy: lastSundayYoy.format("YYYY-MM-DD"),
			});
		} catch (err) {
			console.log(err);
		}
	};

	getYoyEndpoint = async (domain, arr) => {
		if (!domain || !arr) {
			return;
		}

		try {
			let res = await axios.get(
				getLunaUrl(
					`v1/web-engagement-derived/yony/?domain=${domain}&source_type=visits&granularity=weekly&value_type=index`
				),
				getNumericalHeader()
			);
			const pageNum = Math.ceil(res.data.count / 50);
			arr.push(
				getLunaUrl(
					`v1/web-engagement-derived/yony/?domain=${domain}&source_type=visits&granularity=weekly&value_type=index&page=${pageNum}`
				)
			);
		} catch (err) {
			console.log(err);
		}
	};

	toggleMobileMenu = () => {
		this.setState({ isToggled: !this.state.isToggled });
	};

	collapseMenu = () => {
		this.setState({ isCollapsed: !this.state.isCollapsed });
	};

	componentDidMount() {
		window.addEventListener(
			"resize",
			() => {
				window.innerWidth < 1200 && window.innerWidth > 768
					? this.setState({ isCollapsed: true })
					: this.setState({ isCollapsed: false });
				window.innerWidth <= 768
					? this.setState({ isMobile: true })
					: this.setState({ isMobile: false });
			},
			false
		);
		if (this.state.dataFetched) return;
		this.fetchDomains();
		this.fetchTickerCate();
		this.checkLastUpdate();
	}

	render() {
		if (
			!localStorage.getItem("RWLoginToken") ||
			!localStorage.getItem("RWExpireDate")
		) {
			return <Redirect to="/"></Redirect>;
		} else {
			return (
				<div className="wrapper">
					{/* Left Sidebar*/}
					{this.state.isMobile && !this.state.isToggled ? (
						<button
							className="button-menu-mobile open-left btn-toggle"
							onClick={this.toggleMobileMenu}
						>
							<i className="mdi mdi-menu"></i>
						</button>
					) : (
						""
					)}
					<LeftSideBar
						isCollapsed={this.state.isCollapsed}
						isToggled={this.state.isToggled}
						toggleMenu={this.toggleMobileMenu}
						collapseMenu={this.collapseMenu}
					></LeftSideBar>
					{/* Start Page Content here */}
					<div
						className="content-page"
						style={{ marginLeft: this.state.isCollapsed ? "80px" : "270px" }}
					>
						<div className="content">
							<Topbar></Topbar>
							{/* Content section */}
							<div className="container-fluid">
								<Switch>
									<Route path="/tableau/" component={OnePager}></Route>
									<Route path="/category-map/" component={CategoryMap}></Route>
									<Route
										path="/downloader/onepager/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="onepager"
											/>
										)}
									></Route>
									<Route
										path="/downloader/quarter/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												category={false}
												folder="quarterly_campaign_pdf_buffer"
											/>
										)}
									></Route>
									<Route
										path="/downloader/trend-incentive/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="brand_trend_incentive_pdf_buffer"
											/>
										)}
									></Route>
									<Route
										path="/downloader/trend-steep/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="brand_trend_steep_pdf_buffer"
											/>
										)}
									></Route>
									<Route
										path="/downloader/engagement-visits-yoy/raw/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="web_engagement_raw_visits"
												category={false}
												source={true}
											/>
										)}
									></Route>
									<Route
										path="/downloader/engagement-visits-yoy/index/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="web_engagement_idx_visits"
												category={false}
												source={true}
											/>
										)}
									></Route>
									<Route
										path="/downloader/promotional-analysis-ensemble/"
										render={(props) => (
											<Downloader
												timestamp={new Date().toString()}
												folder="spotlight_image_dash"
												category={true}
												source={false}
												isSummary={true}
											/>
										)}
									></Route>
									<Route
										path="/chart/deep-discount/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/chart/deep-discount/")
											);
											return null;
										}}
									></Route>
									<Route path="/chart/deep-discount-react/">
										<CampaignDiscount
											uniqueDomains={this.state.domains}
											tickerCate={this.state.tickerCate}
											lastSundayFI={this.state.lastSundayFI}
											loaded={
												this.state.dataFetched && this.state.domainFetched
											}
										></CampaignDiscount>
									</Route>
									<Route path="/emailfeed-sub/">
										<EmailfeedSubscription></EmailfeedSubscription>
									</Route>
									<Route
										path="/search/"
										component={() => {
											window.location.replace(getLunaWebsiteUrl("/search/"));
											return null;
										}}
									></Route>
									<Route
										path="/rule-result/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/rule-result/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/model/discount-parser/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/model/discount-parser/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/rule-playground/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/rule-playground/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/backfill-summary/text-annotation/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/backfill-summary/text-annotation/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/backfill-summary/category/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/backfill-summary/category/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/backfill-summary/discount/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/backfill-summary/discount/")
											);
											return null;
										}}
									></Route>
									<Route
										path="/backfill/"
										component={() => {
											window.location.replace(getLunaWebsiteUrl("/backfill/"));
											return null;
										}}
									></Route>
									<Route
										path="/document/discount/"
										component={() => {
											window.location.replace(
												getLunaWebsiteUrl("/document/discount/")
											);
											return null;
										}}
									></Route>
									<Route path="/monitor/" component={CeleryMonitor}></Route>
									<Route path="/taskdashboard/airstream/:dag_id">
										<TaskDashBoard isEdScraper={true} />
									</Route>
									<Route
										path="/taskdashboard/:id"
										component={TaskDashBoard}
									></Route>
									<Route
										path="/taskdashboard/"
										component={TaskDashBoard}
									></Route>
									<Route path="/rw-rule-playground">
										<RulePlayground
											uniqueDomains={this.state.domains}
											tickers={this.state.tkrs}
											loaded={this.state.domainFetched}
										></RulePlayground>
									</Route>
									<Route path="/rw-updates" component={UpdatePage}></Route>
									<Route path="/promotion-indexing-stable/">
										<ChartPanel
											uniqueDomains={this.state.domains}
											loaded={this.state.domainFetched}
										></ChartPanel>
									</Route>
									<Route path="/promotion-indexing-by-category/">
										<EmailCategoryIndexPage
											uniqueDomains={this.state.domains}
											tickers={this.state.tkrs}
											tickerCate={this.state.tickerCate}
											loaded={
												this.state.dataFetched && this.state.domainFetched
											}
										></EmailCategoryIndexPage>
									</Route>
									<Route path="/email-volume-trend">
										<EmailVolumePage
											uniqueDomains={this.state.domains}
											tickers={this.state.tkrs}
											tickerCate={this.state.tickerCate}
											loaded={
												this.state.dataFetched && this.state.domainFetched
											}
										></EmailVolumePage>
									</Route>
									<Route path="/composite-chart">
										<CompositeChart
											uniqueDomains={this.state.domains}
											tickers={this.state.tkrs}
											tickerCate={this.state.tickerCate}
											earliestFI={this.state.earliestFI}
											earliestYoy={this.state.earliestYoy}
											FIstatus={this.state.FIstatus}
											Yoystatus={this.state.Yoystatus}
											lastSundayFI={this.state.lastSundayFI}
											lastSundayYoy={this.state.lastSundayYoy}
											loaded={
												this.state.dataFetched && this.state.domainFetched
											}
										></CompositeChart>
									</Route>
									<Route path="/rule-edit/:id" component={RuleEdit}></Route>
									<Route path="/log/">
										<ActionLog
											uniqueDomains={this.state.domains}
											tickers={this.state.tkrs}
											loaded={this.state.domainFetched}
										></ActionLog>
									</Route>
									<Route
										path="/emailfeed-data/"
										component={EmailfeedOnepager}
									></Route>
									<Route path="/billboard">
										<Billboard uniqueDomains={this.state.domains}></Billboard>
									</Route>
									<Route path="/campaignSearch">
										<CampaignSearch
											uniqueDomains={this.state.domains}
											tickerCate={this.state.tickerCate}
											loaded={
												this.state.dataFetched && this.state.domainFetched
											}
										></CampaignSearch>
									</Route>
									<Redirect
										path="/"
										to="downloader/promotional-analysis-ensemble/"
										exact
									></Redirect>
									{/* <Redirect from='/promotion-indexing-by-category/' to='/composite-chart' exact></Redirect> */}
								</Switch>
							</div>
						</div>
					</div>
					{/* footer */}
					<Footer></Footer>
					{/* <ToastContainer
						containerId="toastapp"
						position="top-center"
						transition={Zoom}
						style={{ fontSize: "18px", fontWeight: "bold" }}
						draggable={false}
						hideProgressBar={true}
						autoClose={5000}
					/> */}
				</div>
			);
		}
	}
}
