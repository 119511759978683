import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const EmailListTable = ({ totalData, loadAirtables }) => {
	const { SearchBar } = Search;

	const columns = [
		{
			dataField: "key",
			hidden: true,
		},
		{
			dataField: "fshort_formula",
			hidden: true,
		},
		{
			dataField: "web_domain",
			text: "Web Domain",
			sort: true,
		},
		{
			dataField: "sending_domain",
			text: "Sending Domain",
			sort: true,
		},
		{
			dataField: "label",
			text: "Label",
			sort: true,
		},
		{
			dataField: "fshort",
			text: "Fshort",
			sort: true,
		},
		{
			dataField: "subject",
			text: "Subject",
			sort: true,
		},
		{
			dataField: "createdTime",
			text: "Created Time",
			sort: true,
		},
		{
			dataField: "from_address",
			text: "From Address",
			sort: true,
		},
		{
			dataField: "active_sending_domain",
			text: "Active sending Domain",
			sort: true,
		},
	];

	return (
		<div>
			<Card>
				<CardBody>
					<Row>
						<Col md={2}>
							<h4 className="header-title">Email List</h4>
						</Col>
					</Row>
					<ToolkitProvider
						bootstrap4
						keyField="key"
						data={totalData}
						columns={columns}
						search
					>
						{(props) => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
								</Row>
								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									rowStyle={{ fontWeight: "bold" }}
									pagination={paginationFactory({
										sizePerPage: 25,
										showTotal: true,
									})}
									wrapperClasses="table-responsive"
								/>
							</React.Fragment>
						)}
					</ToolkitProvider>
				</CardBody>
			</Card>
		</div>
	);
};

export default EmailListTable;
